import React, { ReactElement } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Box, Button } from '@mui/material';
import redAlert from 'src/assets/redAlert.png';
import ModalBase from './ModalBase';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '500px',
    padding: 20,
  },
}));

function NoTelematicsModal(props: ModalProps): ReactElement {
  const { open, onClose, onClick } = props;

  const classes = useStyles();

  return (
    <ModalBase open={open} onClose={onClose} className={classes.root}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Close
            onClick={onClose}
            style={{
              display: 'flex',
              alignSelf: 'flex-end',
              cursor: 'pointer',
            }}
          />
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            rowGap: '20px',
            padding: '0px 20px 20px 20px',
          }}
        >
          <img src={redAlert} alt="alert" />
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '32px',
              lineHeight: '35.2px',
              fontWeight: 600,
              color: '#4C504D',
            }}
          >
            Sorry, it seems you don’t have access to our Telematics Features.
          </Typography>

          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              lineHeight: '19.2px',
              color: '#4C504D',
            }}
          >
            Our Telematics customers see vehicles on a GPS map real-time & get
            Alerts on their Vehicle Health to save on Repairs.
          </Typography>

          <Button
            fullWidth
            variant="contained"
            sx={{
              fontFamily: 'Inter',
              backgroundColor: '#005959',
              color: 'white',
              textTransform: 'none',
              borderRadius: '8px',
              padding: '10px 16px',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 500,
              height: '40px',
              ':hover': {
                backgroundColor: '#005959',
              },
            }}
            onClick={onClick}
          >
            Interested in learning more
          </Button>
        </div>
      </Box>
    </ModalBase>
  );
}

export default NoTelematicsModal;
