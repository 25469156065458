import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@material-ui/core';
import clsx from 'clsx';
import { IService } from 'src/types';
import {
  ExpandMore,
  RadioButtonUncheckedRounded,
  RemoveCircle,
} from '@material-ui/icons';
import { estimateItemStatus, setAppointment } from 'src/store/actions';
import useFontStyles from '../../../fontStyles';
import {
  getAppointment,
  removeEstimateService,
  updateEstimateServiceStatus,
} from '../../../../../../api/quote';

const useStyles = makeStyles((theme) => ({
  root: {
    '.MuiAccordion-root::before': {
      display: 'none',
    },
    margin: '16px 0',
    boxShadow: 'none',
    '& .MuiPaper-root': {
      borderRadius: '10px',
    },
  },
  otherRoot: {
    '&.MuiAccordion-root:before': {
      height: 0,
    },
  },
  accordionSummary: {
    borderRadius: '10px 10px 10px 10px',
    background: '#EBF1FA',
    padding: '20px',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    alignItems: 'center',
  },
  accordionDetails: {
    marginBottom: '10px',
    padding: '20px',
    display: 'block',
    color: '#474747',
  },
  dollarSign: {
    color: '#000',
  },
  row: {
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '4px',
    },
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px',
  },
  iconServiceItemInactive: {
    color: '#1B1212',
    marginRight: theme.spacing(1),
    width: 20,
    height: 20,
  },
  rejectBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  iconServiceItem: {
    color: '#DC7979',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 20,
    height: 20,
  },
}));

interface ServiceProps {
  service: IService;
  amtServices: number;
  appId: number;
  memberJob: boolean | null;
}

function ServiceCard(props: ServiceProps): React.ReactElement {
  const { service, amtServices, appId, memberJob } = props;
  const serviceName = Object.keys(service)[0];
  const serviceDetails = service[serviceName];
  const classes = useStyles();
  const fontClasses = useFontStyles();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(true);

  const displayPrice = () => {
    if (!memberJob) {
      return serviceDetails.total_price.toFixed(2);
    }

    return serviceDetails.total_member_price.toFixed(2);
  };

  const asyncReadAppointment = async () => {
    const appointment = await getAppointment(String(appId));
    dispatch(setAppointment(appointment.data));
  };

  const updateStatus = async (statusUpdate: string) => {
    const data = {
      estimate_service: {
        status: statusUpdate,
      },
    };

    updateEstimateServiceStatus(serviceDetails.id, data).then(() => {
      asyncReadAppointment();
      dispatch(estimateItemStatus(serviceName, statusUpdate));
      setToggle(!toggle);
    });
  };

  const removeService = (id: number) => {
    removeEstimateService(id).then((res) => {
      if (res) {
        if (res.data) {
          dispatch(setAppointment(res.data));
        }
      }
    });
  };

  return (
    <Accordion
      classes={{ root: classes.root }}
      className={classes.otherRoot}
      key={serviceDetails.id}
      defaultExpanded
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
        style={
          serviceDetails.status === 'rejected'
            ? { textDecoration: 'line-through', color: '#B1B1B1' }
            : {}
        }
      >
        <div className={clsx(fontClasses.h4, classes.cardTitle)}>
          {serviceName}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={fontClasses.h4} style={{ display: 'flex' }}>
            <span className={classes.dollarSign} style={{ marginRight: '3px' }}>
              $
            </span>
            {displayPrice()}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={clsx(classes.accordionDetails, fontClasses.p1)}
      >
        {serviceDetails.customer_to_supply_parts && (
          <div
            className={classes.row}
            style={{ marginBottom: '30px', fontStyle: 'italic' }}
          >
            * Customer supplying parts
          </div>
        )}
        {serviceDetails.need_vin_to_confirm_parts && (
          <div
            className={classes.row}
            style={{ marginBottom: '30px', fontStyle: 'italic' }}
          >
            * Need VIN to confirm parts, please{' '}
            <a href="tel:469-840-3990">call us</a> for more accurate pricing
          </div>
        )}
        <div className={classes.row}>
          {serviceDetails.parts.map((part) => (
            <div key={part.id}>
              <span>
                {part.name} (${part.price.toFixed(2)} x {part.quantity})
              </span>
              <span>${(part.price * part.quantity).toFixed(2)}</span>
            </div>
          ))}
        </div>
        <div className={classes.row} style={{ marginTop: '30px' }}>
          <div>
            <span>Labor</span>
            <span>${serviceDetails.labor_price.toFixed(2)}</span>
          </div>
          <div className={classes.row} style={{ marginTop: '14px' }}>
            {amtServices > 1 &&
              serviceDetails.status !== 'locked' &&
              (serviceDetails.status === 'rejected' ? (
                <Button
                  className={classes.rejectBtn}
                  onClick={() => updateStatus('approved')}
                  style={{ textTransform: 'none' }}
                >
                  <RadioButtonUncheckedRounded
                    className={classes.iconServiceItemInactive}
                  />
                  Approve Service
                </Button>
              ) : (
                <button
                  style={{
                    color: '#FF7380',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'none',
                    border: 'none',
                    outline: 'none',
                    paddingLeft: '0',
                    paddingTop: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (serviceDetails.type_of_service === 'addon') {
                      removeService(serviceDetails.id);
                    } else {
                      updateStatus('rejected');
                    }
                  }}
                  type="button"
                >
                  <RemoveCircle
                    style={{ marginRight: '4px', fontSize: '15pt' }}
                  />
                  <span style={{ color: '#D72846' }} className={fontClasses.p1}>
                    Remove Service
                  </span>
                </button>
              ))}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default ServiceCard;
