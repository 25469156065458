import { ResponseLead } from 'src/types';
import { callApi } from './request';

export const createLead = async (
  name: string,
  email: string,
  phone: string,
  zip: string,
  utm_source: string,
  utm_medium: string,
  utm_term: string,
  utm_content: string,
  utm_campaign: string,
  path: string,
  message: string
): Promise<ResponseLead> => {
  return callApi<ResponseLead>({
    url: '/api/v1/leads',
    method: 'POST',
    data: {
      lead: {
        name,
        email,
        phone,
        zip,
        utm_source,
        utm_medium,
        utm_term,
        utm_content,
        utm_campaign,
        path,
        lead_type: 'contact_us',
        message,
      },
    },
  });
};

export default createLead;
