import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
    display: 'flex',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: 50,
    },
  },
  desktopDiv: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttons: {
    marginTop: '10px',
  },
  title: {
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(4),

    '& .hidden': {
      display: 'none',
    },
  },

  tab1: {
    alignSelf: 'start',
    borderRadius: 8,
    width: '100%',
    color: '#1A5B74',
    fontWeight: 700,
    border: '1px solid var(--blue-50, #227596)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },
    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',

      textTransform: 'none',

      alignItems: 'start',
    },
    fontFamily: 'Inter',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  tab1notSelected: {
    alignSelf: 'start',
    borderRadius: 8,
    fontWeight: 700,
    width: '100%',
    color: '#646867',
    border: '1px solid var(--blue-50, #D4D5D5)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },
    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',

      textTransform: 'none',

      alignItems: 'start',
    },
    fontFamily: 'Inter',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },

  tab2: {
    alignSelf: 'end',
    borderRadius: 8,
    fontWeight: 700,
    fontFamily: 'Inter',
    color: '#1A5B74',
    border: '1px solid var(--blue-50, #227596)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },

    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',
      textTransform: 'none',

      alignItems: 'start',
    },
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  reservationButton: {
    borderRadius: 31,
    textTransform: 'none',
    margin: '20px auto',
    boxShadow: 'none',
    width: '100%',
    padding: theme.spacing(2),
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  tab2notSelected: {
    alignSelf: 'end',
    borderRadius: 8,
    color: '#646867',
    fontWeight: 700,
    fontFamily: 'Inter',
    border: '1px solid var(--blue-50, #D4D5D5)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },

    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',
      textTransform: 'none',

      alignItems: 'start',
    },
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  customTabs: {
    '& .MuiTabs-fixed': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTabs-indicator': {
      display: 'none !important',
    },

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },

  titleText: {
    color: '#2A2D3C',
    fontSize: 23,
    lineHeight: '24px',
    fontWeight: 900,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  guideText: {
    fontSize: 20,
    lineHeight: '21px',
    fontWeight: 600,
    color: '#79739C',
  },

  guideTextMobile: {
    margin: '10px 0',
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  actionContainer: {
    display: 'block',
    textAlign: 'center',
    minHeight: 200,
    maxWidth: '100%',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
  },

  creditCardButton: {
    borderRadius: 2,
    textTransform: 'none',
    margin: '10px auto',
    boxShadow: 'none',
    minWidth: '100%',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      height: '35px',
      margin: 'auto',
    },
  },
  cardOnFile: {
    padding: 16,
    background: '#E6EEEE',
    borderRadius: 8,
    border: '1px solid var(--blue-50, #227596)',
    marginTop: 20,
    fontFamily: 'Inter',
    textAlign: 'left',
    '& .MuiTypography-body1': {
      fontFamily: 'Inter',
    },
  },
  thickInter: {
    fontSize: 15,
    fontFamily: 'Inter',
    fontWeight: 700,
  },
  thinInter: {
    fontSize: 13,
    fontFamily: 'Inter',
    fontWeight: 400,
  },
}));

export default useStyles;
