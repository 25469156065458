import React, { ReactElement } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { Close, CreditCard } from '@material-ui/icons';

import { ButtonForward, Image } from 'src/components/atoms';
import { cardTypes } from 'src/utils/data';

import { apiUpdateCreditCard } from 'src/api/user';
import { useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';

import CheckoutForm from '../../../Quote/components/Modals/ModalFinishBooking/CheckoutForm';

interface ModalUpdateCCProps {
  show: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  content: {
    minWidth: 600,
    [theme.breakpoints.down('sm')]: {
      minWidth: 500,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
      padding: theme.spacing(0),
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(4),
    '& .title-icon': {
      color: '#C5C9DE',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionContainer: {
    width: '100%',
    textAlign: 'right',
  },
  titleDatetime: {
    fontWeight: 900,
    fontSize: 19,
    lineHeight: '22.8px',
    marginLeft: theme.spacing(1),
  },
  boxInformation: {
    minWidth: '100%',
    background: '#EBF1FA',
    borderRadius: 6,
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      background: 'transparent',
      borderRadius: 0,
      padding: 0,
    },
  },
  contentHolder: {
    minHeight: 250,
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
    padding: theme.spacing(4),
    alignContent: 'flex-start',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      borderRadius: 9,
      minHeight: 500,
      padding: theme.spacing(2),
    },
  },
  success: {
    color: '#36D9A0',
  },
}));

const ModalUpdateCC = (props: ModalUpdateCCProps): ReactElement => {
  const { show, onClose } = props;
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = React.useState<string | undefined | null>(null);
  const [success, setSuccess] = React.useState(false);
  const [stripeRequestInProgress, setStripeRequestInProgress] =
    React.useState(false);

  const user = useSelector((state: IReduxState) => state.auth.user);

  const id: string | undefined = user?.id;

  const handleUpdateCC = async () => {
    setStripeRequestInProgress(true);
    setErrors(null);

    if (!stripe || !elements) {
      setStripeRequestInProgress(false);
      return false;
    }

    const cardElem = elements.getElement(CardElement);

    if (!cardElem) {
      setStripeRequestInProgress(false);
      return false;
    }

    const { token } = await stripe.createToken(cardElem);

    if (token && id) {
      const result = await apiUpdateCreditCard(token.id, id);

      if (result.data.id) {
        setSuccess(true);
      }
    } else {
      setStripeRequestInProgress(false);
      setErrors('Error updating your card, please try again.');
    }

    setStripeRequestInProgress(false);

    return true;
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      className={classes.root}
    >
      <DialogTitle className={classes.title}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container>
          <Grid
            container
            item
            md={12}
            sm={12}
            xs={12}
            className={classes.contentHolder}
          >
            <Box key="payment-title" flexDirection="row" display="flex">
              <CreditCard color="primary" />
              <Typography className={classes.titleDatetime} noWrap>
                Update Your Card on File
              </Typography>
            </Box>
            <Box key="payment" className={classes.boxInformation}>
              <Box key="action-payment">
                <CheckoutForm errors={errors} />
                <Box
                  key="image-payments"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  marginTop={2}
                >
                  {cardTypes.map((ct) => (
                    <Image key={ct.title} src={ct.image} />
                  ))}
                </Box>
              </Box>
              {success && (
                <Typography className={classes.success}>
                  Card updated!
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <DialogActions className={classes.actionContainer}>
          <ButtonForward
            key="update-cc"
            title="Update"
            size="large"
            rounded
            onClickHandler={handleUpdateCC}
            disabled={stripeRequestInProgress}
            processing={stripeRequestInProgress}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ModalUpdateCC.defaultProps = {};

export default ModalUpdateCC;
