import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { IAppointment, ICoupon, IUser } from 'src/types';
import { ButtonForward } from 'src/components/atoms';
import clsx from 'clsx';
import useFontStyles from '../../../fontStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFFFFF',
    width: '400px',
    marginLeft: '70px',
    marginTop: '70px',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '24px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.16)',
    '&.Mui-checked': {
      color: '#3D70B2',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    color: '#707070',
  },
  dollarSign: {
    color: '#000',
  },
  buttonBack: {
    marginRight: theme.spacing(1),
  },
  btnCall: {
    width: '206px',
    height: '42px',
    padding: '10px 20px',
    borderRadius: '30px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
    boxShadow: 'none',
    '& > .MuiButton-label': {
      alignItems: 'center',
    },
    '&:hover': {
      backgroundColor: '#2da379',
      boxShadow: 'none',
    },
  },
  label: {
    fontWeight: 'bold',
  },
  cardModal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    maxWidth: 320,
    minWidth: 300,
    background: '#FFFFFF',
    borderRadius: '16px',
    boxShadow: theme.shadows[5],
    padding: '25px 20px',
    outline: 'none',
  },
}));

interface InvoiceProps {
  price: number;
  className?: string;
  onContinue: (() => void) | null;
  buttonText: string;
  tax: number | null | undefined;
  isRepair: boolean;
  isDiag: boolean;
  diagFee: number;
  hasEstimate: boolean;
  isMember: boolean;
  discount: number;
  coupon: ICoupon | null;
  memberPrice: number | null | undefined;
  nonMemberPrice: number | null | undefined;
  convenienceFee: number;
  laborOnlyFee: number | null | undefined;
  subtotal: number | undefined;
  memberSubtotal: number | undefined;
  showBack: boolean;
  handleBack: () => void;
  isOilChange: boolean | null | undefined;
  estimateResponse: boolean;
  memberSavings: number | undefined;
  inconvenienceDiscount: number | undefined;
  fleetJob: boolean;
}

function Invoice(props: InvoiceProps): React.ReactElement {
  const classes = useStyles();
  const fontClasses = useFontStyles();

  const {
    price,
    onContinue,
    className,
    tax,
    subtotal,
    isRepair,
    isDiag,
    diagFee,
    hasEstimate,
    isMember,
    discount,
    coupon,
    convenienceFee,
    memberPrice,
    buttonText,
    nonMemberPrice,
    showBack,
    handleBack,
    isOilChange,
    memberSubtotal,
    estimateResponse,
    memberSavings,
    laborOnlyFee,
    inconvenienceDiscount,
    fleetJob,
  } = props;

  const getPriceToShow = () => {
    if (isMember) {
      if (isOilChange) {
        return 0;
      }

      if (isDiag) {
        return memberPrice;
      }

      return memberPrice || 0;
    }

    return price;
  };

  const getSubtotal = () => {
    let amt = 0;

    if (isMember) {
      amt += memberSubtotal || 0;
    } else {
      amt += subtotal || 0;
    }

    return amt;
  };

  const Discount = () => {
    const wording = () => {
      if (coupon && coupon.referrer) {
        return 'Referral discount:';
      }

      if (discount === 25) {
        return 'New customer discount:';
      }

      return 'Discount:';
    };

    const toolTipWording = () => {
      if (coupon) {
        if (coupon.referrer) {
          return `Your friend ${coupon.referrer} has invited you to try us out! We're giving you $30 off!`;
        }

        return `You used coupon ${coupon.code} for $${coupon.dollars_off} off!`;
      }

      if (discount === 25) {
        return "We're giving you a $25 discount for trying us out!";
      }

      return null;
    };

    return (
      <div className={clsx(classes.row, fontClasses.s2)}>
        <span>
          {toolTipWording() && (
            <span style={{ marginRight: '4px' }}>
              <Tooltip
                arrow
                title={
                  <Typography style={{ fontSize: '22px' }}>
                    {toolTipWording()}
                  </Typography>
                }
                enterTouchDelay={0}
                leaveTouchDelay={5000}
              >
                <InfoOutlined
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
              </Tooltip>
            </span>
          )}
          {wording()}
        </span>
        <span>
          <span className={classes.dollarSign}>$</span> -{discount?.toFixed(2)}
        </span>
      </div>
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      {(isRepair || hasEstimate) && (
        <>
          {getSubtotal() > 0 ? (
            <div className={clsx(classes.row, fontClasses.s2)}>
              <span>Services:</span>
              <span>
                <span className={classes.dollarSign}>$</span>{' '}
                {getSubtotal().toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}

          {tax && tax > 0 ? (
            <div className={clsx(classes.row, fontClasses.s2)}>
              <span>Taxes:</span>
              <span>
                <span className={classes.dollarSign}>$</span> {tax.toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}

          {convenienceFee > 0 ? (
            <div className={clsx(classes.row, fontClasses.s2)}>
              <span>Convenience Fee:</span>
              <span>
                <span className={classes.dollarSign}>$ </span>
                {convenienceFee?.toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}

          {laborOnlyFee && (
            <div className={clsx(classes.row, fontClasses.s2)}>
              <span style={{ marginRight: '4px' }}>
                <Tooltip
                  arrow
                  title={
                    <Typography
                      style={{ fontSize: '22px', fontFamily: 'Inter' }}
                    >
                      Liability fee since we’re not supplying our parts
                    </Typography>
                  }
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                >
                  <InfoOutlined
                    style={{ fontSize: '20px', marginBottom: '-4px' }}
                  />
                </Tooltip>

                <span style={{ marginLeft: '4px' }}>Liability Fee:</span>
              </span>
              <span>
                <span className={classes.dollarSign}>$ </span>
                {laborOnlyFee?.toFixed(2)}
              </span>
            </div>
          )}

          {discount > 0 ? <Discount /> : <></>}

          {inconvenienceDiscount && inconvenienceDiscount > 0 ? (
            <div className={clsx(classes.row, fontClasses.s2)}>
              <span>
                <span style={{ marginRight: '4px' }}>
                  <Tooltip
                    arrow
                    title={
                      <Typography
                        style={{ fontSize: '22px', fontFamily: 'Inter' }}
                      >
                        To address the inconvenience, we are offering a
                        compensatory discount
                      </Typography>
                    }
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                  >
                    <InfoOutlined
                      style={{ fontSize: '20px', marginBottom: '-4px' }}
                    />
                  </Tooltip>
                </span>
                Inconvenience Discount
              </span>
              <span>
                <span className={classes.dollarSign}>$</span> -
                {inconvenienceDiscount?.toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {isDiag && !estimateResponse && (
        <>
          <div className={clsx(classes.row, fontClasses.s2)}>
            <span>Diagnosis Fee: </span>
            <span>
              <span className={classes.dollarSign}>$</span> {diagFee}.00
            </span>
          </div>
          {discount > 0 && !hasEstimate ? <Discount /> : <></>}
        </>
      )}

      {memberSavings && !fleetJob && (
        <div
          style={{ color: '#005959' }}
          className={clsx(classes.row, fontClasses.s2)}
        >
          <span style={{ marginTop: '4px' }}>
            <span style={{ marginRight: '4px' }}>
              <Tooltip
                arrow
                title={
                  <Typography style={{ fontSize: '22px', fontFamily: 'Inter' }}>
                    Members get FREE oil changes and 10% off all services. Sign
                    up on the next screen!
                  </Typography>
                }
                enterTouchDelay={0}
                leaveTouchDelay={5000}
              >
                <InfoOutlined
                  style={{ fontSize: '20px', marginBottom: '-4px' }}
                />
              </Tooltip>
            </span>
            {isMember ? 'Your Savings' : 'Members Save'}
          </span>
          <span
            style={{
              backgroundColor: '#005959',
              color: '#fff',
              padding: '4px',
              borderRadius: '4px',
            }}
          >
            <span>$</span> {memberSavings.toFixed(2)}
          </span>
        </div>
      )}

      <div
        className={clsx(classes.row, fontClasses.h3)}
        style={{ marginTop: '10px', color: 'black' }}
      >
        <span>Your Quote:</span>
        <span>
          <span className={classes.dollarSign}>$</span>{' '}
          {getPriceToShow()?.toFixed(2)} *
        </span>
      </div>

      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        {onContinue && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{
              borderRadius: 31,
              textTransform: 'none',
              margin: 'auto',
              boxShadow: 'none',
            }}
            className={fontClasses.h3}
            onClick={() => onContinue()}
          >
            {buttonText}
          </Button>
        )}
      </div>
      {showBack && (
        <div style={{ margin: '10px 0', textAlign: 'center' }}>
          <ButtonForward
            title="Back"
            key="Back"
            color="default"
            rounded
            noIcon
            transparent
            size="large"
            onClickHandler={handleBack}
            className={classes.buttonBack}
          />
        </div>
      )}

      <div
        style={{
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        <Typography style={{ fontStyle: 'italic' }}>
          * Payment due at time of service
        </Typography>{' '}
      </div>
    </div>
  );
}

Invoice.defaultProps = {
  className: '',
};

export default Invoice;
