// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//  @ts-nocheck
import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { Container, makeStyles, Typography } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  QuoteShowModal,
  QuoteStep,
  RequestCreateAppointment,
  RequestUpdateAppointmentTime,
  RequestUpdateAppointmentContact,
  ResponseAppointment,
  ResponseSignin,
  RequestUpdateEstimateResponse,
  RequestAuthPayPalDiag,
  RequestAuthPayPalRepair,
} from 'src/types';
import {
  confirmCardOnFile,
  confirmPayPal,
  createAppointment,
  getAppointment,
  updateAppointment,
  updateAppointmentContactInfo,
  updateAppointmentEstimate,
} from 'src/api/quote';
import { IReduxState } from 'src/store/reducers';
import { setAppointment, setAuthToken, setZip } from 'src/store/actions';
import { Splash } from 'src/layouts/components';
import mixPanel from 'src/utils/mixpanel';
import isAllInspections from 'src/utils/inspections';
import { MIXPANEL_TRACK, URLS, INSTANT_QUOTES } from 'src/utils/consts';
import { signIn } from 'src/api/auth';
import { callAdsQuote, callAdsBooking } from 'src/utils/ads';
import { FormContact, SearchCar, ServiceDesk } from './components';
import SimpleCongrats from './components/SimpleCongrats';
import QuoteContainer from './QuoteContainer';
import {
  ModalScheduleService,
  ModalCongrats,
  ModalFinishBooking,
  ModalHandleEstimateResponse,
  NewModalReviewQuote,
} from './components/Modals';
import {
  IQuoteReason,
  IQuoteCar,
  QuoteContext,
  IQuoteContact,
} from './QuoteContext';
import { ModalInputZip } from '../Home/components';
import ModalJoinMembership from './components/Modals/ModalJoinMembership/ModalJoinMembership';

const promise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

const useStyles = makeStyles(() => ({
  root: {
    '& .hidden': {
      display: 'none',
    },
  },
}));

const Quote = (): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const zip = useSelector((state: IReduxState) => state.quote.zip);
  const appt = useSelector((state: IReduxState) => state.quote.appointment);
  const user = useSelector((state: IReduxState) => state.auth.user);
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { referrer } = document;

  const { appId: appIdParam }: { appId: string } = useParams();

  const isCreditCardLink =
    location.pathname === `/appointment/confirm/${appIdParam}`;

  const isMechanicEstimate =
    location.pathname === `/appointment/estimate_response/${appIdParam}`;

  const isOfficeEstimate =
    location.pathname === `/appointment/estimate/${appIdParam}`;

  const bookFromDashboard = query.get('bookQuote') !== null;

  const params = queryString.parse(location.search);

  const zipQuery = Array.isArray(params.zip) ? params.zip[0] || '' : params.zip;
  const referralCode = Array.isArray(params.coupon)
    ? params.coupon[0] || ''
    : params.coupon;

  const redirectStatus = Array.isArray(params.redirect_status)
    ? params.redirect_status[0] || ''
    : params.redirect_status;
  const scheduleEstimate = Array.isArray(params.schedule_estimate)
    ? params.schedule_estimate[0] || ''
    : params.schedule_estimate;

  const [openSplash, setOpenSplash] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);

  useEffect(() => {
    if (!isMechanicEstimate && !isOfficeEstimate && !isCreditCardLink) {
      if (zipQuery && zipQuery !== zip && zipQuery.length === 5) {
        dispatch(setZip(zipQuery));
        mixPanel(MIXPANEL_TRACK.ZIP);
      }
    }

    const asyncReadAppointment = async () => {
      try {
        setOpenSplash(true);
        const appointment = await getAppointment(appIdParam);

        dispatch(setAppointment(appointment.data));

        setOpenSplash(false);
      } catch (err) {
        history.push(URLS.HOME);
      }
    };

    if (appIdParam) {
      asyncReadAppointment();
    }

    return () => {};
  }, [
    zipQuery,
    dispatch,
    history,
    zip,
    isMechanicEstimate,
    appIdParam,
    isOfficeEstimate,
    isCreditCardLink,
  ]);

  const showZipModal =
    !isMechanicEstimate &&
    !isOfficeEstimate &&
    !isCreditCardLink &&
    !zip &&
    !zipQuery;

  const handleSetZipFromModal = (payload: {
    zip?: string;
    customer?: number;
  }) => {
    if (payload.zip) {
      mixPanel(MIXPANEL_TRACK.ZIP);
      dispatch(setZip(payload.zip || ''));
    }
  };

  const appId = useSelector(
    (state: IReduxState) =>
      state.quote.appointment && state.quote.appointment.id
  );

  const [display, setDisplay] = React.useState(0);

  /**
   * Quote Context
   */
  const [step, setStep] = useState(QuoteStep.QUOTE_SERVICE_DESK);
  const handleSetStep = useCallback(
    (newStep: QuoteStep) => setStep(newStep),
    []
  );

  const handleStepChange = (newStep: QuoteStep, bForce?: boolean): void => {
    if (
      bForce ||
      parseInt(newStep as string, 10) <= parseInt(step as string, 10)
    )
      handleSetStep(newStep);
  };

  let modal;

  if (isCreditCardLink) {
    modal = QuoteShowModal.FINISH_BOOKING;
  } else if (isMechanicEstimate && scheduleEstimate) {
    modal = QuoteShowModal.HANDLE_ESTIMATE_RESPONSE;
  } else if (isMechanicEstimate || isOfficeEstimate) {
    modal = QuoteShowModal.REVIEW_QUOTE;
  } else if (location.state && location.state.modal) {
    modal = location.state.modal;
  } else {
    modal = QuoteShowModal.NONE;
  }

  const [showModal, setShowModal] = useState(modal);
  const handleShowModal = useCallback(
    (newShowModal: QuoteShowModal) => setShowModal(newShowModal),
    []
  );

  const [staticServices, setStaticServices] = useState<string[]>([]);
  const handleSetStaticServices = useCallback(
    (newServices: string[]) => {
      setDisplay(display + 1);
      setStaticServices(newServices);
    },
    [display]
  );

  const [serviceDeskTab, setServiceDeskTab] = useState<number>(0);

  const [services, setServices] = useState<string[]>([]);
  const handleSetServices = useCallback(
    (newServices: string[]) => setServices(newServices),
    []
  );

  const [reason, setReason] = useState<IQuoteReason>({
    selection: {},
    otherReason: '',
    note: '',
  });

  const isNotSureFunnel = reason.otherReason !== '';

  const isServiceInspections: boolean = isAllInspections(services);

  const isStaticServiceInspections: boolean = isAllInspections(staticServices);

  const isInspections = isServiceInspections || isStaticServiceInspections;

  const isInstantQuote =
    services.length === 1 && INSTANT_QUOTES.includes(services[0]);

  const instantFunnel = isNotSureFunnel || isInspections || isOfficeEstimate;

  const handleSetReason = useCallback(
    (newReason: IQuoteReason) => setReason(newReason),
    []
  );

  const [membershipSelected, setMembershipSelected] = useState<
    string | undefined
  >();

  const [car, setCar] = useState<IQuoteCar>({
    search: {
      plate_number: '',
      state: '',
    },
    attributes: {
      year: '',
      make: '',
      model: '',
      engine_size: '',
      mileage: '',
      vin: '',
      last_oil_change: '',
      id: undefined,
    },
  });
  const handleSetCar = useCallback((newCar: IQuoteCar) => setCar(newCar), []);

  const [contact, setContact] = useState<IQuoteContact>({
    name: (user && user.attributes.name) || '',
    email: (user && user.attributes.email) || '',
    phone: (user && user.attributes.phone) || '',
    referral_code: referralCode || '',
  });

  const handleSetContact = useCallback(
    (newContact: IQuoteContact) => setContact(newContact),
    []
  );

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const [loggingIn, setLoggingIn] = useState(false);
  const handleSetLoggingIn = (state: boolean): void => {
    setLoggingIn(state);
  };

  const login = async (resp: ResponseAppointment) => {
    handleSetLoggingIn(true);

    const response: ResponseSignin = await signIn(
      {
        id: `${resp.data && resp.data.id}`,
      },
      true
    );

    handleSetLoggingIn(false);

    if (response && response.auth_token && response.user && response.user.id) {
      dispatch(
        setAuthToken(response.auth_token, response.user.id, response.user.email)
      );

      handleSetStep(QuoteStep.QUOTE_CONGRATS);
    } else {
      handleStepChange(QuoteStep.QUOTE_CONTACT, true);
    }
  };

  const clearAll = useCallback(() => {
    handleSetStep(QuoteStep.QUOTE_SERVICE_DESK);
    handleShowModal(QuoteShowModal.NONE);

    handleSetStaticServices([]);
    handleSetServices([]);

    handleSetReason({
      selection: {},
      otherReason: '',
      note: '',
    });

    handleSetCar({
      search: {
        plate_number: '',
        state: '',
      },
      attributes: {
        year: '',
        make: '',
        model: '',
        engine_size: '',
        mileage: '',
        vin: '',
        last_oil_change: '',
        id: undefined,
      },
    });

    handleSetContact({
      name: '',
      email: '',
      phone: '',
    });
  }, [
    handleSetStep,
    handleShowModal,
    handleSetStaticServices,
    handleSetServices,
    handleSetReason,
    handleSetCar,
    handleSetContact,
  ]);

  const showCommonError = (msg?: string | undefined) => {
    const errorMsg: string =
      msg || 'An error occured while processing your quote.';
    enqueueSnackbar(errorMsg, {
      variant: 'error',
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    });
  };

  const handleContinueOnService = (serviceType: string) => {
    if (serviceType === 'diagnosis') {
      mixPanel(MIXPANEL_TRACK.NOT_SURE_WHATS_WRONG_NEXT);
    } else {
      mixPanel(MIXPANEL_TRACK.REPAIR_SERVICE);
    }
    mixPanel(MIXPANEL_TRACK.SERVICE);
    handleStepChange(QuoteStep.QUOTE_SEARCH_CAR, true);
  };

  const grabInputData = (
    data: RequestUpdateAppointmentContact
  ): RequestCreateAppointment => {
    let diagInput = reason.otherReason;

    if (reason.note !== '') {
      diagInput += `. ${reason.note}`;
    }

    if (isInspections) {
      diagInput = services.join(', ');
    }

    let appointmentType;
    let level;
    if (isNotSureFunnel || isInspections) {
      appointmentType = 'diagnosis';
      level = 'A';
    } else {
      appointmentType = 'repair';
      level = 'B';
    }

    const result = {
      kind: 'RequestCreateAppointment',
      appointment_type: appointmentType,
      car_attributes: {
        ...car.attributes,
      },
      tracking_attributes: {
        utm_source: query.get('utm_source') || '',
        utm_medium: query.get('utm_medium') || '',
        utm_term: query.get('utm_term') || '',
        utm_content: query.get('utm_content') || '',
        utm_campaign: query.get('utm_campaign') || '',
        gclid: query.get('gclid') || '',
        client_id: query.get('google_client_id') || '',
        fbp: query.get('fbp') || '',
        fbc: query.get('fbc') || '',
        fbclid: query.get('fbclid') || '',
        msclkid: query.get('msclkid') || '',
        path: query.get('path') || '',
        coupon:
          !referralCode || referralCode === ''
            ? data?.referral_code
            : referralCode,
        adg: query.get('adg') || '',
        ref: referrer || '',
        utm_campaignid: query.get('utm_campaignid') || '',
      },
      location_attributes: {
        zip,
      },
      address: zip,
      diagnosis_input: diagInput,
      services: [...services, ...staticServices],
      level,
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      heard_about_us: data?.heard_about_us,
      referral_code:
        !referralCode || referralCode === ''
          ? data?.referral_code
          : referralCode,
      referral_name: data.referral_name,
    };

    return result;
  };

  const nextAfterContact = (
    instant: boolean,
    status: string | undefined | null
  ) => {
    if (status !== 'no_service_area' && (instantFunnel || instant)) {
      handleShowModal(QuoteShowModal.REVIEW_QUOTE);
    } else {
      handleStepChange(QuoteStep.QUOTE_CONGRATS, true);
      handleShowModal(QuoteShowModal.NONE);
    }
  };

  const handleCreateAppointment = async (
    data: RequestUpdateAppointmentContact
  ) => {
    setRequestInProgress(true);

    if (appId) {
      updateAppointment(appId, grabInputData(data))
        .then((resp: ResponseAppointment) => {
          dispatch(setAppointment(resp.data));

          handleSetCar({
            ...car,
            attributes: {
              ...car.attributes,
              id: resp.data.attributes.car.id,
            },
          });

          const estimateNotNull = resp.data.attributes.estimate !== null;
          nextAfterContact(
            isInstantQuote && estimateNotNull,
            resp.data.attributes.status
          );
        })
        .catch(() => showCommonError())
        .finally(() => setRequestInProgress(false));
    } else {
      createAppointment(grabInputData(data))
        .then((resp: ResponseAppointment) => {
          dispatch(setAppointment(resp.data));

          handleSetCar({
            ...car,
            attributes: {
              ...car.attributes,
              id: resp.data.attributes.car.id,
            },
          });

          const estimateNotNull = resp.data.attributes.estimate !== null;
          nextAfterContact(
            isInstantQuote && estimateNotNull,
            resp.data.attributes.status
          );

          callAdsQuote(resp.data);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            Array.isArray(error.response.data.errors)
          ) {
            handleSetContact({
              ...contact,
              error: error.response.data.errors.join(', '),
            });

            setTimeout(() => {
              handleSetContact({
                ...contact,
                error: '',
              });
            }, 3000);
          } else {
            showCommonError();
          }
        })
        .finally(() => setRequestInProgress(false));
    }
  };

  const handleUpdateContact = async (
    data: RequestUpdateAppointmentContact,
    id: number
  ) => {
    const instantEstimateAlready =
      isInstantQuote && appt && appt.attributes.estimate !== null;

    if (instantEstimateAlready) {
      nextAfterContact(true, appt?.attributes?.status);
      return;
    }

    setRequestInProgress(true);

    await updateAppointmentContactInfo(id, data)
      .then((resp: ResponseAppointment) => {
        dispatch(setAppointment(resp.data));

        const estimateNotNull = resp.data.attributes.estimate !== null;
        nextAfterContact(
          isInstantQuote && estimateNotNull,
          resp.data.attributes.status
        );

        callAdsQuote(resp.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          handleSetContact({
            ...contact,
            error: error.response.data.errors.join(', '),
          });

          setTimeout(() => {
            handleSetContact({
              ...contact,
              error: '',
            });
          }, 3000);
        }
      })
      .finally(() => setRequestInProgress(false));
  };

  const handleUpdateContactInfo = async (
    data: RequestUpdateAppointmentContact
  ) => {
    if (appId) {
      handleUpdateContact(data, appId);
    } else {
      await handleCreateAppointment(data);
    }
  };

  const handleUpdateAppointmentTime = async (
    data: RequestUpdateAppointmentTime
  ) => {
    if (!appId) {
      return;
    }

    setRequestInProgress(true);

    updateAppointment(appId, data)
      .then((resp: ResponseAppointment) => {
        dispatch(setAppointment(resp.data));

        handleShowModal(QuoteShowModal.FINISH_BOOKING);
      })
      .catch(() => {
        // Error 😨
        showCommonError();
      })
      .finally(() => setRequestInProgress(false));
  };

  const handleRespondAppointmentEstimate = async (
    data: RequestUpdateEstimateResponse
  ) => {
    if (!appId) {
      return;
    }

    setRequestInProgress(true);

    updateAppointmentEstimate(appId, data)
      .then(() => {
        if (isMechanicEstimate) {
          handleShowModal(QuoteShowModal.CONGRATS);
        }
      })
      .catch(() => showCommonError())
      .finally(() => setRequestInProgress(false));
  };

  const handleConfirmPaypal = async (
    data: RequestAuthPayPalDiag | RequestAuthPayPalRepair
  ) => {
    if (!appId) {
      return;
    }

    setRequestInProgress(true);

    confirmPayPal(appId, false, data)
      .then((resp: ResponseAppointment) => {
        if (resp.errors) {
          showCommonError(resp.errors.join('. '));
          setRequestInProgress(false);
        } else {
          mixPanel(MIXPANEL_TRACK.CARD_INFO);
          dispatch(setAppointment(resp.data));
          handleShowModal(QuoteShowModal.NONE);
          callAdsBooking(resp.data);
          login(resp);
        }
      })
      .catch(() => {
        showCommonError();
        setRequestInProgress(false);
      })
      .finally(() => setRequestInProgress(false));
  };

  const handleConfirmCardOnFile = async () => {
    if (!appId) {
      // error handling
      return;
    }

    setRequestInProgress(true);

    confirmCardOnFile(appId)
      .then((resp: ResponseAppointment) => {
        if (resp.errors) {
          showCommonError(resp.errors.join('. '));
          setRequestInProgress(false);
        } else {
          dispatch(setAppointment(resp.data));
          handleShowModal(QuoteShowModal.NONE);
          callAdsBooking(resp.data);
          login(resp);
        }
      })
      .catch(() => {
        showCommonError();
        setRequestInProgress(false);
      })
      .finally(() => setRequestInProgress(false));
  };

  const handleConfirmCar = () => {
    mixPanel(MIXPANEL_TRACK.CONFIRM_CAR);

    handleStepChange(QuoteStep.QUOTE_CONTACT, true);
  };

  /**
   * Rendering
   */
  const renderStepComponent = () => {
    switch (step) {
      case QuoteStep.QUOTE_SERVICE_DESK:
        return <ServiceDesk onContinue={handleContinueOnService} />;
      case QuoteStep.QUOTE_SEARCH_CAR:
        return <SearchCar onConfirm={handleConfirmCar} />;
      case QuoteStep.QUOTE_CONTACT:
        return <FormContact />;
      case QuoteStep.QUOTE_CONGRATS:
        return (
          <SimpleCongrats
            isBooked={
              instantFunnel ||
              (isInstantQuote && appt?.attributes.estimate !== null) ||
              bookFromDashboard
            }
          />
        );
      default:
        return (
          <Typography>
            Your request has been submitted! Please call us if you need
            assistance.
          </Typography>
        );
    }
  };

  return (
    <QuoteContext.Provider
      value={{
        step,
        handleSetStep,

        showModal,
        handleShowModal,

        staticServices,
        handleSetStaticServices,

        services,
        handleSetServices,

        serviceDeskTab,
        setServiceDeskTab,

        reason,
        handleSetReason,

        membershipSelected,
        setMembershipSelected,

        car,
        handleSetCar,

        contact,
        handleSetContact,

        handleCreateAppointment,
        handleUpdateAppointmentTime,
        handleUpdateContactInfo,
        handleConfirmPaypal,
        handleRespondAppointmentEstimate,

        clearAll,

        login,
        loggingIn,
        handleSetLoggingIn,

        isMechanicEstimate,
        isOfficeEstimate,
        isInstantQuote,

        requestInProgress,

        isLoggedIn,
        setIsLoggedIn,
      }}
    >
      <Elements
        stripe={promise}
        options={{
          business: 'Goodhood',
          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimaryText: '#005959',
              colorPrimary: '#005959',
              colorBackground: '#ffffff',
              colorDanger: '#ff0000',
              spacingUnit: '2px',
              borderRadius: '4px',
            },
          },
        }}
      >
        <Container className={classes.root}>
          <QuoteContainer currentStep={step} onStepChanged={handleStepChange}>
            {renderStepComponent()}
          </QuoteContainer>
          <Typography className="hidden">{display}</Typography>
          <NewModalReviewQuote
            show={showModal === QuoteShowModal.REVIEW_QUOTE}
            onClose={() => handleShowModal(QuoteShowModal.NONE)}
          />
          <ModalJoinMembership
            show={showModal === QuoteShowModal.JOIN_MEMBER}
            onClose={() => handleShowModal(QuoteShowModal.JOIN_MEMBER)}
          />
          <ModalScheduleService
            show={showModal === QuoteShowModal.SCHEDULE_SERVICE}
            onClose={() => handleShowModal(QuoteShowModal.NONE)}
          />
          <ModalFinishBooking
            show={showModal === QuoteShowModal.FINISH_BOOKING}
            onClose={() => handleShowModal(QuoteShowModal.NONE)}
            onConfirmCard={handleConfirmCardOnFile}
          />
          <ModalCongrats
            show={showModal === QuoteShowModal.CONGRATS}
            onClose={() => handleShowModal(QuoteShowModal.NONE)}
          />
          <ModalHandleEstimateResponse
            show={showModal === QuoteShowModal.HANDLE_ESTIMATE_RESPONSE}
            onClose={() => handleShowModal(QuoteShowModal.NONE)}
            payVerified={scheduleEstimate && redirectStatus !== 'failed'}
          />
          <Splash show={loggingIn || openSplash} />
          <ModalInputZip
            show={showZipModal}
            onGetQuote={handleSetZipFromModal}
          />
        </Container>
      </Elements>
    </QuoteContext.Provider>
  );
};

export default Quote;
