import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Button,
  TextField,
  useMediaQuery,
  makeStyles,
  InputAdornment,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { styled } from '@mui/material';
import { IReduxState } from 'src/store/reducers';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK_FUNNEL_V2 } from 'src/utils/consts';
import { CheckCircle } from '@material-ui/icons';
import VirtualDiagnosisModal from 'src/views/Quote/components/Modals/ModalVirtualDiagnosis';
import LineWithOr from 'src/views/Login/components/LineWithOr';
import { createAppointment, updateAppointmentOutOfRange } from 'src/api/quote';
import { callAdsQuote } from 'src/utils/ads';
import { ButtonForward } from 'src/components/atoms';
import { IAppointment, RequestCreateAppointment } from 'src/types';
import { setAppointment } from 'src/store/actions';
import { QuoteConfirmedContext } from './Context/QuoteConfirmedContext';

interface ComponentProps {
  heardAboutUs: string;
}

const CustomFormField = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '60px',
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    paddingLeft: 20,

    border: 'none',
    color: 'none',
  },

  '& .MuiSelect-select.MuiSelect-select': {
    height: '100%',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    backgroundColor: '#fff',
  },
  '& .MuiSelect-icon': {
    top: 'auto',
  },
}));

interface ContactStepProps {
  onNext: () => void;
  onComplete: () => void;
  activeStep: number;
  setStepUpdated: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles((theme) => ({
  checked: {
    color: '#005959',
  },
  card: {
    padding: theme.spacing(2),
    background: '#fff',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  continueButton: {
    marginLeft: theme.spacing(1),
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      boxShadow: 'none',
    },
  },
  whiteCard: {
    backgroundColor: theme.palette.common.white,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    color: '#fff',
  },
  font: {
    color: 'var(--emerald-green, #005959)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
  },
  mainRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',

    textAlign: 'left',
    padding: '0px 40px 0px 40px',
  },
  container: {
    position: 'relative',
    borderRadius: 31,
    height: '45px',
    background: '#ECE9F1',
    paddingLeft: '20px',
    marginBottom: 20,
  },
  inputZip: {
    height: '100%',
    maxWidth: '280px',
    color: '#646867',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 20,
    background: 'transparent',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  nameLabel: {
    paddingLeft: 30,
    color: '#1F2322',
    fontFamily: 'Inter',

    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
  },
  selectBoxTop: {
    fontFamily: 'inter',
    borderRadius: '8px',
    background: '#f2f2f2',
    borderBottom: 'none',
    fontSize: 17,
    fontWeight: 400,
    borderTopLeftRadius: 8,

    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
      borderRadius: '8px',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
      borderRadius: '8px',
    },
    '& .MuiSelect-iconFilled': {
      right: (props: ComponentProps) => props.heardAboutUs && 40,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      borderRadius: 8,
      background: '#fff',
      fontWeight: 400,
    },
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: '45px',
    fontFamily: 'inter',
    position: 'fixed',
    background: '#fff',
  },
  ButtonForwarded: {
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    '& .MuiButton-endIcon': {
      marginTop: 5,
    },
  },
  ZipLabel: {
    color: '#1F2322',
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    paddingLeft: 12,
    paddingBottom: 5,
    marginBottom: 5,
  },
  buttonForwardVirtual: {
    background: '#005959',
    color: '#fff',
    fontFamily: 'Inter',
    borderRadius: '32px',
    height: '50px',
    '&:hover': {
      background: '#005959',
      color: '#fff',
    },
  },
  hdiv: {
    display: 'flex',
    padding: '20px 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    gap: 20,
  },
}));

const ContactStep: React.FC<ContactStepProps> = ({
  onNext,
  onComplete,
  activeStep,
  setStepUpdated,
}) => {
  const dispatch = useDispatch();

  const appointment: IAppointment | null = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const [requesting, setRequesting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [zipErrorAgain, setZipErrorAgain] = useState<boolean>(false);

  const { QuoteConfirmed, setQuoteConfirmed } = useContext(
    QuoteConfirmedContext
  );
  const query = new URLSearchParams(useLocation().search);

  const isSm = useMediaQuery('(max-width: 1229px)');

  const [firstName, setFirstName] = useState<string>(
    QuoteConfirmed.contact.first_name
  );
  const [referralCode, setReferralCode] = useState<string>(
    QuoteConfirmed.contact.referral_code
  );
  const [heardAboutUs, setHeardAboutUs] = useState<string>(
    QuoteConfirmed.contact.heard_about_us
  );
  const [email, setEmail] = useState<string>(QuoteConfirmed.contact.email_id);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    QuoteConfirmed.contact.phone_number
  );
  const [zipCode, setZipCode] = useState<string>(QuoteConfirmed.car.zipcode);
  const [virtualDiagnosisOn, setVirtualDiagnosisOn] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    firstName: '',
    email: '',
    phoneNumber: '',
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>();
  const [touched, setTouched] = useState({
    firstName: false,
    email: false,
    phoneNumber: false,
  });
  const handleBlur = (field: string) => {
    setTouched((prevTouched) => ({ ...prevTouched, [field]: true }));
  };

  const validateEmail = (givenEmail: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(givenEmail);
  };

  const validatePhoneNumber = (phoneNo: string) => {
    const re = /^\d{10}$/;
    return re.test(phoneNo);
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setFirstName(inputValue);

    if (inputValue.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: 'First name cannot be empty',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: '',
      }));
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    if (inputValue.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Email address cannot be empty',
      }));
    } else if (!validateEmail(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Invalid email address',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '',
      }));
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setPhoneNumber(inputValue);

    if (inputValue.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: 'Phone number cannot be empty',
      }));
    } else if (!validatePhoneNumber(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: 'Invalid phone number',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: '',
      }));
    }
  };

  useEffect(() => {
    setIsFormValid(
      firstName.trim() !== '' &&
        email.trim() !== '' &&
        validateEmail(email) &&
        phoneNumber.trim() !== '' &&
        validatePhoneNumber(phoneNumber)
    );
  }, [firstName, email, phoneNumber]);

  const handleContinue = () => {
    setRequesting(true);

    const modifiedObject = {
      ...QuoteConfirmed,
      contact: {
        ...QuoteConfirmed.contact,
        first_name: firstName,
        email_id: email,
        phone_number: phoneNumber,
      },
    };

    const { referrer } = document;

    const data: RequestCreateAppointment = {
      kind: 'RequestCreateAppointment',
      appointment_type: QuoteConfirmed.service.service_type,
      car_attributes: {
        year: QuoteConfirmed.car.year,
        make: QuoteConfirmed.car.make,
        model: QuoteConfirmed.car.model,
        engine_size: QuoteConfirmed.car.engine,
        vin: QuoteConfirmed.car.vin,
      },
      tracking_attributes: {
        utm_source: query.get('utm_source') || '',
        utm_medium: query.get('utm_medium') || '',
        utm_term: query.get('utm_term') || '',
        utm_content: query.get('utm_content') || '',
        utm_campaign: query.get('utm_campaign') || '',
        gclid: query.get('gclid') || '',
        client_id: query.get('google_client_id') || '',
        fbp: query.get('fbp') || '',
        fbc: query.get('fbc') || '',
        fbclid: query.get('fbclid') || '',
        msclkid: query.get('msclkid') || '',
        path: query.get('path') || '',
        coupon: '',
        adg: query.get('adg') || '',
        ref: referrer || '',
        utm_campaignid: query.get('utm_campaignid') || '',
      },
      location_attributes: {
        zip: zipCode,
      },
      address: zipCode,
      diagnosis_input:
        QuoteConfirmed.service.diagnostic_problem_description &&
        QuoteConfirmed.service.diagnostic_problem_description !== ''
          ? `${
              QuoteConfirmed.service.diagnostic_problem_description
            }. ${QuoteConfirmed.service.diagnostic_problems.join(', ')}`
          : `${QuoteConfirmed.service.diagnostic_problems.join('. ')}`,
      services: QuoteConfirmed.service.repair_problems,
      level: QuoteConfirmed.service.service_type === 'diagnosis' ? 'A' : 'B',
      name: firstName,
      email,
      phone: phoneNumber,
      heard_about_us: heardAboutUs || '',
      referral_code: referralCode || '',
    };

    createAppointment(data)
      .then((res) => {
        setQuoteConfirmed(modifiedObject);

        mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_CONTACT_INFO);

        callAdsQuote(res.data);

        dispatch(setAppointment(res.data));
        if (res.data.attributes.status === 'no_service_area') {
          setIsModalOpen(true);
        } else {
          if (activeStep === 2) {
            onNext();
          } else {
            setStepUpdated((prevStep) => prevStep + 1);
          }
          setError(false);
        }
      })
      .catch((errorResponse) => {
        setError(true);
        const errormessage = errorResponse.response?.data?.errors || [
          'Error creating your request, please try correcting information above',
        ];
        setErrorMessage(errormessage);
      })
      .finally(() => setRequesting(false));
  };

  const [hasCompleted, setHasCompleted] = useState(false);

  useEffect(() => {
    if (!hasCompleted) {
      onComplete();
      setHasCompleted(true);
    }
  }, [hasCompleted, onComplete]);

  const handleZipCodeSubmit = () => {
    if (appointment?.id) {
      updateAppointmentOutOfRange(appointment?.id, zipCode).then((res) => {
        if (res.errors) {
          setZipErrorAgain(true);

          setTimeout(() => {
            setZipErrorAgain(false);
          }, 4000);
        } else {
          dispatch(setAppointment(res.data));

          callAdsQuote(res.data);

          if (activeStep === 2) {
            onNext();
          } else {
            setStepUpdated((prevStep) => prevStep + 1);
          }

          setIsModalOpen(false);
          setError(false);
        }
      });
    }
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter' && zipCode.length >= 5) {
      handleZipCodeSubmit();
    }
  };

  const shuffleArray = (array: string[]): string[] => {
    const shuffledArray = [...array];

    for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = shuffledArray[i];
      shuffledArray[i] = shuffledArray[j];
      shuffledArray[j] = temp;
    }

    return shuffledArray;
  };

  const classes = useStyles({ heardAboutUs });

  return (
    <div className={classes.mainRoot}>
      <Dialog open={isModalOpen} style={{ maxWidth: '450px', margin: 'auto' }}>
        {!virtualDiagnosisOn ? (
          <>
            {' '}
            <DialogContent>
              <div className={classes.hdiv}>
                <Typography style={{ fontSize: 20, fontFamily: 'Inter' }}>
                  Coming to you soon{' '}
                </Typography>
                <Typography style={{ fontSize: 12, fontFamily: 'Inter' }}>
                  Unfortunately we don&apos;t offer services in your area. Try
                  another zip code.
                </Typography>
              </div>

              <div className={classes.container}>
                <input
                  type="text"
                  pattern="[0-9]{5}"
                  inputMode="numeric"
                  className={classes.inputZip}
                  placeholder="Enter Zip"
                  value={zipCode}
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                  onKeyDown={handleKeyPress}
                />
                <ButtonForward
                  title="Continue"
                  rounded
                  size="medium"
                  className={classes.ButtonForwarded}
                  onClickHandler={handleZipCodeSubmit}
                  disabled={zipCode.length < 5}
                />
              </div>

              {zipErrorAgain && (
                <Typography
                  style={{
                    margin: '10px',
                    textAlign: 'center',
                    color: '#ff0000',
                    fontSize: 15,
                    fontFamily: 'Inter',
                  }}
                >
                  We don&apos;t cover that zip yet, try another.
                </Typography>
              )}

              <LineWithOr />
              <div className={classes.hdiv} style={{ marginTop: -20 }}>
                <Typography style={{ fontSize: 12, fontFamily: 'Inter' }}>
                  For $30, a certified mechanic will video chat with you to
                  diagnose over the phone
                </Typography>

                <Button
                  onClick={() => setVirtualDiagnosisOn(true)}
                  className={classes.buttonForwardVirtual}
                >
                  Virtual diagnosis
                </Button>
              </div>
            </DialogContent>{' '}
          </>
        ) : (
          <>
            <DialogContent>
              <VirtualDiagnosisModal
                open={virtualDiagnosisOn}
                onClose={() => setVirtualDiagnosisOn(false)}
                quoteConfirmed={QuoteConfirmed}
                onlyVirtual
              />
            </DialogContent>
          </>
        )}
      </Dialog>
      <TextField
        style={{ width: '100%' }}
        variant="filled"
        label="First Name"
        value={firstName}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end" style={{ background: '#fff' }}>
              {firstName.trim().length >= 1 && (
                <CheckCircle
                  className={classes.checked}
                  style={{ marginLeft: 5, marginTop: isSm ? 10 : 0 }}
                />
              )}
            </InputAdornment>
          ),
          style: {
            borderRadius: '8px',
            fontSize: isSm ? 15 : 17,
            border: '0.5px solid grey',
            background: '#fff',
            fontFamily: 'Inter',
            fontWeight: 500,
          },
        }}
        onChange={handleFirstNameChange}
        onBlur={() => handleBlur('firstName')}
        error={touched.firstName && Boolean(errors.firstName)}
        helperText={touched.firstName && errors.firstName}
      />

      <TextField
        style={{ width: '100%', marginTop: 30 }}
        variant="filled"
        type="email"
        label="Email Address"
        inputMode="email"
        value={email}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              {Boolean(errors.email) === false && email.length >= 1 && (
                <CheckCircle
                  className={classes.checked}
                  style={{ marginLeft: 5, marginTop: isSm ? 10 : 0 }}
                />
              )}
            </InputAdornment>
          ),
          style: {
            borderRadius: '8px',
            fontSize: isSm ? 15 : 17,
            border: '0.5px solid grey',
            background: '#fff',
            fontFamily: 'Inter',
            fontWeight: 500,
          },
        }}
        onChange={handleEmailChange}
        onBlur={() => handleBlur('email')}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />

      <TextField
        style={{ width: '100%', marginTop: 30 }}
        variant="filled"
        label="Phone Number"
        inputMode="tel"
        type="number"
        value={phoneNumber}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end" style={{ background: '#fff' }}>
              {Boolean(errors.phoneNumber) === false &&
                phoneNumber.length >= 10 && (
                  <CheckCircle
                    className={classes.checked}
                    style={{ marginLeft: 5, marginTop: isSm ? 10 : 0 }}
                  />
                )}
            </InputAdornment>
          ),
          style: {
            borderRadius: '8px',
            fontSize: isSm ? 15 : 17,
            border: '0.5px solid grey',
            background: '#fff',
            fontFamily: 'Inter',
            fontWeight: 500,
          },
        }}
        onChange={handlePhoneNumberChange}
        fullWidth
        onBlur={() => handleBlur('phoneNumber')}
        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
        helperText={touched.phoneNumber && errors.phoneNumber}
      />

      <CustomFormField
        variant="outlined"
        style={{
          width: '100%',
          display: 'flex',

          flexDirection: 'column',
          marginTop: 30,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TextField
            select
            fullWidth
            label="How did you hear about us?"
            className={classes.selectBoxTop}
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {heardAboutUs && (
                    <CheckCircle
                      className={classes.checked}
                      style={{ marginLeft: 5, marginTop: isSm ? 10 : 0 }}
                    />
                  )}
                </InputAdornment>
              ),
              style: {
                fontSize: isSm ? 15 : 17,
                background: '#fff',
                border: '0.5px solid grey',
                borderRadius: '8px',
                fontFamily: 'Inter',
              },
              'aria-label': 'Without label',
            }}
            onChange={(
              event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
              }>
            ) => {
              const v = event.target.value as string;
              setHeardAboutUs(v);
            }}
            value={heardAboutUs || ''}
          >
            <MenuItem disabled value="" style={{ fontFamily: 'Inter' }}>
              How did you hear about us?
            </MenuItem>
            {shuffleArray([
              'Google',
              'Facebook',
              'Referral from friend',
              'Mechanic referral',
              'Door Hanger',
              'Mail',
            ]).map((i) => (
              <MenuItem key={i} value={i} style={{ fontFamily: 'Inter' }}>
                {i}
              </MenuItem>
            ))}
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
        </div>
      </CustomFormField>

      <TextField
        style={{ width: '100%', marginTop: 30 }}
        variant="filled"
        label="Coupon code (optional)"
        value={referralCode}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end" style={{ background: '#fff' }}>
              {referralCode !== '' && (
                <CheckCircle
                  className={classes.checked}
                  style={{ marginLeft: 5, marginTop: isSm ? 10 : 0 }}
                />
              )}
            </InputAdornment>
          ),
          style: {
            borderRadius: '8px',
            fontSize: isSm ? 15 : 17,
            border: '0.5px solid grey',
            background: '#fff',
            fontFamily: 'Inter',
            fontWeight: 500,
          },
        }}
        onChange={(e) => setReferralCode(e.target.value)}
        fullWidth
      />

      <Button
        disabled={!isFormValid || requesting}
        onClick={handleContinue}
        style={{
          background: isFormValid ? '#005959' : '#D4D5D5',
          color: '#fff',
          maxWidth: 200,
          margin: '30px auto 30px 0px',
        }}
        className={classes.buttonForward}
        variant="contained"
        size="large"
      >
        {requesting ? 'Calculating Quote...' : 'View Quote'}
      </Button>

      {error && (
        <Typography
          style={{ margin: '10px', color: '#ff0000', fontFamily: 'inter' }}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default ContactStep;
