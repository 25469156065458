import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { Dialog, makeStyles } from '@material-ui/core';

interface ModalBaseProps {
  className?: string;
  open: boolean;
  onClose: () => void;
  children: ReactElement;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '15px',
    borderRadius: '10px',
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#2A2D3C',
  },
  btnClose: {
    marginLeft: 'auto',
  },
}));

export default function ModalBase(props: ModalBaseProps): ReactElement {
  const { className, open, onClose, children, maxWidth } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      onClose={onClose}
      maxWidth={maxWidth}
      PaperProps={{
        className: clsx(classes.root, className),
      }}
    >
      {children}
    </Dialog>
  );
}

ModalBase.defaultProps = {
  className: '',
  maxWidth: 'xs',
};
