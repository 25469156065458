import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box, Typography } from '@mui/material';

type VehicleDataItemProps = {
  icon: string;
  heading: string;
  headingValue: string | number;
  status: string;
  isPressure: boolean;
  wheelIcon: string;
};

const VehicleDetailsElement: React.FC<VehicleDataItemProps> = ({
  icon,
  heading,
  headingValue,
  status,
  isPressure,
  wheelIcon,
}) => {
  const statusColor = () => {
    let color = '';
    if (status.toLocaleLowerCase() === 'good') {
      color = '#157F40';
    } else if (status.toLocaleLowerCase() === 'getting low') {
      color = '#FFC107';
    } else if (status.toLocaleLowerCase() === 'low') {
      color = '#CE3426';
    }
    return color;
  };

  const styles = {
    accordion: {
      borderRadius: '8px',
      border: '1px solid #ECEAEA',
      padding: '20px',
      boxShadow: `0 4px 8px 0 #876B6614`,
      '&:last-of-type': {
        borderRadius: '16px !important',
      },
      '&:first-of-type': {
        borderRadius: '16px !important',
      },
      '&:before': {
        display: 'none',
      },
    },
    accordionSummary: {
      minHeight: 'fit-content',
      margin: '0px',
      padding: '0px',
      '.MuiAccordionSummary-content': {
        margin: '0px',
      },
      '.MuiAccordionSummary-expandIconWrapper': {
        marginTop: '15px',
      },
    },
    iconTextBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      rowGap: 2,
      width: '100%',
    },
    headingText: {
      fontSize: '11px',
      lineHeight: '14.3px',
      color: '#797C7A',
    },
    percentageBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '8px',
    },
    percentageText: {
      fontSize: '16px',
      lineHeight: '19.2px',
      fontWeight: 600,
      color: '#1F2421',
    },
    statusText: {
      fontSize: '11px',
      lineHeight: '14.3px',
      color: statusColor(),
    },
    detailsText: {
      fontSize: '14px',
      lineHeight: '18.2px',
      color: '#797C7A',
    },
    highlightText: {
      fontSize: '14px',
      lineHeight: '18.2px',
      color: '#1F2421',
    },
  };

  return (
    <Accordion disableGutters sx={styles.accordion}>
      <AccordionSummary expandIcon={null} sx={styles.accordionSummary}>
        <Box sx={styles.iconTextBox}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <img src={icon} alt={heading} />
            {isPressure && <img src={wheelIcon} alt={heading} />}
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography sx={styles.headingText}>{heading}</Typography>
            <Box sx={styles.percentageBox}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography sx={styles.percentageText}>
                  {headingValue}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '4px',
                  }}
                >
                  <Box
                    sx={{
                      width: '6px',
                      height: '6px',
                      backgroundColor: statusColor(),
                      borderRadius: '100%',
                    }}
                  />
                  <Typography sx={styles.statusText}>{status}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
    </Accordion>
  );
};

export default VehicleDetailsElement;
