import React, { ReactElement } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ZipcodeQuote } from 'src/components/molecules';

interface ModalInputZipProps {
  show: boolean;
  className?: string;
  onClose?: () => void;
  onGetQuote?: (payload: { zip?: string; customer?: number }) => void;
}

const ModalInputZip = (props: ModalInputZipProps): ReactElement => {
  const { className, show, onGetQuote, ...rest } = props;

  return (
    <Dialog open={show} scroll="body" {...rest}>
      <DialogContent className={className}>
        <ZipcodeQuote onGetQuote={onGetQuote} />
      </DialogContent>
    </Dialog>
  );
};

ModalInputZip.defaultProps = {
  className: undefined,
  onClose: undefined,
  onGetQuote: undefined,
};

export default ModalInputZip;
