export enum QuoteStep {
  QUOTE_SERVICE_DESK = '0',
  QUOTE_SEARCH_CAR = '1',
  QUOTE_CONTACT = '2',
  QUOTE_CONGRATS = '3',
}

export enum QuoteShowModal {
  NONE,
  NOT_SURE_REASON,
  NOT_SURE_OTHER,
  REVIEW_QUOTE,
  SCHEDULE_SERVICE,
  FINISH_BOOKING,
  MEMBER,
  CONTACT,
  CONGRATS,
  HANDLE_ESTIMATE_RESPONSE,
  PAYMENT_OPTIONS,
  JOIN_MEMBER,
}

export enum CarSelectType {
  BY_YEAR_MAKE_MODEL = 'car-select-by-year-make-model',
  BY_PLATE_NUMBER = 'car-select-by-plate-number',
}
