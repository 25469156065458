// Page for unsubscribing from emails

import React, { ReactElement, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { apiBaseUrl } from 'src/utils/config';

function unsubscribe(email: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ unsubscribe: { email } }),
  };

  return fetch(`${apiBaseUrl}/api/v1/unsubscribes`, requestOptions).then(
    (response) => {
      return response.status;
    }
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    height: '100vh',
    background: '#eeeeee',
  },
  card: {
    maxWidth: '800px',
    background: '#ffffff',
  },
  error: {
    color: 'red',
  },
  success: {
    color: theme.palette.primary.main,
  },
}));

const Unsubscribe = (): ReactElement => {
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const handleUnsubscribe = () => {
    unsubscribe(email).then((response) => {
      if (response === 201) {
        setError('');
        setShowSuccess(true);
      } else {
        setError('Error unsubscribing, please try again.');
      }
    });
  };

  // return a material ui card with a form to unsubscribe
  return (
    <div className={classes.root}>
      <div>
        <Card
          className={classes.card}
          style={{ padding: '30px 30px 10px 30px' }}
        >
          <Typography
            variant="h5"
            gutterBottom
            style={{ fontWeight: 'bold', marginLeft: '13px' }}
          >
            Unsubscribe
          </Typography>
          <CardContent>
            <div>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>

            <p className={classes.error}>{error}</p>
            {showSuccess && (
              <p className={classes.success}>You&apos;ve been unsubscribed.</p>
            )}

            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                disableElevation
                size="large"
                onClick={handleUnsubscribe}
                variant="contained"
                color="primary"
                style={{
                  borderRadius: 31,
                  textTransform: 'none',
                }}
              >
                Unsubscribe
              </Button>
            </div>
          </CardContent>
        </Card>
        <Typography
          align="center"
          style={{ fontSize: '13px', marginTop: '8px' }}
        >
          Problems? Contact Us by following{' '}
          <Link href="mailto:team@goodhood.auto">this link</Link>
        </Typography>
      </div>
    </div>
  );
};

export default Unsubscribe;
