import iconAdvantage from 'src/assets/advantage';
import imgCandidate from 'src/assets/candidates';
import imgCustomer from 'src/assets/customers';
import imgBadge from 'src/assets/badges';
import imgService from 'src/assets/services';
import imgPayment from 'src/assets/payment';

import { QuoteStep, CarSelectType } from 'src/types';

export const DIAG_PRICE = 70;

export const OIL_CHANGE_PRICE = 90;

export const advantageCompareList = {
  FIXMYCAR: 'Fixmycar',
  REPAIR_SHOP: 'Repair Shop',
};

export const itemsAdvantage = [
  {
    img: iconAdvantage.money,
    title: 'Affordable & honest',
    subtitle:
      "Fixed & upfront pricing. No hidden fees and you'll get the lowest prices guaranteed.",
  },
  {
    img: iconAdvantage.ring,
    title: "We'll come to you",
    subtitle:
      'Friendly, car mechanics will come to your home, office or parking lot.',
  },
  {
    img: iconAdvantage.time,
    title: 'Flexible hours',
    subtitle:
      'Open every-day from 9am - 9pm. Book our epxerienced certified technicians today.',
  },
];

export const itemsShop = [
  {
    img: iconAdvantage.shop_money,
    title: 'Expensive & devius',
    subtitle: '30% more expensive and come with additional fees & upsells.',
  },
  {
    img: iconAdvantage.shop_location,
    title: 'Inconvinient service locations',
    subtitle: 'Repairs must be done at a physical auto repair shop.',
  },
  {
    img: iconAdvantage.shop_rigid,
    title: 'Rigid hours',
    subtitle: 'Open limited hours and at times closed on weekends.',
  },
];

export const itemsCustomer = [
  {
    img: imgCustomer.mariah,
    name: 'Mariah Heighl',
  },
  {
    img: imgCustomer.johnny,
    name: 'Johnny Riece',
  },
  {
    img: imgCustomer.marian,
    name: 'Marian Huves',
  },
  {
    img: imgCustomer.alice,
    name: 'Alice Bleu',
  },
];

export const itemsLocation = [
  {
    state: 'Michigan',
    description: 'Metro Detroit',
    city: [
      'Detroit',
      'Sterling Heights',
      'Warren',
      'Ann Arbor',
      'Dearborn',
      'Livonia',
      'Detroitt',
      'Sterling Heightst',
      'Warrent',
      'Ann Arbort',
      'Dearbornt',
      'Livoniat',
      'Detroitr',
      'Sterling Heightsr',
      'Warrenr',
      'Ann Arborr',
      'Dearbornr',
      'Livoniar',
      'Detroite',
      'Sterling Heightsa',
      'Warrenq',
    ],
  },
  {
    state: 'Texas',
    description: 'Dallas Fort Worth & Houston',
    city: [
      'Detroit',
      'Sterling Heights',
      'Warren',
      'Ann Arbor',
      'Dearborn',
      'Livonia',
      'Detroitt',
      'Sterling Heightst',
      'Warrent',
      'Ann Arbort',
      'Dearbornt',
      'Livoniat',
      'Detroitr',
      'Sterling Heightsr',
      'Warrenr',
      'Ann Arborr',
      'Dearbornr',
      'Livoniar',
      'Detroite',
      'Sterling Heightsa',
      'Warrene',
    ],
  },
];

export const itemsCandidates = [
  {
    name: 'Steve Gerrard',
    photo: imgCandidate.steven,
    photoASE: 'steven-ase.png',
    rating: 5,
    review: 106,
    bio: 'I have a Master Certification and I pride on good work. Really enjoy working on cars',
    mention: [
      '22 years of experience',
      'Background checked',
      "Passed Goodhood's test",
    ],
    badge: [
      {
        name: 'Brakes',
        img: imgBadge.brakes,
      },
      {
        name: 'Suspension',
        img: imgBadge.suspension,
      },
      {
        name: 'Engine',
        img: imgBadge.engine,
      },
      {
        name: 'Electrical',
        img: imgBadge.electrical,
      },
      {
        name: 'Diagnosis',
        img: imgBadge.diagnosis,
      },
      {
        name: 'European Cars',
        img: imgBadge.european_cars,
      },
    ],
  },
  {
    name: 'Mark Zuckerberg',
    photo: imgCandidate.mark,
    rating: 5,
    review: 106,
    bio: 'I have a Master Certification and I pride on good work. Really enjoy working on cars',
    mention: [
      '22 years of experience',
      'Background checked',
      "Passed Goodhood's test",
    ],
    badge: [
      {
        name: 'Brakes',
        img: imgBadge.brakes,
      },
      {
        name: 'Suspension',
        img: imgBadge.suspension,
      },
      {
        name: 'Engine',
        img: imgBadge.engine,
      },
      {
        name: 'Electrical',
        img: imgBadge.electrical,
      },
      {
        name: 'Diagnosis',
        img: imgBadge.diagnosis,
      },
      {
        name: 'European Cars',
        img: imgBadge.european_cars,
      },
    ],
  },
];

export const itemFooter = {
  menu: [
    {
      title: 'Blogs',
      url: 'https://www.goodhood.auto/blog',
    },
    {
      title: 'Services',
      url: 'https://goodhood.auto/services',
    },
    {
      title: 'FAQ',
      url: 'https://goodhood.auto/faq',
    },
  ],
  carMakers: [
    'Acura',
    'Aston Martin',
    'Bentley',
    'Cadilac',
    'Ferrari',
    'Infinity',
    'Lexus',
    'Lincoin',
    'Maserati',
    'Maybach',
    'Mazda',
    'Mercury',
    'Mini',
    'Bmw',
    'Buick',
    'Hummer',
    'Acurat',
    'Aston Martint',
    'Bentleyt',
    'Cadilact',
    'Ferrarit',
    'Infinityt',
    'Lexust',
    'Lincoint',
    'Maseratit',
    'Maybacht',
    'Mazdat',
    'Mercuryt',
    'Minit',
    'Bmwt',
    'Buickt',
    'Hummert',
    'Acurar',
    'Aston Martinr',
    'Bentleyr',
    'Cadilacr',
    'Ferrarir',
    'Infinityr',
    'Lexusr',
    'Lincoinr',
    'Maseratir',
    'Maybachr',
    'Mazdar',
    'Mercuryr',
    'Minir',
    'Bmwr',
    'Buickr',
    'Hummerr',
  ],
};

// quote variables
export const stateList = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const arrCarSelectTypes = {
  [CarSelectType.BY_YEAR_MAKE_MODEL]: 'Year/make/model',
  [CarSelectType.BY_PLATE_NUMBER]: 'Plate number',
};

export const arrQuoteSteps = [
  QuoteStep.QUOTE_SERVICE_DESK,
  QuoteStep.QUOTE_SEARCH_CAR,
  QuoteStep.QUOTE_CONTACT,
];

export const allMileage = [
  'less than 50,000',
  '50,000 - 75,000',
  '75,000 -  100,000',
  '100,000 - 150,000',
  'more than 150,000',
];

export const allStaticServices = [
  {
    name: 'Oil Change',
    image: imgService.oil_change,
    imageSelected: imgService.oil_change_selected,
  },
  {
    name: 'Front Brake Pads & Rotors',
    image: imgService.brakes,
    imageSelected: imgService.brakes_selected,
  },
  {
    name: 'Replace Alternator',
    image: imgService.diagnosis,
    imageSelected: imgService.diagnosis_selected,
  },
  {
    name: 'Replace Starter',
    image: imgService.replace_starter,
    imageSelected: imgService.replace_starter_selected,
  },
  {
    name: 'Replace Battery',
    image: imgService.replace_battery,
    imageSelected: imgService.replace_battery_selected,
  },
  {
    name: 'Rear Brake Pads & Rotors',
    image: imgService.brakes,
    imageSelected: imgService.brakes_selected,
  },
];

export const faq = [
  {
    q: 'Does the inspection include the travel fee?',
    a: 'The insepction service includes all the travel fees. The fixed price you get, is the fixed price you pay.',
  },
  {
    q: 'How long does it take for a tech to contact me?',
    a: 'The insepction service includes all the travel fees. The fixed price you get, is the fixed price you pay.',
  },
  {
    q: 'If a tech cannot find me, will they call?',
    a: 'The insepction service includes all the travel fees. The fixed price you get, is the fixed price you pay.',
  },
  {
    q: 'If my car cannot be fixed, what happens?',
    a: 'The insepction service includes all the travel fees. The fixed price you get, is the fixed price you pay.',
  },
  {
    q: 'May I use the same tech in the future?',
    a: 'The insepction service includes all the travel fees. The fixed price you get, is the fixed price you pay.',
  },
  {
    q: 'What if I find a cheaper alternative?',
    a: 'The insepction service includes all the travel fees. The fixed price you get, is the fixed price you pay.',
  },
];

export const cardTypes = [
  {
    title: 'visa',
    image: imgPayment.visa,
  },
  {
    title: 'mastercard',
    image: imgPayment.mastercard,
  },
  {
    title: 'american-express',
    image: imgPayment.american_express,
  },
];

export const staticServiceList = [
  'Positive Battery Cable Replacement',
  'Negative Battery Cable Replacement',
  'Battery Replacement',
  'Car AC Belt Replacement',
  'Alternator Replacement',
  'Alternator Belt Replacement',
  'Drive Belt Tensioner Replacement',
  'Front Brake Pad Replacement',
  'Rear Brake Pad Replacement',
  'Front & Rear Brake Pad Replacement',
  'Front Brake Pads & Rotors',
  'Rear Brake Pads & Rotors',
  'Front Brake Rotors/Discs Replacement',
  'Front Brake Pads & Rotors',
  'Rear Brake Pads & Rotors',
  'Rear Brake Rotors/Discs Replacement',
  'Front & Rear Brake Rotors/Discs Replacement',
  'Anti Lock Control Relay Replacement',
  'Front Brake Caliper Replacement',
  'Rear Brake Caliper Replacement',
  'Brake Master Cylinder Replacement',
  'Rear Brake Shoe Replacement',
  'Brake Vacuum Pump Replacement',
  'Emergency/Parking Brake Cable Replacement',
  'Emergency/Parking Brake Shoe Replacement',
  'Emergency Brake Control Replacement',
  'Parking Brake Release Cable Replacement',
  'Vacuum Brake Booster Check Valve Replacement',
  'Vacuum Pump Repair',
  'Hub & Bearing Assembly',
  'Front Wheel Speed Sensor Replacement (One Side)',
  'Front Wheel Speed Sensor Replacement (Both Sides)',
  'Rear Wheel Speed Sensor Replacement (One Side)',
  'Rear Wheel Speed Sensor Replacement (Both Sides)',
  'Pre-Purchase Inspection',
  'Axle Shaft Seal Replacement',
  'Differential Gasket Replacement',
  'Differential Output Seal Replacement',
  'Shift Interlock Solenoid Replacement',
  'Shift Selector Cable Replacement',
  'Shifter Interlock Solenoid Replacement',
  'Transmission Position Sensor (Switch) Replacement',
  'Transmission Speed Sensor Replacement',
  'Car Door Lock Actuator Replacement',
  'Car Door Lock Relay Replacement',
  'Hood Latch Replacement',
  'Hood Lift Support Shocks Replacement',
  'Trunk Latch Release Cable Replacement',
  'Trunk Lift Support Shocks Replacement',
  'Trunk Lock Actuator Replacement',
  'Throttle Cable Repair',
  'Air Bleed Housing Assembly Replacement',
  'Car Air Pump Check Valve Replacement',
  'Car Air Pump Replacement',
  'Automatic Shutdown Relay Replacement',
  'Barometric Sensor Replacement',
  'Coolant Recovery Reservoir Replacement',
  'Coolant Reservoir Replacement',
  'Coolant Temperature Switch (Sensor) Replacement',
  'Crankshaft Harmonic Balancer Replacement',
  'Cruise Control Cable Replacement',
  'Distributor Replacement',
  'Engine or Transmission Mount Replacement',
  'Exhaust Gas Recirculation/EGR Valve Replacement',
  'Exhaust Manifold Gasket Replacement',
  'Fan Clutch Replacement',
  'Front Crankshaft Seal Replacement',
  'Car Heater Hoses Replacement',
  'Idle Control Valve Replacement',
  'Idler Pulley Replacement',
  'Intake Manifold Gaskets Replacement',
  'Intake Manifold Runner Control Replacement',
  'Manifold Absolute Pressure Sensor (MAP Sensor) Replacement',
  'Oil Cooler Adapter Gasket Replacement',
  'Oil Cooler Lines Replacement',
  'Oil Cooler Repair',
  'Oil Filter Housing Gasket Replacement',
  'Oil Pan Gasket Replacement',
  'Oil Pan Replacement',
  'Oil Pressure Sensor Replacement',
  'PCV Valve Hose Replacement',
  'Positive Crankcase Ventilation (PCV) Valve Replacement',
  'Power Steering Belt Replacement',
  'Serpentine/Drive Belt Replacement',
  'Supercharger Belt Replacement',
  'Car Thermostat Replacement',
  'Throttle Body Replacement',
  'Throttle Cable Replacement',
  'Throttle Controller Replacement',
  'Valve Cover Gasket Replacement',
  'Variable Valve Timing (VVT) Solenoid Replacement',
  'Variable Valve Timing (VVT) Switch Replacement',
  'Vent Oil Separator Replacement',
  'Water Pump Pulley Replacement',
  'Water Pump Replacement',
  'Windshield Washer Reservoir Replacement',
  'Canister Purge Solenoid Replacement',
  'EVP Position Sensor Replacement',
  'Evaporation Vent Solenoid Replacement',
  'Evaporative Emission Control Canister Replacement',
  'Evaporator Temperature Sensor (Switch) Replacement',
  'Car Engine Air Filter Replacement',
  'Cabin Air Filter Replacement',
  'Fuel Filter Replacement',
  'Brake System Drain/Refill',
  'Engine Oil Change',
  'Car Radiator Replacement',
  'Cooling System Drain/Refill',
  'Power Steering Fluid Drain/Refill',
  'Radiator Drain/Refill',
  'Cooling/Radiator Fan Motor Replacement',
  'Pressure Test Radiator Cap',
  'Fuel Injector O Rings Replacement',
  'Fuel Injector Replacement',
  'Fuel Pressure Regulator Replacement',
  'Fuel Pressure Sensor Replacement',
  'Fuel Pump Replacement',
  'Injector Control Pressure Sensor Replacement',
  'Blower Motor Relay Replacement',
  'Blower Motor Switch Replacement',
  'Condenser Fan Relay Replacement',
  'Expansion Valve (Orifice Tube) Replacement',
  'Car Heater Blower Motor Replacement',
  'Car Heater Blower Motor Resistor Replacement',
  'Car Heater Control Valve Replacement',
  'Air Injection Hose Replacement',
  'Brake Hose Replacement',
  'Exhaust Gas Recirculation (EGR) Tube Replacement',
  'Upper Radiator Hose Replacement',
  'Lower Radiator Hose Replacement',
  'Ignition Cable (Spark Plug Wires) Replacement',
  'Ignition Coil Replacement',
  'Replace Starter',
  'Spark Plug Replacement',
  'Tune Up',
  'Brake Light Bulb Replacement',
  'Brake Light Switch Replacement',
  'Console Light Bulb Replacement',
  'Fog Light Relay Replacement',
  'Fog/Driving Light Bulb Replacement',
  'Hazard / Turn Signal Flasher Replacement',
  'Headlight Bulb Replacement (Low Beam)',
  'Headlight Bulb Replacement (High Beam)',
  'License Plate Light Replacement',
  'Reverse Light Bulb Replacement',
  'Tail Light Bulb Replacement',
  'Tail Light Assembly Replacement',
  'Trunk Light Bulb Replacement',
  'Turn Signal Bulb Replacement',
  'Abs Speed Sensor Replacement',
  'Air Charge Temperature Sensor Replacement',
  'Air Cleaner Temperature Sensor Replacement',
  'Camshaft Position Sensor Replacement',
  'Coolant Level Sensor Replacement',
  'Crankshaft Position Sensor Replacement',
  'Low Water Sensor Replacement',
  'Manifold Temperature Sensor Replacement',
  'Mass Airflow Sensor Replacement',
  'Oil Temperature Sensor Replacement',
  'Oxygen Sensor Replacement',
  'Throttle Position Sensor (Tps) Replacement',
  'Throttle/Accelerator Pedal Position Sensor Replacement',
  'Wheel Speed Sensor Replacement',
  'Cv Axle / Shaft Assembly Replacement (Front Driver Side)',
  'Cv Axle / Shaft Assembly Replacement (Rear Passenger Side)',
  'Cv Axle / Shaft Assembly Replacement (Front Driver Side)',
  'Cv Axle / Shaft Assembly Replacement (Rear Passenger Side)',
  'Ball Joint Replacement (Front Passenger Side)',
  'Ball Joint Replacement (Front Driver Side)',
  'Ball Joint Replacement (Rear Passenger Side)',
  'Ball Joint Replacement (Rear Driver Side)',
  'Center (Drag) Link Replacement',
  'Front Control Arm Assembly Replacement (Left Lower)',
  'Front Control Arm Assembly Replacement (Left Upper)',
  'Front Control Arm Assembly Replacement (Right Lower)',
  'Front Control Arm Assembly Replacement (Right Upper)',
  'Rear Control Arm Assembly Replacement (Left Lower)',
  'Rear Control Arm Assembly Replacement (Left Upper)',
  'Rear Control Arm Assembly Replacement (Right Lower)',
  'Rear Control Arm Assembly Replacement (Right Upper)',
  'Differential/Gear Oil Replacement',
  'Differential Fluid Service Replacement',
  'Drag Link Replacement',
  'Lateral Link Replacement',
  'Pitman Arm Replacement',
  'Power Steering Fluid Reservoir Replacement',
  'Power Steering Input Shaft Seal Replacement',
  'Power Steering Pressure Hose Replacement',
  'Power Steering Pressure Switch Replacement',
  'Power Steering Pump Pulley Replacement',
  'Power Steering Pump Replacement',
  'Front Shock Absorber Replacement (One Side)',
  'Front Shock Absorber Replacement (Both Sides)',
  'Rear Shock Absorber Replacement (One Side)',
  'Rear Shock Absorber Replacement (Both Sides)',
  'Front & Rear Shock Absorber Replacement (Both Sides)',
  'Stabilizer Bar Bushings Replacement',
  'Stabilizer Bar Links Replacement',
  'Front Strut Assembly Replacement (Driver Side)',
  'Front Strut Assembly Replacement (Passenger Side)',
  'Front Strut Assembly Replacement (Both Sides)',
  'Rear Strut Assembly Replacement (Driver Side)',
  'Rear Strut Assembly Replacement (Passenger Side)',
  'Rear Strut Assembly Replacement (Both Sides)',
  'Sway Bar Replacement',
  'Tie Rod End Replacement (Front Left Inner)',
  'Tie Rod End Replacement (Front Left Outer)',
  'Tie Rod End Replacement (Front Right Inner)',
  'Tie Rod End Replacement (Front Right Outer)',
  'Tie Rod End Replacement (Rear Left Inner)',
  'Tie Rod End Replacement (Rear Left Outer)',
  'Tie Rod End Replacement (Rear Right Inner)',
  'Tie Rod End Replacement (Rear Right Outer)',
  'Universal Joint (U Joint) Replacement',
  'Wheel/Hub Bearings Replacement (Front Left)',
  'Wheel/Hub Bearings Replacement (Front Right)',
  'Wheel/Hub Bearings Replacement (Rear Left)',
  'Wheel/Hub Bearings Replacement (Rear Right)',
  'Wheel Stud Replacement',
  'Reverse Lights Switch Replacement',
  'Clutch Safety Switch Replacement',
  'Clutch Switch Replacement',
  'Car Door Lock Switch Replacement',
  'Headlight Switch Replacement',
  'Neutral Safety Switch Replacement',
  'Power Window Switch Repair',
  'Turn Signal Switch Replacement',
  'Power Window Switch Repair (Front Left)',
  'Power Window Switch Repair (Front Right)',
  'Power Window Switch Repair (Right Left)',
  'Power Window Switch Repair (Right Rear)',
  'Window Motor Regulator Replacement (Front Left)',
  'Window Motor Regulator Replacement (Front Right)',
  'Window Motor Regulator Replacement (Right Left)',
  'Window Motor Regulator Replacement (Right Right)',
  'Window Motor Regulator Replacement',
  'Windshield Washer Pump Replacement',
  'Windshield Wiper Arm Replacement',
  'Windshield Wiper Blade Replacement',
  'Windshield Wiper Linkage Replacement',
  'Windshield Wiper Motor Replacement',
  'Windshield Wiper Refill Replacement',
  'Windshield Wiper Switch Replacement',
  'Wiper Gearbox Replacement',
  'Windshield Replacement',
  'Side Mirror Replacement',
  'Rear View Mirror Replacement',
  'Driver Side Window Glass Replacement',
  'Passenger Side Window Glass Replacement',
];

export const carFacts = [
  'Most vehicles are equipped with a cabin air filter that filters the air for your A/C!',
  'Cold air can actually shrink the air in your tires, causing your light to come on in the winter!',
  'If your engine air filter gets too dirty it can actually restrict air flow and sometimes shut off the engine!',
  'Changing your spark plugs every 75,000 to 100,000 miles can help increase your fuel efficiency!',
  'Taking 5 minutes every other time you get gas, to check your fluids can save your car!',
  "If you're planning to let your car sit for an extended period, make sure to start it every few days and let it run for about 10 minutes!",
  'If your vehicle overheats, you should try not to drive it until you can get the problem addressed. It could save your engine!',
  'Using a fuel treatment at every oil change could clean the carbon build up off your fuel injectors and help fuel efficiency!',
  "Some fluids in the vehicle need to be flushed through and replaced with a clean fluid! Check your owner's manual for a maintenance schedule!",
  'A flashing check engine light is a sign to stop driving the vehicle. It means there is something detrimentally wrong in the engine somewhere!',
  'Getting your check engine light scanned, is often times not a diagnosis of the vehicle! It only tells you where to begin looking!',
];

export const quoteOptions = [
  'Car is not starting',
  'Check engine light is on',
  'Warning light is on',
  'Fluids are leaking',
  'Car is shaking (vibrating)',
  'Brakes are squeaking',
  'Smoke or steam is coming out of the car',
  'Car is overheating',
  'Windows are not going up or down',
  'Other',
];
