import React, { ErrorInfo } from 'react';
import postError from './api/error';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    postError({ error: `${error.message}, STACK\n${error.stack}` });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div style={{ margin: '20px', padding: '20px' }}>
          <h3>Something went wrong. Our team has been notified.</h3>
          <p>
            Call us at <a href="tel:469-840-3990">469-840-3990</a> if you need
            assistance.
          </p>
        </div>
      );
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
