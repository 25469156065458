import React, { useContext, useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  Card,
  Typography,
  Button,
  makeStyles,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import AmericanExpress from 'src/assets/payment/american-express.svg';
import MasterCard from 'src/assets/payment/mastercard.svg';
import VisaCard from 'src/assets/payment/visa.svg';
import {
  CardOnFile,
  RequestAuthPayPalDiag,
  RequestAuthPayPalRepair,
  RequestConfirmAppointment,
  ResponseAppointment,
  ResponseSignin,
} from 'src/types';
import {
  confirmAppointment,
  confirmCardOnFile,
  confirmPayPal,
} from 'src/api/quote';
import postError from 'src/api/error';
import { setAppointment, setAuthToken } from 'src/store/actions';
import { callAdsBooking } from 'src/utils/ads';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK_FUNNEL_V2 } from 'src/utils/consts';
import CheckoutForm from 'src/views/Quote/components/Modals/ModalFinishBooking/CheckoutForm';
import { IReduxState } from 'src/store/reducers';
import callApi from 'src/api/request';
import { useSnackbar } from 'notistack';
import { signIn } from 'src/api/auth';
import { PaypalContent } from 'src/views/Quote/components/Modals/ModalFinishBooking/ModalFinishBooking';
import { useDispatch, useSelector } from 'react-redux';
import { QuoteConfirmedContext } from './Context/QuoteConfirmedContext';
import QuoteSummaryMobile from '../atoms/QuotesummaryMobile';

interface PaymentStepProps {
  onNext: () => void;
  onBack: () => void;
  onComplete: () => void;
  priceSelected: string;
  billingType: string;
  memberSelected: boolean;
  hideBack: boolean;
}

const useStyles = makeStyles((theme) => ({
  cardOnFile: {
    padding: 16,
    background: '#E6EEEE',
    borderRadius: 8,
    border: '1px solid var(--blue-50, #227596)',
    marginTop: 20,
    fontFamily: 'Inter',
    '& .MuiTypography-body1': {
      fontFamily: 'Inter',
    },
  },
  Diagnosescard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
    background: '#fff',
  },
  clock: {
    marginTop: 30,
    width: 80,
    height: 80,
    flexShrink: 0,
    color: '#005959',
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  li: {
    color: '#005959',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#fff',
  },
  whiteCard: {
    paddingTop: 15,
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    flex: 1,

    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  mobileRoot: {
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    background: '#fff',
    padding: theme.spacing(3),
    outline: 'none',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalTitle: {
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  Hdiv: {
    display: 'flex',
    flexDirection: 'row',
  },
  customFontHead: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',

    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '30px',
    marginTop: 40,
  },
  customFontSub: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '30px',
    marginTop: 10,
  },
  customTabs: {
    '& .MuiTabs-fixed': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTabs-indicator': {
      display: 'none !important',
    },

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  tab1: {
    alignSelf: 'start',
    borderRadius: 8,
    width: '50%',
    color: '#1A5B74',
    fontWeight: 700,
    border: '1px solid var(--blue-50, #227596)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },
    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',

      textTransform: 'none',

      alignItems: 'start',
    },
    fontFamily: 'Inter',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  tab1notSelected: {
    alignSelf: 'start',
    borderRadius: 8,
    fontWeight: 700,
    width: '50%',
    color: '#646867',
    border: '1px solid var(--blue-50, #D4D5D5)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },
    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',

      textTransform: 'none',

      alignItems: 'start',
    },
    fontFamily: 'Inter',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },

  tab2: {
    alignSelf: 'end',
    borderRadius: 8,
    fontWeight: 700,
    fontFamily: 'Inter',
    color: '#1A5B74',
    border: '1px solid var(--blue-50, #227596)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },

    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',
      textTransform: 'none',

      alignItems: 'start',
    },
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  tab2notSelected: {
    alignSelf: 'end',
    borderRadius: 8,
    color: '#646867',
    fontWeight: 700,
    fontFamily: 'Inter',
    border: '1px solid var(--blue-50, #D4D5D5)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },

    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',
      textTransform: 'none',

      alignItems: 'start',
    },
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  reservationButton: {
    borderRadius: 31,
    textTransform: 'none',
    margin: '20px auto',
    boxShadow: 'none',
    width: '100%',
    padding: theme.spacing(2),
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
}));

const PaymentStep: React.FC<PaymentStepProps> = ({
  onNext,
  onBack,
  onComplete,
  priceSelected,
  billingType,
  memberSelected,
  hideBack,
}) => {
  const { QuoteConfirmed, setQuoteConfirmed } = useContext(
    QuoteConfirmedContext
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState<string | undefined | null>(null);
  const [stripeRequestInProgress, setStripeRequestInProgress] = useState(false);
  const [paypalRequestInProgress, setPaypalRequestInProgress] = useState(false);
  const [payPalError, setPayPalError] = useState<string | undefined>();
  const [payPalTotal, setPayPalTotal] = useState<number>(0);
  const [message, setMessage] = useState<string | undefined>();
  const [isCardOnFile, setIsCardOnFile] = useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [lastFour, setLastFour] = useState<string>('');
  const [cardBrand, setCardBrand] = useState<string>('');
  const [prevCardSelected, setPrevCardSelected] = useState(false);
  const [errorCardOnFile, setErrorCardOnFile] = useState<
    string | undefined | null
  >(null);

  const login = async (resp: ResponseAppointment) => {
    const response: ResponseSignin = await signIn(
      {
        id: `${resp.data && resp.data.id}`,
      },
      true
    );

    if (response && response.auth_token && response.user && response.user.id) {
      dispatch(
        setAuthToken(response.auth_token, response.user.id, response.user.email)
      );

      onComplete();
      onNext();
    } else {
      setStripeRequestInProgress(false);
      setErrors('Invalid payment information');
    }
  };

  const handleCheckboxChange = () => {
    setPrevCardSelected(!prevCardSelected);
  };

  const showCommonError = (msg?: string | undefined) => {
    const errorMsg: string =
      msg || 'An error occured while processing your quote.';
    enqueueSnackbar(errorMsg, {
      variant: 'error',
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    });
  };

  const handleConfirmCardOnFile = async () => {
    if (!appointment?.id) {
      return;
    }

    setRequestInProgress(true);

    confirmCardOnFile(appointment?.id)
      .then((resp: ResponseAppointment) => {
        if (resp.errors) {
          showCommonError(resp.errors.join('. '));
          setRequestInProgress(false);
        } else {
          dispatch(setAppointment(resp.data));
          callAdsBooking(resp.data);
          login(resp);
        }
      })
      .catch(() => {
        showCommonError();
        setRequestInProgress(false);
      })
      .finally(() => setRequestInProgress(false));
  };

  useEffect(() => {
    const userId = appointment?.attributes.user_id;

    if (userId) {
      callApi<CardOnFile>({
        url: `/api/v2/user/card_on_file/${userId}`,
        method: 'GET',
      }).then((response) => {
        if (response.errors && response.errors.length > 0) {
          setErrorCardOnFile(
            'An error occurred while processing your request.'
          );
        } else if (
          response.card_on_file?.card_brand &&
          response.card_on_file?.last_4_digits
        ) {
          setIsCardOnFile(true);
          setLastFour(response.card_on_file?.last_4_digits as string);
          setCardBrand(response.card_on_file?.card_brand);
        }
      });
    }
  }, [appointment?.attributes.user_id]);

  const stripe = useStripe();
  const elements = useElements();

  const isSm = useMediaQuery('(max-width: 1300px)');

  const handleCheckOut = async () => {
    setStripeRequestInProgress(true);
    setErrors(null);

    if (!appointment?.id) {
      setStripeRequestInProgress(false);
      return false;
    }

    if (!stripe || !elements) {
      setStripeRequestInProgress(false);
      return false;
    }

    const cardElem = elements.getElement(CardElement);

    if (!cardElem) {
      setStripeRequestInProgress(false);
      return false;
    }

    const { token } = await stripe.createToken(cardElem);

    if (token) {
      const data: RequestConfirmAppointment = {
        token: token.id,
      };

      if (memberSelected) {
        if (billingType === 'monthly') {
          data.member_price_agreed_on_before_job = 180;
        } else {
          data.member_price_agreed_on_before_job = 290;
        }
      }

      await confirmAppointment(appointment.id, data)
        .then((resp: ResponseAppointment) => {
          if (resp.errors) {
            setErrors(resp.errors.join(', '));
            setStripeRequestInProgress(false);
          } else {
            dispatch(setAppointment(resp.data));
            callAdsBooking(resp.data);
            setStripeRequestInProgress(false);
            onComplete();
            onNext();
            mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_CARD_INFO);
          }
        })
        .catch((error) => {
          setStripeRequestInProgress(false);
        });
    } else {
      setStripeRequestInProgress(false);
      setErrors('Invalid payment information');
    }

    setStripeRequestInProgress(false);

    return true;
  };

  useEffect(() => {
    const getPayPalTotal = () => {
      let price = 0;

      if (appointment?.attributes.appointment_type === 'diagnosis') {
        const diagPrice = appointment?.attributes.member_job
          ? appointment?.attributes.diagnosis_price_member
          : appointment?.attributes.diagnosis_price;

        if (diagPrice) {
          price += diagPrice;
        }
      }

      if (appointment?.attributes.estimate) {
        const servicePrice = appointment?.attributes.member_job
          ? appointment?.attributes.estimate.total_member_price
          : appointment?.attributes.estimate.total_price;

        if (servicePrice) {
          price += servicePrice;
        }
      }

      return price || 0;
    };

    setPayPalTotal(getPayPalTotal() || 0);
  }, [
    appointment?.attributes.diagnosis_price,
    appointment?.attributes.diagnosis_price_member,
    appointment?.attributes.appointment_type,
    appointment?.attributes.member_job,
    appointment?.attributes.estimate,
  ]);

  const handleConfirmPaypal = async (
    data: RequestAuthPayPalDiag | RequestAuthPayPalRepair
  ) => {
    if (!appointment) {
      return;
    }

    setPaypalRequestInProgress(true);

    confirmPayPal(appointment.id, false, data)
      .then((resp: ResponseAppointment) => {
        if (resp.errors) {
          setPaypalRequestInProgress(false);
        } else {
          dispatch(setAppointment(resp.data));
          callAdsBooking(resp.data);
          onComplete();
          onNext();
        }
      })
      .catch(() => {
        setPaypalRequestInProgress(false);
      })
      .finally(() => setPaypalRequestInProgress(false));
  };

  const handlePayPalCheckout = async (
    order_id: string,
    auth_id: string,
    payer_id: string
  ) => {
    if (
      appointment?.attributes.appointment_type === 'diagnosis' &&
      !appointment?.attributes.estimate
    ) {
      const data: RequestAuthPayPalDiag = {
        paypal_diag_order_id: order_id,
        paypal_diag_auth_id: auth_id,
        paypal_payer_id: payer_id,
      };

      await handleConfirmPaypal(data);
    } else {
      const data: RequestAuthPayPalRepair = {
        paypal_repair_order_id: order_id,
        paypal_repair_auth_id: auth_id,
        paypal_payer_id: payer_id,
      };

      await handleConfirmPaypal(data);
    }
  };

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card');

  const handlePaymentMethodChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const isCardSelected = selectedPaymentMethod === 'card';
  const isPayPalSelected = selectedPaymentMethod === 'paypal';

  return (
    <>
      <>
        {hideBack ? (
          <div style={{ height: 50 }} />
        ) : (
          <Button
            className={classes.buttonForward}
            style={{
              color: '#005959',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 0px 20px 0px',
            }}
            onClick={() => {
              onBack();
            }}
          >
            <ArrowBack
              style={{
                width: 20,
                height: 20,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />{' '}
            <Typography
              style={{
                margin: 'auto 0 auto 10px',
                fontSize: 16,
                fontFamily: 'Inter',
              }}
            >
              Back
            </Typography>
          </Button>
        )}
        <Card
          style={{
            minWidth: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: isSm ? 20 : 0,
            padding: 10,
            background: '#fff',
            width: isSm ? '100%' : 600,
          }}
          elevation={0}
        >
          {isSm && <QuoteSummaryMobile priceSelected={priceSelected} />}
          <Card
            style={{
              background: '#fff',
              padding: 20,
              minWidth: 'fit-content',
              width: '100%',
              borderRadius: '8px',
            }}
            elevation={0}
          >
            <img
              src={VisaCard}
              alt="Credit Card"
              style={{
                width: 50,
                marginBottom: 20,
                marginRight: 10,
              }}
            />
            <img
              src={MasterCard}
              alt="Credit Card"
              style={{
                width: 30,
                marginBottom: 20,
                marginRight: 10,
              }}
            />
            <img
              src={AmericanExpress}
              alt="Credit Card"
              style={{
                width: 50,
                marginBottom: 20,
              }}
            />
            <Typography
              style={{
                fontSize: 26,
                fontFamily: 'Inter',
                fontWeight: 600,
                marginBottom: 15,
              }}
            >
              Confirm Payment
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: 400,

                display: 'flex',
                flexDirection: 'row',
              }}
            >
              Confirm appointment details.{' '}
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: 600,
                marginBottom: 15,
              }}
            >
              You won&apos;t be charged until the day of service. &nbsp;
            </Typography>{' '}
            {appointment?.attributes?.appointment_type === 'repair' && (
              <RadioGroup
                value={selectedPaymentMethod}
                onChange={handlePaymentMethodChange}
                style={{ display: 'flex', gap: 20, flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="card"
                  control={<Radio style={{ color: '#005959' }} />}
                  label={
                    <>
                      <Typography style={{ fontFamily: 'Inter' }}>
                        {' '}
                        Card
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value="paypal"
                  control={<Radio style={{ color: '#005959' }} />}
                  label={
                    <>
                      <Typography style={{ fontFamily: 'Inter' }}>
                        PayPal
                      </Typography>
                    </>
                  }
                />
              </RadioGroup>
            )}
            {isCardSelected && (
              <div>
                {isCardOnFile && (
                  <Card className={classes.cardOnFile} elevation={0}>
                    <Typography
                      style={{ fontFamily: 'Inter', fontWeight: 600 }}
                    >
                      Use card on file
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={prevCardSelected}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label={`${cardBrand} ending ${lastFour}`}
                    />
                  </Card>
                )}

                {!prevCardSelected && (
                  <CheckoutForm errors={errors} message={message} />
                )}
              </div>
            )}
            {isPayPalSelected && (
              <div>
                {(QuoteConfirmed?.service?.service_type === 'repair' ||
                  appointment?.attributes?.appointment_type === 'repair') && (
                  <Card
                    style={{
                      width: '100%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      padding: 20,
                      background: '#fff',
                      marginTop: 20,
                      fontFamily: 'Inter',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                    }}
                    elevation={1}
                  >
                    {payPalTotal > 0 ? (
                      <PayPalScriptProvider
                        options={{
                          clientId:
                            process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
                          intent: 'authorize',
                          'disable-funding': 'card',
                          'enable-funding': 'paylater',
                          components: 'messages,buttons',
                        }}
                      >
                        <PaypalContent
                          payPalTotal={payPalTotal}
                          handlePayPalCheckout={handlePayPalCheckout}
                          setPayPalError={setPayPalError}
                        />
                      </PayPalScriptProvider>
                    ) : (
                      <></>
                    )}
                  </Card>
                )}{' '}
              </div>
            )}
            {!isPayPalSelected && (
              <Button
                onClick={
                  isCardOnFile && prevCardSelected
                    ? handleConfirmCardOnFile
                    : handleCheckOut
                }
                disabled={
                  stripeRequestInProgress ||
                  paypalRequestInProgress ||
                  requestInProgress
                }
                style={{
                  background:
                    stripeRequestInProgress ||
                    paypalRequestInProgress ||
                    requestInProgress
                      ? '#D4D5D5'
                      : '#005959',
                  marginTop: '35px',
                  marginLeft: '2px',
                  color: '#fff',
                }}
                className={classes.buttonForward}
                variant="contained"
                size="large"
              >
                Confirm Payment
                {!stripeRequestInProgress && <ArrowForward />}
              </Button>
            )}
          </Card>
        </Card>
      </>
    </>
  );
};

export default PaymentStep;
