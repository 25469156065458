import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';

import {
  Card,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import Confetti from 'src/assets/payment/Confetti.svg';
import { apiApplyReferralCode } from 'src/api/quote';
import { setAppointment } from 'src/store/actions';

interface ReferralProps {
  bgColor?: string;
}

const ReferralBox: React.FC<ReferralProps> = (props: ReferralProps) => {
  const { bgColor } = props;

  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const dispatch = useDispatch();

  const [referralCode, setReferralCode] = useState(
    appointment?.attributes?.coupon_used?.code || ''
  );
  const [referralApplied, setReferralApplied] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const isSm = useMediaQuery('(max-width: 1300px)');

  const applyReferralCode = () => {
    if (appointment?.id) {
      apiApplyReferralCode(appointment?.id, referralCode).then((res) => {
        if (res.errors) {
          setValidationMessage(res.errors[0]);
        } else {
          setReferralApplied(true);
          dispatch(setAppointment(res.data));
        }
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferralCode(e.target.value);
    setReferralApplied(false);
    setValidationMessage('');
  };

  if (appointment?.attributes?.appointment_type === 'diagnosis') {
    return <></>;
  }

  const getBgColor = () => {
    if (bgColor) {
      return bgColor;
    }
    return isSm ? '#F3F3F3' : '#fff';
  };

  return (
    <Card
      style={{
        width: '100%',
        background: getBgColor(),
        marginTop: 10,
        borderRadius: '8px',
        padding: isSm ? '20px 0px' : '20px 20px 10px 20px',
      }}
      elevation={0}
    >
      <Typography
        style={{
          marginBottom: 15,
          fontFamily: 'Inter',
          fontSize: isSm ? 16 : 18,
        }}
      >
        Got a Coupon Code?
      </Typography>
      <TextField
        variant="filled"
        value={referralCode}
        onChange={handleChange}
        label="Enter code"
        style={{
          background: '#B3CDCD',
          marginBottom: !referralApplied ? 15 : 0,
          borderRadius: 8,
          fontFamily: 'Inter',
          width: '70%',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={applyReferralCode}
                color="primary"
                disabled={referralApplied}
                style={{ background: '#E6EEEE' }}
              >
                {referralApplied ? (
                  <img
                    src={Confetti}
                    alt="Applied!"
                    style={{
                      width: 24,
                    }}
                  />
                ) : (
                  <Button style={{ textTransform: 'none' }} color="primary">
                    Apply
                  </Button>
                )}
              </IconButton>
            </InputAdornment>
          ),
          style: {
            background: '#E6EEEE',
            fontFamily: 'Inter',
            border: '1px solid grey',
            borderRadius: '8px',
          },
          disableUnderline: true,
        }}
        error={!!validationMessage}
      />
      {validationMessage && (
        <Typography
          style={{
            margin: 15,
            marginTop: 0,
            fontFamily: 'Inter',
            fontWeight: 500,
            color: 'red',
          }}
        >
          {validationMessage}
        </Typography>
      )}
      {referralApplied && (
        <Typography
          style={{
            fontFamily: 'Inter',
            fontWeight: 500,
            marginTop: 10,
            color: '#005959',
            fontSize: 16,
          }}
        >
          Code Applied!
        </Typography>
      )}
    </Card>
  );
};

ReferralBox.defaultProps = {
  bgColor: undefined,
};

export default ReferralBox;
