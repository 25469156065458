import React, { ReactElement, useContext } from 'react';
import {
  Dialog,
  Container,
  useMediaQuery,
  useTheme,
  Box,
  Divider,
} from '@material-ui/core';
import { DirectionsCarOutlined } from '@material-ui/icons';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { QuoteContext } from 'src/views/Quote/QuoteContext';
import ReferralCode from 'src/views/Quote_New/components/atoms/ReferralCode';
import { IReduxState } from 'src/store/reducers';
import { IAppointment, QuoteShowModal, QuoteStep } from 'src/types';
import { useSelector } from 'react-redux';
import { Layout } from 'src/layouts';
import { acceptEstimate } from 'src/api/quote';
import { brandOf } from 'src/assets/brands';
import mixPanel from 'src/utils/mixpanel';
import isAllInspections from 'src/utils/inspections';
import { MIXPANEL_TRACK } from 'src/utils/consts';
import Invoice from './components/Invoice';
import InvoiceFooter from './components/InvoiceFooter';
import ServiceCard from './components/ServiceCard';
import useFontStyles from '../../fontStyles';
import useStyles from './styles';
import FlatServiceCard from './components/FlatServiceCard';
import getFlatCardDescriptions from './components/data';
import PayPalText from './components/PayPalText';

interface ModalReviewQuoteProps {
  show: boolean;
  onClose: () => void;
}

const NewModalReviewQuote = (props: ModalReviewQuoteProps): ReactElement => {
  const query = new URLSearchParams(useLocation().search);
  const { show, onClose } = props;
  const fontClasses = useFontStyles();
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const appointment: IAppointment | null = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const estimate = useSelector(
    (state: IReduxState) => state.quote.appointment?.attributes.estimate
  );

  const {
    handleShowModal,
    isMechanicEstimate,
    isOfficeEstimate,
    handleSetStep,
  } = useContext(QuoteContext);

  if (!appointment || !appointment?.attributes || !appointment?.id) {
    return <></>;
  }

  const { attributes } = appointment;

  const {
    appointment_type: appointmentType,
    car,
    services,
    diagnosis_price: diagFee,
    diagnosis_price_member: diagFeeMember,
    member_job: memberJob,
    member_type: memberType,
    diag_discount: diagDiscount,
    coupon_used: coupon,
    fleet_job: fleetJob,
  } = attributes;

  const isServiceQuote: boolean = appointmentType === 'repair';
  const isDiag: boolean = appointmentType === 'diagnosis';
  const isInspections: boolean =
    estimate === null && isAllInspections(services);
  const hasEstimate: boolean = estimate !== null;

  const isOilChange: boolean | null | undefined =
    estimate &&
    estimate.created_by === 'Flat Oil Change' &&
    services.length === 1 &&
    services[0].includes('Oil Change');

  const viewingQuote = query.get('viewQuote');

  const isRepair = !((isDiag || isInspections) && !isMechanicEstimate);

  const getPrice = () => {
    let price = 0;

    if (isServiceQuote || estimate || isMechanicEstimate)
      price += estimate ? estimate.total_price : 0;

    if (!isMechanicEstimate) {
      if (isDiag) price += diagFee;
    }

    return price;
  };

  const getFlatPrice = () => {
    if (!memberType) {
      return attributes.diagnosis_fee;
    }

    if (memberType === 'Unlimited') {
      return 0;
    }

    return attributes.diagnosis_fee * 0.9;
  };

  const getMemberPrice = () => {
    if (isDiag && !estimate)
      return memberType === 'Unlimited' ? 0 : diagFeeMember;

    return estimate?.total_member_price || 0;
  };

  const getNonMemberPrice = () => {
    if (isDiag && !estimate) return diagFee;

    return estimate?.non_member_price || 0;
  };

  const getTitle = () => {
    if (!isMechanicEstimate) {
      if (isDiag && !isInspections) return 'Diagnosis';
    }

    if (isMechanicEstimate && estimate?.services) {
      return Object.keys(estimate.services).join(', ');
    }

    return services.join(', ');
  };

  const handleContinue = () => {
    if (isMechanicEstimate && !isOfficeEstimate && estimate) {
      acceptEstimate(estimate.id, null).then(() => {
        handleShowModal(QuoteShowModal.HANDLE_ESTIMATE_RESPONSE);
      });
    } else {
      if (isServiceQuote) {
        mixPanel(MIXPANEL_TRACK.REPAIR_ESTIMATE);
      } else {
        mixPanel(MIXPANEL_TRACK.DIAGNOSIS_ESTIMATE);
      }

      if (!memberType) handleShowModal(QuoteShowModal.JOIN_MEMBER);
      else handleShowModal(QuoteShowModal.SCHEDULE_SERVICE);
    }
  };

  const getNextStep = () => {
    return handleContinue();
  };

  const getButtonText = () => {
    if (isMechanicEstimate) {
      return 'Approve Services';
    }

    return 'Continue';
  };

  const getDiscount = () => {
    if (!isMechanicEstimate) {
      if (isDiag && !hasEstimate) {
        return diagDiscount || coupon?.dollars_off || 0;
      }
    }

    return estimate?.discount_total || 0;
  };

  if (!isMechanicEstimate) {
    if (isServiceQuote) {
      mixPanel(MIXPANEL_TRACK.LOADED_REPAIR_ESTIMATE);
    } else {
      mixPanel(MIXPANEL_TRACK.LOADED_DIAG_ESTIMATE);
    }
  } else {
    mixPanel(MIXPANEL_TRACK.LOADED_ESTIMATE_RESPONSE);
  }

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      fullScreen
      className={classes.dialog}
    >
      <Layout className={isSm ? classes.layout : ''}>
        <Container
          maxWidth="lg"
          className={classes.root}
          style={{
            background: '#ffffff',
            marginBottom: '5rem',
          }}
        >
          <motion.div
            style={{ flex: 1 }}
            initial={{ y: 250 }}
            animate={{ y: -10 }}
            transition={{ duration: 0.5 }}
          >
            <h1
              className={isSm ? fontClasses.h4 : fontClasses.h2}
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: isSm ? 'center' : 'left',
              }}
            >
              {isSm && (
                <img
                  src={brandOf(car.make)}
                  alt=""
                  style={{ marginRight: '4px', height: '50px' }}
                />
              )}
              Your Service Quote
            </h1>

            {hasEstimate && !fleetJob && (
              <Box style={{ margin: '10px' }}>
                <PayPalText
                  amount={memberJob ? `${getMemberPrice()}` : `${getPrice()}`}
                />
              </Box>
            )}

            {appointment.attributes.car && (
              <>
                {!isSm && (
                  <h2
                    className={fontClasses.h5}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: isSm ? 'center' : 'left',
                    }}
                  >
                    <DirectionsCarOutlined
                      style={{ fontSize: '18px', marginRight: '4px' }}
                    />{' '}
                    Your Vehicle
                  </h2>
                )}
                <h2
                  className={fontClasses.h5}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: isSm ? 'center' : 'left',
                  }}
                >
                  {car.year} {car.make} {car.model}
                </h2>
                {car.vin && (
                  <h2
                    className={fontClasses.h5}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: isSm ? 'center' : 'left',
                    }}
                  >
                    VIN: {car.vin}
                  </h2>
                )}
                {car.name && (
                  <h2
                    className={fontClasses.h5}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: isSm ? 'center' : 'left',
                    }}
                  >
                    {car.name}
                  </h2>
                )}
              </>
            )}

            {!isRepair && !isMechanicEstimate && (
              <FlatServiceCard
                title={getTitle()}
                price={getFlatPrice()}
                descriptions={getFlatCardDescriptions('diagnosis')}
              />
            )}

            {isOilChange ? (
              <FlatServiceCard
                title={services[0]}
                price={getPrice()}
                descriptions={getFlatCardDescriptions('oil_change')}
              />
            ) : (
              <div>
                {estimate &&
                  Object.keys(estimate.services).map((serviceName: string) => {
                    const serviceDetails = estimate.services[serviceName];
                    return (
                      <ServiceCard
                        key={serviceDetails.id}
                        amtServices={Object.keys(estimate.services).length}
                        appId={appointment.id}
                        memberJob={memberJob}
                        service={{
                          [serviceName]: serviceDetails,
                        }}
                      />
                    );
                  })}
              </div>
            )}

            {isSm && (
              <Box
                style={{
                  margin: '20px 0',
                }}
              >
                <div style={{ margin: '10px 0' }} />
                <Divider />
                <div style={{ margin: '10px 0' }} />
                {appointment.attributes.appointment_type !== 'diagnosis' && (
                  <ReferralCode
                    bgColor={isMechanicEstimate ? '#fff' : undefined}
                  />
                )}
              </Box>
            )}

            <div style={{ paddingBottom: '200px' }} />
          </motion.div>
          <motion.div
            className={classes.invoice}
            style={{ flex: 1 }}
            initial={{ y: 250 }}
            animate={{ y: -10 }}
            transition={{ duration: 0.5 }}
          >
            <Invoice
              subtotal={estimate?.subtotal}
              memberSubtotal={estimate?.member_subtotal}
              price={getPrice()}
              isRepair={isRepair}
              isDiag={isDiag}
              diagFee={getFlatPrice()}
              hasEstimate={hasEstimate}
              tax={estimate?.tax}
              isMember={memberJob || false}
              discount={getDiscount()}
              coupon={coupon}
              convenienceFee={estimate?.convenience_fee || 0}
              inconvenienceDiscount={estimate?.inconvenience_discount}
              laborOnlyFee={estimate?.labor_only_fee}
              memberPrice={getMemberPrice()}
              nonMemberPrice={getNonMemberPrice()}
              onContinue={() => getNextStep()}
              buttonText={getButtonText()}
              showBack={!isMechanicEstimate && !isOfficeEstimate}
              handleBack={() => {
                handleShowModal(QuoteShowModal.NONE);
                if (viewingQuote) {
                  window.location.assign('/dashboard');
                } else {
                  handleSetStep(QuoteStep.QUOTE_CONTACT);
                }
              }}
              isOilChange={isOilChange}
              estimateResponse={isMechanicEstimate}
              memberSavings={estimate?.member_savings}
              fleetJob={appointment?.attributes?.fleet_job}
            />
            {!isSm && (
              <Box
                style={{
                  marginLeft: '70px',
                  marginTop: '20px',
                }}
              >
                <ReferralCode />
              </Box>
            )}
          </motion.div>

          {isSm && (
            <InvoiceFooter
              subtotal={estimate?.subtotal}
              memberSubtotal={estimate?.member_subtotal}
              price={getPrice()}
              onContinue={() => getNextStep()}
              isMember={memberJob || false}
              tax={estimate?.tax}
              discount={getDiscount()}
              coupon={coupon}
              convenienceFee={estimate?.convenience_fee || 0}
              laborOnlyFee={estimate?.labor_only_fee}
              memberPrice={getMemberPrice()}
              inconvenienceDiscount={estimate?.inconvenience_discount}
              nonMemberPrice={getNonMemberPrice()}
              buttonText={getButtonText()}
              isRepair={isRepair}
              hasEstimate={hasEstimate}
              isDiag={isDiag}
              diagFee={getFlatPrice()}
              showBack={!isMechanicEstimate && !isOfficeEstimate}
              handleBack={() => {
                handleShowModal(QuoteShowModal.NONE);
                if (viewingQuote) {
                  window.location.assign('/dashboard');
                } else {
                  handleSetStep(QuoteStep.QUOTE_CONTACT);
                }
              }}
              isOilChange={isOilChange}
              estimateResponse={isMechanicEstimate}
              memberType={memberType}
              memberSavings={estimate?.member_savings}
              fleetJob={appointment?.attributes?.fleet_job}
            />
          )}
        </Container>
      </Layout>
    </Dialog>
  );
};
export default NewModalReviewQuote;
