import React, { ReactElement } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import QuoteDone from 'src/views/Quote/QuoteDone';

import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Quote_New_Layout,
  DashboardUC,
} from 'src/layouts';
import NewQuoteLayoutWithoutLogin from 'src/layouts/NewQuoteLayoutWithoutLogin';
import { URLS } from 'src/utils/consts';

import NewLogin from 'src/views/NewLogin';
import SignUp from 'src/views/SignUp';
import RouteWithLayout from './RouteWithLayout';

import {
  Quote,
  Home,
  Dashboard,
  Login,
  Unsubscribe,
  EditAppointment,
  CancelAppointment,
  RescheduleAppointment,
  Quote_New,
  ReviewNew,
  QuoteServiceConfirm,
  QuotePaymentConfirm,
} from '../views';

const Routes = (): ReactElement => {
  return (
    <Switch>
      <RouteWithLayout
        component={Home}
        exact
        layout={MainLayout}
        path={URLS.HOME}
        otherDomainUrl="https://www.goodhood.auto/"
      />
      <RouteWithLayout
        component={Quote_New}
        exact
        layout={Quote_New_Layout}
        path={URLS.QUOTE_NEW}
      />
      <RouteWithLayout
        component={Quote_New}
        exact
        layout={Quote_New_Layout}
        path={URLS.QUOTE}
      />
      <RouteWithLayout
        component={Quote_New}
        exact
        layout={Quote_New_Layout}
        path={URLS.NEW_ESTIMATE}
      />
      <RouteWithLayout
        component={Quote}
        exact
        layout={MinimalLayout}
        path={URLS.ESTIMATE_RESPONSE}
      />
      <RouteWithLayout
        component={QuoteServiceConfirm}
        exact
        layout={Quote_New_Layout}
        path={URLS.ESTIMATE}
      />
      <RouteWithLayout
        component={QuoteDone}
        exact
        layout={Quote_New_Layout}
        path={URLS.CONFIRMED}
      />
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={DashboardUC}
        path={URLS.DASHBOARD}
      />
      <RouteWithLayout
        component={NewLogin}
        exact
        layout={NewQuoteLayoutWithoutLogin}
        path={URLS.LOGIN}
      />
      <RouteWithLayout
        component={Login}
        exact
        layout={NewQuoteLayoutWithoutLogin}
        path={URLS.LOGIN_FLEET}
      />
      <RouteWithLayout
        component={SignUp}
        exact
        layout={NewQuoteLayoutWithoutLogin}
        path={URLS.SIGN_UP_FLEET}
      />
      <RouteWithLayout
        component={QuotePaymentConfirm}
        exact
        layout={Quote_New_Layout}
        path={URLS.CONFIRM}
      />
      <RouteWithLayout
        component={EditAppointment}
        exact
        layout={MainLayout}
        path={URLS.EDIT_APPOINTMENT}
      />
      <RouteWithLayout
        component={CancelAppointment}
        exact
        layout={MinimalLayout}
        path={URLS.CANCEL_APPOINTMENT}
      />
      <RouteWithLayout
        component={RescheduleAppointment}
        exact
        layout={MinimalLayout}
        path={URLS.RESCHEDULE_APPOINTMENT}
      />
      <Route component={Unsubscribe} exact path={URLS.UNSUBSCRIBE} />
      <RouteWithLayout
        component={ReviewNew}
        exact
        layout={MinimalLayout}
        path={URLS.REVIEW}
      />
      <Redirect to={URLS.HOME} />
    </Switch>
  );
};

export default Routes;
