import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import {
  Card,
  Typography,
  Button,
  makeStyles,
  Select,
  MenuItem,
  styled,
  FormControl,
  TextField,
} from '@material-ui/core';

import 'react-calendar/dist/Calendar.css';

import MDatePicker from 'src/components/atoms/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { GoogleAddressNew } from 'src/components/atoms';
import { ResponseAvailability } from 'src/types';
import {
  checkAvailability,
  updateAppointment,
  updateZipCode,
} from 'src/api/quote';

import { setAppointment } from 'src/store/actions';

import moment from 'moment';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import mixPanel from 'src/utils/mixpanel';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    background: '#fff',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: '50px',
    fontFamily: 'inter',
    position: 'fixed',
  },
  continueButton: {
    marginLeft: theme.spacing(1),
  },
  timePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '100%',
    maxHeight: 320,
    margin: 'auto',
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  verticalInputs: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > *': {
        marginBottom: 10,
      },
    },
  },
  itemTimeSlot: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: '#7E7A92',

    background: theme.palette.common.white,

    '&.selected': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    color: '#fff',
  },
  whiteCard: {
    backgroundColor: theme.palette.common.white,
  },
  mobileRoot: {
    width: '100%',
  },
  TimeandLocation: {
    marginTop: 20,

    paddingBottom: 20,
    background: '#fff',
    marginBottom: 0,
    maxWidth: 350,
    width: '100%',
  },
}));

const CustomFormField = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: '40px',
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: '4px',

    padding: 0,
    paddingLeft: 20,

    border: 'none',
    color: 'none',
  },
  '& .MuiSelect-outlined': {
    borderRadius: '8px',
  },
  '& .MuiSelect-selectMenu': {
    minHeight: '0px',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    height: '2px',
    fontWeight: 500,

    fontFamily: 'Inter',
    paddingBottom: 19,
    backgroundColor: '#fff',
  },
  '& .MuiSelect-icon': {
    top: 'auto',
  },
}));

interface Appointment {
  selected_day: string | undefined;
  selected_window: string | undefined;
  exact_address: string | undefined;
}

interface Car {
  id: number;
  year: number | undefined;
  make: string;
  model: string;
  engine_size: string;
  vin: string;
}

interface StepProps {
  nextStep: () => void;
  prevStep: () => void;
  carDetails: Car | undefined;
  appointmentDetails: Appointment | undefined;
  setAppointmentDetails: (service: Appointment | undefined) => void;
}

const AppointmentSelectionstep: React.FC<StepProps> = ({
  nextStep,
  prevStep,
  appointmentDetails,
  setAppointmentDetails,
  carDetails,
}) => {
  const classes = useStyles();

  const user = useSelector((state: IReduxState) => state.auth.user);
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const [availability, setAvailability] = useState<
    ResponseAvailability | undefined
  >();

  const dispatch = useDispatch();

  const [addressDetails, setAddressDetails] = useState({
    route: '',
    street_number: '',
    locality: '',
    administrative_area_level_one: '',
    administrative_area_level_two: '',
    country: '',
    postal_code: '',
  });
  const [addressError, setErrorAddress] = useState<boolean>(false);

  const [location, setLocation] = useState(appointmentDetails?.exact_address);
  const [selectedTime, setSelectedTime] = useState<string | undefined>(
    appointmentDetails?.selected_window
  );
  const [arrivalInstructions, setArrivalInstructions] = useState<string>('');
  const [errorWithZip, setErrorWithZip] = useState<boolean>(false);

  const [expandedServicesSelected, setExpandedServicesSelected] =
    useState<boolean>(true);

  const [zipCode, setZipCode] = useState<string | undefined>(
    user?.attributes?.zip_code
  );

  const [continueClicked, setContinueClicked] = useState<boolean>(false);

  function convertToMoment(dateString: string | undefined): moment.Moment {
    const momentDate = moment(dateString, 'YYYY-MM-DD');

    return momentDate;
  }

  let initialSelectedDate;
  const selectedDateFromApi = moment(
    appointmentDetails?.selected_day,
    'YYYY-MM-DD'
  );
  if (selectedDateFromApi.isValid()) {
    initialSelectedDate = selectedDateFromApi.format('YYYY-MM-DD');
  } else {
    const today = moment();
    initialSelectedDate = today.format('YYYY-MM-DD');
  }

  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    convertToMoment(initialSelectedDate)
  );

  const isDateDisabled = (day: MaterialUiPickersDate | null): boolean => {
    if (!day) return false;

    const selectedDateTime = day.toDate().setHours(0, 0, 0, 0);
    const currentDate = new Date().setHours(0, 0, 0, 0);

    if (selectedDateTime < currentDate) return true;

    const selectedFormattedDay = day.format('YYYY-MM-DD').toString();

    if (availability?.data.attributes.availability) {
      const appointmentsOnSelectedDay =
        availability?.data.attributes.availability[selectedFormattedDay];

      if (!appointmentsOnSelectedDay) return true;
    }

    return false;
  };

  const handleTimeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTime(event.target.value as string);
  };

  const handleContinue = () => {
    setContinueClicked(true);
    if (appointment && selectedDate && selectedTime && location) {
      updateAppointment(appointment?.id, {
        appointment_day: selectedDate.format('YYYY-MM-DD').toString(),
        appointment_time: selectedTime,
        exact_address: location,
        type_of_site: '',
        hints_to_find: arrivalInstructions,
        apartment_number: '',
        location_attributes: {
          zip: zipCode || '',
          route: addressDetails.route,
          street_number: addressDetails.street_number,
          locality: addressDetails.locality,
          administrative_area_level_one:
            addressDetails.administrative_area_level_one,
          administrative_area_level_two:
            addressDetails.administrative_area_level_two,
          country: addressDetails.country,
          postal_code: addressDetails.postal_code,
        },
      }).then((res) => {
        setContinueClicked(false);
        dispatch(setAppointment(res.data));
        mixPanel('Fleet Booked', `${appointment?.attributes?.user_id}`);
        nextStep();
      });
    }
  };

  const selectedFormattedDay = selectedDate?.format('YYYY-MM-DD').toString();
  const currentDate = moment().format('YYYY-MM-DD');
  const appointmentsForSelectedDay =
    availability?.data.attributes.availability[
      selectedFormattedDay || currentDate
    ];

  const updateZipCodeAsync = async (newZipCode: string | undefined) => {
    try {
      if (appointment && newZipCode) {
        const zipresponse = await updateZipCode(newZipCode, appointment.id);

        if (zipresponse.errors) {
          setErrorWithZip(true);

          setTimeout(() => {
            setErrorWithZip(false);
          }, 5000);
        } else if (zipresponse.message) {
          setErrorWithZip(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateZipCodeAsync(zipCode);
  });

  const handleLocationInputChange = (key: string, value: string) => {
    setLocation(value);
  };

  const setExpandedFalse = () => {
    setExpandedServicesSelected(false);
  };

  const handleZipCodeChanged = (newZipCode: string) => {
    updateZipCodeAsync(newZipCode);
    setZipCode(newZipCode);
  };

  useEffect(() => {
    if (!availability && appointment) {
      checkAvailability(appointment?.id).then((res: ResponseAvailability) => {
        setAvailability(res);

        if (res.data.attributes.dedicated_schedule_time) {
          setSelectedTime(res.data.attributes.dedicated_schedule_time);
        }
      });
    }
  }, [availability, appointment, zipCode]);

  return (
    <>
      <Card
        style={{
          background: '#fff',
          textAlign: 'left',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%',
          borderRadius: '8px',
          boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
          padding: 30,
          paddingBottom: 100,
          paddingTop: 0,
          maxHeight: 750,
          overflow: 'scroll',
        }}
        elevation={1}
        onClick={setExpandedFalse}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: '16px',
            marginRight: 'auto',
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: '20px',
            fontWeight: 600,
          }}
        >
          Select Appointment Date
        </Typography>
        <MDatePicker
          date={selectedDate}
          shouldDisableDate={isDateDisabled}
          onChangeDate={(d: MaterialUiPickersDate) => {
            setSelectedDate(d);
          }}
        />

        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: '16px',
            marginRight: 'auto',
            marginTop: 10,
            marginBottom: '20px',
            marginLeft: '20px',
            fontWeight: 600,
          }}
        >
          Select Location
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          className={classes.verticalInputs}
        >
          <GoogleAddressNew
            value={location}
            valueChanged={(val: string) =>
              handleLocationInputChange('exact_address', val)
            }
            zipCodeChanged={handleZipCodeChanged}
            currentZip={appointment?.attributes.address}
            setAddressDetails={setAddressDetails}
            addressDetails={addressDetails}
            setErrorAddress={setErrorAddress}
            fleet={appointment?.attributes.fleet_job || false}
          />

          {errorWithZip && (
            <Typography
              style={{ color: 'red', fontFamily: 'Inter', fontSize: 14 }}
            >
              we do not have coverage in the {zipCode}, please select different
              address
            </Typography>
          )}
        </div>

        <CustomFormField fullWidth>
          <TextField
            style={{
              borderRadius: '8px',
              marginBottom: '20px',
              marginTop: '10px',
              border: '1px solid #D4D5D5',
            }}
            variant="outlined"
            placeholder="Extra arrival instructions (optional)"
            value={arrivalInstructions}
            onChange={(e) => setArrivalInstructions(e.target.value)}
          />
        </CustomFormField>

        {availability?.data.attributes.dedicated_schedule ? (
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              marginRight: 'auto',
              marginTop: 30,
              marginBottom: '20px',
              marginLeft: '20px',
              fontStyle: 'italic',
            }}
          >
            * Our tech will be out during your window of{' '}
            {availability?.data.attributes.dedicated_schedule_time}
          </Typography>
        ) : (
          <>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                marginRight: 'auto',
                marginTop: 30,
                marginBottom: '20px',
                marginLeft: '20px',
                fontWeight: 600,
              }}
            >
              Arrival Window
            </Typography>
            <CustomFormField fullWidth>
              <Select
                value={selectedTime}
                onChange={handleTimeSelect}
                variant="outlined"
                placeholder="select"
                MenuProps={{
                  classes: { paper: classes.menuPaper },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                fullWidth
              >
                <MenuItem value="">
                  <em>Select a Window</em>
                </MenuItem>
                {appointmentsForSelectedDay &&
                appointmentsForSelectedDay.length > 0 ? (
                  appointmentsForSelectedDay.map((appt) => (
                    <MenuItem
                      key={appt}
                      value={appt}
                      style={{ fontFamily: 'Inter' }}
                    >
                      {appt}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No available windows for this date
                  </MenuItem>
                )}
              </Select>
            </CustomFormField>
          </>
        )}

        <Button
          onClick={prevStep}
          style={{
            background: '#D4D5D5',

            marginTop: '35px',
            marginLeft: '10px',
            color: '#646867',
          }}
          className={classes.buttonForward}
          variant="contained"
          size="large"
        >
          <ArrowBack />
          &nbsp; Back
        </Button>
        <Button
          disabled={
            !location ||
            !selectedTime ||
            !selectedDate ||
            errorWithZip ||
            continueClicked
          }
          onClick={handleContinue}
          style={{
            background:
              !location || !selectedTime || !selectedDate || continueClicked
                ? '#D4D5D5'
                : '#005959',
            marginTop: '35px',
            marginLeft: '10px',
            color:
              !location || !selectedTime || !selectedDate || continueClicked
                ? '#000'
                : '#fff',
          }}
          className={classes.buttonForward}
          variant="contained"
          size="large"
        >
          {continueClicked ? 'Loading...' : 'Continue'} &nbsp;
          <ArrowForward />
        </Button>
      </Card>
    </>
  );
};

export default AppointmentSelectionstep;
