import React, { useContext, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Tab,
  Tabs,
  makeStyles,
  Chip,
  FormControl,
} from '@material-ui/core';
import { Autocomplete, TextField } from '@mui/material';
import {
  quoteOptions,
  staticServiceList,
  allStaticServices,
} from 'src/utils/data';
import { styled } from '@mui/material/styles';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK_FUNNEL_V2 } from 'src/utils/consts';
import { Cancel, AddCircle, ArrowForward } from '@material-ui/icons';
import { QuoteConfirmedContext } from './Context/QuoteConfirmedContext';

interface ServicesStepProps {
  onNext: () => void;
  onComplete: () => void;
  activeStep: number;
  setStepUpdated: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '50%',
    margin: 'auto',
    marginTop: 50,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
    background: '#fff',
    borderRadius: 5,
    '@media (max-width: 1450px)': {
      width: '100%',
    },
  },
  font: {
    fontSize: '18px',
    fontFamily: 'Inter',
  },
  fontSmall: {
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  chipSelected: {
    margin: theme.spacing(0.5),
    background: '#B3CDCD',
    color: '#005959',
    fontWeight: 600,
    fontFamily: 'Inter',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontFamily: 'Inter',
  },
  chipDiagnoses: {
    margin: theme.spacing(0.5),
    fontFamily: 'Inter',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      whiteSpace: 'nowrap',
    },
  },
  selectedChips: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    marginTop: 80,
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      whiteSpace: 'nowrap',
      maxHeight: 200,
      marginTop: 80,
    },
  },
  selectedChipsRepair: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      whiteSpace: 'nowrap',
      maxHeight: 200,
    },
  },
  cancelIcon: {
    background: '#B3CDCD',
    color: '#005959',
    '&:hover': {
      color: '#E74D4D',
    },
  },
  addIcon: {
    color: '#337A7A',
    '&:hover': {
      color: '#005959',
    },
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      boxShadow: 'none',
    },
  },
  scrollableContainer: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      maxHeight: 100,
      overflow: 'auto',
    },
  },
  selectBoxTop: {
    width: '100%',
    fontFamily: 'inter',
    borderRadius: '22px',
  },
  listbox: {
    maxHeight: 200,
    overflow: 'auto',
    fontSize: 16,
  },
  cellphoneHeader: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: 13,
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: '22px',
  },
  cellphone: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: 13,
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: '22px',
    textDecoration: 'underline',
  },
}));

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px', // Set the border radius to 5px
    height: '108px',
  },
}));

const CustomAutoComplete = styled(Autocomplete)(() => ({
  '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
    fontSize: '14px',
  },
  '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
    top: 'auto',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    height: 48,
  },

  '& .MuiSelect-select.MuiSelect-select': {
    height: '100%',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    backgroundColor: '#fff',
  },
}));

const ServicesStep: React.FC<ServicesStepProps> = ({
  onNext,
  onComplete,
  activeStep,
  setStepUpdated,
}) => {
  const { QuoteConfirmed, setQuoteConfirmed } = useContext(
    QuoteConfirmedContext
  );

  const serviceTypeLabel = ['diagnosis', 'repair'];

  const [valueEx, setValue] = useState(
    QuoteConfirmed.service.service_type
      ? serviceTypeLabel.indexOf(QuoteConfirmed.service.service_type)
      : 0
  );
  const [selectedOptionsDiagnoses, setSelectedOptionsDiagnoses] = useState<
    string[]
  >(QuoteConfirmed.service.diagnostic_problems);

  const [selectedUniqueOptionsDiagnoses, setSelectedUniqueOptionsDiagnoses] =
    useState<string[]>(QuoteConfirmed.service.diagnostic_problems);

  const [selectedUniqueOptionsRepair, setSelectedUniqueOptionsRepair] =
    useState<string[]>(QuoteConfirmed.service.repair_problems);

  const [selectedOptionsRepair, setSelectedOptionsRepair] = useState<string[]>(
    QuoteConfirmed.service.repair_problems
  );

  const [selectedServiceType, setSelectedServiceType] = useState<string>(
    QuoteConfirmed.service.service_type
  );

  const [describedProblem, setDescribedProblem] = useState<string>(
    QuoteConfirmed.service.diagnostic_problem_description
  );

  const [isObtainedDiagnoses, setIsObtainedDiagnoses] =
    useState<boolean>(false);
  const [isObtainedRepair, setIsObtainedRepair] = useState<boolean>(false);
  const [hasCompleted, setHasCompleted] = useState(false);

  const classes = useStyles();

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!hasCompleted) {
      onComplete();
      setHasCompleted(true);
    }
  }, [hasCompleted, onComplete]);

  const handleChipAddDiagnoses = (option: string) => {
    if (selectedOptionsDiagnoses.includes(option)) {
      return;
    }
    setSelectedOptionsDiagnoses((prevSelectedOptions) => [
      ...prevSelectedOptions,
      option,
    ]);
    setSelectedServiceType('diagnosis');
    setSelectedUniqueOptionsRepair([]);
    onComplete();
  };

  const handleChipAddRepair = (option: string) => {
    if (selectedOptionsRepair.includes(option) && option !== '') {
      return;
    }

    setSelectedOptionsRepair((prevSelectedOptions) => [
      ...prevSelectedOptions,
      option,
    ]);
    setSelectedServiceType('repair');
    setDescribedProblem('');
    setSelectedUniqueOptionsDiagnoses([]);
    onComplete();
  };

  const handleChipDeleteDiagnoses = (option: string) => {
    setSelectedOptionsDiagnoses((prevSelectedOptions) =>
      prevSelectedOptions.filter((selectedOption) => selectedOption !== option)
    );
  };

  const handleChipDeleteRepair = (option: string) => {
    setSelectedOptionsRepair((prevSelectedOptions) =>
      prevSelectedOptions.filter((selectedOption) => selectedOption !== option)
    );
  };

  const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedServiceType('diagnosis');
    setDescribedProblem(event.target.value);
  };

  useEffect(() => {
    const diagnosisStatus = describedProblem.trim() !== '';
    setIsObtainedDiagnoses(diagnosisStatus);
  }, [describedProblem]);

  const serviceOptions = allStaticServices.map((service) => service.name);

  const handleContinueDiagnoses = () => {
    setQuoteConfirmed({
      ...QuoteConfirmed,
      service: {
        ...QuoteConfirmed.service,
        service_type: selectedServiceType,
        diagnostic_problems: selectedUniqueOptionsDiagnoses,
        diagnostic_problem_description: describedProblem,
        repair_problems: selectedUniqueOptionsRepair,
      },
    });

    mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_SELECTED_SERVICE);
    mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_DIAG);

    if (activeStep === 1) {
      onNext();
    } else {
      setStepUpdated((prevStep) => prevStep + 1);
    }
  };

  const handleContinueRepair = () => {
    setQuoteConfirmed({
      ...QuoteConfirmed,
      service: {
        ...QuoteConfirmed.service,
        service_type: selectedServiceType,
        diagnostic_problems: selectedUniqueOptionsDiagnoses,
        diagnostic_problem_description: describedProblem,
        repair_problems: selectedUniqueOptionsRepair,
      },
    });
    mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_SELECTED_SERVICE);
    mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_REPAIR);

    if (activeStep === 1) {
      onNext();
    } else {
      setStepUpdated((prevStep) => prevStep + 1);
    }
  };

  useEffect(() => {
    setSelectedUniqueOptionsDiagnoses(
      Array.from(new Set(selectedOptionsDiagnoses))
    );
  }, [selectedOptionsDiagnoses]);

  useEffect(() => {
    setSelectedUniqueOptionsRepair(Array.from(new Set(selectedOptionsRepair)));
  }, [selectedOptionsRepair]);

  useEffect(() => {
    if (valueEx === 0 && selectedOptionsDiagnoses.length >= 1) {
      setIsObtainedDiagnoses(true);
    } else {
      setIsObtainedDiagnoses(false);
    }
  }, [valueEx, selectedOptionsDiagnoses]);

  useEffect(() => {
    if (valueEx === 1 && selectedUniqueOptionsRepair.length >= 1) {
      setIsObtainedRepair(true);
    } else {
      setIsObtainedRepair(false);
    }
  }, [valueEx, selectedUniqueOptionsRepair]);

  const phoneNumber = '469-840-3990';
  const handleCall = () => {
    window.location.href = `tel:${phoneNumber.replace('-', '')}`;
  };

  return (
    <>
      <Card
        style={{
          background: '#fff',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        elevation={0}
      >
        <CardContent
          style={{
            background: '#fff',
            margin: 'auto',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Tabs
            value={valueEx}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{ width: '90%' }}
          >
            <Tab label="Diagnosis" className={classes.fontSmall} />
            <Tab label="Repair or Service" className={classes.fontSmall} />
          </Tabs>

          {valueEx === 0 && (
            <div>
              {selectedUniqueOptionsDiagnoses.includes('Other') && (
                <CustomTextField
                  value={describedProblem}
                  style={{
                    marginTop: 20,
                    borderRadius: '8px',
                    height: 48,
                  }}
                  placeholder="Describe the problem you are facing"
                  fullWidth
                  multiline
                  maxRows={4}
                  rows={3}
                  onChange={handleTextboxChange}
                />
              )}

              {selectedUniqueOptionsDiagnoses.length >= 1 && (
                <div className={classes.selectedChips}>
                  {selectedUniqueOptionsDiagnoses.map((option) => (
                    <Chip
                      key={option}
                      label={option}
                      onDelete={() => handleChipDeleteDiagnoses(option)}
                      deleteIcon={<Cancel className={classes.cancelIcon} />}
                      className={classes.chipSelected}
                    />
                  ))}
                </div>
              )}

              <div
                style={{
                  marginTop:
                    selectedUniqueOptionsDiagnoses.length >= 1 ? 15 : 85,
                  marginBottom: 10,
                  overflowX: 'scroll',
                }}
              >
                {quoteOptions.map((option) => (
                  <Chip
                    key={option}
                    label={option}
                    onClick={() => handleChipAddDiagnoses(option)}
                    onDelete={() => handleChipAddDiagnoses(option)}
                    deleteIcon={<AddCircle className={classes.addIcon} />}
                    className={classes.chip}
                  />
                ))}
              </div>

              <Typography className={classes.cellphoneHeader}>
                Can’t find what you are looking for?
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography className={classes.cellphoneHeader}>
                  {' '}
                  Call Us:&nbsp;
                </Typography>
                <Typography
                  className={classes.cellphone}
                  onClick={handleCall}
                  style={{ cursor: 'pointer' }}
                >
                  469-840-3990
                </Typography>
              </div>

              <Button
                disabled={!isObtainedDiagnoses}
                onClick={handleContinueDiagnoses}
                style={{
                  background: !isObtainedDiagnoses ? '#D4D5D5' : '#005959',
                  marginTop: '35px',
                  marginLeft: '2px',
                }}
                className={classes.buttonForward}
                variant="contained"
                size="large"
              >
                Continue &nbsp;
                <ArrowForward />
              </Button>
            </div>
          )}

          {valueEx === 1 && (
            <>
              <Typography className={classes.font} style={{ marginTop: 20 }}>
                Select your services
              </Typography>

              <CustomAutoComplete
                fullWidth
                classes={{ listbox: classes.listbox }}
                options={staticServiceList}
                style={{ marginTop: 10, width: '100%' }}
                disablePortal
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleChipAddRepair(newValue as string);
                  }
                }}
                renderInput={(params) => (
                  <FormControl fullWidth>
                    <TextField
                      {...params}
                      placeholder="Search a Service"
                      variant="outlined"
                      className={classes.selectBoxTop}
                      fullWidth
                    />
                  </FormControl>
                )}
              />

              {selectedUniqueOptionsRepair.length >= 1 && (
                <div className={classes.selectedChipsRepair}>
                  {selectedUniqueOptionsRepair.map((option) => (
                    <Chip
                      key={option}
                      label={option}
                      onDelete={() => handleChipDeleteRepair(option)}
                      deleteIcon={<Cancel className={classes.cancelIcon} />}
                      className={classes.chipSelected}
                    />
                  ))}
                </div>
              )}
              <Typography
                className={classes.fontSmall}
                style={{ marginTop: 10 }}
              >
                Our Popular Services
              </Typography>
              <div style={{ marginTop: 15, marginBottom: 10 }}>
                {serviceOptions.map((option) => (
                  <Chip
                    key={option}
                    label={option}
                    onClick={() => handleChipAddRepair(option)}
                    onDelete={() => handleChipAddRepair(option)}
                    deleteIcon={<AddCircle className={classes.addIcon} />}
                    className={classes.chip}
                  />
                ))}
              </div>
              <Typography className={classes.cellphoneHeader}>
                Can&apos;t find what you are looking for?
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography className={classes.cellphoneHeader}>
                  {' '}
                  Call Us:&nbsp;
                </Typography>
                <Typography
                  className={classes.cellphone}
                  onClick={handleCall}
                  style={{ cursor: 'pointer' }}
                >
                  469-840-3990
                </Typography>
              </div>

              <Button
                disabled={!isObtainedDiagnoses && !isObtainedRepair}
                onClick={handleContinueRepair}
                style={{
                  background:
                    !isObtainedDiagnoses && !isObtainedRepair
                      ? '#D4D5D5'
                      : '#005959',
                  marginTop: '35px',
                  marginLeft: '2px',
                }}
                className={classes.buttonForward}
                variant="contained"
                size="large"
              >
                Continue &nbsp;
                <ArrowForward />
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ServicesStep;
