import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/store/actions';
import { URLS } from 'src/utils/consts';
import {
  AppBar,
  Toolbar,
  Button,
  Avatar,
  Hidden,
  Chip,
} from '@material-ui/core';
import { Image } from 'src/components/atoms';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import SvgLogo from 'src/assets/logo.svg';
import { IReduxState } from 'src/store/reducers';
import LoginIcon from '@mui/icons-material/Login';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

interface NewQuoteLayoutProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: '#fff',
    color: 'black',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoImage: {
    display: 'flex',
    width: '177.6px',
    height: '32px',
    padding: '0.856px 0.704px 0.793px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,

    // Media Query for mobile screens
    '@media (max-width: 600px)': {
      width: '134px',
      height: '24px',
      padding: '0.642px 0.531px 0.595px 0px',
    },
  },
  imageCenterAlign: {
    flexGrow: 1,
    textAlign: 'center',
  },
  phone: {
    marginRight: '20px',
    color: '#000',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  logout: {
    marginRight: '20px',
    textTransform: 'none',
    color: '#000',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  chip: {
    marginRight: '20px',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
    background: 'var(--light-emerald-bg, #E6EEEE)',
    color: 'var(--text-color, #003E3E)',
    '@media (max-width: 600px)': {
      marginRight: '0px',
    },
    cursor: 'pointer',
  },
}));
const phoneNumber = '469-840-3990';

const NewQuoteLayout: React.FC = (props: NewQuoteLayoutProps) => {
  const { children } = props;
  const classes = useStyles();
  const user = useSelector((state: IReduxState) => state.auth.user);
  const handleCall = () => {
    window.location.href = `tel:${phoneNumber.replace('-', '')}`;
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickLogout = () => {
    dispatch(logout());
    history.push(URLS.LOGIN);
  };

  const [pathname, setPathname] = useState('');

  useEffect(() => {
    const handlePathname = () => {
      setPathname(window.location.pathname);
    };

    handlePathname();

    window.addEventListener('popstate', handlePathname);

    return () => {
      window.removeEventListener('popstate', handlePathname);
    };
  }, []);

  const loggedIn = useSelector((state: IReduxState) => state.auth.loggedIn);

  return (
    <div
      style={{
        background: '#F3F3F3',
        height: `100vh`,
      }}
    >
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <a href="/" title="thefront">
            <Image
              className={classes.logoImage}
              src={SvgLogo}
              alt="thefront"
              lazy={false}
            />
          </a>

          <Hidden smDown>
            <div style={{ marginLeft: 'auto' }}>
              <Button
                className={classes.phone}
                startIcon={<CallOutlinedIcon />}
                onClick={handleCall}
                color="inherit"
              >
                {phoneNumber}
              </Button>

              {loggedIn ? (
                <Button
                  className={classes.logout}
                  startIcon={<ExitToAppIcon />}
                  color="inherit"
                  onClick={handleClickLogout}
                >
                  Log out
                </Button>
              ) : (
                <Button
                  color="inherit"
                  href={URLS.LOGIN}
                  startIcon={<LoginIcon />}
                  className={classes.logout}
                >
                  Login
                </Button>
              )}

              {(pathname === '/profile' || pathname.includes('inspection')) && (
                <a href="/dashboard/new">
                  <Chip
                    className={classes.chip}
                    style={{ marginLeft: 'auto' }}
                    label="Dashboard"
                  />
                </a>
              )}

              {pathname === '/dashboard/new' && (
                <a href="/profile">
                  <Chip
                    className={classes.chip}
                    avatar={<Avatar>{user?.attributes.name[0]}</Avatar>}
                    style={{ marginLeft: 'auto' }}
                    label={user?.attributes.name}
                  />
                </a>
              )}
            </div>
          </Hidden>

          <Hidden mdUp>
            {(pathname === '/profile' || pathname.includes('inspection')) && (
              <a href="/dashboard/new" style={{ marginLeft: 'auto' }}>
                <Chip
                  className={classes.chip}
                  style={{ marginLeft: 'auto' }}
                  label="Dashboard"
                />
              </a>
            )}

            {pathname === '/dashboard/new' && (
              <a href="/profile" style={{ marginLeft: 'auto' }}>
                <Chip
                  className={classes.chip}
                  avatar={<Avatar>{user?.attributes.name[0]}</Avatar>}
                  style={{ marginLeft: 'auto' }}
                  label={user?.attributes.name}
                />
              </a>
            )}
          </Hidden>
        </Toolbar>
      </AppBar>
      <main style={{ background: '#F3F3F3' }}>{children}</main>
    </div>
  );
};

NewQuoteLayout.defaultProps = {
  children: undefined,
};

export default NewQuoteLayout;
