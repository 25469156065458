import React, { ReactElement, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, OverlayView } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from 'src/utils/config';
import { ConnectedVehicle } from 'src/types';
import { apiGetConnectedCarData } from 'src/api/user';
import MapMarker from 'src/assets/MapMarker.png';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import speedoMeter from 'src/assets/speedoMeter.svg';
import speedoMeter2 from 'src/assets/speed.svg';
import tyrePressure from 'src/assets/tire.svg';
import fuelLevel from 'src/assets/fuel.svg';
import frontLeftWheel from 'src/assets/front-left-wheel.svg';
import frontRightWheel from 'src/assets/front-right-wheel.svg';
import rearLeftWheel from 'src/assets/rear-left-wheel.svg';
import rearRightWheel from 'src/assets/rear-right-wheel.svg';
import { brandOfCar } from 'src/assets/brands';
import { brandOfCarLight } from 'src/assets/marker-logos';
import { useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import createLead from 'src/api/lead';
import EngineDetails from './GpsComponents/EngineDetails';
import VehicleDetailsItem from './GpsComponents/VehicleDetailsItem';
import VehicleDetailsElement from './GpsComponents/VehicleDetailsElement';
import NoTelematicsModal from './NoTelematicsModal';
import WeWillOutSoonModal from './WeWillOutSoonModal';

const sidebarWidth = 400;

const defaultCenter = {
  lat: 42.226172,
  lng: -83.345384,
};

const styles = {
  sidebarMainContainer: {
    width: '400px',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'white',
  },
};

export default function GpsServices(): ReactElement {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY || '',
  });

  const [connectedCarsData, setConnectedCarsData] = useState<
    ConnectedVehicle[]
  >([]);
  const [selectedCar, setSelectedCar] = useState<ConnectedVehicle | null>(null);
  const user = useSelector((state: IReduxState) => state.auth.user);
  const [telematicsFeatures, setTelematicsFeatures] = useState<boolean>(false);
  const [weWillOutSoon, setWeWillOutSoon] = useState<boolean>(false);

  const handleClick = () => {
    setTelematicsFeatures(false);
    const message = `${user?.attributes.name} is interested in learning more about Telematics Features`;
    createLead(
      user?.attributes.name || '',
      user?.attributes.email || '',
      user?.attributes.phone || '',
      user?.attributes.zip_code || '',
      '',
      '',
      '',
      '',
      '',
      '',
      message
    ).then(() => {
      alert('Your request has been submitted');
    });
    setWeWillOutSoon(true);
  };

  useEffect(() => {
    const fetchConnectedCarData = async () => {
      const carsData = await apiGetConnectedCarData();

      setConnectedCarsData(carsData.data);
    };

    fetchConnectedCarData();
  }, []);

  useEffect(() => {
    setTelematicsFeatures(user?.attributes?.no_obds || false);
  }, [user?.attributes?.no_obds]);

  const isEmptyObject = (obj: Record<string, unknown> | undefined): boolean =>
    !obj || Object.keys(obj).length === 0;

  function getTirePressureStatus(
    pressure: number
  ): 'Low' | 'Running Low' | 'Good' {
    if (pressure >= 30) {
      return 'Good';
    }
    if (pressure < 30 && pressure > 24) {
      return 'Running Low';
    }
    return 'Low';
  }

  const customMarker = (car: ConnectedVehicle) => {
    return (
      <Box
        style={{
          width: '48px',
          height: '56px',
          backgroundImage: `url(${MapMarker})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          transform: 'translate(-50%, -100%)',
          position: 'absolute',
        }}
        onClick={() => setSelectedCar(car)}
      >
        <img
          src={brandOfCarLight(car.title)}
          alt="Vehicle"
          style={{
            width: '34px',
            height: '34px',
            marginTop: '8px',
          }}
        />
      </Box>
    );
  };

  return isLoaded ? (
    <div style={{ position: 'relative', width: '100%', height: '550px' }}>
      <GoogleMap
        mapContainerStyle={{
          width: selectedCar ? `calc(100% - ${sidebarWidth}px)` : '100%',
          height: '550px',
        }}
        center={defaultCenter}
        zoom={5}
      >
        {!user?.attributes?.no_obds &&
          connectedCarsData.map((car) => (
            <OverlayView
              key={car.title}
              position={{
                lat: car.position.latitude,
                lng: car.position.longitude,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              {customMarker(car)}
            </OverlayView>
          ))}
      </GoogleMap>

      {selectedCar && (
        <Box sx={styles.sidebarMainContainer}>
          <Button onClick={() => setSelectedCar(null)}>
            <ArrowBackIcon style={{ color: '#005959' }} />
          </Button>

          <Box
            sx={{
              height: '100%',
              padding: '10px 40px 40px 40px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
              }}
            >
              <Avatar src={brandOfCar(selectedCar?.title)} />
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '30px',
                  fontWeight: 700,
                  color: '#1F2322',
                  fontFamily: 'Inter',
                }}
              >
                {selectedCar.title}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#F4F4F4',
                borderRadius: '16px',
                border: '1px solid #BCBEBD',
                padding: '24px 20px',
                marginTop: '30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 1,
                }}
              >
                <Avatar sx={{ width: 24, height: 24 }} src={speedoMeter} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '18.2px',
                    fontWeight: 500,
                    color: '#005959',
                    fontFamily: 'Inter',
                  }}
                >
                  Mileage
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '19.36px',
                    fontWeight: 600,
                    color: '#005959',
                    fontFamily: 'Inter',
                  }}
                >
                  {selectedCar.vehicle_data.odometer} miles
                </Typography>
              </Box>
            </Box>

            <EngineDetails selectedCar={selectedCar} />

            <Grid container spacing={1} sx={{ marginTop: '20px' }}>
              {!isEmptyObject(selectedCar.vehicle_data.oil_life) && (
                <Grid item md={12}>
                  <VehicleDetailsItem
                    icon={speedoMeter2}
                    heading="Oil level"
                    headingValue={
                      selectedCar.vehicle_data.oil_life?.value || ''
                    }
                    status={selectedCar.vehicle_data.oil_life?.text || ''}
                  />
                </Grid>
              )}

              {!isEmptyObject(selectedCar.vehicle_data.fuel_level) && (
                <Grid item md={12}>
                  <VehicleDetailsItem
                    icon={fuelLevel}
                    heading="Fuel Level"
                    headingValue={
                      selectedCar.vehicle_data.fuel_level?.value || ''
                    }
                    status=""
                  />
                </Grid>
              )}

              {!isEmptyObject(selectedCar.vehicle_data.tire_pressures) && (
                <>
                  {selectedCar.vehicle_data.tire_pressures
                    ?.front_left_tire_pressure && (
                    <Grid item md={6}>
                      <VehicleDetailsElement
                        icon={tyrePressure}
                        heading="Tire Pressure of the Left front wheel"
                        headingValue={
                          `${selectedCar.vehicle_data.tire_pressures.front_left_tire_pressure} psi` ||
                          ''
                        }
                        status={getTirePressureStatus(
                          selectedCar.vehicle_data.tire_pressures
                            .front_left_tire_pressure
                        )}
                        isPressure
                        wheelIcon={frontLeftWheel}
                      />
                    </Grid>
                  )}

                  {selectedCar.vehicle_data.tire_pressures
                    ?.front_right_tire_pressure && (
                    <Grid item md={6}>
                      <VehicleDetailsElement
                        icon={tyrePressure}
                        heading="Tire Pressure of the Right front wheel"
                        headingValue={
                          `${selectedCar.vehicle_data.tire_pressures.front_right_tire_pressure} psi` ||
                          ''
                        }
                        status={getTirePressureStatus(
                          selectedCar.vehicle_data.tire_pressures
                            .front_right_tire_pressure
                        )}
                        isPressure
                        wheelIcon={frontRightWheel}
                      />
                    </Grid>
                  )}

                  {selectedCar.vehicle_data.tire_pressures
                    ?.rear_left_tire_pressure && (
                    <Grid item md={6}>
                      <VehicleDetailsElement
                        icon={tyrePressure}
                        heading="Tire Pressure of the Left rear wheel"
                        headingValue={
                          `${selectedCar.vehicle_data.tire_pressures.rear_left_tire_pressure} psi` ||
                          ''
                        }
                        status={getTirePressureStatus(
                          selectedCar.vehicle_data.tire_pressures
                            .rear_left_tire_pressure
                        )}
                        isPressure
                        wheelIcon={rearLeftWheel}
                      />
                    </Grid>
                  )}

                  {selectedCar.vehicle_data.tire_pressures
                    ?.rear_right_tire_pressure && (
                    <Grid item md={6}>
                      <VehicleDetailsElement
                        icon={tyrePressure}
                        heading="Tire Pressure of the Right rear wheel"
                        headingValue={
                          `${selectedCar.vehicle_data.tire_pressures.rear_right_tire_pressure} psi` ||
                          ''
                        }
                        status={getTirePressureStatus(
                          selectedCar.vehicle_data.tire_pressures
                            .rear_right_tire_pressure
                        )}
                        isPressure
                        wheelIcon={rearRightWheel}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Box>
        </Box>
      )}
      <NoTelematicsModal
        open={telematicsFeatures}
        onClose={() => setTelematicsFeatures(false)}
        onClick={handleClick}
      />

      <WeWillOutSoonModal
        open={weWillOutSoon}
        onClose={() => setWeWillOutSoon(false)}
      />
    </div>
  ) : (
    <CircularProgress />
  );
}
