import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import {
  Card,
  Typography,
  Button,
  makeStyles,
  Select,
  MenuItem,
  useMediaQuery,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  styled,
  FormControl,
  TextField,
  Box,
  InputAdornment,
} from '@material-ui/core';
import radio from 'src/assets/Radio.png';
import radioselected from 'src/assets/Radioselected.png';

import 'react-calendar/dist/Calendar.css';

import MDatePicker from 'src/components/atoms/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { GoogleAddressNew } from 'src/components/atoms';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK_FUNNEL_V2 } from 'src/utils/consts';
import { ResponseAvailability } from 'src/types';
import {
  checkAvailability,
  updateAppointment,
  updateZipCode,
} from 'src/api/quote';

import { setAppointment } from 'src/store/actions';

import moment from 'moment';
import { ArrowBack, ArrowForward, Done, ExpandMore } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { QuoteConfirmedContext } from './Context/QuoteConfirmedContext';
import QuoteSummaryMobile from '../atoms/QuotesummaryMobile';
import GetBackStep from './GetBackStep';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    background: '#fff',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: '50px',
    fontFamily: 'inter',
    position: 'fixed',
  },
  continueButton: {
    marginLeft: theme.spacing(1),
  },
  timePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '100%',
    maxHeight: 320,
    margin: 'auto',
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  verticalInputs: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > *': {
        marginBottom: 10,
      },
    },
  },
  itemTimeSlot: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: '#7E7A92',

    background: theme.palette.common.white,

    '&.selected': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    color: '#fff',
  },
  whiteCard: {
    backgroundColor: theme.palette.common.white,
  },
  mobileRoot: {
    width: '100%',
  },
  TimeandLocation: {
    paddingBottom: 20,
    background: '#fff',
    marginBottom: 0,
    maxWidth: 350,
    width: 400,
  },
  vehicleColor: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '1px solid #D4D5D5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vehicleOther: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: '1px solid #D4D5D5',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  addColorToggle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const CustomFormField = styled(FormControl)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: '40px',
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: '4px',

    padding: 0,
    paddingLeft: 20,

    border: 'none',
    color: 'none',
  },
  '& .MuiSelect-outlined': {
    borderRadius: '8px',
  },
  '& .MuiSelect-selectMenu': {
    minHeight: '0px',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    height: '2px',
    fontWeight: 500,

    fontFamily: 'Inter',
    paddingBottom: 19,
    backgroundColor: '#fff',
  },
  '& .MuiSelect-icon': {
    top: 'auto',
  },
}));

const CustomFormFieldNoBorder = styled(FormControl)(() => ({
  '& .MuiOutlinedInput-input': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
  },
}));

interface AppointmentStepProps {
  onNext: () => void;
  onBack: () => void;
  onComplete: () => void;
  priceSelected: string;
}

const colorList = [
  {
    color: 'Blue',
  },
  {
    color: 'Black',
  },
  {
    color: 'Red',
  },
  {
    color: 'White',
  },
  {
    color: 'Grey',
  },
];

const AppointmentStep: React.FC<AppointmentStepProps> = ({
  onNext,
  onBack,
  onComplete,
  priceSelected,
}) => {
  const classes = useStyles();
  const isSm = useMediaQuery('(max-width: 1300px)');
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );
  const [apartmentCode, setApartmentCode] = useState<string>('');
  const [apartmentNo, setApartmentNo] = useState<string>('');
  const [locationSelected, setLocationSelected] = useState<string>('');
  const [parkingType, setParkingType] = useState<string>('');
  const [garageOption, setGarageOption] = useState<string>('');
  const [addColorToggle, setAddColorToggle] = useState<boolean>(false);
  const [colorValue, setColorValue] = useState<string>('');
  const [seletedColor, setSelectedColor] = useState<string>('');
  const dispatch = useDispatch();

  const [addressDetails, setAddressDetails] = useState({
    route: '',
    street_number: '',
    locality: '',
    administrative_area_level_one: '',
    administrative_area_level_two: '',
    country: '',
    postal_code: '',
  });
  const [errorAddress, setErrorAddress] = useState<boolean>(false);

  const { QuoteConfirmed, setQuoteConfirmed } = useContext(
    QuoteConfirmedContext
  );

  const [location, setLocation] = useState(
    QuoteConfirmed.appointment.exact_address
  );
  const [selectedTime, setSelectedTime] = useState<string>(
    QuoteConfirmed.appointment.selected_window
  );
  const [availability, setAvailability] = useState<
    ResponseAvailability | undefined
  >();
  const [errorWithZip, setErrorWithZip] = useState<boolean>(false);

  const [expandedServicesSelected, setExpandedServicesSelected] =
    useState<boolean>(true);

  const [zipCode, setZipCode] = useState<string | undefined>(
    appointment?.attributes?.address
  );

  const actualAvailability = availability?.data?.attributes?.availability || {};
  const today = moment();
  const FirstAvailableDate =
    Object.keys(actualAvailability)[0] || today.format('YYYY-MM-DD');

  const selectedDateFromApi = moment(
    QuoteConfirmed.appointment.selected_day,
    'YYYY-MM-DD'
  );

  const initialSelectedDate =
    selectedDateFromApi.format('YYYY-MM-DD') || FirstAvailableDate;

  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    moment(initialSelectedDate)
  );

  useEffect(() => {
    setSelectedDate(moment(FirstAvailableDate));
  }, [FirstAvailableDate]);

  const isDateDisabled = (day: MaterialUiPickersDate | null): boolean => {
    if (!day) return false; // Handle null value

    const selectedDateTime = day.toDate().setHours(0, 0, 0, 0);
    const currentDate = new Date().setHours(0, 0, 0, 0);

    if (selectedDateTime < currentDate) return true;

    const selectedFormattedDay = day.format('YYYY-MM-DD').toString();

    if (availability?.data.attributes.availability) {
      const appointmentsOnSelectedDay =
        availability?.data.attributes.availability[selectedFormattedDay];

      if (!appointmentsOnSelectedDay || appointmentsOnSelectedDay.length === 0)
        return true;
    }

    return false;
  };

  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
  const [isAccordionExpandedTime, setIsAccordionExpandedTime] = useState(false);
  const [isAccordionExpandedAddress, setIsAccordionExpandedAddress] =
    useState(false);
  const [isAccordionExpandedColor, setIsAccordionExpandedColor] =
    useState(false);

  const handleAccordionExpandTime = () => {
    setIsAccordionExpandedTime(!isAccordionExpandedTime);
  };

  const handleAccordionExpandAddress = () => {
    setIsAccordionExpandedAddress(!isAccordionExpandedAddress);
  };

  const handleAccordionExpandColor = () => {
    setIsAccordionExpandedColor(!isAccordionExpandedColor);
  };

  const handleAddColorToggle = () => {
    setAddColorToggle(true);
  };

  const handleColorSelect = (value: string) => {
    setSelectedColor(value);
  };

  const handleTimeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTime(event.target.value as string);
    setIsAccordionExpandedAddress(true);
    onComplete();
  };

  const handleContinue = () => {
    if (!appointment || !selectedDate) {
      return;
    }

    setRequestInProgress(true);

    const modifiedObject = {
      ...QuoteConfirmed,
      appointment: {
        ...QuoteConfirmed.appointment,
        selected_day: selectedDate?.format('YYYY-MM-DD').toString(),
        selected_window: selectedTime,
        exact_address: location,
      },
    };

    setQuoteConfirmed(modifiedObject);

    updateAppointment(appointment.id, {
      appointment_day: selectedDate.format('YYYY-MM-DD').toString(),
      appointment_time: selectedTime,
      exact_address: location,
      apartment_number: apartmentNo,
      apartment_code: apartmentCode,
      type_of_site: locationSelected,
      garage: garageOption,
      covered: parkingType,
      hints_to_find: '',
      car_attributes: {
        id: appointment.attributes.car.id,
        color: seletedColor === '' ? colorValue : seletedColor,
      },
      location_attributes: {
        zip: zipCode || '',
        route: addressDetails.route,
        street_number: addressDetails.street_number,
        locality: addressDetails.locality,
        administrative_area_level_one:
          addressDetails.administrative_area_level_one,
        administrative_area_level_two:
          addressDetails.administrative_area_level_two,
        country: addressDetails.country,
        postal_code: addressDetails.postal_code,
      },
    }).then((res) => {
      dispatch(setAppointment(res.data));

      mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_SCHEDULE_APPT);

      setRequestInProgress(false);

      onNext();
    });
  };

  const selectedFormattedDay = selectedDate?.format('YYYY-MM-DD').toString();
  const currentDate = moment().format('YYYY-MM-DD');
  const appointmentsForSelectedDay =
    availability?.data.attributes.availability[
      selectedFormattedDay || currentDate
    ];

  useEffect(() => {
    if (!availability && appointment) {
      checkAvailability(appointment?.id).then(
        (res: React.SetStateAction<ResponseAvailability | undefined>) => {
          setAvailability(res);
        }
      );
    }
  }, [availability, appointment, zipCode]);

  const updateZipCodeAsync = async (newZipCode: string | undefined) => {
    try {
      if (appointment && newZipCode) {
        const zipresponse = await updateZipCode(newZipCode, appointment.id);

        if (zipresponse.errors) {
          setErrorWithZip(true);

          setTimeout(() => {
            setErrorWithZip(false);
          }, 5000);
        } else if (zipresponse.message) {
          setErrorWithZip(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateZipCodeAsync(zipCode);
  });

  const handleLocationInputChange = (key: string, value: string) => {
    setLocation(value);
  };

  const handleVehicleLocationInputChange = (value: string) => {
    setLocationSelected(value);
  };

  const handleParkingTypeInputChange = (value: string) => {
    setParkingType(value);
  };

  const handleGarageOptionInputChange = (value: string) => {
    setGarageOption(value);
  };

  useEffect(() => {
    if (
      selectedDate !== undefined &&
      selectedDate &&
      selectedDate.format('YYYY-MM-DD') !== currentDate.toString()
    )
      setIsAccordionExpandedTime(true);
  }, [selectedDate, currentDate]);

  const setExpandedFalse = () => {
    setExpandedServicesSelected(false);
  };

  const handleZipCodeChanged = (newZipCode: string) => {
    updateZipCodeAsync(newZipCode);
    setZipCode(newZipCode);
  };

  return (
    <div>
      <Button
        className={classes.buttonForward}
        style={{
          color: '#005959',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          margin: '0px 0px 20px 0px',
        }}
        onClick={() => {
          onBack();
        }}
      >
        <ArrowBack
          style={{
            width: 20,
            height: 20,
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        />{' '}
        <Typography
          style={{
            margin: 'auto 0 auto 10px',
            fontSize: 16,
            fontFamily: 'Inter',
          }}
        >
          Back
        </Typography>
      </Button>
      {appointment?.attributes.appointment_type === 'repair' &&
      !appointment?.attributes.estimate ? (
        <GetBackStep />
      ) : (
        <Card
          className={classes.card}
          style={{
            marginLeft: 'auto',
            marginRight: !isSm ? 20 : 'auto',
            marginBottom: 30,
            width: 'fit-content',
            background: '#FFFFFF',
          }}
          elevation={0}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: isSm ? 'column' : 'row',
              background: '#FFFFFF',
            }}
          >
            {isSm && <QuoteSummaryMobile priceSelected={priceSelected} />}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: isSm ? 0 : 20,
              }}
            >
              <Card
                style={{
                  background: '#fff',
                  textAlign: 'left',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                }}
                elevation={1}
                onClick={setExpandedFalse}
              >
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    marginRight: 'auto',
                    marginTop: 30,
                    marginBottom: '20px',
                    marginLeft: '20px',
                    fontWeight: 600,
                  }}
                >
                  Select Appointment Date
                </Typography>
                <MDatePicker
                  date={selectedDate}
                  shouldDisableDate={isDateDisabled}
                  onChangeDate={(d: MaterialUiPickersDate) => {
                    setSelectedDate(d);
                    onComplete();
                  }}
                />
              </Card>

              <Accordion
                expanded={isAccordionExpandedTime}
                onChange={handleAccordionExpandTime}
                style={{
                  background: '#fff',
                  marginTop: !isSm ? 20 : 8,
                  borderRadius: '8px',
                  position: 'inherit',
                  width: '100%',
                  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                }}
                elevation={1}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ fontFamily: 'Inter', fontWeight: 600 }}>
                    Select Time
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    paddingLeft: -10,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Card className={classes.TimeandLocation} elevation={0}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        marginBottom: 20,
                        color: '#4C4F4E',
                      }}
                    >
                      {appointment?.attributes.shop_network
                        ? 'The tow truck will arrive in a 4-hour window.'
                        : 'The mechanic will arrive in a 3-hour window'}
                    </Typography>
                    <CustomFormField fullWidth>
                      <Select
                        value={selectedTime}
                        onChange={handleTimeSelect}
                        variant="outlined"
                        placeholder="select"
                        MenuProps={{
                          classes: { paper: classes.menuPaper },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>Select a Window</em>
                        </MenuItem>
                        {appointmentsForSelectedDay &&
                        appointmentsForSelectedDay.length > 0 ? (
                          appointmentsForSelectedDay.map((appt) => (
                            <MenuItem
                              key={appt}
                              value={appt}
                              style={{ fontFamily: 'Inter' }}
                            >
                              {appt}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            No available windows for this date
                          </MenuItem>
                        )}
                      </Select>
                    </CustomFormField>
                  </Card>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={isAccordionExpandedAddress}
                onChange={handleAccordionExpandAddress}
                style={{
                  background: '#fff',
                  marginTop: !isSm ? 20 : 8,
                  borderRadius: '8px',
                  width: '100%',
                  position: 'inherit',
                  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                }}
                elevation={1}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ fontFamily: 'Inter', fontWeight: 600 }}>
                    Vehicle Location
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card className={classes.TimeandLocation} elevation={0}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        marginBottom: 20,
                        color: '#4C4F4E',
                      }}
                    >
                      Location type
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        gap: 30,
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        onClick={() =>
                          handleVehicleLocationInputChange('Apartment')
                        }
                        display="flex"
                        style={{ gap: 10 }}
                      >
                        <img
                          src={
                            locationSelected === 'Apartment'
                              ? radioselected
                              : radio
                          }
                          alt="apartment"
                          style={{ width: 20, height: 20 }}
                        />

                        <Typography style={{ fontFamily: 'Inter' }}>
                          Apartment
                        </Typography>
                      </Box>

                      <Box
                        onClick={() =>
                          handleVehicleLocationInputChange('House')
                        }
                        display="flex"
                        style={{ gap: 10 }}
                      >
                        <img
                          src={
                            locationSelected === 'House' ? radioselected : radio
                          }
                          alt="apartment"
                          style={{ width: 20, height: 20 }}
                        />

                        <Typography style={{ fontFamily: 'Inter' }}>
                          House
                        </Typography>
                      </Box>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 10,
                      }}
                      className={classes.verticalInputs}
                    >
                      <GoogleAddressNew
                        value={location}
                        valueChanged={(val: string) =>
                          handleLocationInputChange('exact_address', val)
                        }
                        zipCodeChanged={handleZipCodeChanged}
                        currentZip={appointment?.attributes.address}
                        setAddressDetails={setAddressDetails}
                        addressDetails={addressDetails}
                        setErrorAddress={setErrorAddress}
                        fleet={appointment?.attributes.fleet_job || false}
                      />
                    </div>

                    {locationSelected === 'House' && (
                      <>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            marginTop: 20,
                            marginBottom: 20,
                            color: '#4C4F4E',
                          }}
                        >
                          Have a Garage? (Optional)
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            gap: 30,
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            onClick={() => handleGarageOptionInputChange('yes')}
                            display="flex"
                            style={{ gap: 10 }}
                          >
                            <img
                              src={
                                garageOption === 'yes' ? radioselected : radio
                              }
                              alt="apartment"
                              style={{ width: 20, height: 20 }}
                            />

                            <Typography style={{ fontFamily: 'Inter' }}>
                              Yes
                            </Typography>
                          </Box>

                          <Box
                            onClick={() => handleGarageOptionInputChange('no')}
                            display="flex"
                            style={{ gap: 10 }}
                          >
                            <img
                              src={
                                garageOption === 'no' ? radioselected : radio
                              }
                              alt="apartment"
                              style={{ width: 20, height: 20 }}
                            />

                            <Typography style={{ fontFamily: 'Inter' }}>
                              No
                            </Typography>
                          </Box>
                        </div>
                      </>
                    )}

                    {locationSelected === 'Apartment' && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <CustomFormFieldNoBorder fullWidth>
                            <TextField
                              style={{
                                margin: '12px 0px 0px 0px',
                                borderRadius: '15px',
                                width: '100%',
                                fontFamily: 'Inter',
                              }}
                              label="Apartment Code*"
                              variant="outlined"
                              placeholder=""
                              value={apartmentCode}
                              onChange={(e) => setApartmentCode(e.target.value)}
                            />
                          </CustomFormFieldNoBorder>

                          <CustomFormFieldNoBorder fullWidth>
                            <TextField
                              style={{
                                margin: '20px 0px 0px 0px',
                                borderRadius: '8px',
                                width: '100%',
                                fontFamily: 'Inter',
                              }}
                              label="Apartment No*"
                              variant="outlined"
                              placeholder=""
                              value={apartmentNo}
                              onChange={(e) => setApartmentNo(e.target.value)}
                            />
                          </CustomFormFieldNoBorder>
                        </div>

                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            marginBottom: 20,
                            marginTop: 20,
                            color: '#4C4F4E',
                          }}
                        >
                          Parking Type (Optional)
                        </Typography>

                        <div
                          style={{
                            display: 'flex',
                            gap: 30,
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            onClick={() =>
                              handleParkingTypeInputChange('Covered')
                            }
                            display="flex"
                            style={{ gap: 10 }}
                          >
                            <img
                              src={
                                parkingType === 'Covered'
                                  ? radioselected
                                  : radio
                              }
                              alt="apartment"
                              style={{ width: 20, height: 20 }}
                            />

                            <Typography style={{ fontFamily: 'Inter' }}>
                              Covered
                            </Typography>
                          </Box>

                          <Box
                            onClick={() =>
                              handleParkingTypeInputChange('Not Covered')
                            }
                            display="flex"
                            style={{ gap: 10 }}
                          >
                            <img
                              src={
                                parkingType === 'Not Covered'
                                  ? radioselected
                                  : radio
                              }
                              alt="apartment"
                              style={{ width: 20, height: 20 }}
                            />

                            <Typography style={{ fontFamily: 'Inter' }}>
                              Not Covered
                            </Typography>
                          </Box>
                        </div>
                      </>
                    )}
                  </Card>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={isAccordionExpandedColor}
                onChange={handleAccordionExpandColor}
                style={{
                  background: '#fff',
                  marginTop: !isSm ? 20 : 8,
                  borderRadius: '8px',
                  width: '100%',
                  position: 'inherit',
                  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                }}
                elevation={1}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ fontFamily: 'Inter', fontWeight: 600 }}>
                    Select Vehicle Color
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card className={classes.TimeandLocation} elevation={0}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        marginBottom: 20,
                        color: '#4C4F4E',
                      }}
                    >
                      What is your vehicle color?
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        gap: '25px 50px',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {colorList.map((list) => (
                        <Box
                          display="flex"
                          className={classes.addColorToggle}
                          onClick={() => handleColorSelect(list.color)}
                        >
                          <Box
                            className={classes.vehicleColor}
                            style={{ backgroundColor: list.color }}
                          >
                            {colorValue === '' &&
                              seletedColor === list.color && (
                                <Done
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    color: '#D4D5D5',
                                  }}
                                />
                              )}
                          </Box>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              paddingLeft: 10,
                              paddingTop: 5,
                              maxWidth: '40px',
                            }}
                          >
                            {list.color}
                          </Typography>
                        </Box>
                      ))}
                      <Box
                        display="flex"
                        className={classes.addColorToggle}
                        onClick={handleAddColorToggle}
                      >
                        <Box className={classes.vehicleOther}>
                          {' '}
                          <Typography
                            style={{
                              fontSize: 20,
                            }}
                          >
                            +
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            paddingLeft: 10,
                            paddingTop: 5,
                            maxWidth: '40px',
                          }}
                        >
                          Other
                        </Typography>
                      </Box>
                    </div>
                    {addColorToggle && (
                      <Box style={{ marginTop: 20 }}>
                        <CustomFormFieldNoBorder fullWidth>
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CloseIcon
                                    className={classes.addColorToggle}
                                    onClick={() => {
                                      setAddColorToggle(false);
                                      setColorValue('');
                                    }}
                                    style={{
                                      color: 'black',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            style={{
                              margin: '12px 0px 0px 0px',
                              borderRadius: '15px',
                              width: '100%',
                              fontFamily: 'Inter',
                            }}
                            label="Add color"
                            variant="outlined"
                            placeholder=""
                            value={colorValue}
                            onChange={(e) => setColorValue(e.target.value)}
                          />
                        </CustomFormFieldNoBorder>
                      </Box>
                    )}
                  </Card>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <Button
            disabled={
              !location ||
              !selectedTime ||
              !selectedDate ||
              errorWithZip ||
              (!errorAddress && addressDetails.street_number === '') ||
              requestInProgress
            }
            onClick={handleContinue}
            style={{
              background:
                !location ||
                !selectedTime ||
                !selectedDate ||
                requestInProgress ||
                errorWithZip ||
                (!errorAddress && addressDetails.street_number === '')
                  ? '#D4D5D5'
                  : '#005959',
              marginTop: '35px',
              marginLeft: '10px',
              color: '#fff',
            }}
            className={classes.buttonForward}
            variant="contained"
            size="large"
          >
            Continue &nbsp;
            <ArrowForward />
          </Button>
        </Card>
      )}
    </div>
  );
};

export default AppointmentStep;
