import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles, useMediaQuery, Typography } from '@material-ui/core';
import './index.css';
import QuoteProvider from './components/steps/Context/QuoteConfirmedContext';
import PaymentStep from './components/steps/PaymentStep';
import QuoteSummary from './components/atoms/QuoteSummary';
import Referral from './components/atoms/ReferralCode';
import InfoCard from './components/atoms/InfoCard';
import SuccessPage from './components/steps/SuccessPage';

interface StepItem {
  id: number;
  label: string;
  image: JSX.Element;
}

interface QuotePaymentStepperProps {
  steps: StepItem[];
}

const useStyles = makeStyles({
  stepConnector: {
    width: '100%',
    '& span': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      '& .Mui-active': {
        color: '#005959 !important',
      },
      '& .Mui-completed': {
        color: '#005959 !important',
      },
    },
  },
  boxStyle: {
    width: '100%',
    marginTop: 10,
  },
  customFontHead: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',

    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '30px',
    marginTop: 40,
  },
  customFontSub: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '30px',
    marginTop: 10,
  },
  customStepperLabel: {
    color: '#005959',
  },
});

const QuotePaymentStepper: React.FC<QuotePaymentStepperProps> = ({ steps }) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [priceSelected, setPriceSelected] = useState('non-member');
  const [completedSteps, setCompletedSteps] = React.useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ]);

  const isSm = useMediaQuery('(max-width: 1300px)');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepCompletion = () => {
    const updatedCompletedSteps = completedSteps.map((completed, index) =>
      index === activeStep ? true : completed
    );
    setCompletedSteps(updatedCompletedSteps);
  };

  const getStepComponent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <QuoteProvider>
            <Typography
              className={classes.customFontHead}
              style={{ fontSize: isSm ? 17 : 20 }}
              variant="body2"
            >
              Securely Enter Payment Info
            </Typography>
            {!isSm && (
              <Typography className={classes.customFontSub}>
                Get a Fixed Quote • Technician Comes To You • Pay at Time of
                Service
              </Typography>
            )}
            <div
              style={{
                display: !isSm ? 'flex' : 'unset',
                flexDirection: 'row',
              }}
            >
              <div style={{ marginLeft: !isSm ? 'auto' : 'none' }}>
                <PaymentStep
                  onNext={handleNext}
                  onBack={handleBack}
                  onComplete={handleStepCompletion}
                  priceSelected={priceSelected}
                  billingType=""
                  memberSelected={false}
                  hideBack
                />
              </div>
              <div
                style={{
                  marginRight: !isSm ? 'auto' : 'none',
                  marginTop: 50,
                  marginLeft: 10,
                }}
              >
                {!isSm && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: 'min-content',
                    }}
                  >
                    <QuoteSummary priceSelected={priceSelected} /> <InfoCard />{' '}
                    <Referral />{' '}
                  </div>
                )}
              </div>
            </div>
          </QuoteProvider>
        );
      case 1:
        return (
          <QuoteProvider>
            <SuccessPage />
          </QuoteProvider>
        );

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        marginTop: 10,
        paddingBottom: 20,
        maxWidth: '65%',
        minWidth: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media (max-width: 600px)': {
          minWidth: '100%',
        },
      }}
    >
      {getStepComponent(activeStep)}
    </Box>
  );
};

export default QuotePaymentStepper;
