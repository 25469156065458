import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';
import { GOOGLE_API_KEY } from 'src/utils/config';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from 'react-google-places-autocomplete';

interface GoogleAddressNewProps {
  className?: string;
  value?: string;
  start?: React.ReactNode;
  valueChanged?: (v: string) => void;
  zipCodeChanged?: (zipCode: string) => void;
  currentZip: string | undefined;
  setAddressDetails: ({
    route,
    street_number,
    locality,
    administrative_area_level_one,
    administrative_area_level_two,
    country,
    postal_code,
  }: {
    route: string;
    street_number: string;
    locality: string;
    administrative_area_level_one: string;
    administrative_area_level_two: string;
    country: string;
    postal_code: string;
  }) => void;
  addressDetails: {
    route: string;
    street_number: string;
    locality: string;
    administrative_area_level_one: string;
    administrative_area_level_two: string;
    country: string;
    postal_code: string;
  };
  setErrorAddress: (error: boolean) => void;
  fleet: boolean;
}
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
    alignItems: 'center',

    borderRadius: 15,
    border: '1px solid #D8D8D8',
    background: theme.palette.common?.white,
    padding: theme.spacing(1),
    paddingTop: 10,
    paddingBottom: 10,

    '&.input-checked': {
      border: '2px solid #005959',
    },

    '& .google-places-autocomplete': {
      flexGrow: 1,
      height: '100%',
      paddingTop: theme.spacing(0.8),
      paddingBottom: theme.spacing(0.8),

      '& input': {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 17,
        lineHeight: '21px',
        color: '#2A2D3C',
        width: '100%',
        border: 'none',
        outline: 'none',
      },
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  checked: {
    height: '100%',
    color: '#005959',
    fontSize: 25,
    width: 30,
    maxHeight: 55,
  },
  start: {
    background: 'transparent',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    maxHeight: 55,
  },
  googlecontainer: {
    padding: 10,
    fontSize: 14,
    fontFamily: 'Inter',
  },
  googlesuggestion: {
    padding: 5,
    fontSize: 12,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#e5e5e5',
    },
  },
}));

const CustomFormField = styled(FormControl)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: '40px',
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: '8px',
    padding: 0,
    paddingLeft: 20,
    border: 'none',
    color: 'none',
  },
  '& .MuiSelect-outlined': {
    borderRadius: '8px',
  },
  '& .MuiSelect-selectMenu': {
    minHeight: '0px',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    height: '2px',
    fontWeight: 500,
    fontFamily: 'Inter',
    paddingBottom: 19,
    backgroundColor: '#fff',
  },
}));

/**
 * Component to display the icon
 *
 * @param GoogleAddressNewProps props
 */
const GoogleAddressNew = (props: GoogleAddressNewProps): ReactElement => {
  const {
    className,
    valueChanged,
    value,
    start,
    zipCodeChanged,
    currentZip,
    setAddressDetails,
    addressDetails,
    setErrorAddress,
    fleet,
  } = props;
  const [expandFields, setExpandFields] = useState(false);
  const [street, setStreet] = useState<string | undefined>();
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');

  const classes = useStyles();

  const handleChange = async (newAddr: string) => {
    if (valueChanged) {
      valueChanged(newAddr);
    }

    try {
      const results = await geocodeByAddress(newAddr);

      if (results && results.length > 0) {
        const addressComponents = results[0]
          .address_components as AddressComponent[];

        const streetNumber = addressComponents.find((component) =>
          component.types.includes('street_number')
        )?.long_name;
        const route = addressComponents.find((component) =>
          component.types.includes('route')
        )?.long_name;

        if (streetNumber && route) setStreet(`${streetNumber} ${route}`);

        const cy =
          addressComponents.find((component) =>
            component.types.includes('locality')
          )?.long_name || '';
        setCity(cy);

        const st =
          addressComponents.find((component) =>
            component.types.includes('administrative_area_level_1')
          )?.short_name || '';
        setState(st);

        const zip =
          addressComponents.find((component) =>
            component.types.includes('postal_code')
          )?.long_name || '';
        setZipCode(zip);

        if (!fleet) {
          if ((!zip || zip !== currentZip) && zipCodeChanged) {
            setExpandFields(true);
            zipCodeChanged(zip);
          }

          if (!streetNumber) {
            setExpandFields(true);
          }
        }

        setAddressDetails({
          route: route || '',
          street_number:
            streetNumber && route ? `${streetNumber} ${route}` : '',
          locality: cy,
          administrative_area_level_one: st,
          administrative_area_level_two:
            addressComponents.find((component) =>
              component.types.includes('administrative_area_level_2')
            )?.short_name || '',
          country:
            addressComponents.find((component) =>
              component.types.includes('country')
            )?.short_name || '',
          postal_code: zip,
        });
      }
    } catch (e) {
      setErrorAddress(true);
      console.error(e);
    }
  };

  return (
    <>
      <CustomFormField
        variant="outlined"
        fullWidth
        className={clsx(
          classes.formControl,
          classes.root,
          className,
          start && 'with-start-icon',
          value && 'input-checked'
        )}
      >
        {start && <Box className={classes.start}>{start}</Box>}
        <GooglePlacesAutocomplete
          onSelect={(address) => {
            handleChange(address.description);
          }}
          placeholder="Search for your address"
          apiKey={GOOGLE_API_KEY}
          initialValue={value}
          debounce={300}
          suggestionsClassNames={{
            container: classes.googlecontainer,
            suggestion: classes.googlesuggestion,
          }}
        />
      </CustomFormField>

      {expandFields && (
        <>
          <Typography variant="h6" style={{ marginTop: 20 }}>
            Confirm Address
          </Typography>
          <TextField
            style={{ margin: '2px 0' }}
            error={!street}
            label="Street Address"
            variant="outlined"
            fullWidth
            value={street}
            onChange={(e) => {
              setAddressDetails({
                ...addressDetails,
                street_number: e.target.value,
              });
              setStreet(e.target.value);
            }}
          />
          <TextField
            style={{ margin: '2px 0' }}
            label="City"
            variant="outlined"
            fullWidth
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            style={{ margin: '2px 0' }}
            label="State"
            variant="outlined"
            fullWidth
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <TextField
            style={{ margin: '2px 0' }}
            error={!zipCode}
            label="ZIP Code"
            variant="outlined"
            fullWidth
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </>
      )}
    </>
  );
};

GoogleAddressNew.defaultProps = {
  className: '',
  value: '',
  valueChanged: undefined,
  start: undefined,
  zipCodeChanged: undefined,
};

export default GoogleAddressNew;
