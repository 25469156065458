import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const useFontStyles = makeStyles(() => ({
  h1: {
    fontSize: '24px',
    lineHeight: '33px',
    fontWeight: 600,
    fontFamily: 'Tiempos',
    color: '#000000',
  },
  h2: {
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 'bold',
    fontFamily: 'Tiempos',
    color: '#252044',
  },
  modalHeader: {
    fontSize: '24px',
    lineHeight: '33px',
    fontWeight: 'bold',
    fontFamily: 'Tiempos',
    color: '#252044',
  },
  modalSubtitle: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    fontFamily: 'Tiempos',
    color: '#4A37B1',
  },
  subtitle: {
    fontFamily: 'Tiempos',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '19px',
    color: '#403A65',
  },
}));
