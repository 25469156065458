import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import {
  Typography,
  Button,
  makeStyles,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Info, InfoOutlined } from '@material-ui/icons';
import Bar from 'src/assets/Bar.png';

const useStyles = makeStyles((theme) => ({
  Diagnosescard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
    background: '#fff',
  },
  clock: {
    marginTop: 30,
    width: 80,
    height: 80,
    flexShrink: 0,
    color: '#005959',
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  li: {
    color: '#005959',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#FFF',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#000',
    borderRadius: 10,
  },
  whiteCard: {
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    flex: 1,
  },
  mobileRoot: {
    width: '100%',
  },
  buttonDiscount: {
    width: '89px',
    height: '24px',
    padding: '4px 8px',
    gap: '8px',
    flexShrink: 0,
    borderRadius: '32px',
    background: 'var(--green-30, #A5CD65)',
    color: '#005959',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
    lineHeight: 'normal',
    marginLeft: '10px',
  },
}));

interface InvoiceMobileProps {
  fullWidth?: boolean;
  nonMember?: boolean;
  onNext: () => void;
  changedPrice?: string;
}

const InvoiceMobile: React.FC<InvoiceMobileProps> = ({
  fullWidth,
  nonMember,
  onNext,
  changedPrice,
}) => {
  const classes = useStyles();
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const isSm = useMediaQuery('(max-width: 1229px)');
  const [toggleBreakdown, setToggleBreakdown] = useState(false);

  const getPrice = () => {
    let price = 0;

    const estimate = appointment?.attributes.estimate;

    if (estimate) {
      if (
        !appointment?.attributes.member_job ||
        appointment?.attributes.shop_network
      ) {
        price += estimate.total_price;
      } else {
        price += estimate.total_member_price;
      }
    }

    if (appointment?.attributes.appointment_type === 'diagnosis') {
      if (appointment.attributes.member_free_diag) {
        price += 0;
      } else {
        price += appointment.attributes.diagnosis_price;
      }
    }

    price = price >= 0 ? price : 0;

    return price.toFixed(2);
  };

  if (!appointment) {
    return <></>;
  }

  return (
    <div
      style={{
        boxSizing: 'border-box',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.07)',
        position: 'fixed',
        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: '10px',
        zIndex: 3,
        background: '#ffffff',
        width: '100%',
        margin: 'auto',
      }}
    >
      <div
        className={classes.greenCard}
        style={{ borderTop: '1px solid grey' }}
      >
        {!toggleBreakdown ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10vw',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: !isSm ? 5 : 15,
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
                onClick={() => setToggleBreakdown(!toggleBreakdown)}
              >
                PRICE DETAILS
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  style={{
                    alignSelf: 'flex-end',
                    fontSize: '14px',
                    fontFamily: 'Inter',
                    borderRadius: '5px',
                    fontWeight: 700,
                  }}
                >
                  ${changedPrice !== '' ? changedPrice : getPrice()}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {toggleBreakdown ? (
                    <ExpandMore
                      style={{ fontSize: '24px' }}
                      onClick={() => setToggleBreakdown(!toggleBreakdown)}
                    />
                  ) : (
                    <ExpandLess
                      onClick={() => setToggleBreakdown(!toggleBreakdown)}
                      style={{ paddingTop: 10, fontSize: '24px' }}
                    />
                  )}
                </div>
              </div>
            </div>
            <Button
              onClick={onNext}
              style={{
                background: '#005959',
                marginTop: '15px',
                marginLeft: '10px',
                color: '#EDF5E0',
                maxWidth: 'fit-content',
                fontSize: '16px',
                height: '56px',
              }}
              className={classes.buttonForward}
              variant="contained"
              size="large"
            >
              Book Now
            </Button>
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setToggleBreakdown(!toggleBreakdown)}
                aria-label="Toggle Breakdown"
              >
                <img src={Bar} alt="logo" />
              </Button>
            </div>

            <Typography style={{ fontFamily: 'Inter', fontWeight: 700 }}>
              Your Quote
            </Typography>

            {appointment?.attributes.estimate?.subtotal && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: !isSm ? 5 : 0,
                    marginTop: 15,
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      alignSelf: 'flex-start',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                    }}
                  >
                    Services:
                  </Typography>
                  <Typography
                    style={{
                      alignSelf: 'flex-end',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                    }}
                  >
                    $
                    {nonMember
                      ? appointment.attributes.estimate.subtotal
                      : appointment.attributes.estimate.member_subtotal}
                  </Typography>
                </div>
              </>
            )}

            {appointment?.attributes.appointment_type === 'diagnosis' &&
              appointment?.attributes?.diagnosis_fee && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: !isSm ? 5 : 0,
                      marginTop: 15,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        alignSelf: 'flex-start',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                      }}
                    >
                      Diagnosis fee:
                    </Typography>
                    <Typography
                      style={{
                        alignSelf: 'flex-end',
                        fontFamily: 'Inter',
                      }}
                    >
                      ${appointment?.attributes?.diagnosis_fee}
                    </Typography>
                  </div>
                </>
              )}

            {appointment?.attributes.estimate?.convenience_fee &&
            appointment?.attributes.estimate?.convenience_fee > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontFamily: 'Inter',
                  margin: !isSm ? 5 : 0,
                  marginTop: 10,
                }}
              >
                <Typography
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Convenience Fee:
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  ${appointment.attributes.estimate.convenience_fee}
                </Typography>
              </div>
            ) : (
              <></>
            )}

            {appointment?.attributes.estimate?.labor_only_fee && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: !isSm ? 5 : 0,
                  marginTop: 15,
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Liability Fee:
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: '16px' }}>
                        For quality assurance, using your own parts incurs a $90
                        liability fee and is not covered under warranty.
                      </Typography>
                    }
                  >
                    <InfoOutlined
                      style={{
                        fontSize: '16px',
                        marginLeft: '5px',
                        verticalAlign: 'middle',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  ${appointment.attributes.estimate.labor_only_fee}
                </Typography>
              </div>
            )}

            {appointment?.attributes?.estimate?.tax &&
            appointment?.attributes?.estimate?.tax > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontFamily: 'Inter',
                  margin: !isSm ? 5 : 0,
                  marginTop: 10,
                }}
              >
                <Typography
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Tax:
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  ${appointment?.attributes?.estimate?.tax}
                </Typography>
              </div>
            ) : (
              <></>
            )}

            {appointment?.attributes.estimate?.discount_total &&
            appointment?.attributes.estimate?.discount_total > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontFamily: 'Inter',
                  margin: !isSm ? 5 : 0,
                  marginTop: 10,
                }}
              >
                <Typography
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Discount:
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    color: '#005959',
                  }}
                >
                  - ${appointment?.attributes.estimate?.discount_total}
                </Typography>
              </div>
            ) : (
              <></>
            )}

            {appointment?.attributes.estimate?.inconvenience_discount && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: !isSm ? 5 : 0,
                  marginTop: 15,
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Inconvenience Discount:
                  <Tooltip
                    title={
                      <Typography style={{ fontFamily: 'Inter', fontSize: 12 }}>
                        {' '}
                        To address the inconvenience, we are offering a
                        compensatory discount
                      </Typography>
                    }
                  >
                    <IconButton>
                      <Info
                        style={{
                          height: '20px',
                          width: '20px',
                          color: '#A4A2A2',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Typography>

                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  - ${appointment.attributes.estimate.inconvenience_discount}
                </Typography>
              </div>
            )}

            <div style={{ borderTop: '1px solid #1F232266', marginTop: 20 }} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: !isSm ? 5 : 0,
                marginTop: 15,
              }}
            >
              <Typography
                variant="body2"
                style={{
                  alignSelf: 'flex-start',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                Due Now
              </Typography>
              <Typography
                style={{
                  alignSelf: 'flex-end',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                $0
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: !isSm ? 5 : 0,
                marginTop: 15,
              }}
            >
              <Typography
                variant="body2"
                style={{
                  alignSelf: 'flex-start',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                }}
              >
                Due After Service
              </Typography>

              <Typography
                style={{
                  alignSelf: 'flex-end',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                }}
              >
                ${changedPrice !== '' ? changedPrice : getPrice()}
              </Typography>
            </div>
          </>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              color: '#646867',
              fontSize: 12,
              fontFamily: 'Inter',
              textAlign: 'center',
              marginTop: '10px',
            }}
          >
            {' '}
            You <b>won&apos;t be charged</b> until time of service
          </Typography>
        </div>
      </div>
    </div>
  );
};

InvoiceMobile.defaultProps = {
  fullWidth: false,
  nonMember: false,
  changedPrice: '',
};

export default InvoiceMobile;
