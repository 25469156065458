import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { InputWithStatus, SelectWithStatus } from 'src/components/atoms';
import { CardBase } from 'src/components/organisms';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router-dom';
import { createAppointment } from 'src/api/quote';
import { RequestCreateAppointment, ResponseAppointment } from 'src/types';
import { callAdsLanding } from 'src/utils/ads';
import { useSnackbar } from 'notistack';
import handCar from '../../assets/badges/hand-car.svg';
import handMobile from '../../assets/badges/hand-mobile.svg';
import handMoney from '../../assets/badges/hand-money.svg';
import useForm, { PHONE_NUMBER_LENGTH } from './useLandingForm';
import useStyles from './styles';
import logo from '../../assets/logo/FMC-logoFMC-Color-horizontal@2x.png';
import featuredInMobile from '../../assets/featured-in-mobile.svg';
import featuredInDesktop from '../../assets/featured-in-desktop.svg';

const Landing = (): ReactElement => {
  const { account }: { account: string } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const [formSubmitted, setSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { fields, handleChange, options } = useForm();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [step, setStep] = useState<string>('car');

  const { referrer } = document;

  const getZip = () => {
    let zip;

    if (account === 'trilogy') {
      zip = '48103';
    } else if (account === 'avison-young') {
      zip = '75201';
    } else if (account === 'google') {
      zip = '48105';
    }

    return zip || '';
  };

  const getMainText = () => {
    let text;

    if (account === 'trilogy') {
      text =
        'At-Home Car Maintenance for Villas at Main Street & Northstar Residents';
    } else if (account === 'avison-young') {
      text = 'At-work car maintenance service for Parkview tenants';
    } else if (account === 'google') {
      text = 'Car Repair & Maintenance Delivered for Google Employees';
    }

    return text || '';
  };

  const getSubText = () => {
    let text;

    if (account === 'avison-young') {
      text =
        'Our mechanics come to your workplace parking garage to service your car';
    } else {
      text = 'Our mechanics come to you';
    }

    return text;
  };

  const getStepText = () => {
    let text;

    if (step === 'car') {
      text = 'Car Info';
    }

    if (step === 'contact') {
      text = 'Contact Info';
    }

    if (step === 'service') {
      text = 'Service Info';
    }

    return text;
  };

  const isNotValidString = (str: string) => {
    return str.replace(/\s/g, '').length === 0;
  };

  const handleSubmit = () => {
    const requestBody: RequestCreateAppointment = {
      kind: 'RequestCreateAppointment',
      appointment_type: 'repair',
      car_attributes: {
        year: fields.year,
        make: fields.make,
        model: fields.model,
        engine_size: fields.engine,
        mileage: fields.mileage,
      },
      tracking_attributes: {
        utm_source: query.get('utm_source') || '',
        utm_medium: query.get('utm_medium') || '',
        utm_term: query.get('utm_term') || '',
        utm_content: query.get('utm_content') || '',
        utm_campaign: query.get('utm_campaign') || '',
        gclid: query.get('gclid') || '',
        client_id: query.get('google_client_id') || '',
        fbp: query.get('fbp') || '',
        fbc: query.get('fbc') || '',
        fbclid: query.get('fbclid') || '',
        msclkid: query.get('msclkid') || '',
        path: query.get('path') || '',
        coupon: '',
        adg: query.get('adg') || '',
        ref: referrer || '',
      },
      location_attributes: {
        zip: getZip(),
      },
      diagnosis_input: fields.diagnosis_input,
      level: 'B',
      services: [fields.services],
      address: getZip(),
      name: fields.name,
      email: fields.email,
      phone: fields.phone,
      property_job: true,
    };

    createAppointment(requestBody)
      .then((resp: ResponseAppointment) => {
        callAdsLanding(resp.data.id);
        setSubmitted(true);
      })
      .catch(() => {
        enqueueSnackbar('An error occured while processing your information.', {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        });
      });
  };

  return (
    <Grid container className={classes.gridContainer} spacing={4}>
      <Grid item xs={12} className={clsx(classes.logoContainer)}>
        <div className={classes.borderBox}>
          <a href="https://goodhood.auto/">
            <img style={{ width: '200px' }} src={logo} alt="FixMyCar Logo" />
          </a>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(classes.borderBox, classes.contentSignup)}
      >
        <div>
          <Typography variant="h3" component="h1" className={classes.title}>
            {getMainText()}
          </Typography>
          <Typography variant="h6" component="p" color="textSecondary">
            {getSubText()}
          </Typography>
        </div>
        <div className={clsx(classes.borderBox, classes.features)}>
          <div>
            <img src={handCar} alt="" />
            <Typography variant="caption">2 Year Service Warranty</Typography>
          </div>
          <div>
            <img src={handMobile} alt="" />
            <Typography variant="caption">Fixed, Up-Front Pricing</Typography>
          </div>
          <div>
            <img src={handMoney} alt="" />
            <Typography variant="caption">
              Convenient &amp; Affordable
            </Typography>
          </div>
        </div>
        {/* Only show this on desktop */}
        <div className={clsx({ [classes.hidden]: isSm })}>
          <Typography variant="h5" style={{ marginBottom: 30 }}>
            As Featured In
          </Typography>
          <img
            src={featuredInDesktop}
            style={{ width: '100%' }}
            alt="As Featured In"
          />
        </div>
      </Grid>

      <Grid item xs={12} md={6} className={classes.borderBox}>
        <CardBase
          liftUp={false}
          className={clsx(classes.contactFormCard, {
            [classes.hidden]: formSubmitted,
          })}
          align="left"
        >
          <Typography variant="h5">{getStepText()}</Typography>
          <form className={classes.contactForm} noValidate autoComplete="off">
            {step === 'car' && (
              <>
                <SelectWithStatus
                  value={fields.year}
                  valueChanged={(value) => handleChange('year', value)}
                  label="Year"
                  items={options.years}
                />
                <SelectWithStatus
                  value={fields.make}
                  valueChanged={(value) => handleChange('make', value)}
                  label="Make"
                  items={options.makes}
                />
                <SelectWithStatus
                  value={fields.model}
                  valueChanged={(value) => handleChange('model', value)}
                  label="Model"
                  items={options.models}
                />
                <SelectWithStatus
                  value={fields.engine}
                  valueChanged={(value) => handleChange('engine', value)}
                  label="Engine"
                  items={options.engines}
                  extraOptions={{ "I don't know": "I don't know" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !fields.year ||
                    !fields.make ||
                    !fields.model ||
                    !fields.engine
                  }
                  onClick={() => setStep('contact')}
                >
                  Next
                </Button>
              </>
            )}

            {step === 'contact' && (
              <>
                <InputWithStatus
                  value={fields.name}
                  valueChanged={(value) => handleChange('name', value)}
                  placeholder="Full Name"
                />
                <InputWithStatus
                  value={fields.email}
                  valueChanged={(value) => handleChange('email', value)}
                  placeholder="Email"
                  email
                />
                <InputWithStatus
                  value={fields.phone}
                  valueChanged={(value) => handleChange('phone', value)}
                  placeholder="Phone"
                  forceLength={PHONE_NUMBER_LENGTH}
                />
                <div />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    isNotValidString(fields.name) ||
                    isNotValidString(fields.email) ||
                    isNotValidString(fields.phone)
                  }
                  onClick={() => setStep('service')}
                >
                  Next
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setStep('car')}
                >
                  Back
                </Button>
              </>
            )}

            {step === 'service' && (
              <>
                <SelectWithStatus
                  label="Select Service"
                  valueChanged={(value) => handleChange('services', value)}
                  value={fields.services}
                  items={{
                    'Oil Change': 'Oil Change',
                    'Tune Up': 'Tune Up',
                    'Tire Rotation': 'Tire Rotation',
                    Brakes: 'Brakes',
                    'Wiper Blade Service': 'Wiper Blade Service',
                    'Routine Maintenance': 'Routine Maintenance',
                    'Air Filter': 'Air Filter',
                    'Cabin Filter': 'Cabin Filter',
                    'Battery Replacement': 'Battery Replacement',
                  }}
                />

                {fields.services === 'Brakes' && (
                  <SelectWithStatus
                    label="What brake service do you need?"
                    valueChanged={(value) =>
                      handleChange('services_brakes', value)
                    }
                    value={fields.services_brakes}
                    items={{
                      'Front brake pads': 'Front brake pads',
                      'Front brake pads and rotors':
                        'Front brake pads and rotors',
                      'Rear brake pads': 'Rear brake pads',
                      'Rear brake pads and rotors':
                        'Rear brake pads and rotors',
                      'Diagnose my brake issue': 'Diagnose my brake issue',
                    }}
                  />
                )}

                {fields.services !== '' && (
                  <>
                    <SelectWithStatus
                      label="Current approximate mileage"
                      items={options.mileages}
                      value={fields.mileage}
                      valueChanged={(val) => handleChange('mileage', val)}
                      extraOptions={{ "I don't know": "I don't know" }}
                    />
                    <InputWithStatus
                      value={fields.diagnosis_input}
                      valueChanged={(value) =>
                        handleChange('diagnosis_input', value)
                      }
                      placeholder="Anything else you'd like to add?"
                    />
                  </>
                )}
                <div />
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!fields.services}
                  onClick={handleSubmit}
                >
                  Submit Quote
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setStep('contact')}
                >
                  Back
                </Button>
              </>
            )}
          </form>
        </CardBase>
        <CardBase
          liftUp={false}
          className={clsx(classes.contactFormCard, {
            [classes.hidden]: !formSubmitted,
          })}
          align="left"
        >
          <Typography variant="h4" className={classes.marginBottom}>
            Your quote is submitted! Expect a FixMyCar Service Advisor to send
            you your quote ASAP.
          </Typography>
        </CardBase>
      </Grid>
      <Grid item xs={12}>
        {/* Only shows on mobile */}
        <div
          style={{ textAlign: 'center' }}
          className={clsx({ [classes.hidden]: !isSm })}
        >
          <Typography variant="h5" style={{ marginBottom: 30 }}>
            As Featured In
          </Typography>
          <img
            src={featuredInMobile}
            style={{ width: '100%' }}
            alt="As Featured In"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Landing;
