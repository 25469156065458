const getFlatCardDescriptions = (descriptionType: string): string[] => {
  const descriptions = [];

  if (descriptionType === 'diagnosis') {
    descriptions.push('Our technician will come to you.');
    descriptions.push('Comprehensive multipoint inspection.');
  } else {
    descriptions.push('Comprehensive vehicle health check.');
    descriptions.unshift('Includes parts and labor.');
  }

  return descriptions;
};

export default getFlatCardDescriptions;

export const addOnData = [
  {
    name: 'Engine Oil Change',
    parts_attributes: [],
    labor_price: 69.0,
    labor_hours: 1.0,
    extra_data: {
      price: '$69',
      iconText: 'Comes with synthetic oil change + filter',
    },
    shop_monkey_canned_service_id: '61faf0b3718d7f02723f9918',
    isWash: false,
    isOil: true,
  },
];
