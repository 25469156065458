import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import clsx from 'clsx';
import { ExpandMore, Check } from '@material-ui/icons';
import useFontStyles from '../../../fontStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '& .MuiPaper-root': {
      borderRadius: '10px',
    },
    '& .MuiAccordion-rounded:first-child': {
      borderRadius: '10px',
    },
    '& .MuiAccordion-rounded:last-child': {
      borderRadius: '10px',
    },
  },
  accordionSummary: {
    borderRadius: '10px 10px 10px 10px',
    background: '#EBF1FA',
    padding: '20px',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    alignItems: 'center',
  },
  accordionDetails: {
    marginBottom: '10px',
    padding: '20px',
    display: 'block',
  },
  dollarSign: {
    color: '#000',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '8px',
    '& svg': {
      color: theme.palette.secondary.main,
    },
    '& > *': {
      marginRight: '10px',
    },
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px',
  },
}));

interface FlatServiceCardProps {
  title: string;
  price: number;
  descriptions: string[];
}

function FlatServiceCard(props: FlatServiceCardProps): React.ReactElement {
  const classes = useStyles();
  const fontClasses = useFontStyles();
  const { title, price, descriptions } = props;

  return (
    <Accordion className={classes.root} defaultExpanded>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
      >
        <div className={clsx(fontClasses.h4, classes.cardTitle)}>{title}</div>
        <div>
          <span className={fontClasses.h4}>
            <span className={classes.dollarSign}>$</span> {price.toFixed(2)}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={clsx(classes.accordionDetails, fontClasses.p1)}
      >
        {descriptions.map((description) => {
          return (
            <div className={classes.row}>
              <Check />
              {description}
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

export default FlatServiceCard;
