// HealthModal.tsx
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Modal,
  Typography,
  Button,
  Theme,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  TextField,
  IconButton,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {
  AccountCircle,
  CheckCircle,
  Close,
  EmailOutlined,
  ExpandMore,
  Help,
  Message,
  Phone,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { postVirtualDiagnosisLead } from 'src/api/quote';
import mixPanel from 'src/utils/mixpanel';
import { QuoteConfirmedDetails } from 'src/views/Quote_New/components/steps/Context/QuoteConfirmedContext';
import callback from '../../../../assets/payment/CallOut.svg';
import CheckoutForm from './ModalFinishBooking/CheckoutForm';

enum Step {
  SELECT,
  CONTACT,
  PAYMENT,
  CONFIRMATION,
}

interface VirtualDiagnosisProps {
  open: boolean;
  onClose: () => void;
  quoteConfirmed: QuoteConfirmedDetails;
  onlyVirtual: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'brightness(200%)',
    border: 'none',
  },
  paper: {
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    maxWidth: '400px',
    backgroundColor: '#E3F2F9',
    borderRadius: '6px',
    outline: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      maxWidth: '400px',
      borderRadius: '12px',
    },
  },
  hdiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  gotIt: {
    background: '#005959',
    color: '#EDF5E0',
    margin: 'auto',
    fontFamily: 'Inter',
    fontSize: 13,
    padding: '10px 60px',
    borderRadius: 32,
    marginTop: 30,
    textTransform: 'none',
    '&:hover': {
      background: '#005959',
      color: '#EDF5E0',
    },
  },
  image: {
    width: 25,
    height: 25,
  },
  vdiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 7,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },

  textFieldStyle: {
    borderRadius: '8px',
    borderColor: 'grey',

    '& .MuiOutlinedInput-input ': {
      border: 'none',
    },
  },

  title: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
  },
  title2: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 600,
  },
  Radio: {
    border: '2px solid var(--blue-50, #227596)',
    background: '#fff',
    borderRadius: 8,
    padding: '7px 7px',
    margin: 'auto',
    marginTop: 10,
    color: '#003E3E',
    width: '100%',
    fontSize: 17,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  RadioUnSelected: {
    border: '1px solid #A5A7A7',
    background: '#fff',
    borderRadius: 8,
    padding: '7px 7px',
    margin: 'auto',
    marginTop: 10,
    color: '#003E3E',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  RadioButton: {
    color: '#003E3E',
    alignSelf: 'flex-start',
    '& .checked': {
      color: '#003E3E',
    },
  },
  discount: {
    background: '#A5CD65',
    color: '#fff',

    fontSize: 12,
    padding: '4px 8px',
    borderRadius: 32,
    fontWeight: 600,
    fontFamily: 'Inter',
    alignSelf: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  h7: {
    fontSize: 11,
    fontFamily: 'Inter',
  },
  h5: {
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  button: {
    backgroundColor: '#1A5B74',
    color: '#E3F2F9',
    width: '100%',
    marginTop: 15,
    borderRadius: 8,
    textTransform: 'none',
    fontFamily: 'Inter',
    padding: '12px 24px',
    '&:hover': {
      backgroundColor: '#1A5B74',
      color: '#E3F2F9',
    },
  },
  backButton: {
    backgroundColor: '#fff',
    color: '#000',
    width: '100%',
    marginTop: 15,
    borderRadius: 8,
    textTransform: 'none',
    fontFamily: 'Inter',
    padding: '12px 24px',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  disabledButton: {
    backgroundColor: '#D4D5D5',
    color: '#1F232229',
    width: '100%',
    marginTop: 15,
    borderRadius: 8,
    textTransform: 'none',
    fontFamily: 'Inter',
    padding: '12px 24px',
    '&: hover': {
      backgroundColor: '#D4D5D5',
      color: '#1F232229',
    },
  },
  h6: {
    color: '#646867',
    textAlign: 'center',
    fontSize: 12,
    margin: 'auto',
    marginTop: 20,
    fontFamily: 'Inter',
  },
  h6B: {
    color: '#646867',
    textAlign: 'center',
    fontSize: 14,
    margin: 'auto',
    marginTop: 20,
    fontFamily: 'Inter',
  },
  chooseOption: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  planTypography: {
    fontSize: 16,
    fontFamily: 'Inter',
    marginTop: '2%',
    fontWeight: 500,
  },
  planDescription: {
    fontSize: theme.breakpoints.down('sm') ? 13 : 15,
    fontFamily: 'Inter',
    margin: theme.breakpoints.down('sm') ? ' 5% 0% 0% -10%' : ' 5% 0% 0% -5%',
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    marginTop: 30,
  },
  inputPropsStyle: {
    borderColor: 'none',
    borderRadius: '8px',
    background: 'white',
    border: '0px',
  },
  h7MarginTop: {
    marginTop: -25,
    marginLeft: 10,
  },
  h5MarginLeft: {
    marginLeft: 0,
  },
  h7MarginTopMinus10: {
    marginTop: -10,
    marginLeft: 10,
  },
  mobileDiagnosisTitle: {
    fontSize: 16,
    fontFamily: 'Inter',
    marginTop: '2%',
    fontWeight: 500,
  },
  mobileDiagnosisDescription: {
    fontSize: theme.breakpoints.down('sm') ? 13 : 15,
    fontFamily: 'Inter',
    margin: ' 5% 0% 0% 0%',
  },
  mobileDiagnosisNote: {
    fontSize: theme.breakpoints.down('sm') ? 13 : 15,
    fontFamily: 'Inter',
    margin: ' 0%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[800],
  },
  accordionRoot: {
    width: '100%',
    marginLeft: '-10%',
    marginTop: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: 'Inter',
    color: '#005959',
    textDecorationLine: 'underline',
    fontWeight: 500,
  },
  iFrame: {
    width: '100%',
    height: '200%',
    border: '1px solid #e6e6e6',
    borderRadius: 8,
  },
}));

const VirtualDiagnosisModal: React.FC<VirtualDiagnosisProps> = ({
  open,
  onClose,
  quoteConfirmed,
  onlyVirtual,
}) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(Step.SELECT);
  const [selection, setSelection] = useState(onlyVirtual ? 'virtual' : '');
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [ref, setRef] = useState<string | undefined>();
  const [isValidName, setIsValidName] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = React.useState<string | undefined | null>(null);
  const [stripeRequestInProgress, setStripeRequestInProgress] =
    React.useState(false);
  const [problem, setProblem] = useState('');
  const [isValidProblem, setIsValidProblem] = useState(false);
  const history = useHistory();

  const validateName = (e: string) => {
    return e.trim().length > 0;
  };
  const validatePhone = (e: string) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(e);
  };

  const validateEmail = (e: string): boolean => {
    const emailRegex = /^[\w._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return emailRegex.test(e);
  };

  const handleSelectionNew = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelection(event.target.value);
  };

  const handleOptions = () => {
    if (selection === 'virtual') {
      mixPanel('New Funnel Virtual Diag Selected');
      setCurrentStep(Step.CONTACT);
    } else {
      onClose();
    }
  };

  const handleLeads = async () => {
    setStripeRequestInProgress(true);
    setErrors(null);

    if (!stripe || !elements) {
      setStripeRequestInProgress(false);
      return false;
    }

    const cardElem = elements.getElement(CardElement);

    if (!cardElem) {
      setStripeRequestInProgress(false);
      return false;
    }

    const { token } = await stripe.createToken(cardElem);

    const car = ` ${quoteConfirmed?.car?.make} ${quoteConfirmed?.car?.model} ${quoteConfirmed?.car?.engine}`;
    const issue = quoteConfirmed?.service?.diagnostic_problems.join(', ');

    if (token) {
      const lead = {
        name,
        email,
        phone,
        zip: quoteConfirmed.car.zipcode,
        lead_type: 'virtual_diagnosis',
        message: onlyVirtual ? problem : `${issue}\nCar: ${car}, `,
      };

      await postVirtualDiagnosisLead(lead, token.id).then((res) => {
        if (res.errors) {
          setError(true);

          setTimeout(() => {
            setError(false);
          }, 4000);
        } else {
          mixPanel('New Funnel Virtual Diag Requested');
          setError(false);
          setSubmitted(true);
          setCurrentStep(Step.CONFIRMATION);
        }
      });
    } else {
      setStripeRequestInProgress(false);
      setErrors('Invalid card, try again.');
    }

    setStripeRequestInProgress(false);

    return true;
  };

  useEffect(() => {
    const reff = document.referrer;
    setRef(reff);
  }, [ref]);

  const handleNameChange = (e: { target: { value: string } }) => {
    const nameValue = e.target.value;
    setName(nameValue);
    setIsValidName(validateName(nameValue));
  };

  const handlePhoneChange = (e: { target: { value: string } }) => {
    const phoneValue = e.target.value;
    setPhone(phoneValue);
    setIsValidPhone(validatePhone(phoneValue));
  };

  const handleEmailChange = (e: { target: { value: string } }) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsValidEmail(validateEmail(emailValue));
  };

  const handleProblemChange = (e: { target: { value: string } }) => {
    const prob = e.target.value;
    setProblem(prob);
    setIsValidProblem(validateName(prob));
  };

  const paperRef = React.useRef<HTMLDivElement | null>(null);

  const handleClose = (event: React.SyntheticEvent) => {
    if (paperRef.current && !paperRef.current.contains(event.target as Node)) {
      return;
    }
    onClose();
  };

  const handleEnd = () => {
    history.push('/');
  };

  const isError = onlyVirtual
    ? !isValidEmail || !isValidName || !isValidPhone || !isValidProblem
    : !isValidEmail || !isValidName || !isValidPhone;

  const renderStepContent = () => {
    switch (currentStep) {
      case Step.SELECT:
        return (
          <div className={classes.chooseOption}>
            {onlyVirtual ? (
              <Typography className={classes.title}>
                Virtual diagnosis
              </Typography>
            ) : (
              <Typography className={classes.title}>Choose Option</Typography>
            )}
            <RadioGroup
              aria-label="plan"
              value={selection}
              onChange={handleSelectionNew}
              row
            >
              <FormControlLabel
                value="virtual"
                control={
                  <Radio color="primary" className={classes.RadioButton} />
                }
                label={
                  <>
                    <Typography className={classes.planTypography}>
                      Virtual Diagnosis - $30
                    </Typography>{' '}
                    <Typography className={classes.planDescription}>
                      A certified mechanic will video chat with you to diagnose
                      over the phone
                    </Typography>{' '}
                    <div className={classes.accordionRoot}>
                      <Accordion
                        elevation={0}
                        style={{ background: '#fff' }}
                        expanded={onlyVirtual}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            Watch Demo Video
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <iframe
                            className={classes.iFrame}
                            src="https://www.youtube.com/embed/JErfxDmZF6E?si=flLfeSM7j_3zP5O3"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                className={
                  selection === 'virtual'
                    ? classes.Radio
                    : classes.RadioUnSelected
                }
              />
              {!onlyVirtual && (
                <>
                  <Typography className={classes.h6}>
                    or continue with
                  </Typography>
                  <FormControlLabel
                    value="mobile"
                    control={
                      <Radio color="primary" className={classes.RadioButton} />
                    }
                    label={
                      <>
                        <Typography className={classes.mobileDiagnosisTitle}>
                          Mobile Diagnosis - $90
                        </Typography>

                        <ul style={{ marginLeft: '-20%' }}>
                          <li>
                            <Typography
                              className={classes.mobileDiagnosisDescription}
                            >
                              Our technician will come to you
                            </Typography>
                          </li>
                          <li>
                            <Typography className={classes.mobileDiagnosisNote}>
                              Receive a detailed diagnostic
                            </Typography>
                          </li>
                        </ul>
                      </>
                    }
                    className={
                      selection === 'mobile'
                        ? classes.Radio
                        : classes.RadioUnSelected
                    }
                  />
                </>
              )}
            </RadioGroup>
            <Button
              disabled={selection === ''}
              onClick={handleOptions}
              className={
                selection === '' ? classes.disabledButton : classes.button
              }
            >
              Continue
            </Button>
          </div>
        );

      case Step.CONTACT:
        return (
          <div>
            <Typography className={classes.title}>Contact</Typography>

            <div className={classes.contactContainer}>
              <TextField
                variant="outlined"
                placeholder="Name"
                onChange={handleNameChange}
                value={name}
                InputProps={{
                  inputMode: 'numeric',
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle style={{ color: '#646867' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <CheckCircle
                        style={{
                          color: isValidName ? 'green' : 'white',
                        }}
                      />
                    </InputAdornment>
                  ),

                  style: {
                    borderColor: 'none',
                    borderRadius: '8px',
                    background: 'white',
                    border: '0px',
                  },
                }}
                className={classes.textFieldStyle}
                fullWidth
              />

              <TextField
                variant="outlined"
                placeholder="Email"
                inputMode="email"
                value={email}
                onChange={handleEmailChange}
                InputProps={{
                  inputMode: 'numeric',
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined style={{ color: '#646867' }} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment position="end">
                      <CheckCircle
                        style={{
                          color: isValidEmail ? 'green' : 'white',
                        }}
                      />
                    </InputAdornment>
                  ),

                  style: {
                    borderColor: 'none',
                    borderRadius: '8px',
                    background: 'white',
                  },
                }}
                className={classes.textFieldStyle}
                fullWidth
              />

              <TextField
                variant="outlined"
                placeholder="Phone"
                onChange={handlePhoneChange}
                inputMode="tel"
                value={phone}
                InputProps={{
                  inputMode: 'numeric',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone style={{ color: '#646867' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <CheckCircle
                        style={{
                          color: isValidPhone ? 'green' : 'white',
                        }}
                      />
                    </InputAdornment>
                  ),

                  style: {
                    borderColor: 'none',
                    borderRadius: '8px',
                    background: 'white',
                  },
                }}
                className={classes.textFieldStyle}
                fullWidth
              />
              <Typography
                className={classes.h7}
                style={{ marginTop: -15, marginLeft: 10 }}
              >
                Must be a valid 10-digit number
              </Typography>

              {onlyVirtual && (
                <TextField
                  variant="outlined"
                  placeholder="What help do you need?"
                  onChange={handleProblemChange}
                  value={problem}
                  multiline
                  rows={2}
                  InputProps={{
                    inputMode: 'numeric',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Help
                          style={{
                            color: '#646867',
                            marginBottom: 20,
                            marginLeft: 10,
                          }}
                        />
                      </InputAdornment>
                    ),

                    style: {
                      borderColor: 'none',
                      borderRadius: '8px',
                      background: 'white',
                      border: '0px',
                    },
                  }}
                  className={classes.textFieldStyle}
                  fullWidth
                />
              )}
            </div>

            <Button
              disabled={isError}
              onClick={() => {
                setCurrentStep(Step.PAYMENT);
              }}
              className={isError ? classes.disabledButton : classes.button}
            >
              {stripeRequestInProgress ? <CircularProgress /> : 'Continue'}
            </Button>

            <Button
              className={classes.backButton}
              onClick={() => {
                setCurrentStep(Step.SELECT);
              }}
              variant="outlined"
            >
              Back
            </Button>
          </div>
        );

      case Step.PAYMENT:
        return (
          <>
            {' '}
            <Typography className={classes.title}>Payment</Typography>
            <div>
              <Typography
                className={classes.h5}
                style={{ marginLeft: 0, marginTop: 20 }}
              >
                Credit/ Debit Card
              </Typography>
              <CheckoutForm errors={errors} />
              <Typography
                className={classes.h7}
                style={{ marginTop: -10, marginLeft: 10 }}
              >
                You won’t be charged until the video call is completed
              </Typography>
            </div>
            {error && (
              <Typography style={{ margin: '10px', color: '#ff0000' }}>
                Error processing, please correct information and try again.
              </Typography>
            )}
            <Button
              disabled={stripeRequestInProgress || isError}
              onClick={handleLeads}
              className={isError ? classes.disabledButton : classes.button}
            >
              {stripeRequestInProgress ? <CircularProgress /> : 'Continue'}
            </Button>
            <Button
              className={classes.backButton}
              onClick={() => {
                setCurrentStep(Step.CONTACT);
              }}
              variant="outlined"
            >
              Back
            </Button>
          </>
        );

      case Step.CONFIRMATION:
        return (
          <div className={classes.hdiv}>
            <div className={classes.vdiv}>
              <img src={callback} height={30} alt="logo" />
              <Typography className={classes.title2}>
                Virtual Diagnosis Request
              </Typography>
            </div>

            <Typography className={classes.h6B}>
              {' '}
              A qualified mechanic will reach out within the hour to do a
              virtual video diagnosis
            </Typography>
            <Typography className={classes.h6B}>
              <p>If you have any questions</p>
              Call us:{' '}
              <a
                href="tel:469-840-3990"
                style={{
                  textDecoration: 'none',
                  color: '#1A5B74',
                  fontWeight: 700,
                }}
              >
                469-840-3990
              </a>
            </Typography>

            <Button onClick={handleEnd} className={classes.button}>
              Got it
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      onBackdropClick={(e) => e.stopPropagation()}
    >
      <div ref={paperRef} className={classes.paper}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
        {renderStepContent()}
      </div>
    </Modal>
  );
};

export default VirtualDiagnosisModal;
