import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK_FUNNEL_V2 } from 'src/utils/consts';
import { getAppointment } from 'src/api/quote';
import { setAppointment } from 'src/store/actions';
import { Today, Payment, TimerOff } from '@material-ui/icons';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import QuoteServiceConfirmStepper from './QuoteServiceConfirmStepper';

const promise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

const QuoteServiceConfirm: React.FC = (): ReactElement => {
  const { appId: appIdParam }: { appId: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncReadAppointment = async () => {
      const appointment = await getAppointment(appIdParam);

      dispatch(setAppointment(appointment.data));
    };

    if (appIdParam) {
      asyncReadAppointment();
    }

    mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_QUOTE_LOADED);
  }, [appIdParam, dispatch]);

  const steps = [
    {
      id: 1,
      label: 'Quote',
      image: <TimerOff />,
    },
    {
      id: 2,
      label: 'Appointment',
      image: <Today />,
    },
    {
      id: 3,
      label: 'Payment',
      image: <Payment />,
    },
  ];

  return (
    <Elements
      stripe={promise}
      options={{
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimaryText: '#005959',
            colorPrimary: '#005959',
            colorBackground: '#ffffff',
            colorDanger: '#ff0000',
            spacingUnit: '2px',
            borderRadius: '4px',
          },
        },
      }}
    >
      <QuoteServiceConfirmStepper steps={steps} />
    </Elements>
  );
};

export default QuoteServiceConfirm;
