import React from 'react';
import { Typography } from '@material-ui/core';
import paypal from 'src/assets/paypal.png';

const PayInFour: React.FC = () => {
  return (
    <>
      {' '}
      <Typography
        style={{
          color: '#000',
          textAlign: 'center',
          fontFamily: 'Inter',
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '30px',
          marginTop: 10,
          marginBottom: 5,
        }}
      >
        Pay in interest-free installments with
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
        <img src={paypal} alt="Credit Card" style={{ width: 50 }} />
      </div>
    </>
  );
};

export default PayInFour;
