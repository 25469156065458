import {
  PaletteOptions,
  Palette,
  SimplePaletteColorOptions,
} from '@material-ui/core/styles/createPalette';

type Modify<T, R> = Omit<T, keyof R> & R;

export type CustomPaletteOptions = Modify<
  PaletteOptions,
  {
    alternate: SimplePaletteColorOptions;
    cardShadow: string;
    background: {
      paper: string;
      default: string;
      level2: string;
      level1: string;
      footer: string;
    };
  }
>;

export type CustomPalette = Modify<
  Palette,
  {
    alternate: SimplePaletteColorOptions;
    cardShadow: string;
    background: {
      paper: string;
      default: string;
      level2: string;
      level1: string;
      footer: string;
    };
  }
>;

export const palette: CustomPaletteOptions = {
  alternate: {
    main: 'rgb(247, 249, 250)',
    dark: '#e8eaf6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  type: 'light',
  primary: {
    main: '#005959',
    light: '#CCDDDD',
    dark: '#004747',
    contrastText: '#fff',
  },
  secondary: {
    light: '#d9eef6',
    main: '#A1D5EA',
    dark: '#7095a3',
    contrastText: '#0000ff',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  warning: {
    light: '#ffb74d',
    main: '#ff9800',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    light: '#64b5f6',
    main: '#2196f3',
    dark: '#1976d2',
    contrastText: '#fff',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  grey: {
    '50': '#fafafa',
    '100': '#f5f5f5',
    '200': '#eeeeee',
    '300': '#e0e0e0',
    '400': '#bdbdbd',
    '500': '#9e9e9e',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    default: '#FFFFFF',
    paper: '#F4F7FC',
    level2: '#f5f5f5',
    level1: '#fff',
    footer: '#302A3C',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};
