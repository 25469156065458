import { PayPalMessages, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';

interface PayPalTextProps {
  amount: string | undefined;
}

function PayPalText(props: PayPalTextProps): React.ReactElement {
  const { amount } = props;

  return (
    <div
      style={{
        margin: '10px auto',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PayPalScriptProvider
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
          components: 'messages',
        }}
      >
        <PayPalMessages amount={amount} />
      </PayPalScriptProvider>
    </div>
  );
}

export default PayPalText;
