const isAllInspections = (collection: string[]): boolean => {
  if (collection.length === 1 && collection[0] === 'Pre-Purchase Inspection') {
    return false;
  }

  const inspectionLength: number = collection.filter(
    (x) => typeof x === 'string' && x.toLowerCase().includes('inspection')
  ).length;

  return inspectionLength > 0 && inspectionLength === collection.length;
};

export default isAllInspections;
