import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface QuoteConfirmedContextType {
  QuoteConfirmed: QuoteConfirmedDetails;
  setQuoteConfirmed: (QuoteDetails: QuoteConfirmedDetails) => void;
}

export interface QuoteConfirmedDetails {
  car: {
    selected_type_id: number;
    selected_type: string;
    zipcode: string;
    year: string;
    make: string;
    model: string;
    engine: string;
    license_plate: string;
    state: string;
    vin: string;
  };
  service: {
    service_type: string;
    diagnostic_problems: string[];
    diagnostic_problem_description: string;
    repair_problems: string[];
  };
  appointment: {
    selected_day: string | undefined;
    selected_window: string;
    exact_address: string;
  };
  contact: {
    first_name: string;
    email_id: string;
    phone_number: string;
    referral_code: string;
    heard_about_us: string;
  };
  referral: {
    referral_code: string;
    referral_applied: boolean;
  };
  membership: {
    membership_selected: string;
  };
}

export const QuoteConfirmedContext = createContext<QuoteConfirmedContextType>({
  QuoteConfirmed: {
    car: {
      selected_type_id: 0,
      selected_type: '',
      zipcode: '',
      year: '',
      make: '',
      model: '',
      engine: '',
      license_plate: '',
      state: '',
      vin: '',
    },
    service: {
      service_type: '',
      diagnostic_problems: [],
      diagnostic_problem_description: '',
      repair_problems: [],
    },
    appointment: {
      selected_day: '',
      selected_window: '',
      exact_address: '',
    },
    contact: {
      first_name: '',
      email_id: '',
      phone_number: '',
      referral_code: '',
      heard_about_us: '',
    },
    referral: {
      referral_code: '',
      referral_applied: false,
    },
    membership: {
      membership_selected: '',
    },
  },
  setQuoteConfirmed: () => {},
});

const QuoteProvider: React.FC = ({ children }) => {
  const query = new URLSearchParams(useLocation().search);

  const [QuoteConfirmed, setQuoteConfirmed] = useState<QuoteConfirmedDetails>({
    car: {
      selected_type_id: 0,
      selected_type: '',
      zipcode: '',
      year: '',
      make: '',
      model: '',
      engine: '',
      license_plate: '',
      state: '',
      vin: '',
    },
    service: {
      service_type: '',
      diagnostic_problems: [],
      diagnostic_problem_description: '',
      repair_problems: [],
    },
    appointment: {
      selected_day: '',
      selected_window: '',
      exact_address: '',
    },
    contact: {
      first_name: '',
      email_id: '',
      phone_number: '',
      referral_code: query.get('coupon') || query.get('referral_coupon') || '',
      heard_about_us: '',
    },
    referral: {
      referral_code: '',
      referral_applied: false,
    },
    membership: {
      membership_selected: '',
    },
  });

  return (
    <QuoteConfirmedContext.Provider
      value={{
        QuoteConfirmed,
        setQuoteConfirmed,
      }}
    >
      {children}
    </QuoteConfirmedContext.Provider>
  );
};

export default QuoteProvider;
