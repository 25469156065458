import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import {
  Card,
  Typography,
  Button,
  makeStyles,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Info, InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  Diagnosescard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
    background: '#fff',
  },
  clock: {
    marginTop: 30,
    width: 80,
    height: 80,
    flexShrink: 0,
    color: '#005959',
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  li: {
    color: '#005959',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#fff',
  },
  whiteCard: {
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    flex: 1,
  },
  mobileRoot: {
    width: '100%',
  },
  buttonDiscount: {
    width: '89px',
    height: '24px',
    padding: '4px 8px',
    gap: '8px',
    flexShrink: 0,
    borderRadius: '32px',
    background: 'var(--green-30, #A5CD65)',
    color: '#005959',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
    lineHeight: 'normal',
    marginLeft: '10px',
  },
}));

interface QuoteSummaryProps {
  fullWidth?: boolean;
  nonMember?: boolean;
  priceSelected?: string;
}

const QuoteSummary: React.FC<QuoteSummaryProps> = ({
  fullWidth,
  nonMember,
  priceSelected,
}) => {
  const classes = useStyles();
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const isSm = useMediaQuery('(max-width: 1229px)');
  const [toggleBreakdown, setToggleBreakdown] = useState(false);

  const getPrice = () => {
    let price = 0;

    const estimate = appointment?.attributes.estimate;

    if (estimate) {
      if (
        !appointment?.attributes.member_job ||
        appointment?.attributes.shop_network
      ) {
        price += estimate.total_price;
      } else {
        price += estimate.total_member_price;
      }
    }

    if (appointment?.attributes.appointment_type === 'diagnosis') {
      if (appointment.attributes.member_free_diag) {
        price += 0;
      } else {
        price += appointment.attributes.diagnosis_price;
      }
    }

    price = price >= 0 ? price : 0;

    return price.toFixed(2);
  };

  const getMemberPrice = () => {
    let price = 0;

    const estimate = appointment?.attributes.estimate;

    if (appointment?.attributes.appointment_type === 'diagnosis' && !estimate) {
      return (appointment.attributes.diagnosis_price * 0.9).toFixed(2);
    }

    if (estimate) {
      price += estimate.total_member_price;
    }

    if (appointment?.attributes.appointment_type === 'diagnosis') price += 0;

    return price.toFixed(2);
  };

  const getNonMemberPrice = () => {
    let price = 0;

    const estimate = appointment?.attributes.estimate;

    if (estimate) {
      price += estimate.total_price;
    }

    if (appointment?.attributes.appointment_type === 'diagnosis')
      price += appointment.attributes.diagnosis_fee;

    price = price >= 0 ? price : 0;

    return price.toFixed(2);
  };

  const memberSavings = () => {
    let savings = 0;

    const estimate = appointment?.attributes.estimate;

    if (appointment?.attributes.appointment_type === 'diagnosis' && !estimate) {
      return (appointment.attributes.diagnosis_price * 0.1).toFixed(2);
    }

    if (estimate) {
      savings = estimate.member_savings;
    } else if (appointment) {
      savings = appointment?.attributes.diagnosis_fee;
    }
    return savings.toFixed(2);
  };

  if (!appointment) {
    return <></>;
  }

  return (
    <Card
      style={{
        minWidth: !isSm ? '364px' : '95%',
        maxWidth: fullWidth ? '100%' : '364px',
        height: 'fit-content',
        background: '#fff',
        borderRadius: '8px',
        margin: fullWidth ? '0px 20px' : 'none',
      }}
    >
      <div className={classes.greenCard}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: !isSm ? 5 : 0,
          }}
        >
          <Typography
            variant="body2"
            style={{
              alignSelf: 'flex-start',
              marginTop: 10,
              fontFamily: 'Inter',
              fontSize: '16px',
            }}
          >
            Your Quote:
          </Typography>
          <Typography
            variant="body2"
            style={{
              alignSelf: 'flex-end',
              fontSize: '32px',
              fontFamily: 'Inter',
              borderRadius: '5px',
              padding: 4,
            }}
          >
            ${priceSelected === 'member' ? getMemberPrice() : getPrice()}
          </Typography>
        </div>

        {(nonMember && appointment.attributes.estimate) || !nonMember ? (
          <Button
            role="button"
            onClick={() => setToggleBreakdown(!toggleBreakdown)}
            style={{
              textTransform: 'none',
              textDecoration: 'underline',
              padding: 0,
              marginTop: 10,
              marginBottom: 10,
              fontFamily: 'Inter',
              color: '#fff',
            }}
          >
            {toggleBreakdown ? 'Hide Price Breakdown' : 'View Price Breakdown'}
          </Button>
        ) : null}

        {toggleBreakdown && (
          <>
            {appointment?.attributes.estimate?.subtotal && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: !isSm ? 5 : 0,
                  marginTop: 15,
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Services:
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  ${appointment.attributes.estimate.subtotal}
                </Typography>
              </div>
            )}

            {appointment?.attributes.appointment_type === 'diagnosis' &&
              appointment?.attributes?.diagnosis_fee && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: !isSm ? 5 : 0,
                    marginTop: 15,
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      alignSelf: 'flex-start',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                    }}
                  >
                    Diagnosis fee:
                  </Typography>
                  <Typography
                    style={{
                      alignSelf: 'flex-end',
                      fontFamily: 'Inter',
                    }}
                  >
                    ${appointment?.attributes?.diagnosis_fee}
                  </Typography>
                </div>
              )}

            {appointment?.attributes.estimate?.convenience_fee && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontFamily: 'Inter',
                  margin: !isSm ? 5 : 0,
                  marginTop: 10,
                }}
              >
                <Typography
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Convenience Fee:
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  ${appointment.attributes.estimate.convenience_fee}
                </Typography>
              </div>
            )}

            {appointment?.attributes.estimate?.labor_only_fee && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: !isSm ? 5 : 0,
                  marginTop: 15,
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Liability Fee:
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: '16px' }}>
                        For quality assurance, using your own parts incurs a $90
                        liability fee and is not covered under warranty.
                      </Typography>
                    }
                  >
                    <InfoOutlined
                      style={{
                        fontSize: '16px',
                        marginLeft: '5px',
                        verticalAlign: 'middle',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  ${appointment.attributes.estimate.labor_only_fee}
                </Typography>
              </div>
            )}

            {appointment?.attributes.estimate?.tax &&
            appointment?.attributes.estimate?.tax > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontFamily: 'Inter',
                  margin: !isSm ? 5 : 0,
                  marginTop: 10,
                }}
              >
                <Typography
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Tax:
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  ${appointment.attributes.estimate.tax}
                </Typography>
              </div>
            ) : (
              <></>
            )}

            {appointment?.attributes.estimate?.discount_total &&
            appointment?.attributes.estimate?.discount_total > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontFamily: 'Inter',
                  margin: !isSm ? 5 : 0,
                  marginTop: 10,
                }}
              >
                <Typography
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Discount:
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  - ${appointment?.attributes.estimate?.discount_total}
                </Typography>
              </div>
            ) : (
              <></>
            )}

            {appointment?.attributes.estimate?.inconvenience_discount && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: !isSm ? 5 : 0,
                  marginTop: 15,
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Inconvenience Discount:
                  <Tooltip
                    title={
                      <Typography style={{ fontFamily: 'Inter', fontSize: 12 }}>
                        {' '}
                        To address the inconvenience, we are offering a
                        compensatory discount
                      </Typography>
                    }
                  >
                    <IconButton>
                      <Info
                        style={{
                          height: '20px',
                          width: '20px',
                          color: '#A4A2A2',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Typography>

                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  - ${appointment.attributes.estimate.inconvenience_discount}
                </Typography>
              </div>
            )}

            {appointment?.attributes.estimate?.discount && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: !isSm ? 5 : 0,
                  marginTop: 15,
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  Discount:
                </Typography>
                <Typography
                  style={{
                    alignSelf: 'flex-end',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  - ${appointment.attributes.estimate.discount}
                </Typography>
              </div>
            )}

            {!nonMember &&
              !appointment?.attributes.fleet_job &&
              !appointment?.attributes.shop_network && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: !isSm ? 5 : 0,
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        alignSelf: 'flex-start',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                      }}
                    >
                      Members Save:
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        alignSelf: 'flex-end',
                        background: '#fff',
                        borderRadius: 4,
                        padding: '2px 4px',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        color: '#005959',
                      }}
                    >
                      ${memberSavings()}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      margin: !isSm ? 5 : 0,
                      marginTop: isSm ? 12 : 10,
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        alignSelf: 'flex-start',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                      }}
                    >
                      Member Price:
                      {!isSm && (
                        <Button className={classes.buttonDiscount}>
                          Save 10%
                        </Button>
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        alignSelf: 'flex-end',
                        background: '#fff',
                        borderRadius: 4,
                        padding: '2px 4px',
                        fontFamily: 'Inter',
                        fontStyle: 'italic',
                        color: '#005959',
                        fontSize: '14px',
                      }}
                    >
                      <s>${getNonMemberPrice()}</s> ${getMemberPrice()}
                    </Typography>
                  </div>
                  <div>
                    {isSm && (
                      <Button className={classes.buttonDiscount}>
                        Save 10%
                      </Button>
                    )}
                  </div>
                </>
              )}
          </>
        )}
      </div>
    </Card>
  );
};

QuoteSummary.defaultProps = {
  fullWidth: false,
  nonMember: false,
  priceSelected: '',
};

export default QuoteSummary;
