import * as React from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  makeStyles,
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { CreditCardRounded } from '@material-ui/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import BtnDashboard from 'src/views/Dashboard/components/DashboardButton';
import MembershipUpgradeModal from './components/Modals/MembershipUpgradeModal';
import ModalUpdateCC from '../Dashboard/components/Modals/UpdateCC';

import Profile from './components/ProfileCard';

export enum MODALS {
  NONE,
  REFERRAL,
  MEMBER,
  UPDATE_CARD,
  PAST_APPOINTMENTS,
  VEHICLES,
}

const useStyles = makeStyles((theme) => ({
  creditCard: {
    marginTop: 110,
    textTransform: 'none',
    paddingLeft: 9,
    paddingRight: 9,
    color: '#005959',
    borderRadius: '32px',
    fontWeight: 400,
    fontFamily: 'Inter',
    maxWidth: '300px',
    marginLeft: 'auto',
    background: '#E6EEEE',
    '&:hover': {
      background: '#E6EEEE',
      color: '#005959',
    },
    '@media (max-width: 600px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 'auto',
    },
  },
  title: {
    color: '#1F2322',
    fontSize: '24px',
    fontFamily: 'Inter',
    fontWeight: 700,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  memberCard: {
    maxWidth: '600px',
    background: '#fff',
  },
  memberText: {
    fontFamily: 'Inter',
  },
  UpgradeButton: {
    marginTop: 20,
    background: '#005959',
    textTransform: 'none',
    paddingLeft: 9,
    paddingRight: 9,
    maxWidth: 200,
    color: '#EDF5E0',
    fontFamily: 'Inter',
    borderRadius: '32px',
    fontWeight: 400,
    '&:hover': {
      background: '#005959',
      color: '#EDF5E0',
    },
  },
  MemberSubText: {
    color: 'var(--black-100, #1F2322)',
    fontSize: '18px',
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontWeight: 500,
    marginLeft: 0,
    marginTop: 20,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '700px',
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },

  memberSubText: {
    textAlign: 'center',
  },
  subscriptionBadge: {
    textTransform: 'none',
    paddingLeft: 9,
    paddingRight: 9,
    color: '#005959',
    borderRadius: '0px',
    borderBottomRightRadius: '5px',
    fontWeight: 400,
    fontFamily: 'Inter',
    maxWidth: '300px',
    marginLeft: 'auto',
    background: '#E6EEEE',
    '&:hover': {
      background: '#E6EEEE',
      color: '#005959',
    },

    '&.Mui-disabled': {
      background: '#E6EEEE',
      color: '#005959',
    },
  },
}));

const promise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

const ProfilePage: React.FC = () => {
  const classes = useStyles();
  const [currentModal, setCurrentModal] = React.useState<MODALS>(MODALS.NONE);
  const [isModalOpen, setModalOpen] = React.useState(false);

  const handleUpgrade = () => {
    // Handle the upgrade logic here
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const showModal = (modal: MODALS) => {
    return () => {
      setCurrentModal(modal);
    };
  };

  const user = useSelector((state: IReduxState) => state.auth.user);
  const [username, setUserName] = React.useState(user?.attributes.name);
  const [phoneNum, setPhoneNum] = React.useState(user?.attributes.phone);
  const [userAddress, setUserAddress] = React.useState(
    user?.attributes.default_address
  );

  return (
    <Elements stripe={promise}>
      <Container>
        <div>
          <Typography className={classes.title}>My Profile</Typography>
          <Profile
            userId={user?.id}
            setUserName={setUserName}
            setPhoneNumber={setPhoneNum}
            name={username}
            address={userAddress || ''}
            phoneNumber={phoneNum}
          />

          <Grid container spacing={2}>
            {user?.attributes?.is_member &&
            user?.attributes?.membership_information &&
            user?.attributes?.membership_information.renewal_date ? (
              <Grid item xs={12} sm={6}>
                <Typography className={classes.title}>
                  Membership Details
                </Typography>
                <Card className={classes.memberCard} elevation={3}>
                  {user?.attributes?.membership_information
                    .upgrade_available ? (
                    <Button className={classes.subscriptionBadge} disabled>
                      Monthly Member
                    </Button>
                  ) : (
                    <Button className={classes.subscriptionBadge} disabled>
                      Yearly Member
                    </Button>
                  )}
                  <CardContent style={{ paddingBottom: '16px' }}>
                    <Typography className={classes.memberText}>
                      You have been a member for{' '}
                      {user?.attributes?.membership_information.member_for} days
                      🎉
                    </Typography>
                    {user?.attributes?.membership_information
                      .upgrade_available && (
                      <Stack>
                        <Button
                          className={classes.UpgradeButton}
                          onClick={() => setModalOpen(true)}
                        >
                          Upgrade to Yearly Plan
                        </Button>

                        <MembershipUpgradeModal
                          isOpen={isModalOpen}
                          onUpgrade={handleUpgrade}
                          onCancel={handleCancel}
                        />

                        <Typography className={classes.MemberSubText}>
                          Save 20% by paying $164 yearly
                        </Typography>
                      </Stack>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <Typography className={classes.title}>
                  Membership Details
                </Typography>
                <Card className={classes.memberCard} elevation={3}>
                  <CardContent>
                    <Typography className={classes.memberText}>
                      Become a Member
                    </Typography>
                    <Box style={{ margin: '20px 0' }}>
                      <Typography>
                        Check out our membership which offers FREE oil changes
                        and 10% off all repairs. Sign up{' '}
                        <a
                          href="https://goodhood.auto/membership"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                        .
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Typography className={classes.title}>Account</Typography>
              <BtnDashboard
                variant="primary"
                onClick={showModal(MODALS.UPDATE_CARD)}
                startIcon={<CreditCardRounded />}
                className={classes.creditCard}
              >
                Update Card Information
              </BtnDashboard>
            </Grid>
          </Grid>

          <ModalUpdateCC
            show={currentModal === MODALS.UPDATE_CARD}
            onClose={showModal(MODALS.NONE)}
          />
        </div>
      </Container>
    </Elements>
  );
};

export default ProfilePage;
