import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    button: {
      padding: '20px',
      fontSize: '15pt',
      width: 'min(300px, 80vw)',
    },
    dialog: {
      '& .MuiAccordion-rounded:first-child': {
        borderRadius: '10px',
      },
      '& .MuiAccordion-rounded:last-child': {
        borderRadius: '10px',
      },
    },
    root: {
      '& h1': {
        color: theme.palette.primary.dark,
        [theme.breakpoints.up('md')]: {
          textTransform: 'none',
          color: '#2A2D3C',
          marginBlockEnd: '0',
        },
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    invoice: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    layout: {
      marginBottom: '180px',
    },
  };
});

export default useStyles;
