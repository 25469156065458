import React, { ReactElement } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Box } from '@mui/material';
import envelopeImage from 'src/assets/envelopeImage.png';
import ModalBase from './ModalBase';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '500px',
    padding: 20,
  },
}));

function WeWillOutSoonModal(props: ModalProps): ReactElement {
  const { open, onClose } = props;

  const classes = useStyles();

  return (
    <ModalBase open={open} onClose={onClose} className={classes.root}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Close
            onClick={onClose}
            style={{
              display: 'flex',
              alignSelf: 'flex-end',
              cursor: 'pointer',
            }}
          />
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            rowGap: '30px',
            padding: '0px 20px 20px 20px',
          }}
        >
          <img src={envelopeImage} alt="alert" />
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '32px',
              lineHeight: '35.2px',
              fontWeight: 600,
              color: '#4C504D',
              textAlign: 'center',
            }}
          >
            We will reach out to you soon as we can!
          </Typography>
        </div>
      </Box>
    </ModalBase>
  );
}

export default WeWillOutSoonModal;
