import { IAppointment, ResponseSignin } from 'src/types';

const gtagReportConversion = (userId: number, sendTo: string, url?: string) => {
  const callback = () => {
    if (typeof url !== 'undefined') {
      window.location.href = url;
    }
  };

  const { gtag } = window;

  if (typeof gtag !== 'undefined') {
    gtag('event', 'conversion', {
      send_to: sendTo,
      user_id: userId,
      event_callback: callback,
    });
  }
  return false;
};

const bingTrack = (event: string) => {
  window.uetq = window.uetq || [];
  window.uetq.push('event', event, {
    event_category: event,
    event_label: event,
    event_value: '0',
  });
};

const dataLayer = (appointment: IAppointment) => {
  let serviceName;

  if (appointment.attributes.appointment_type === 'repair') {
    serviceName = appointment.attributes.services.join(', ');
  } else {
    serviceName = 'Diagnose my car';
  }

  const data = {
    quoteInfo: {
      jobId: appointment.id,
      services: [{ serviceName }],
      car: {
        make: appointment.attributes.car.make,
        model: appointment.attributes.car.model,
        year: appointment.attributes.car.year,
      },
      appointment_type: appointment.attributes.appointment_type,
      conversionValue:
        appointment.attributes.appointment_type === 'diagnosis' ? 69 : 0,
      membershipStatus: appointment.attributes.member_job ? 'True' : 'False',
      userInfo: {
        userId: appointment.attributes.user_id,
      },
    },
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'quoteRequest',
    data,
  });

  if (appointment.attributes.status !== 'no_service_area') {
    window.dataLayer.push({
      event: 'quote_correctzipcode_only',
      data,
    });
  }
};

const dataLayerBooking = (
  appointment: IAppointment,
  totalVal: number | undefined
) => {
  let serviceName;

  if (appointment.attributes.appointment_type === 'repair') {
    serviceName = appointment.attributes.services.join(', ');
  } else {
    serviceName = 'Diagnose my car';
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'bookingScheduled',
    jobId: appointment.id,
    services: [{ serviceName }],
    car: {
      make: appointment.attributes.car.make,
      model: appointment.attributes.car.model,
      year: appointment.attributes.car.year,
    },
    appointment_type: appointment.attributes.appointment_type,
    conversionValue: totalVal,
    membershipStatus: appointment.attributes.member_job ? 'True' : 'False',
    userInfo: {
      userId: appointment.attributes.user_id,
    },
  });
};

export const dataLayerLogin = (userInfo: ResponseSignin): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'login',
    userInfo: {
      userId: userInfo.user.id,
      clientId: userInfo.user.client_id,
      source: userInfo.user.source,
    },
  });
};

export const callAdsQuote = (appointment: IAppointment): void => {
  bingTrack('Quote');
  dataLayer(appointment);
};

export const callAdsLanding = (id: number): void => {
  gtagReportConversion(id, 'AW-792197156/x55TCJ_osu4CEKTw3_kC');
};

export const callAdsBooking = (appointment: IAppointment): void => {
  const { attributes } = appointment;

  let totalVal;

  if (attributes.appointment_type === 'diagnosis') {
    totalVal = attributes.diagnosis_fee;
  } else {
    totalVal = attributes.estimate?.total_price;
  }

  bingTrack('Booking');
  dataLayerBooking(appointment, totalVal);
};
