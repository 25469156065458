import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

interface ButtonPrimaryProps {
  className?: string;
  children: ReactElement | string;
  onClick: () => void;
  startIcon?: string | ReactElement;
  variant?: 'primary' | 'secondary' | 'ghost' | 'green' | 'black';
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.dark,
    textTransform: 'none',
    fontFamily: 'Tiempos',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'none',
    outlineStyle: 'none',
    '& > * + *': {
      marginLeft: '8px',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
    },
    cursor: 'pointer',
  },
  primary: {
    width: '100%',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    padding: '16px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
    '&:active': {
      background: theme.palette.primary.main,
    },
  },
  secondary: {
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
  },
  ghost: {
    background: 'white',
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    padding: '10px',
    '&:hover': {
      background: 'white',
      color: theme.palette.primary.main,
    },
    '&:active': {
      background: 'white',
      color: theme.palette.primary.main,
    },
  },
  green: {
    background: '#81c784',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    padding: '10px',
    '&:hover': {
      background: 'white',
      color: theme.palette.primary.dark,
    },
    '&:active': {
      background: 'white',
      color: theme.palette.primary.dark,
    },
  },
  black: {
    background: '#000',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    padding: '10px',
    '&:hover': {
      background: 'white',
      color: theme.palette.primary.dark,
    },
    '&:active': {
      background: 'white',
      color: theme.palette.primary.dark,
    },
  },
}));

export default function BtnDashboard(props: ButtonPrimaryProps): ReactElement {
  const { className, children, onClick, startIcon, variant, disabled } = props;
  const classes = useStyles();

  return (
    <button
      className={clsx(className, classes.root, {
        [classes.primary]: variant === 'primary',
        [classes.secondary]: variant === 'secondary',
        [classes.ghost]: variant === 'ghost',
        [classes.green]: variant === 'green',
        [classes.black]: variant === 'black',
      })}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {typeof startIcon === 'string' ? (
        <img src={startIcon} alt="startIcon" />
      ) : (
        startIcon
      )}
      <span>{children}</span>
    </button>
  );
}

BtnDashboard.defaultProps = {
  className: '',
  startIcon: null,
  variant: 'ghost',
  disabled: false,
};
