import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkVin, getAppointment } from 'src/api/quote';
import { setAppointment } from 'src/store/actions';
import {
  DirectionsCar,
  Build,
  Today,
  PermPhoneMsg,
  Payment,
  MoneyOff,
} from '@material-ui/icons';
import { Box } from '@material-ui/core';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import StepperComponent from './stepper';

interface ICar {
  year: string;
  make: string;
  model: string;
  engine: string;
  vin: string;
}

const promise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

const QuoteNew: React.FC = (): ReactElement => {
  const { appId: appIdParam }: { appId: string } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const [car, setCar] = useState<ICar>({
    year: '',
    make: '',
    model: '',
    engine: '',
    vin: '',
  });

  const vin = query.get('vin');

  useEffect(() => {
    const asyncReadAppointment = async () => {
      const appointment = await getAppointment(appIdParam);

      dispatch(setAppointment(appointment.data));
    };

    if (appIdParam) {
      asyncReadAppointment();
    }

    if (vin) {
      checkVin(vin).then((res) => {
        if (!res.errors) {
          setCar({
            year: res.year,
            make: res.make,
            model: res.model,
            engine: res.engine,
            vin,
          });
        }
      });
    }
  }, [appIdParam, dispatch, vin]);

  const steps = [
    {
      id: 1,
      label: 'Car Details',
      image: <DirectionsCar />,
    },
    {
      id: 2,
      label: 'Services',
      image: <Build />,
    },

    {
      id: 3,
      label: 'Contact',
      image: <PermPhoneMsg />,
    },

    {
      id: 4,
      label: 'Quote',
      image: <MoneyOff />,
    },

    {
      id: 5,
      label: 'Appointment',
      image: <Today />,
    },
    {
      id: 6,
      label: 'Payment',
      image: <Payment />,
    },
  ];

  return (
    <Elements
      stripe={promise}
      options={{
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimaryText: '#005959',
            colorPrimary: '#005959',
            colorBackground: '#ffffff',
            colorDanger: '#ff0000',
            spacingUnit: '2px',
            borderRadius: '4px',
          },
        },
      }}
    >
      <Box
        sx={{
          paddingBottom: 150,
        }}
      >
        <StepperComponent steps={steps} car={car} />
      </Box>
    </Elements>
  );
};

export default QuoteNew;
