import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Box } from '@material-ui/core';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';

interface CallUsProps {
  helpfulText?: string;
  onClick?: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 15,
    border: '1px solid #ECECEC',
    padding: 20,
    borderRadius: 10,
    textAlign: 'center',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  findText: {
    color: '#333',
    textDecoration: 'none',
  },
  innerBox: {
    alignItems: 'center',
  },
  callHelp: {
    paddingTop: 5,
    paddingRight: 8,
    fontSize: 18,
    fontWeight: 800,
    textAlign: 'center',
  },
  iconPhone: {
    marginTop: 4,
    height: 20,
    color: '#7157FF',
    cursor: 'pointer',
  },
}));

const CallUsLink = (props: CallUsProps): ReactElement => {
  const styles = useStyles();
  const { helpfulText, onClick } = props;

  return (
    <Box className={styles.root}>
      <Link
        onClick={onClick}
        className={styles.linkStyle}
        href="tel:469-840-3990"
      >
        <Typography className={styles.findText}>{helpfulText}</Typography>
        <Box className={styles.innerBox}>
          <Typography className={styles.callHelp}>
            Give Us A Call <PhoneRoundedIcon className={styles.iconPhone} />
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

CallUsLink.defaultProps = {
  helpfulText: undefined,
  onClick: undefined,
};

export default CallUsLink;
