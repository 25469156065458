import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ICarFromList, ConnectedVehicle } from 'src/types';

import { Button, Typography } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridClasses,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Add, EditOutlined } from '@material-ui/icons';
import { IReduxState } from 'src/store/reducers';
import { apiGetConnectedCarDataByVin } from 'src/api/user';
import mixPanel from 'src/utils/mixpanel';
import ServiceModal from 'src/views/Dashboard/components/Modals/ServiceModal';
import createLead from 'src/api/lead';
import VehicleForm from './VehicleForm';

interface Car {
  year: number | undefined;
  make: string;
  model: string;
  engine_size: string;
  vin: string;
  name: string;
}

interface IssuesData {
  [vin: string]: ConnectedVehicle;
}

type IssueFieldProps = {
  text: string;
};

type StatusFieldProps = {
  text: string;
  color: string;
};

const IssueField = ({ text }: IssueFieldProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px',
        borderRadius: '120px',
        border: '1px solid #CE3426',
        minHeight: '36px',
        width: 'fit-content',
        columnGap: '5px',
      }}
    >
      <div
        style={{
          width: '8px',
          height: '8px',
          backgroundColor: '#CE3426',
          borderRadius: '100%',
        }}
      />
      <Typography
        style={{
          color: '#CE3426',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '15.6px',
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

const StatusField = ({ text, color }: StatusFieldProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px',
        borderRadius: '120px',
        border: `1px solid ${color}`,
        minHeight: '36px',
        width: 'fit-content',
        columnGap: '5px',
      }}
    >
      <Typography
        style={{
          color: `${color}`,
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '15.6px',
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

const screenWidth = window.innerWidth || 1000;

export default function Vehicles(): ReactElement {
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<number | undefined>();
  const [yearOptions, setYearOptions] = useState<string[] | undefined>();
  const [filtered, setFiltered] = useState<ICarFromList[]>();
  const [car, setCar] = useState<Car | undefined>();
  const [issuesData, setIssuesData] = useState<IssuesData>({});
  const [noDeviceVins, setNoDeviceVins] = useState<string[]>([]);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [currentCarId, setCurrentCarId] = useState<number | null | undefined>();

  const user = useSelector((state: IReduxState) => state.auth.user);

  const openServiceModal = () => setIsServiceModalOpen(true);
  const closeServiceModal = () => setIsServiceModalOpen(false);

  useEffect(() => {
    if (user?.attributes.total_unique_cars) {
      const years = user.attributes.total_unique_cars.map((i) => i.one_string);
      setYearOptions(Array.from(new Set(years)).sort());
      setFiltered(user.attributes.total_unique_cars);
    }
  }, [user]);

  useEffect(() => {
    if (filtered) {
      filtered.forEach((vehicle) => {
        const { vin } = vehicle;

        if (!vin) {
          setNoDeviceVins((prevVins) => [...prevVins, vin]);
          return;
        }

        apiGetConnectedCarDataByVin(vin).then((response) => {
          if (response.data.length === 0) {
            setNoDeviceVins((prevVins) => [...prevVins, vin]);
          } else {
            const vehicleData = response.data[0];

            setIssuesData((prevData) => ({
              ...prevData,
              [vin]: vehicleData,
            }));
          }
        });
      });
    }
  }, [filtered]);

  const isEmptyObject = (obj: Record<string, unknown> | undefined): boolean =>
    !obj || Object.keys(obj).length === 0;

  const handleOrderDevice = (selectedCar: ICarFromList) => {
    const message = `${user?.attributes.name} is requesting a new device for ${selectedCar.year}, ${selectedCar.make}, ${selectedCar.model}, ${selectedCar.vin}`;
    createLead(
      user?.attributes.name || '',
      user?.attributes.email || '',
      user?.attributes.phone || '',
      user?.attributes.zip_code || '',
      '',
      '',
      '',
      '',
      '',
      '',
      message
    ).then(() => {
      alert('Your request has been submitted');
    });
  };

  const VehicleCell = ({
    row: { year, make, model, vin },
  }: GridRenderCellParams) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: 16,
            color: '#000',
          }}
        >
          {`${year} ${make} ${model}`}
        </Typography>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: 14,
            color: '#797C7A',
            marginTop: 4,
          }}
        >
          {vin && `Vin: ${vin}`}
        </Typography>
      </div>
    );
  };

  let columns: GridColDef[] = [];

  if (user?.attributes.no_obds) {
    columns = [
      {
        field: 'vehicle',
        headerName: 'Vehicle',
        minWidth: screenWidth * 0.3,
        renderCell: VehicleCell,
      },
      {
        field: 'name',
        headerName: 'Name',
        minWidth: screenWidth * 0.3,
        renderCell: (params) => (
          <>
            <Typography
              style={{
                fontFamily: 'Inter',
                textTransform: 'none',
                color: '#005959',
                fontSize: 16,
              }}
            >
              {params.row.name}
            </Typography>
            <Button
              onClick={() => {
                setEditingName(true);
                setEditingId(params.row.id);
                setCar({
                  year: params.row.year,
                  make: params.row.make,
                  model: params.row.model,
                  engine_size: params.row.engineSize,
                  vin: params.row.vin,
                  name: params.row.name,
                });
              }}
            >
              <EditOutlined
                style={{ marginRight: 10, color: '#005959', height: '25px' }}
              />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  textTransform: 'none',
                  color: '#005959',
                  fontSize: 16,
                }}
              >
                {' '}
                Edit Name
              </Typography>
            </Button>
          </>
        ),
      },
    ];
  } else {
    columns = [
      {
        field: 'vehicle',
        headerName: 'Vehicle',
        minWidth: screenWidth * 0.12,
        renderCell: VehicleCell,
      },
      {
        field: 'issues',
        headerName: 'Issues',
        minWidth: screenWidth * 0.175,
        renderCell: (params) => {
          const { vin } = params.row;
          const issues = issuesData[vin] || [];
          const { dtcs, vehicle_data: vehicleData } = issues;
          const { oil_life: oilLife, tire_pressures: tirePressures } =
            vehicleData || {};

          return !isEmptyObject(issuesData) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              {!isEmptyObject(dtcs) &&
                Object.keys(dtcs).map((code) => (
                  <IssueField key={code} text={code} />
                ))}
              {!isEmptyObject(oilLife) && oilLife?.text && oilLife?.value && (
                <IssueField
                  text={`Oil level ${oilLife.text} ${oilLife.value}`}
                />
              )}
              {tirePressures && (
                <>
                  {tirePressures.front_left_tire_pressure && (
                    <IssueField
                      text={`Tire pressure Front Left: ${tirePressures.front_left_tire_pressure} psi`}
                    />
                  )}
                  {tirePressures.front_right_tire_pressure && (
                    <IssueField
                      text={`Tire pressure Front Right: ${tirePressures.front_right_tire_pressure} psi`}
                    />
                  )}
                  {tirePressures.rear_left_tire_pressure && (
                    <IssueField
                      text={`Tire pressure Rear Left: ${tirePressures.rear_left_tire_pressure} psi`}
                    />
                  )}
                  {tirePressures.rear_right_tire_pressure && (
                    <IssueField
                      text={`Tire pressure Rear Right: ${tirePressures.rear_right_tire_pressure} psi`}
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <div />
          );
        },
      },
      {
        field: 'status',
        headerName: 'Vehicle Status',
        minWidth: screenWidth * 0.12,
        renderCell: (params) => {
          const getStatusColor = (vStatus: string) => {
            switch (vStatus) {
              case 'Needs Service':
                return '#CE3426';
              case 'Needs a Device':
                return '#FFC107';
              default:
                return '#157F40';
            }
          };

          return (
            <StatusField
              text={params.row.status}
              color={getStatusColor(params.row.status)}
            />
          );
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        minWidth: screenWidth * 0.16,
        renderCell: (params) => (
          <>
            <Typography
              style={{
                fontFamily: 'Inter',
                textTransform: 'none',
                color: '#005959',
                fontSize: 16,
              }}
            >
              {params.row.name}
            </Typography>
            <Button
              onClick={() => {
                setEditingName(true);
                setEditingId(params.row.id);
                setCar({
                  year: params.row.year,
                  make: params.row.make,
                  model: params.row.model,
                  engine_size: params.row.engineSize,
                  vin: params.row.vin,
                  name: params.row.name,
                });
              }}
            >
              <EditOutlined
                style={{ marginRight: 10, color: '#005959', height: '25px' }}
              />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  textTransform: 'none',
                  color: '#005959',
                  fontSize: 16,
                }}
              >
                {' '}
                Edit Name
              </Typography>
            </Button>
          </>
        ),
      },
      {
        field: 'takeAction',
        headerName: 'Take Action',
        minWidth: screenWidth * 0.17,
        renderCell: (params) => (
          <>
            {params.row.status === 'Needs Service' && (
              <Button
                style={{
                  maxWidth: 'fit-content',
                  background: '#005959',
                  color: 'white',
                  borderRadius: 8,
                  fontFamily: 'Inter',
                  textTransform: 'none',
                }}
                onClick={() => {
                  openServiceModal();
                  mixPanel('Fleet Schedule Service', user?.id);
                  setCurrentCarId(params.row.id);
                }}
              >
                Schedule Service
              </Button>
            )}

            {params.row.status === 'All Good' && (
              <Typography
                style={{
                  color: '#005959',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                No action needed
              </Typography>
            )}

            {params.row.status === 'Needs a Device' && (
              <Button
                style={{
                  maxWidth: 'fit-content',
                  background: 'white',
                  color: '#005959',
                  borderRadius: 8,
                  fontFamily: 'Inter',
                  textTransform: 'none',
                  border: '1px solid #005959',
                }}
                onClick={() => handleOrderDevice(params.row)}
              >
                Request a Device
              </Button>
            )}
          </>
        ),
      },
    ];
  }

  filtered?.sort((a, b) => a.id - b.id);

  const rows =
    filtered?.map((vehicle) => {
      const vehicleIssues = issuesData[vehicle.vin] || {};
      const hasDtcs = !isEmptyObject(vehicleIssues.dtcs);
      const needsDevice = noDeviceVins.includes(vehicle.vin) || !vehicle.vin;

      let vStatus = 'All Good';
      if (needsDevice) {
        vStatus = 'Needs a Device';
      } else if (hasDtcs) {
        vStatus = 'Needs Service';
      }

      return {
        id: vehicle.id,
        vehicle: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        year: vehicle.year,
        make: vehicle.make,
        model: vehicle.model,
        engineSize: vehicle.engine_size,
        vin: vehicle.vin,
        name: vehicle.name,
        status: vStatus,
      };
    }) || [];

  function CustomToolbar() {
    const [openCreateCar, setOpenCreateCar] = useState<boolean>(false);
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '0.1px solid lightgrey',
        }}
      >
        <Typography
          style={{ fontFamily: 'Inter', fontSize: '28px', margin: 10 }}
        >
          Vehicles
        </Typography>
        <GridToolbarContainer>
          <GridToolbarFilterButton style={{ color: '#344054' }} />
          <Button
            onClick={() => setOpenCreateCar(true)}
            style={{
              maxWidth: 'fit-content',
              marginRight: 10,
              marginLeft: 10,
              background: '#E6EEEE',
              color: '#005959',
              borderRadius: 8,
              padding: '6px 15px',
            }}
          >
            <Add style={{ marginRight: 10 }} />
            <Typography style={{ fontFamily: 'Inter', textTransform: 'none' }}>
              {' '}
              Add Vehicle
            </Typography>
          </Button>
          <VehicleForm
            open={openCreateCar}
            onClose={() => setOpenCreateCar(false)}
            type="create"
            carId={undefined}
            carDetails={undefined}
          />
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <div>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
        style={{ width: '100%' }}
        getRowHeight={() => 'auto'}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
            alignItems: 'flex-start',
          },
        }}
      />
      {car && (
        <VehicleForm
          open={editingName}
          onClose={() => setEditingName(false)}
          type={editingName ? 'update' : 'create'}
          carId={editingId}
          carDetails={car}
        />
      )}
      <ServiceModal
        isOpen={isServiceModalOpen}
        onClose={closeServiceModal}
        selectedCarId={currentCarId || null || undefined}
      />
    </div>
  );
}
