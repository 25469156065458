import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title2: {
    color: 'var(--black-100, #1F2322)',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '30px',
  },
  title: {
    color: 'var(--black-100, #1F2322)',
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: 700,
    marginTop: 20,
  },
  dialog: {
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cancelButton: {
    marginBottom: 20,
    background: '#E6EEEE',
    textTransform: 'none',
    paddingLeft: 15,
    paddingRight: 15,
    fontFamily: 'Inter',
    color: '#005959',
    borderRadius: '25px',
    fontWeight: 600,
  },
  yesButton: {
    marginBottom: 20,
    background: '#005959',
    textTransform: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    color: '#EDF5E0',
    fontFamily: 'Inter',
    borderRadius: '25px',
    fontWeight: 400,
    '&:hover': {
      color: '#E6EEEE',
      background: '#005959',
    },
  },
}));

interface ModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onUpgrade: () => void;
}

const MembershipUpgradeModal: React.FC<ModalProps> = ({
  isOpen,
  onCancel,
  onUpgrade,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={onCancel} className={classes.dialog}>
      <Typography className={classes.title}>Are you sure?</Typography>

      <DialogContent>
        <Typography className={classes.title2}>
          you will be charged $164 for the upgrade now, and will not be charged
          again until next year
        </Typography>
      </DialogContent>

      <DialogActions style={{ margin: 'auto', gap: 30 }}>
        <Button
          onClick={onCancel}
          color="primary"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={onUpgrade}
          color="primary"
          className={classes.yesButton}
        >
          Yes, Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MembershipUpgradeModal;
