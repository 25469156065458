import React, { useEffect } from 'react';
import {
  Card,
  Typography,
  makeStyles,
  useMediaQuery,
  Box,
  Link,
} from '@material-ui/core';
import { Image } from 'src/components/atoms';
import AppleDownload from 'src/assets/iOS.svg';
import GoogleDownload from 'src/assets/play-badge.svg';
import Featured from 'src/assets/Featured.svg';
import iphone13 from 'src/assets/iphone13.png';
import { QRCodeSVG } from 'qrcode.react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import { getAppointment } from 'src/api/quote';
import { useParams } from 'react-router-dom';
import { setAppointment } from 'src/store/actions';

const useStyles = makeStyles((theme) => ({
  Diagnosescard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
    background: '#fff',
  },
  clock: {
    marginTop: 30,
    width: 60,
    height: 60,
    flexShrink: 0,
    color: '#5B7B28',
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  li: {
    color: '#005959',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#fff',
  },
  whiteCard: {
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    flex: 1,
  },
  mobileRoot: {
    width: '100%',
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },
}));

const NewLogin: React.FC = () => {
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const { appId: appIdParam }: { appId: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncReadAppointment = async () => {
      const app = await getAppointment(String(appIdParam));
      dispatch(setAppointment(app.data));
    };

    asyncReadAppointment();
  }, [appIdParam, dispatch]);

  const classes = useStyles();
  const isSm = useMediaQuery('(max-width: 929px)');
  const isVerySm = useMediaQuery('(max-width: 375px)');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 80,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 40,
        paddingBottom: 40,
      }}
    >
      <div>
        <Card
          className={classes.Diagnosescard}
          style={{
            marginTop: 50,
            background: '#F3F3F3',
            width: isSm ? '95%' : 'max-content',
            margin: 'auto',
            paddingLeft: isSm ? 20 : 0,
            paddingRight: isSm ? 20 : 0,
          }}
          elevation={0}
        >
          <div
            style={{
              padding: 10,
              background: '#E3F2F9',
              marginRight: 'auto',
              borderRadius: 12,
            }}
          >
            <img
              src={Featured}
              alt="correct-pic"
              style={{ marginRight: 'auto', width: 50, height: 50 }}
            />
          </div>

          <Typography
            style={{
              marginTop: 30,
              fontSize: isSm ? 32 : 40,
              fontFamily: 'Inter',
              fontWeight: 700,
              marginRight: 'auto',
              maxWidth: 500,
              color: '#003E3E',
            }}
          >
            Login to your account using our mobile app
          </Typography>

          <Typography
            style={{
              marginTop: 30,
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: 500,
              marginRight: 'auto',
            }}
          >
            (Now only on the app)
          </Typography>

          <ul style={{ marginRight: 'auto', marginLeft: -20 }}>
            <li>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                }}
              >
                Stay up to date on your service
              </Typography>
            </li>
            <li>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                }}
              >
                Chat with your technician
              </Typography>
            </li>
            <li>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                }}
              >
                Invite friends and earn rewards
              </Typography>
            </li>
          </ul>

          {isSm && (
            <div
              style={{
                background: '#E3F2F9',
                padding: 20,
                borderRadius: 12,
                marginTop: 20,
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: '#0F3443',
                  fontFamily: 'Inter',
                  textAlign: 'center',
                }}
              >
                Download the app to view quotes, book appointments and more
              </Typography>
            </div>
          )}
          <div
            style={{
              width: '100%',
              padding: 20,
              fontFamily: 'Inter',
              textAlign: 'center',
              marginTop: 10,
              paddingRight: 'auto',
              marginLeft: !isSm ? -40 : -70,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: isVerySm ? 'column' : 'row',
                justifyContent: !isSm ? 'center' : 'center',
                alignItems: 'center',
                gap: '2vw',
                width: 'fit-content',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 10,
                }}
              >
                <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                  <Link href="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907">
                    <Image className={classes.appleIcon} src={AppleDownload} />
                  </Link>
                </Box>
                <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                  <Link href="https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp">
                    <Image src={GoogleDownload} />
                  </Link>
                </Box>
              </div>
              <div
                style={{
                  border: '6px solid #005959',
                  padding: 10,
                  paddingBottom: 2,
                  borderRadius: '12px',
                }}
              >
                {' '}
                <QRCodeSVG value="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907" />
              </div>
            </div>
          </div>
        </Card>
      </div>

      {!isSm ? (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={iphone13}
              alt="nothing"
              height={500}
              width={250}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NewLogin;
