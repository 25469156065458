import React, { ReactElement, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link, useMediaQuery } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import checkCircle from 'src/assets/check_circle.png';
import calendar from 'src/assets/payment/calendar.svg';
import clock from 'src/assets/payment/clock.svg';
import Tool from 'src/assets/payment/Tool.svg';
import coin from 'src/assets/payment/coin.svg';
import location from 'src/assets/payment/pin.svg';

import { ImageNode } from 'src/components/molecules';
import mechanic from 'src/assets/payment/mechanic.svg';
import SvgQuestion from 'src/assets/badges/question-primary.svg';
import SvgInformation from 'src/assets/badges/information-primary.svg';
import { QuoteContext } from 'src/views/Quote/QuoteContext';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import { useParams } from 'react-router-dom';
import { getAppointment } from 'src/api/quote';
import { setAppointment } from 'src/store/actions';

interface ModalCongratsProps {
  show: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 600,
    [theme.breakpoints.down('sm')]: {
      minWidth: 250,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    margin: 0,
    padding: 0,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(6),
    position: 'relative',
  },
  titleText: {
    fontFamily: 'Tiempos',
    color: '#2A2D3C',
    fontSize: 45,
    lineHeight: '28px',
    fontWeight: 700,
    textAlign: 'center',
    zIndex: 100,
  },
  subTitleText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: '#7E7A92',
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 400,
    maxWidth: 500,
    textAlign: 'center',
    zIndex: 100,
  },
  titleBgImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionContainer: {
    width: '100%',
    justifyContent: 'center',
  },

  titleDatetime: {
    fontWeight: 900,
    fontSize: 24,
    lineHeight: '32.8px',
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  boxInformation: {
    width: '100%',
    background: '#EBF1FA',
    borderRadius: 6,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  containerQuestion: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  imgQuestion: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    marginRight: theme.spacing(2),
  },
  titleQuestion: {
    color: '#7E7A92',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 15,
    lineHeight: '20px',
    '& b': {
      color: '#302A3C',
      fontWeight: 800,
    },
    '& .arrow': {
      position: 'absolute',
      top: 0,
      right: theme.spacing(1),
      height: '100%',
      width: 25,
      color: '#BDC1DA',
      cursor: 'pointer',
    },
  },
  titlePhone: {
    color: '#7E7A92',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 15,
    lineHeight: '20px',
    textDecoration: 'none',
  },
  helperQuestion: {
    marginBottom: theme.spacing(3),
  },
}));

const ModalCongrats = (props: ModalCongratsProps): ReactElement => {
  const { show, onClose } = props;
  const classes = useStyles();

  const { isMechanicEstimate } = useContext(QuoteContext);

  const isSm = useMediaQuery('(max-width: 1229px)');

  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const { appId: appIdParam }: { appId: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncReadAppointment = async () => {
      const app = await getAppointment(String(appIdParam));
      dispatch(setAppointment(app.data));
    };

    asyncReadAppointment();
  }, [appIdParam, dispatch]);

  const getPrice = () => {
    let price = 0;

    const estimate = appointment?.attributes.estimate;

    if (estimate) {
      if (!appointment?.attributes.member_job) {
        price += estimate.total_price;
      } else {
        price += estimate.total_member_price;
      }
    }

    if (appointment?.attributes.appointment_type === 'diagnosis')
      price += appointment.attributes.diagnosis_price;

    price = price >= 0 ? price : 0;

    return price.toFixed(2);
  };

  const formatDate = (dateStr?: string): string => {
    if (!dateStr) {
      return 'Invalid date format';
    }

    try {
      const [year, month, day] = dateStr.split('-');
      const dateObject = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10)
      );
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const formattedDate = dateObject.toLocaleDateString('en-US', options);
      return formattedDate;
    } catch (error) {
      return 'Invalid date format';
    }
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
    >
      <DialogContent style={{ margin: 40 }}>
        <img
          src={checkCircle}
          alt="correct-pic"
          style={{
            marginRight: 'auto',
            height: isSm ? 60 : 80,
            width: isSm ? 60 : 80,
          }}
        />
        <Typography
          style={{
            marginTop: 20,
            fontSize: isSm ? 20 : 32,
            fontFamily: 'Inter',
            fontWeight: 700,
            marginRight: 'auto',
            maxWidth: 500,
          }}
        >
          Appointment Confirmed
        </Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 'auto',
            marginTop: 30,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <img
            src={mechanic}
            alt="mechanic"
            style={{
              color: '#646867',
              margin: 15,
              marginTop: 0,
              marginLeft: 0,
              alignSelf: 'flex-top',
            }}
          />
          <div>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: 700,
              }}
            >
              {appointment?.attributes?.mechanic?.name || 'Houston'}
            </Typography>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: 'Inter',
                fontWeight: 500,
                color: '#646867',
              }}
            >
              Mechanic
            </Typography>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 'auto',
            marginTop: 10,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <img
            src={Tool}
            alt="mechanic"
            style={{
              color: '#646867',
              margin: 15,
              marginTop: 0,
              marginBottom: 10,
              marginLeft: 0,
            }}
          />
          {appointment?.attributes.appointment_type === 'repair' ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                Service / Repair
              </Typography>
              <ul
                style={{
                  marginTop: 10,
                  color: '#646867',
                  fontFamily: 'Inter',
                  paddingLeft: 0,
                  marginLeft: 18,
                  fontSize: 16,
                }}
              >
                {appointment?.attributes.services.map((problem, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>{problem}</li>
                ))}
              </ul>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                  marginTop: 10,
                }}
              >
                {' '}
                Diagnosis
              </Typography>
            </div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 'auto',
          }}
        >
          <img
            src={calendar}
            alt="mechanic"
            style={{
              color: '#646867',
              margin: 15,
              marginTop: 0,
              marginLeft: 0,
            }}
          />
          <Typography
            style={{
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: 700,
            }}
          >
            {formatDate(appointment?.attributes.appointment_day)}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 'auto',
            marginTop: 10,
          }}
        >
          <img
            src={clock}
            alt="mechanic"
            style={{
              color: '#646867',
              margin: 15,
              marginTop: 0,
              marginLeft: 0,
            }}
          />
          <Typography
            style={{
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: 700,
            }}
          >
            {appointment?.attributes.appointment_time}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 'auto',
            marginTop: 10,
          }}
        >
          <img
            src={location}
            alt="mechanic"
            style={{
              color: '#646867',
              margin: 15,
              marginTop: 0,
              marginLeft: 0,
            }}
          />
          <Typography
            style={{
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: 700,
            }}
          >
            {appointment?.attributes.exact_address ||
              appointment?.attributes.address}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 'auto',
          }}
        >
          <img
            src={coin}
            alt="mechanic"
            style={{
              color: '#646867',
              margin: 15,
              marginTop: 0,
              marginLeft: 0,
              alignSelf: 'flex-top',
            }}
          />
          <div>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: 700,
              }}
            >
              ${getPrice() || 'TBD'}
            </Typography>
            {!appointment?.attributes.shop_network && (
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  color: '#646867',
                }}
              >
                to be charged at time of service
              </Typography>
            )}
          </div>
        </div>

        {!isMechanicEstimate && (
          <Box key="payment" className={classes.boxInformation}>
            <ImageNode
              key="questions"
              title={
                <>
                  <b>Questions?</b>
                  <br />
                  <Link
                    key="tel-team-fixmycar"
                    className={classes.titlePhone}
                    href="tel:469-840-3990"
                  >
                    Call us 469-840-3990
                  </Link>
                </>
              }
              imgUrl={SvgQuestion}
              titleProps={{ className: classes.titleQuestion }}
              imgProps={{ className: classes.imgQuestion }}
              className={classes.containerQuestion}
            />
            <ImageNode
              key="next-steps"
              title={
                <>
                  <b>Next steps:</b>
                  <br />
                  See your service request in your Active
                </>
              }
              imgUrl={SvgInformation}
              titleProps={{ className: classes.titleQuestion }}
              imgProps={{ className: classes.imgQuestion }}
              className={classes.containerQuestion}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

ModalCongrats.defaultProps = {};

export default ModalCongrats;
