// JS file since it's copied from the old codebase.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { apiBaseUrl as apiUrl } from '../utils/config';

function headers() {
  const user = JSON.parse(localStorage.getItem('user'));
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${user ? user.auth_token : 'Unauthorized'}`,
  };
}

function submitReview(review = {}) {
  const requestOptions = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify({
      mechanic_review: {
        appointment_id: review.id,
        rating_text: review.rating_text,
        feedback: review.feedback,
        user_id: review.user_id,
        diag_feedback: review.diag_feedback,
      },
    }),
  };

  return fetch(`${apiUrl}/api/v1/mechanic/reviews`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then((json) => {
      return json;
    });
}

function submitTip(tip, reviewId) {
  const requestOptions = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify({
      mechanic_review: {
        tip_amount: tip,
      },
    }),
  };

  return fetch(
    `${apiUrl}/api/v1/mechanic/reviews/tip/${reviewId}`,
    requestOptions
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then((json) => {
      return json;
    });
}

const reviewService = {
  submitReview,
  submitTip,
};
export default reviewService;
