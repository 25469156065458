import {
  ResponseGetUserAppointments,
  ResponseGetUser,
  ResponsePatchUser,
  ResponsePaymentMethods,
  ResponseAppointment,
  ResponseCannedServices,
  ConnectedVehicle,
  ResponseConnectedCars,
  ResponseConnectedCarsByVin,
} from 'src/types';
import { callApi } from './request';

export const apiGetUser = async (): Promise<ResponseGetUser> => {
  return callApi<ResponseGetUser>({
    url: `/api/v2/user`,
    method: 'GET',
  });
};

export const apiGetUserAppointments = async (
  status: string
): Promise<ResponseGetUserAppointments> => {
  return callApi<ResponseGetUserAppointments>({
    url: `/api/v2/user/appointments`,
    params: { status },
    method: 'GET',
  });
};

export const apiUpdateCreditCard = async (
  token: string,
  id: string | null
): Promise<ResponseGetUser> => {
  return callApi<ResponseGetUser>({
    url: `/api/v2/user/${id}`,
    params: { token },
    method: 'PATCH',
  });
};

export const apiUpdateProfileInfo = (
  id: string,
  info: { name: string; phone: string }
): Promise<ResponsePatchUser> => {
  return callApi({
    url: `/api/v2/user/profile/${id}`,
    data: {
      user: info,
    },
    method: 'PATCH',
  });
};

export const apiGetBankSetupIntentSecret = async (): Promise<{
  client_secret: string;
}> => {
  return callApi({
    url: '/api/v2/user/bank_account_secret/',
    method: 'GET',
  });
};

export const apiGetUnpaidAppointments =
  async (): Promise<ResponseGetUserAppointments> => {
    return callApi({
      url: '/api/v2/user/appointments/unpaid',
      method: 'GET',
    });
  };

export const apiGetUserPaymentMethods =
  async (): Promise<ResponsePaymentMethods> => {
    return callApi({
      url: '/api/v2/user/payment_methods',
      method: 'GET',
    });
  };

export const apiPayInvoice = async (
  paymentId: number,
  payment_method_id: string,
  total_price: number
): Promise<ResponseAppointment> => {
  return callApi({
    url: `/api/v2/user/payments/${paymentId}`,
    method: 'PUT',
    data: {
      payment_method_id,
      total_price,
    },
  });
};

export const apiPayBulkInvoices = async (
  appointmentIds: number[],
  payment_method_id: string,
  total_price: number,
  user_id: string
): Promise<ResponseAppointment> => {
  return callApi({
    url: `/api/v2/bulk_payments`,
    method: 'POST',
    data: {
      user_id,
      appointment_ids: appointmentIds,
      payment_method_id,
      total_price,
    },
  });
};

export const apiGetCannedServices = async (
  user_id: string
): Promise<ResponseCannedServices> => {
  return callApi({
    url: `/api/v2/canned_services?user_id=${user_id}`,
    method: 'GET',
  });
};

export const apiGetConnectedCarData =
  async (): Promise<ResponseConnectedCars> => {
    return callApi({
      url: '/api/v2/car_payloads/show_grouped',
      method: 'GET',
    });
  };

export const apiGetConnectedCarDataByVin = async (
  vin: string
): Promise<ResponseConnectedCarsByVin> => {
  return callApi({
    url: `/api/v2/car_payloads/show_by_vin/${vin}`,
    method: 'GET',
  });
};
