import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import AOS from 'aos';

import { createFMCTheme, CustomTheme } from 'src/themes';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store';
import './assets/index.css';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'aos/dist/aos.css';
import ErrorBoundary from './ErrorBoundary';

const browserHistory = createBrowserHistory();

browserHistory.listen(() => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // In all other cases, scroll to top
    window.scrollTo(0, 0);
  });
});

const myTheme = createFMCTheme();
const App = () => {
  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <MuiThemeProvider<CustomTheme> theme={myTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={3}>
                <CssBaseline />
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
