import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  Chip,
  Card,
} from '@material-ui/core';
import { Close, ExpandMore, FiberManualRecord } from '@material-ui/icons';
import { updateEstimateServiceStatusV2 } from '../../../../api/quote';
import { setAppointment } from '../../../../store/actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ViewEstimateModal({
  open,
  onClose,
  estimate,
  services,
  onStatusChange,
  diagnosis,
  diagnosisFee,
}) {
  const dispatch = useDispatch();

  const handleStatus = (service) => {
    const data = {
      estimate_service: {
        status:
          service.status === 'rejected' ||
          service.status === 'pending' ||
          !service.status
            ? 'approved'
            : 'rejected',
      },
    };

    updateEstimateServiceStatusV2(service.id, data).then((res) => {
      onStatusChange();
      dispatch(setAppointment(res.data));
    });
  };

  function sortObjectChildrenById(obj) {
    // Convert the object into an array of {key, value} pairs
    const sortedArray = Object.entries(obj)
      .map(([key, value]) => ({ key, ...value }))
      .sort((a, b) => a.id - b.id);

    // Convert the sorted array back into an object
    const sortedObj = {};
    sortedArray.forEach((item) => {
      sortedObj[item.key] = item;
      delete sortedObj[item.key].key; // Remove the key property from each child
    });

    return sortedObj;
  }

  return (
    <Dialog styles={{ padding: '10px' }} open={open} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ fontSize: '24px', fontFamily: 'Inter', margin: 25 }}
        >
          Estimate
        </Typography>
        <Close onClick={onClose} style={{ margin: 25, cursor: 'pointer' }} />
      </div>

      {diagnosis && (
        <Card
          style={{
            background: '#fff',
            marginLeft: 20,
            marginRight: 20,
            borderRadius: 8,
            border: '1px solid #005959',
            marginBottom: 20,
            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
          }}
          elevation={1}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              style={{
                fontFamily: 'Inter',
              }}
            >
              Diagnosis
            </Typography>
          </AccordionSummary>

          <Box style={{ margin: '10px' }}>
            <Typography style={{ margin: '10px', fontFamily: 'Inter' }}>
              - Our technician will come to you and find out what&apos;s wrong.
            </Typography>
            <Typography style={{ margin: '10px', fontFamily: 'Inter' }}>
              - Complimentary multi-point inspection.
            </Typography>
            <Typography style={{ margin: '10px', fontFamily: 'Inter' }}>
              - A detailed estimate and repair plan will be provided.
            </Typography>
          </Box>
        </Card>
      )}
      <DialogContent>
        {estimate && services && (
          <>
            <Box>
              {Object.keys(sortObjectChildrenById(services)).map((service) => (
                <>
                  <div style={{ display: 'flex', gap: 20, marginTop: 30 }}>
                    <Typography style={{ fontFamily: 'Inter' }}>
                      Status
                    </Typography>
                    {services[service].status === 'rejected' && (
                      <Chip
                        icon={
                          <FiberManualRecord
                            style={{
                              width: '15%',
                              height: '50%',
                              color: '#E74D4D',
                            }}
                          />
                        }
                        style={{
                          background: '#FCE6E6',
                          color: '#E74D4D',
                          fontFamily: 'Inter',
                          fontSize: 12,
                        }}
                        label="Rejected"
                      />
                    )}
                    {services[service].status === 'pending' && (
                      <Chip
                        icon={
                          <FiberManualRecord
                            style={{
                              width: '15%',
                              height: '50%',
                              color: '#F5AE22',
                            }}
                          />
                        }
                        style={{
                          background: '#FFF6D7',
                          color: '#F5AE22',
                          fontFamily: 'Inter',
                          fontSize: 12,
                        }}
                        label="Pending"
                      />
                    )}
                    {(services[service].status === 'approved' ||
                      services[service].status === 'locked') && (
                      <Chip
                        icon={
                          <FiberManualRecord
                            style={{
                              width: '15%',
                              height: '50%',
                              color: '#037847',
                            }}
                          />
                        }
                        style={{
                          background: '#ECFDF3',
                          color: '#037847',
                          fontFamily: 'Inter',
                          fontSize: 12,
                        }}
                        label="Approved"
                      />
                    )}
                  </div>
                  <Accordion
                    style={{
                      border: '2px solid var(--Emerald-Green, #005959)',
                      borderRadius: 8,
                      marginTop: 16,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        direction="row"
                        spacing={1}
                      >
                        <Typography
                          style={{ fontFamily: 'Inter', marginRight: 200 }}
                        >
                          {service}
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container direction="column" spacing={1}>
                        {services[service].parts.length > 0 && (
                          <Typography
                            className="text-sm"
                            style={{
                              color: '#A5A7A7',
                              fontFamily: 'Inter',
                            }}
                          >
                            PARTS
                          </Typography>
                        )}
                        {services[service].parts.length > 0 ? (
                          services[service].parts.map((part) => {
                            return (
                              <Box style={{ margin: '10px 0' }}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  direction="row"
                                  spacing={1}
                                >
                                  <Grid item>
                                    <Typography style={{ fontFamily: 'Inter' }}>
                                      {part.name}
                                      {part.part_number &&
                                        ` (${part.part_number})`}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <span
                                      style={{
                                        fontFamily: 'Inter',
                                      }}
                                    >
                                      ${part.unit_price}
                                      {part.quantity && ` (x ${part.quantity})`}
                                    </span>
                                  </Grid>
                                </Grid>
                                <hr
                                  style={{ background: '#D4D5D5', height: 1 }}
                                />
                              </Box>
                            );
                          })
                        ) : (
                          <></>
                        )}
                        <Typography
                          className="text-sm"
                          style={{
                            color: '#A5A7A7',
                            fontFamily: 'Inter',
                          }}
                        >
                          LABOR
                        </Typography>
                        <Box style={{ margin: '10px 0' }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography style={{ fontFamily: 'Inter' }}>
                                Labor price
                              </Typography>
                            </Grid>
                            <Grid item>
                              <span
                                style={{
                                  fontFamily: 'Inter',
                                }}
                              >
                                ${services[service].labor_price}
                              </span>
                            </Grid>
                          </Grid>
                          <hr style={{ background: '#D4D5D5', height: 1 }} />
                        </Box>

                        <Box style={{ margin: '10px 0' }}>
                          {estimate.convenience_fee > 0 ? (
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              direction="row"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography style={{ fontFamily: 'Inter' }}>
                                  Convenience fee
                                </Typography>
                              </Grid>
                              <Grid item>
                                <span
                                  style={{
                                    fontFamily: 'Inter',
                                  }}
                                >
                                  ${estimate.convenience_fee}
                                </span>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}

                          {estimate.tax > 0 ? (
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              direction="row"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography style={{ fontFamily: 'Inter' }}>
                                  Taxes
                                </Typography>
                              </Grid>
                              <Grid item>
                                <span
                                  style={{
                                    fontFamily: 'Inter',
                                  }}
                                >
                                  ${estimate.tax}
                                </span>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}

                          {estimate.discount > 0 ? (
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              direction="row"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography>Discount</Typography>
                              </Grid>
                              <Grid item>
                                <span style={{ fontFamily: 'Inter' }}>
                                  - ${estimate.discount}
                                </span>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}

                          <Grid
                            item
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: 30,
                            }}
                          >
                            <span style={{ fontFamily: 'Inter' }}>
                              <Button
                                variant="outlined"
                                style={{
                                  color:
                                    services[service].status === 'rejected' ||
                                    services[service].status === 'pending' ||
                                    !services[service].status
                                      ? '#F3F3F3'
                                      : '#ff0000',
                                  fontFamily: 'Inter',
                                  textTransform: 'none',
                                  background:
                                    services[service].status === 'rejected' ||
                                    services[service].status === 'pending' ||
                                    !services[service].status
                                      ? '#005959'
                                      : '#F3F3F3',
                                  borderRadius: '32px',
                                  border: 'none',
                                }}
                                onClick={() => handleStatus(services[service])}
                              >
                                {services[service].status === 'rejected' ||
                                services[service].status === 'pending' ||
                                !services[service].status
                                  ? 'Approve Service'
                                  : 'Decline Service'}
                              </Button>
                            </span>
                          </Grid>
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </Box>
          </>
        )}
      </DialogContent>

      {estimate && (
        <Container style={{ marginTop: 16, marginBottom: 16 }}>
          <hr
            style={{
              background: '#D4D5D5',
              marginBottom: 15,
              height: 1,
              marginTop: 10,
            }}
          />
          <Box style={{ margin: '10px 0' }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              spacing={1}
            >
              <Grid item>
                <Typography style={{ fontFamily: 'Inter' }}>
                  Total price
                </Typography>
              </Grid>
              <Grid item>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Inter',
                    color: '#005959',
                    marginRight: 15,
                  }}
                >
                  ${parseFloat(estimate.non_member_price).toFixed(2)}
                </span>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Dialog>
  );
}
