import { callApi } from './request';

const postError = async (data: { error: string }): Promise<void> => {
  return callApi<void>({
    url: `/api/v2/errors`,
    method: 'POST',
    data,
  });
};

export default postError;
