import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    hidden: {
      display: 'none',
    },
    marginBottom: {
      marginBottom: theme.spacing(3),
    },
    logoContainer: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    gridContainer: {
      padding: '1rem 1rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    },
    contentSignup: {
      width: theme.breakpoints.values.xs,
      '& > div': {
        marginBottom: theme.spacing(7),
      },
      '& > div:last-child': {
        marginBottom: 0,
      },
    },
    features: {
      '& span': {
        fontSize: '1.2rem',
      },
      '& img': {
        height: '30px',
      },
      '& > div': {
        display: 'grid',
        gridTemplateColumns: '60px 1fr',
        marginBottom: theme.spacing(5),
      },
      '& > div:last-child': {
        marginBottom: 0,
      },
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    borderBox: {},
    contactFormCard: {
      '& .card-base__content': {
        padding: theme.spacing(3),
      },
      background: theme.palette.background.paper,
      paddingTop: '0',
      '& h5': {
        fontWeight: 'bold',
      },
    },
    contactForm: {
      marginTop: theme.spacing(2),
      padding: 0,
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(1),

      '& > *': {
        marginLeft: 0,
        [theme.breakpoints.down('xl')]: {
          // make inputs full width for all screens
          gridColumn: '1/-1',
        },
      },
    },

    fullWidth: {
      gridColumn: '1/-1',
    },
    btnContact: {
      fontSize: '1.4rem',
      whiteSpace: 'nowrap', // for safari
      display: 'flex',
      alignItems: 'center',
      '& .MuiButton-label': {
        // don't know why this isn't the default...
        alignItems: 'center',
      },
    },
    radioGroup: {
      margin: theme.spacing(2, 0),
      '& .MuiFormControlLabel-label': {
        fontSize: '1.2rem',
      },
    },
  };
});

export default useStyles;
