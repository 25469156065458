import money from "./money.svg";
import ring from "./ring.svg";
import time from "./time.svg";
import shop_rigid from "./shop-rigid.svg";
import shop_location from "./shop-location.svg";
import shop_money from "./shop-money.svg";
import money_black from "./money-black.svg";

export default {
  money,
  ring,
  time,
  shop_rigid,
  shop_location,
  shop_money,
  money_black,
};
