import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  makeStyles,
  useMediaQuery,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import './index.css';
import { IReduxState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { ExpandMore } from '@material-ui/icons';
import QuoteProvider from './components/steps/Context/QuoteConfirmedContext';
import CarDetailsStep from './components/steps/CarDetailsStep';
import ServicesStep from './components/steps/ServicesStep';
import AppointmentStep from './components/steps/AppointmentStep';
import ContactStep from './components/steps/ContactStep';
import PaymentStep from './components/steps/PaymentStep';
import EmptyQuote from './components/atoms/EmptyQuote';
import QuoteSummary from './components/atoms/QuoteSummary';
import ReferralCode from './components/atoms/ReferralCode';
import InfoCard from './components/atoms/InfoCard';
import PayInFour from './components/atoms/PayInFour';
import EstimateStep from './components/steps/EstimateStep';
import SuccessPage from './components/steps/SuccessPage';

interface StepItem {
  id: number;
  label: string;
  image: JSX.Element;
}

interface StepperComponentProps {
  steps: StepItem[];
  car?: {
    year: string;
    make: string;
    model: string;
    engine: string;
    vin: string;
  };
}

const useStyles = makeStyles({
  stepConnector: {
    width: '100%',
    '& span': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active ': {
      color: '#005959',
    },
    '& .css-1hv8oq8-MuiStepLabel-label.Mui-active': {
      color: '#005959',
    },
    '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
      color: '#005959',
    },
    '& .css-1hv8oq8-MuiStepLabel-label.Mui-completed': {
      color: '#005959',
    },
    '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
      color: '#005959',
    },
  },
  boxStyle: {
    width: '100%',
    marginTop: 10,
  },
  customFontHead: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',

    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '30px',
    marginTop: 40,
  },
  customFontSub: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '30px',
    marginTop: 10,
  },
  customStepperLabel: {
    color: ' #005959',
  },
});

const StepperComponent: React.FC<StepperComponentProps> = ({
  steps,
  car = { year: '', make: '', model: '', engine: '', vin: '' },
}) => {
  const classes = useStyles();

  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const [priceSelected, setPriceSelected] = useState('non-member');
  const [billingType, setBillingType] = useState('yearly');
  const [memberSelected, setMemberSelected] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepUpdated, setStepUpdated] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [isAccordionExpandedCar, setIsAccordionExpandedCar] = useState(false);
  const [isAccordionExpandedService, setIsAccordionExpandedService] =
    useState(false);
  const [isAccordionExpandedContact, setIsAccordionExpandedContact] =
    useState(false);
  const [isAccordionExpandedAppointment, setIsAccordionExpandedAppointment] =
    useState(false);
  const [isAccordionExpandedPayment, setIsAccordionExpandedPayment] =
    useState(false);
  const handleStepClick = (stepIndex: number) => {
    if (completedSteps[stepIndex]) {
      setActiveStep(stepIndex - 1);
    }
  };

  const isSm = useMediaQuery('(max-width: 1300px)');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepCompletion = () => {
    const updatedCompletedSteps = completedSteps.map((completed, index) =>
      index === activeStep ? true : completed
    );
    setCompletedSteps(updatedCompletedSteps);
  };

  type AccordionKey = 'car' | 'service' | 'contact' | 'appointment' | 'payment';

  const accordionSetters: Record<
    AccordionKey,
    React.Dispatch<React.SetStateAction<boolean>>
  > = {
    car: setIsAccordionExpandedCar,
    service: setIsAccordionExpandedService,
    contact: setIsAccordionExpandedContact,
    appointment: setIsAccordionExpandedAppointment,
    payment: setIsAccordionExpandedPayment,
  };

  const setAccordionState = (expanded: AccordionKey) => {
    Object.keys(accordionSetters).forEach((key) => {
      accordionSetters[key as AccordionKey](key === expanded);
    });
  };
  // Service step accordion handling
  const handleAccordionExpandServiceOnServiceStep = () => {
    setAccordionState('service');
  };

  const handleAccordionExpandCarOnServiceStep = () => {
    setAccordionState(isAccordionExpandedCar ? 'service' : 'car');
  };

  // Contact step accordion handling
  const handleAccordionExpandCarOnContactStep = () => {
    setAccordionState(isAccordionExpandedCar ? 'contact' : 'car');
  };

  const handleAccordionExpandServicesOnContactStep = () => {
    setAccordionState(isAccordionExpandedService ? 'contact' : 'service');
  };

  const handleAccordionExpandContactOnContactStep = () => {
    setAccordionState('contact');
  };

  useEffect(() => {
    setIsAccordionExpandedCar(false);
    setIsAccordionExpandedService(false);
    setIsAccordionExpandedContact(false);
    setIsAccordionExpandedAppointment(false);
    setIsAccordionExpandedPayment(false);

    switch (activeStep) {
      case 1:
        setIsAccordionExpandedService(true);
        break;
      case 2:
        setIsAccordionExpandedContact(true);
        break;
      case 3:
        setIsAccordionExpandedAppointment(true);
        break;
      case 4:
        setIsAccordionExpandedPayment(true);
        break;
      default:
        break;
    }
  }, [activeStep, stepUpdated]);

  const getStepComponent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <QuoteProvider>
            <Typography
              className={classes.customFontHead}
              style={{ fontSize: isSm ? 17 : 20 }}
              variant="body2"
            >
              Unlock your free quote by answering a few simple questions
            </Typography>
            {!isSm && (
              <Typography className={classes.customFontSub}>
                Get a Fixed Quote • Technician Comes To You • Pay at Time of
                Service
              </Typography>
            )}
            <PayInFour />

            <div
              style={{
                display: !isSm ? 'flex' : 'unset',
                flexDirection: 'row',
              }}
            >
              <div style={{ marginLeft: !isSm ? 'auto' : 'none' }}>
                <Accordion
                  expanded
                  style={{
                    width: isSm ? '95%' : 540,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: isSm ? 20 : 50,
                    background: '#fff',
                    borderRadius: 8,
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={<ExpandMore />}
                  >
                    <Typography
                      style={{ fontFamily: 'Inter', fontWeight: 700 }}
                    >
                      Select Vehicle
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: isSm ? 'unset' : 'flex' }}
                  >
                    <CarDetailsStep
                      onNext={handleNext}
                      onComplete={handleStepCompletion}
                      activeStep={activeStep}
                      setStepUpdated={setStepUpdated}
                      car={car}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
              <div
                style={{
                  marginRight: !isSm ? 'auto' : 'none',
                  marginTop: 50,
                  marginLeft: 10,
                  width: 'min-content',
                }}
              >
                {!isSm && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {' '}
                    <EmptyQuote />
                  </div>
                )}
              </div>
            </div>
          </QuoteProvider>
        );

      case 1:
        return (
          <QuoteProvider>
            <Typography
              className={classes.customFontHead}
              style={{ fontSize: isSm ? 17 : 20 }}
              variant="body2"
            >
              Unlock your free quote by answering a few simple questions
            </Typography>
            {!isSm && (
              <Typography className={classes.customFontSub}>
                Get a Fixed Quote • Technician Comes To You • Pay at Time of
                Service
              </Typography>
            )}
            <PayInFour />
            <div
              style={{
                display: !isSm ? 'flex' : 'unset',
                flexDirection: 'row',
              }}
            >
              <div style={{ marginLeft: !isSm ? 'auto' : 'none' }}>
                <Accordion
                  expanded={isAccordionExpandedCar}
                  onChange={handleAccordionExpandCarOnServiceStep}
                  style={{
                    width: isSm ? '95%' : 540,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: isSm ? 20 : 50,
                    background: '#fff',
                    borderRadius: 8,

                    transition: 'box-shadow 0.3s ease',
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{ fontFamily: 'Inter', fontWeight: 650 }}
                    >
                      {' '}
                      Select Vehicle
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: isSm ? 'unset' : 'flex' }}
                  >
                    <CarDetailsStep
                      onNext={handleNext}
                      onComplete={handleStepCompletion}
                      activeStep={activeStep}
                      setStepUpdated={setStepUpdated}
                      car={car}
                    />
                  </AccordionDetails>
                </Accordion>

                <div>
                  <Accordion
                    expanded={isAccordionExpandedService}
                    onChange={handleAccordionExpandServiceOnServiceStep}
                    style={{
                      width: isSm ? '95%' : 540,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: isSm ? 20 : 20,
                      background: '#fff',
                      height: 'fit-content',
                      borderRadius: 8,

                      position: 'inherit',

                      transition: 'box-shadow 0.3s ease',
                    }}
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        style={{ fontFamily: 'Inter', fontWeight: 700 }}
                      >
                        Select Services
                      </Typography>
                    </AccordionSummary>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        marginLeft: 18,
                        marginRight: 18,
                        fontSize: isSm ? 16 : 18,
                      }}
                    >
                      Not sure what&apos;s wrong? We&apos;ll send a diagnostic
                      technician to you to figure out the issue.
                    </Typography>
                    <AccordionDetails>
                      <ServicesStep
                        onNext={handleNext}
                        onComplete={handleStepCompletion}
                        activeStep={activeStep}
                        setStepUpdated={setStepUpdated}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <div
                style={{
                  marginRight: !isSm ? 'auto' : 'none',
                  marginLeft: 10,
                  marginTop: 50,
                }}
              >
                {!isSm && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 10,
                      maxWidth: 'min-content',
                    }}
                  >
                    {' '}
                    <EmptyQuote /> <InfoCard />{' '}
                  </div>
                )}
              </div>
            </div>
          </QuoteProvider>
        );

      case 2:
        return (
          <QuoteProvider>
            <Typography
              className={classes.customFontHead}
              style={{ fontSize: isSm ? 17 : 20 }}
              variant="body2"
            >
              Almost Done! Just a few more details to book your appointment
            </Typography>
            {!isSm && (
              <Typography className={classes.customFontSub}>
                Get a Fixed Quote • Technician Comes To You • Pay at Time of
                Service
              </Typography>
            )}
            <PayInFour />
            <div
              style={{
                display: !isSm ? 'flex' : 'unset',
                flexDirection: 'row',
              }}
            >
              <div style={{ marginLeft: !isSm ? 'auto' : 'none' }}>
                <Accordion
                  expanded={isAccordionExpandedCar}
                  onChange={handleAccordionExpandCarOnContactStep}
                  style={{
                    width: isSm ? '95%' : 540,
                    marginLeft: 'auto',
                    marginTop: isSm ? 20 : 50,
                    marginRight: 'auto',
                    background: '#fff',
                    borderRadius: 8,

                    position: 'inherit',

                    transition: 'box-shadow 0.3s ease',
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={<ExpandMore />}
                  >
                    <Typography
                      style={{ fontFamily: 'Inter', fontWeight: 700 }}
                    >
                      Select Vehicle
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: isSm ? 'unset' : 'flex' }}
                  >
                    <CarDetailsStep
                      onNext={handleNext}
                      onComplete={handleStepCompletion}
                      activeStep={activeStep}
                      setStepUpdated={setStepUpdated}
                      car={car}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={isAccordionExpandedService}
                  onChange={handleAccordionExpandServicesOnContactStep}
                  style={{
                    width: isSm ? '95%' : 540,
                    marginLeft: 'auto',
                    marginTop: 10,
                    marginRight: 'auto',
                    background: '#fff',
                    borderRadius: 8,

                    position: 'inherit',

                    transition: 'box-shadow 0.3s ease',
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={<ExpandMore />}
                  >
                    <Typography
                      style={{ fontFamily: 'Inter', fontWeight: 700 }}
                    >
                      Select Services
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ServicesStep
                      onNext={handleNext}
                      onComplete={handleStepCompletion}
                      activeStep={activeStep}
                      setStepUpdated={setStepUpdated}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={isAccordionExpandedContact}
                  onChange={handleAccordionExpandContactOnContactStep}
                  style={{
                    width: isSm ? '95%' : 540,
                    marginLeft: 'auto',
                    marginTop: 10,
                    marginRight: 'auto',
                    background: '#fff',
                    borderRadius: 8,

                    position: 'inherit',

                    transition: 'box-shadow 0.3s ease',
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={<ExpandMore />}
                  >
                    <Typography
                      style={{ fontFamily: 'Inter', fontWeight: 700 }}
                    >
                      Contact Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ContactStep
                      onNext={handleNext}
                      onComplete={handleStepCompletion}
                      activeStep={activeStep}
                      setStepUpdated={setStepUpdated}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>

              <div
                style={{
                  marginRight: !isSm ? 'auto' : 'none',
                  marginTop: 50,
                  marginLeft: 10,
                }}
              >
                {!isSm && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: 'min-content',
                    }}
                  >
                    {' '}
                    <EmptyQuote /> <InfoCard />{' '}
                  </div>
                )}
              </div>
            </div>
          </QuoteProvider>
        );

      case 3:
        return (
          <QuoteProvider>
            {((appointment?.attributes.appointment_type === 'repair' &&
              appointment?.attributes.estimate) ||
              appointment?.attributes.appointment_type === 'diagnosis') &&
              (!isSm ? (
                <>
                  <Typography
                    variant="body2"
                    className={classes.customFontHead}
                    style={{ fontSize: 20 }}
                  >
                    Your Fixed Quote
                  </Typography>

                  <Typography className={classes.customFontSub}>
                    Get a Fixed Quote • Technician Comes To You • Pay After
                    Service
                  </Typography>
                  <PayInFour />
                </>
              ) : (
                <>
                  <Typography
                    className={classes.customFontHead}
                    style={{ fontSize: 17 }}
                  >
                    Your Fixed Quote
                  </Typography>
                  <PayInFour />
                </>
              ))}

            <div
              style={{
                display: !isSm ? 'flex' : 'unset',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  marginLeft: !isSm ? 'auto' : 'none',
                  marginTop: !isSm ? 30 : 0,
                }}
              >
                <EstimateStep
                  onNext={handleNext}
                  onBack={handleBack}
                  bookingLink={false}
                  priceSelected={priceSelected}
                  setPriceSelected={setPriceSelected}
                  billingType={billingType}
                  setBillingType={setBillingType}
                  memberSelected={memberSelected}
                  setMemberSelected={setMemberSelected}
                />
              </div>
              <div
                style={{
                  marginRight: !isSm ? 'auto' : 'none',
                  marginTop: 50,
                  marginLeft: 10,
                }}
              >
                {!isSm &&
                  (appointment?.attributes.estimate ||
                    appointment?.attributes.appointment_type ===
                      'diagnosis') && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 'min-content',
                      }}
                    >
                      <QuoteSummary priceSelected={priceSelected} />{' '}
                      <InfoCard />
                      {!appointment?.attributes.shop_network && (
                        <ReferralCode />
                      )}
                    </div>
                  )}
              </div>
            </div>
          </QuoteProvider>
        );
      case 4:
        return (
          <QuoteProvider>
            {!isSm && (
              <>
                <Typography
                  variant="body2"
                  className={classes.customFontHead}
                  style={{ fontSize: 20 }}
                >
                  Choose a time that suits you best! We will be there
                </Typography>

                <Typography className={classes.customFontSub}>
                  Get a Fixed Quote • Technician Comes To You • Pay at Time of
                  Service
                </Typography>
              </>
            )}
            {isSm && (
              <Typography
                className={classes.customFontHead}
                style={{ fontSize: 17 }}
              >
                Choose a time that suits you best! We will be there
              </Typography>
            )}
            <PayInFour />
            <div
              style={{
                display: !isSm ? 'flex' : 'unset',
                flexDirection: 'row',
              }}
            >
              <div style={{ marginLeft: !isSm ? 'auto' : 'none' }}>
                <AppointmentStep
                  onNext={handleNext}
                  onBack={handleBack}
                  onComplete={handleStepCompletion}
                  priceSelected={priceSelected}
                />
              </div>
              <div
                style={{
                  marginRight: !isSm ? 'auto' : 'none',
                  marginTop: 50,
                  marginLeft: 10,
                }}
              >
                {!isSm && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: 'min-content',
                    }}
                  >
                    <QuoteSummary priceSelected={priceSelected} /> <InfoCard />
                    {!appointment?.attributes.shop_network && <ReferralCode />}
                  </div>
                )}
              </div>
            </div>
          </QuoteProvider>
        );
      case 5:
        return (
          <QuoteProvider>
            <Typography
              className={classes.customFontHead}
              style={{ fontSize: isSm ? 17 : 20 }}
              variant="body2"
            >
              Securely Enter Payment Info
            </Typography>
            {!isSm && (
              <Typography className={classes.customFontSub}>
                Get a Fixed Quote • Technician Comes To You • Pay at Time of
                Service
              </Typography>
            )}
            <PayInFour />
            <div
              style={{
                display: !isSm ? 'flex' : 'unset',
                flexDirection: 'row',
              }}
            >
              <div style={{ marginLeft: !isSm ? 'auto' : 'none' }}>
                <PaymentStep
                  onNext={handleNext}
                  onBack={handleBack}
                  onComplete={handleStepCompletion}
                  priceSelected={priceSelected}
                  billingType={billingType}
                  memberSelected={memberSelected}
                  hideBack={false}
                />
              </div>
              <div
                style={{
                  marginRight: !isSm ? 'auto' : 'none',
                  marginTop: 50,
                  marginLeft: 10,
                }}
              >
                {!isSm && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: 'min-content',
                    }}
                  >
                    <QuoteSummary priceSelected={priceSelected} /> <InfoCard />{' '}
                    {!appointment?.attributes.shop_network && <ReferralCode />}
                  </div>
                )}
              </div>
            </div>
          </QuoteProvider>
        );
      case 6:
        return (
          <QuoteProvider>
            <SuccessPage />
          </QuoteProvider>
        );

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        marginTop: 10,
        maxWidth: '65%',
        minWidth: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media (max-width: 600px)': {
          minWidth: '100%',
        },
      }}
    >
      {!isSm &&
        activeStep <= 5 &&
        (activeStep === 3 &&
        ((appointment?.attributes.appointment_type === 'repair' &&
          appointment?.attributes.estimate) ||
          appointment?.attributes.appointment_type === 'diagnosis') ? (
          <Stepper
            alternativeLabel
            className={classes.stepConnector}
            activeStep={activeStep}
          >
            {steps.map((step, index) => (
              <Step key={step.label} onClick={() => handleStepClick(step.id)}>
                <StepLabel
                  className={classes.stepConnector}
                  style={{
                    cursor: completedSteps[index] ? 'pointer' : 'default',
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : (
          <></>
        ))}

      {getStepComponent(activeStep)}
    </Box>
  );
};

StepperComponent.defaultProps = {
  car: { year: '', make: '', model: '', engine: '', vin: '' },
};

export default StepperComponent;
