import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  CircularProgress,
  Hidden,
  Link,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import SvgLogo from 'src/assets/logo.svg';
import phoneIcon from 'src/assets/phone-icon.svg';
import usericon from 'src/assets/user-icon.svg';
import emailIcon from 'src/assets/email-icon.svg';
import LoginSideImg from 'src/assets/LoginPageImg.png';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { ButtonForward, InputWithStatus, Image } from 'src/components/atoms';
import { getToken, signIn } from 'src/api/auth';
import { ResponseAuth, ResponseSignin } from 'src/types';
import { setAuthCode, setAuthToken } from 'src/store/actions';
import { MODE_LOGIN, URLS } from 'src/utils/consts';
import { dataLayerLogin } from 'src/utils/ads';
import { CONTACT_NUMBER } from 'src/utils/config';
import GoogleLoginButton from './components/GoogleLoginButton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: '#fff',
    overflowY: 'hidden',
    fontFamily: 'Inter',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
  },
  showcaseImg: {
    width: '100%',
    height: '100%',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '30px',
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },

  error: {
    color: 'red',
  },
  success: {
    color: theme.palette.primary.main,
  },
  input: {
    width: '100%',
    zIndex: 100,
    border: 'none',
    marginLeft: '-2px',
    marginBottom: '3px',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '12px',
    zIndex: 100,
    border: '1px',
    borderRadius: '8px',
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
  },
  appBar: {
    background: '#fff',
    color: 'black',
    boxShadow: 'none',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoImage: {
    display: 'flex',
    width: '177.6px',
    height: '32px',
    padding: '0.856px 0.704px 0.793px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,

    // Media Query for mobile screens
    '@media (max-width: 600px)': {
      width: '134px',
      height: '24px',
      padding: '0.642px 0.531px 0.595px 0px',
    },
  },
  imageCenterAlign: {
    flexGrow: 1,
    textAlign: 'center',
  },
  phone: {
    marginRight: '20px',
    color: '#000',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  gridButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  iconImg: {
    width: '20px',
    marginTop: '4px',
    marginRight: '10px',
  },
  customIconBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '8px',
    marginTop: '20px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
  },

  createAccountBlock: {
    borderTop: '1px solid #E5E5E5',
    marginTop: '40px',
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Login = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState('');
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  const hideError = () => {
    setTimeout(() => {
      setLoginError('');
    }, 3000);
  };

  const [data, setData] = useState({
    mode: MODE_LOGIN.PHONE,
    email: '',
    password: '',
    phone: '',
    code: '',
    codeCheck: '',
    codeSent: false,
    extraLoginPhone: '',
  });

  const handleInputChange = (key: string, val: string) => {
    if (data.codeSent)
      setData((state) => ({
        ...state,
        code: val,
      }));
    else
      setData((state) => ({
        ...state,
        [key]: val,
      }));
  };

  const handleForward = async () => {
    setLoggingIn(true);

    if (
      (data.mode === MODE_LOGIN.PHONE || data.mode === MODE_LOGIN.EMAIL) &&
      !data.codeSent
    ) {
      const userParams =
        data.mode === MODE_LOGIN.PHONE
          ? {
              user: {
                phone: data.phone,
              },
            }
          : {
              user: {
                email: data.email,
              },
            };
      const response: ResponseAuth = await getToken(userParams).finally(() =>
        setLoggingIn(false)
      );

      if (response && response.status === 'Code sent') {
        dispatch(setAuthCode(response.code));

        setData((state) => ({
          ...state,
          codeSent: true,
          codeCheck: response.code,
          code: '',
          extraLoginPhone: response.extra_login_phone || '',
        }));
      } else {
        setLoginError(
          'Error sending your code, please try with a different phone number'
        );
        hideError();
      }
    } else {
      const signInParams: {
        user: { code: string };
        extra_login?: { phone: string };
      } = {
        user: {
          code: data.code,
        },
      };

      if (data.extraLoginPhone) {
        signInParams.extra_login = {
          phone: data.extraLoginPhone,
        };
      }

      const response: ResponseSignin = await signIn(signInParams).finally(() =>
        setLoggingIn(false)
      );

      if (
        response &&
        response.auth_token &&
        response.user &&
        response.user.id
      ) {
        dispatch(
          setAuthToken(
            response.auth_token,
            response.user.id,
            response.user.email
          )
        );
        dataLayerLogin(response);

        history.push(URLS.DASHBOARD);
      } else {
        setLoginError('Invalid code');
        hideError();
      }
    }
  };

  const handleCall = () => {
    window.location.href = `tel:${CONTACT_NUMBER?.replace('-', '')}`;
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleForward();
    }
  };

  return (
    <Box className={classes.root} onKeyDown={handleKeyPress}>
      <Grid container spacing={0} className={classes.mainContainer}>
        <Grid item md={6} spacing={0}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Image
              className={classes.showcaseImg}
              src={LoginSideImg}
              alt="showcase"
              lazy={false}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} spacing={0}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar>
              <a href="/" title="thefront">
                <Image
                  className={classes.logoImage}
                  src={SvgLogo}
                  alt="thefront"
                  lazy={false}
                />
              </a>

              <Hidden smDown>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    className={classes.phone}
                    startIcon={<CallOutlinedIcon />}
                    onClick={handleCall}
                    color="inherit"
                  >
                    {CONTACT_NUMBER}
                  </Button>
                </div>
              </Hidden>
            </Toolbar>
          </AppBar>

          <div className={classes.fieldContainer}>
            <Box component="form" sx={{ mt: 3, width: '100%' }}>
              <Typography
                variant="h4"
                style={{ fontSize: '25px', color: '#1F2322' }}
              >
                {!data.codeSent
                  ? 'Login to your account'
                  : 'Verify your account'}
              </Typography>
              {data.mode === MODE_LOGIN.EMAIL ? (
                <>
                  <Typography
                    align="left"
                    style={{
                      fontSize: '13px',
                      marginTop: '10px',
                      marginBottom: '15px',
                      color: '#BEBBB8',
                    }}
                  >
                    {!data.codeSent
                      ? 'Enter your email to proceed'
                      : 'Please enter your OTP code'}
                  </Typography>
                  <InputWithStatus
                    className={classes.input}
                    placeholder={!data.codeSent ? 'Email' : 'Verification Code'}
                    value={data.codeSent ? data.code : data.email}
                    valueChanged={(val: string) =>
                      handleInputChange(data.codeSent ? 'code' : 'email', val)
                    }
                    inputMode={data.codeSent ? 'numeric' : 'email'}
                  />
                  <ButtonForward
                    className={classes.buttons}
                    key="forward"
                    title={data.codeSent ? 'Login' : 'Send code'}
                    size="large"
                    onClickHandler={handleForward}
                    disabled={
                      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data?.email) ||
                      loggingIn
                    }
                  />
                </>
              ) : (
                <>
                  <Typography
                    align="left"
                    style={{
                      fontSize: '13px',
                      marginTop: '10px',
                      marginBottom: '15px',
                      color: '#BEBBB8',
                    }}
                  >
                    {!data.codeSent
                      ? 'Enter your phone number to proceed'
                      : 'Please enter your OTP code'}
                  </Typography>
                  <InputWithStatus
                    className={classes.input}
                    placeholder={
                      !data.codeSent ? 'Phone number' : 'Verification Code'
                    }
                    value={data.codeSent ? data.code : data.phone}
                    valueChanged={(val: string) =>
                      handleInputChange(data.codeSent ? 'code' : 'phone', val)
                    }
                    inputMode={data.codeSent ? 'numeric' : 'tel'}
                  />
                  <ButtonForward
                    className={classes.buttons}
                    key="forward"
                    title={data.codeSent ? 'Login' : 'Send code'}
                    size="large"
                    onClickHandler={handleForward}
                    disabled={
                      (data.codeSent && !data.code) ||
                      (!data.codeSent && !data.phone) ||
                      loggingIn
                    }
                  />
                </>
              )}

              <Box className="" style={{ margin: '10px 0' }}>
                {loggingIn ? (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Box alignItems="center" justifyContent="center">
                      <Box>
                        <GoogleLoginButton setLoggingIn={setLoggingIn} />
                      </Box>
                      <Box>
                        <Button
                          variant="outlined"
                          color="default"
                          className={classes.customIconBtn}
                          onClick={() => {
                            setData((state) => ({
                              ...state,
                              mode:
                                data.mode === MODE_LOGIN.EMAIL
                                  ? MODE_LOGIN.PHONE
                                  : MODE_LOGIN.EMAIL,
                            }));
                          }}
                        >
                          <Image
                            className={classes.iconImg}
                            src={
                              data.mode === MODE_LOGIN.EMAIL
                                ? phoneIcon
                                : emailIcon
                            }
                            alt="icon"
                            lazy={false}
                          />
                          {data.mode === MODE_LOGIN.EMAIL
                            ? ' Login with phone'
                            : ' Login with email'}
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>

              {loginError !== '' ? (
                <Typography
                  style={{
                    textAlign: 'center',
                    margin: '10px',
                    color: '#ff0000',
                  }}
                >
                  {loginError}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            <Typography
              align="center"
              style={{ fontSize: '13px', marginTop: '8px', color: '#1F2322' }}
            >
              Login issues? Call or text{' '}
              <Link href="tel:+14693491635">469-349-1635</Link>, ask for James.
            </Typography>

            <Box className={classes.createAccountBlock}>
              <Typography
                variant="body2"
                style={{
                  textAlign: 'center',
                  marginBottom: '10px',
                  marginTop: '10px',
                  color: '#1F2322',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                New Fleet?
              </Typography>
              <Button
                variant="outlined"
                color="default"
                className={classes.customIconBtn}
                style={{ borderColor: '#005959' }}
                onClick={() => history.push(URLS.SIGN_UP_FLEET)}
              >
                <Image
                  className={classes.iconImg}
                  src={usericon}
                  alt="icon"
                  lazy={false}
                />
                Create New Account
              </Button>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
