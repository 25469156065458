import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  layout: {
    marginBottom: '280px',
  },
  reservationButton: {
    borderRadius: 31,
    textTransform: 'none',
    margin: '20px auto',
    boxShadow: 'none',
    width: '100%',
    padding: theme.spacing(2),
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  footer: {
    maxWidth: '350px',
    height: '220px',
    background: '#FFFFFF',
    borderRadius: '24px',
    marginTop: '70px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.16)',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      gridTemplateColumns: 'auto',
      marginTop: '-50px',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 3,
      position: 'fixed',
      maxWidth: '100%',
    },
    '&> :first-child': {
      padding: '30px',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      '& > .row': {
        display: 'flex',
        flexDirection: 'row',
        color: '#2E2E2E',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '15px',
        '& strong': {
          color: '#24CF93',
        },
      },
    },
  },
  root: {
    overflowX: 'hidden',
  },
  contentContainer: {},
  content: {
    marginBottom: '100px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '40px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(4),
    '& .title-icon': {
      color: '#C5C9DE',
    },
  },
  titleText: {
    color: '#2A2D3C',
    fontSize: 23,
    lineHeight: '24px',
    fontWeight: 900,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonGroupBack: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',

    [theme.breakpoints.down('xs')]: {
      '& .title-button': {
        display: 'none',
      },
    },
  },
  actionContainer: {
    marginBottom: '30px',
    marginTop: '-10px',
    width: '100%',
    minHeight: 70,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  checked: {
    height: '100%',
    color: '#36D9A0',
    fontSize: 25,
    width: 30,
    maxHeight: 55,
  },
  iconFlex: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  titleDatetime: {
    fontWeight: 900,
    fontSize: 19,
    lineHeight: '22.8px',
    marginLeft: theme.spacing(1),
  },
  boxDateTime: {
    width: '100%',
    background: '#EBF1FA',
    borderRadius: 6,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      borderRadius: 4,
    },
  },

  boxLocation: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    '& > div': {
      flexGrow: 1,
    },
  },

  containerDatepicker: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    '& .MuiPickersBasePicker-container': {
      alignItems: 'center',
      justifyContent: 'center',
    },
    // fixes overflow issues with iphone SE viewport
    '& .MuiPickersBasePicker-pickerView': {
      minWidth: '278px',
    },
  },
  callContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
  },
  callText: {
    fontSize: 14,
    fontFamily: 'Tiempos',
    paddingLeft: 2,
    paddingRight: 2,
  },
  containerTimeSlots: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 225,
    overflowY: 'scroll',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  itemTimeSlot: {
    cursor: 'pointer',
    marginBottom: theme.spacing(0.5),
    borderRadius: 6,
    padding: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 500,
    color: '#7E7A92',
    border: '1px solid #FFFFFF',

    background: theme.palette.common.white,

    '&.selected': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },

    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5),
      width: '45%',
      flexGrow: 1,
    },
  },

  btnCall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    width: '206px',
    height: '42px',
    padding: '10px 20px',
    borderRadius: '30px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
    boxShadow: 'none',
    '& > .MuiButton-label': {
      alignItems: 'center',
    },
    '&:hover': {
      backgroundColor: '#2da379',
      boxShadow: 'none',
    },
  },
  buttonBack: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
