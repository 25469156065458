/* eslint-disable react/require-default-props */
import React, { ReactElement, useEffect, useState } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  Button,
  makeStyles,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import VEHICLE_YEAR_OPTIONS from 'src/utils/cars';
import { createCar, updateCarWithProps } from 'src/api/quote';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import { IReduxState } from 'src/store/reducers';
import { IVehicle, ResponseGetUser } from 'src/types';
import { setUser } from 'src/store/actions';
import apiGetVehicles from 'src/api/vehicles';
import ModalBase from './ModalBase';

interface CarProps {
  type: string;
  carId: number | undefined;
  open: boolean;
  carDetails: Car | undefined;
  onClose: () => void;
  setNewCarAdded?: () => void;
}

interface Car {
  year: number | undefined;
  make: string;
  model: string;
  engine_size: string;
  vin: string;
  name: string;
}

const DEFAULT_CAR = {
  year: undefined,
  make: '',
  model: '',
  engine_size: '',
  vin: '',
  name: '',
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '500px',
    padding: 50,
    paddingTop: 40,
  },
}));

function VehicleForm(props: CarProps): ReactElement {
  const { open, onClose, type, carId, carDetails, setNewCarAdded } = props;

  const classes = useStyles();

  const user = useSelector((state: IReduxState) => state.auth.user);

  const dispatch = useDispatch();

  const [success, setSuccess] = useState<boolean>(false);
  const [makes, setMakes] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [engines, setEngines] = useState<string[]>([]);
  const [car, setCar] = useState<Car>(DEFAULT_CAR);

  const updateYear = (year: number) => {
    setCar({ ...car, year });
    setEngines([]);
    setModels([]);
    setMakes([]);

    apiGetVehicles({ year }).then((res) => {
      const makesRes = res.data.map((i: IVehicle) => i.attributes.make);
      setMakes(Array.from(new Set(makesRes)));
    });
  };

  const updateMake = (make: string) => {
    setCar({ ...car, make });
    setEngines([]);
    setModels([]);

    apiGetVehicles({ year: car.year, make }).then((res) => {
      const modelsRes = res.data.map((i: IVehicle) => i.attributes.model);
      setModels(Array.from(new Set(modelsRes)));
    });
  };

  const updateModel = (model: string) => {
    setCar({ ...car, model });
    setEngines([]);

    apiGetVehicles({
      year: car.year,
      make: car.make,
      model,
    }).then((res) => {
      const enginesRes = res.data.map(
        (i: IVehicle) => i.attributes.engine_size
      );
      setEngines(Array.from(new Set(enginesRes)));
      setCar({ ...car, model, engine_size: enginesRes[0] });
    });
  };

  useEffect(() => {
    if (type === 'update' && carDetails) {
      setCar(carDetails);

      apiGetVehicles({ year: carDetails.year }).then((res) => {
        const makesRes = res.data.map((i: IVehicle) => i.attributes.make);
        setMakes(Array.from(new Set(makesRes)));

        if (carDetails.make) {
          apiGetVehicles({ year: carDetails.year, make: carDetails.make }).then(
            (resultant) => {
              const modelsRes = resultant.data.map(
                (i: IVehicle) => i.attributes.model
              );
              setModels(Array.from(new Set(modelsRes)));

              if (carDetails.model) {
                apiGetVehicles({
                  year: carDetails.year,
                  make: carDetails.make,
                  model: carDetails.model,
                }).then((result) => {
                  const enginesRes = result.data.map(
                    (i: IVehicle) => i.attributes.engine_size
                  );
                  setEngines(Array.from(new Set(enginesRes)));
                });
              }
            }
          );
        }
      });
    }
  }, [type, carDetails]);

  const handleCreateCar = () => {
    if (user && car.year) {
      createCar({
        user_id: user?.id,
        year: car.year,
        make: car.make,
        model: car.model,
        engine_size: car.engine_size,
        vin: car.vin,
        name: car.name,
      }).then((res: ResponseGetUser) => {
        dispatch(setUser(res.data));
        setCar(DEFAULT_CAR);
        setMakes([]);
        setModels([]);
        setEngines([]);

        setSuccess(true);
        if (setNewCarAdded) {
          setNewCarAdded();
        }
        setTimeout(() => {
          setSuccess(false);
          onClose();
        }, 4000);
      });
    }
  };

  const handleUpdateCar = () => {
    if (user && car.year) {
      updateCarWithProps({
        user_id: user?.id,
        id: carId,
        year: car.year,
        make: car.make,
        model: car.model,
        engine_size: car.engine_size,
        vin: car.vin,
        name: car.name,
      }).then((res: ResponseGetUser) => {
        dispatch(setUser(res.data));
        setCar(DEFAULT_CAR);
        setMakes([]);
        setModels([]);
        setEngines([]);

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          onClose();
        }, 4000);
      });
    }
  };

  return (
    <ModalBase open={open} onClose={onClose} className={classes.root}>
      <>
        {type === 'update' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography style={{ fontFamily: 'Inter', fontSize: 22 }}>
              Edit Vehicle Name
            </Typography>
            <Close
              onClick={onClose}
              style={{ display: 'flex', alignSelf: 'flex-end' }}
            />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography style={{ fontFamily: 'Inter', fontSize: 22 }}>
              Add New Vehicle
            </Typography>
            <Close
              onClick={onClose}
              style={{ display: 'flex', alignSelf: 'flex-end' }}
            />
          </div>
        )}
        {type === 'create' && !success && (
          <FormControl fullWidth style={{ margin: '10px 0' }}>
            <Typography
              style={{ color: '#000', fontFamily: 'Inter', fontSize: 16 }}
            >
              Year
            </Typography>
            <Select
              id="year-select"
              value={car.year}
              fullWidth
              variant="outlined"
              style={{ background: '#fff', color: '#000' }}
              onChange={(event) => updateYear(Number(event.target.value))}
            >
              {VEHICLE_YEAR_OPTIONS.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {type === 'create' && (makes.length > 0 || car.make !== '') && (
          <FormControl fullWidth style={{ margin: '10px 0' }}>
            <Typography
              style={{ color: '#000', fontFamily: 'Inter', fontSize: 16 }}
            >
              Make
            </Typography>
            <Select
              id="make-select-label"
              value={car.make}
              fullWidth
              variant="outlined"
              style={{ background: '#fff', color: '#000' }}
              onChange={(event) => updateMake(event.target.value as string)}
            >
              {makes.map((item: string) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {type === 'create' && (models.length > 0 || car.model !== '') && (
          <FormControl fullWidth style={{ margin: '10px 0' }}>
            <Typography
              style={{ color: '#000', fontFamily: 'Inter', fontSize: 16 }}
            >
              Model
            </Typography>
            <Select
              id="model-select-label"
              value={car.model}
              fullWidth
              variant="outlined"
              style={{ background: '#fff', color: '#000' }}
              onChange={(event) => updateModel(event.target.value as string)}
            >
              {models.map((item: string) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {type === 'create' &&
          (engines.length > 0 || car.engine_size !== '') && (
            <>
              <FormControl fullWidth style={{ margin: '10px 0' }}>
                <Typography
                  style={{ color: '#000', fontFamily: 'Inter', fontSize: 16 }}
                >
                  Engine
                </Typography>
                <Select
                  id="engine-select-label"
                  value={car.engine_size}
                  fullWidth
                  variant="outlined"
                  style={{ background: '#fff', color: '#000', marginTop: 5 }}
                  onChange={(event) =>
                    setCar({
                      ...car,
                      engine_size: event.target.value as string,
                    })
                  }
                >
                  {engines.map((item: string) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ margin: '10px 0' }}>
                <Typography
                  style={{ color: '#000', fontFamily: 'Inter', fontSize: 16 }}
                >
                  VIN (optional)
                </Typography>
                <OutlinedInput
                  fullWidth
                  value={car.vin}
                  placeholder="VIN (optional)"
                  onChange={(event) =>
                    setCar({ ...car, vin: event.target.value as string })
                  }
                />
              </FormControl>
            </>
          )}

        {!success && (
          <FormControl fullWidth style={{ margin: '10px 0' }}>
            <Typography
              style={{ color: '#000', fontFamily: 'Inter', fontSize: 16 }}
            >
              {type === 'create' ? ' Name (optional) ' : 'Name'}
            </Typography>
            <OutlinedInput
              fullWidth
              value={car.name}
              placeholder="NAME (optional)"
              onChange={(event) =>
                setCar({ ...car, name: event.target.value as string })
              }
            />
          </FormControl>
        )}

        {!success && (
          <Button
            onClick={type === 'update' ? handleUpdateCar : handleCreateCar}
            style={{ margin: '10px 0' }}
            variant="contained"
            color="primary"
            disabled={!car.year || !car.make || !car.model || !car.engine_size}
          >
            {type === 'update' ? 'Update Car' : 'Add Car'}
          </Button>
        )}

        {success && type === 'update' && (
          <Typography style={{ margin: '10px 0' }}>Car updated</Typography>
        )}

        {success && type === 'create' && (
          <Typography style={{ margin: '10px 0' }}>Car added</Typography>
        )}
      </>
    </ModalBase>
  );
}

export default VehicleForm;
