import React, { ReactElement, useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ButtonForward from 'src/components/atoms/ButtonForward';
import { InputWithStatus } from 'src/components/atoms';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK } from 'src/utils/consts';
import { QuoteStep } from 'src/types';
import {
  Email,
  HelpOutline,
  LocalActivity,
  Person,
  PersonAdd,
  Phone,
} from '@material-ui/icons';
import { QuoteContext } from '../QuoteContext';

interface FormContactProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 23,
    lineHeight: '28px',
    fontWeight: 800,
    marginBottom: theme.spacing(1),

    '.modal-view &': {
      textAlign: 'center',
    },
  },
  error: {
    fontSize: 15,
    lineHeight: '28px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: '#FF3030',
  },
  inlineError: {
    textAlign: 'center',
    color: '#FF3030',
    margin: '10px',
    fontSize: 12,
    lineHeight: '28px',
    fontWeight: 500,
    fontStyle: 'italic',
  },
  subTitle: {
    fontSize: 22,
    lineHeight: '25px',
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    maxWidth: 300,
    flexWrap: 'wrap',
  },
  mobileSecurityBtn: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopSecurityBtn: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  contentContainer: {
    flexGrow: 1,
  },

  lineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  flexGrow: {
    flexGrow: 1,
  },

  actionContainer: {
    margin: '10px 0 80px 0',
    width: '100%',
    minHeight: 70,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',

    '& .button-mobile-info': {
      width: 45,
      height: 45,
    },
  },
  buttonBack: {
    marginRight: theme.spacing(1),
  },
  border: {
    '& .MuiSelect-outlined': {
      border: '0px',
    },
  },
}));

const FormContact = (props: FormContactProps): ReactElement => {
  const { className } = props;
  const [isHowHeardAboutUsSelected, setIsHowHeardAboutUsSelected] =
    useState(false);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const classes = useStyles();

  const {
    handleUpdateContactInfo,
    contact,
    handleSetContact,
    loggingIn,
    requestInProgress,
    handleSetStep,
  } = useContext(QuoteContext);

  const handleBack = () => {
    handleSetStep(QuoteStep.QUOTE_SEARCH_CAR);
  };

  const handleInputChange = (key: string, value: string) => {
    handleSetContact({
      ...contact,
      [key]: value,
    });
    if (key === 'heard_about_us') {
      setIsHowHeardAboutUsSelected(!!value);
    }
  };

  const handleContinue = () => {
    mixPanel(MIXPANEL_TRACK.CONTACT_INFO);

    handleUpdateContactInfo({
      name: contact.name,
      email: contact.email,
      phone: contact.phone,
      referral_code: contact.referral_code,
      heard_about_us: contact.heard_about_us,
      work_commute: contact.work_commute,
      referral_name: contact.referral_name,
    });
  };

  const validateEmail = (em: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(em).toLowerCase());
  };

  const PHONE_NUMBER_LENGTH = 10;
  const isReadyToContinue =
    !!contact.name &&
    !!contact.email &&
    !!contact.phone &&
    validateEmail(contact.email) &&
    contact.phone.length === PHONE_NUMBER_LENGTH;

  const shuffleArray = (array: string[]): string[] => {
    const shuffledArray = [...array];

    for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = shuffledArray[i];
      shuffledArray[i] = shuffledArray[j];
      shuffledArray[j] = temp;
    }

    return shuffledArray;
  };

  return (
    <Box className={clsx('quote-form-contact', classes.root, className)}>
      <Typography key="title" className={classes.title}>
        Contact Info
      </Typography>
      {contact.error && (
        <Typography key="error" className={classes.error}>
          {contact.error}
        </Typography>
      )}
      <Grid container className={classes.contentContainer}>
        <Grid item md={8} sm={12} xs={12}>
          <form>
            <Box key="input-name" className={classes.lineContainer}>
              <InputWithStatus
                className={classes.flexGrow}
                placeholder="First Name"
                value={contact.name}
                valueChanged={(val: string) => handleInputChange('name', val)}
                start={<Person color="secondary" />}
              />
            </Box>
            <Box key="input-email" className={classes.lineContainer}>
              <InputWithStatus
                className={classes.flexGrow}
                placeholder="Email"
                email
                value={contact.email}
                valueChanged={(val: string) => handleInputChange('email', val)}
                start={<Email color="secondary" />}
                inputMode="email"
              />
            </Box>
            <Box key="input-phone" className={classes.lineContainer}>
              <InputWithStatus
                className={classes.flexGrow}
                placeholder={`Phone (${PHONE_NUMBER_LENGTH} digits)`}
                value={contact.phone}
                forceLength={PHONE_NUMBER_LENGTH}
                valueChanged={(val: string) => handleInputChange('phone', val)}
                start={<Phone color="secondary" />}
                inputMode="tel"
              />
            </Box>

            {contact.phone && contact.phone.length > PHONE_NUMBER_LENGTH && (
              <Box key="input-phone-error" className={classes.lineContainer}>
                <p className={classes.inlineError}>
                  * Must be a valid, 10-digit phone number
                </p>
              </Box>
            )}

            <FormControl
              variant="outlined"
              fullWidth
              style={{
                margin: '8px',
                maxWidth: isSm ? '95%' : '97%',
                border: '1px solid #A2A1A8',
                borderRadius: 6,
              }}
            >
              <InputLabel
                htmlFor="heard-about-us"
                shrink={!!contact.heard_about_us}
                style={{
                  zIndex: 999,
                  backgroundColor: 'white',
                  paddingLeft: '8px',
                  transform: `translate(${
                    contact.heard_about_us ? '0' : '10%'
                  }, 90% )`,
                  transition:
                    'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  position: 'absolute',
                  left: '16px',
                }}
                className={classes.border}
              >
                How did you hear about us?
              </InputLabel>

              <Select
                key="heard-about-us"
                id="heard-about-us"
                startAdornment={
                  <InputAdornment position="start">
                    <HelpOutline color="secondary" />
                  </InputAdornment>
                }
                placeholder="How did you hear about us?"
                className={classes.border}
                onChange={(
                  event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: unknown;
                  }>
                ) => {
                  const v = event.target.value as string;
                  handleInputChange('heard_about_us', v);
                }}
                value={contact.heard_about_us || ''}
              >
                <MenuItem disabled value="">
                  How did you hear about us?
                </MenuItem>
                {shuffleArray([
                  'Google',
                  'Facebook',
                  'Referral from friend',
                  'Mechanic referral',
                  'Door Hanger',
                ]).map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>

            {contact.heard_about_us &&
              contact.heard_about_us === 'Referral from friend' && (
                <Box key="input-friend-name" className={classes.lineContainer}>
                  <InputWithStatus
                    className={classes.flexGrow}
                    placeholder="What's your friend's name who referred you?"
                    value={contact.referral_name}
                    valueChanged={(val: string) =>
                      handleInputChange('referral_name', val)
                    }
                    start={<PersonAdd color="secondary" />}
                  />
                </Box>
              )}

            <Box key="input-phone" className={classes.lineContainer}>
              <InputWithStatus
                className={classes.flexGrow}
                placeholder="Coupon code (optional)"
                value={contact.referral_code}
                valueChanged={(val: string) =>
                  handleInputChange('referral_code', val)
                }
                start={<LocalActivity color="secondary" />}
              />
            </Box>
          </form>
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.actionContainer}>
          <ButtonForward
            title="Back"
            key="Back"
            color="default"
            rounded
            noIcon
            transparent
            size="large"
            onClickHandler={handleBack}
            className={classes.buttonBack}
          />

          <ButtonForward
            title={requestInProgress ? 'Calculating Quote...' : 'Continue'}
            key="Continue"
            rounded
            size="large"
            onClickHandler={handleContinue}
            disabled={
              !isReadyToContinue ||
              loggingIn ||
              requestInProgress ||
              !isHowHeardAboutUsSelected
            }
            processing={requestInProgress}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

FormContact.defaultProps = {
  className: undefined,
};

export default FormContact;
