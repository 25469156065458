import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { getAppointment } from 'src/api/quote';
import { ButtonForward } from 'src/components/atoms';
import CancelAppointment from './CancelAppointment';
import RescheduleAppointment from './RescheduleAppointment';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    background: '#eeeeee',
    height: '65vh',
  },
  container: {
    display: 'grid',
    placeItems: 'center',
    gap: '20px',
  },
  button: {
    padding: '20px',
    fontSize: '15pt',
    width: 'min(300px, 80vw)',
  },
}));

const EditAppointment = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { appId: appIdParam }: { appId: string } = useParams();
  const [status, setStatus] = useState<string | null>();

  useEffect(() => {
    if (!appIdParam) {
      return;
    }
    getAppointment(appIdParam)
      .then((response) => {
        if (response && response.data) {
          const { attributes } = response.data;

          setStatus(attributes.status);
        }
      })
      .catch(() => {});
  }, [appIdParam]);

  return (
    <div className={classes.root}>
      {!status ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <div className={classes.container}>
          {status === 'completed' ? (
            <>
              <p>Your appointment has already been completed</p>
              <ButtonForward
                noIcon
                size="medium"
                className={classes.button}
                title="Book a New Appointment"
                onClickHandler={() => history.push('/get-quote')}
              />
            </>
          ) : (
            <>
              <ButtonForward
                noIcon
                size="medium"
                className={classes.button}
                title="Reschedule My Appointment"
                onClickHandler={() =>
                  history.push(`/user/reschedule/${appIdParam}`)
                }
              />
              <Typography>OR</Typography>
              <ButtonForward
                noIcon
                size="medium"
                className={classes.button}
                title="Cancel My Appointment"
                onClickHandler={() =>
                  history.push(`/user/cancel/${appIdParam}`)
                }
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default EditAppointment;
export { CancelAppointment };
export { RescheduleAppointment };
