import React from 'react';
import {
  Card,
  Typography,
  makeStyles,
  useMediaQuery,
  Button,
} from '@material-ui/core';

import { AccessTime, Check } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  Diagnosescard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    background: '#fff',
  },
  clock: {
    marginTop: 30,
    width: 60,
    height: 60,
    flexShrink: 0,
    color: '#5B7B28',
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  li: {
    color: '#005959',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#fff',
  },
  whiteCard: {
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    flex: 1,
  },
  mobileRoot: {
    width: '100%',
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },
}));

const GetBackStep: React.FC = () => {
  const classes = useStyles();
  const isSm = useMediaQuery('(max-width: 1229px)');
  const history = useHistory();

  const handleEnd = () => {
    history.push('/');
  };

  return (
    <Card
      className={classes.Diagnosescard}
      style={{
        marginLeft: 'auto',
        marginRight: isSm ? 'auto' : 10,
        marginBottom: 30,
        background: '#f3f3f3',
      }}
      elevation={0}
    >
      <AccessTime className={classes.clock} />
      <Typography
        style={{
          marginTop: 30,
          fontSize: isSm ? 30 : 40,
          fontFamily: 'Inter',
        }}
      >
        We&apos;ll get back
      </Typography>
      <Typography
        style={{
          marginTop: 10,
          fontSize: isSm ? 12 : 16,
          color: 'var(--black-50, #646867)',
          fontFamily: 'Inter',
          marginBottom: isSm ? 20 : 0,
        }}
      >
        With an upfront fixed quote within an hour
      </Typography>
      <Card
        elevation={3}
        style={{
          padding: 15,
          alignContent: 'left',
          margin: 30,
          gap: 16,
          width: 'min(95%, 500px)',
          borderRadius: '8px',
          boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
          background: '#E6EEEE',
        }}
      >
        <Typography
          style={{
            marginTop: 10,
            fontSize: isSm ? 12 : 16,
            fontFamily: 'Inter',
            fontWeight: 700,
            marginLeft: 20,
          }}
        >
          Next Steps:
        </Typography>
        <ul
          style={{
            marginTop: 10,
            fontSize: isSm ? 12 : 16,
            color: '#1F2322',
            fontFamily: 'Inter',
            marginBottom: isSm ? 20 : 0,
          }}
        >
          <li className={classes.li}>
            {' '}
            We&apos;ll text and email you the quote{' '}
          </li>
          <li className={classes.li}>You can pick a time that works for you</li>
          <li className={classes.li}>Our technician will come to you</li>
        </ul>
      </Card>

      <Button
        style={{
          background: '#005959',
          marginTop: '35px',
          marginLeft: '2px',
        }}
        className={classes.buttonForward}
        variant="contained"
        size="large"
        onClick={handleEnd}
      >
        Got it &nbsp;
        <Check />
      </Button>
    </Card>
  );
};

export default GetBackStep;
