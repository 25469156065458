import johnny from './johnny.png';
import mariah from './mariah.png';
import marian from './marian.png';
import alice from './alice.png';

export default {
  alice,
  mariah,
  marian,
  johnny,
};
