import american_express from './american-express.svg';
import mastercard from './mastercard.svg';
import visa from './visa.svg';
import cardIcon from './card-icon.svg';
import arrowRight from './arrow-right.svg';
import mechanic from './mechanic.svg';
import calendar from './calendar.svg';
import pin from './pin.svg';
import clock from './clock.svg';
import coin from './coin.svg';
import paypal from './paypal.svg';
import phone from './phone.svg';
import cardCalendar from './card-calendar.svg';
import cardLock from './card-lock.svg';
import success from './Success.svg';

export default {
  american_express,
  mastercard,
  visa,
  cardIcon,
  arrowRight,
  mechanic,
  calendar,
  pin,
  clock,
  coin,
  paypal,
  phone,
  cardCalendar,
  cardLock,
  success,
};
