import React, { ReactElement, useState } from 'react';
import { Button, Card, TextField, Typography } from '@material-ui/core';
import checkCircle from 'src/assets/check_circle.png';
import review from '../../../../api/review';

interface FeedbackProps {
  userId?: string | null | undefined;
}

export default function Feedback(props: FeedbackProps): ReactElement {
  const { userId } = props;
  const [feedback, setFeedback] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = () => {
    const reviewRequest = {
      user_id: userId,
      feedback,
    };

    review.submitReview(reviewRequest).then((res) => {
      setSubmitted(true);
    });
  };

  return (
    <>
      {!submitted ? (
        <Card
          style={{ width: '100%', padding: 30, background: '#fff' }}
          elevation={0}
        >
          <Typography
            style={{
              fontFamily: 'Inter',
              marginBottom: 40,
              fontSize: 30,
              fontWeight: 600,
            }}
          >
            Share your feedback
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              placeholder="Let us know how we can do better"
              style={{ width: 500, borderRadius: 12 }}
              multiline
              minRows={8}
              value={feedback}
              onChange={handleFeedbackChange}
            />

            <Button
              style={{
                marginRight: 'auto',
                marginTop: 20,
                backgroundColor: '#005959',
                color: '#FFF',
                fontFamily: 'Inter',
                padding: '10px 25px',
                borderRadius: '24px',
                textTransform: 'none',
              }}
              onClick={handleSubmit}
            >
              Submit Feedback
            </Button>
          </div>
        </Card>
      ) : (
        <Card
          style={{ width: '100%', padding: 30, background: '#fff' }}
          elevation={0}
        >
          <Typography
            style={{
              fontFamily: 'Inter',
              marginBottom: 40,
              fontSize: 30,
              fontWeight: 600,
            }}
          >
            Share your feedback
          </Typography>
          <Card
            style={{
              maxWidth: '600px',
              padding: 20,
              borderRadius: 10,
              display: 'flex',
              gap: 36,
              background: '#F3F3F3',
            }}
            elevation={0}
          >
            <img
              src={checkCircle}
              alt="correct-pic"
              style={{
                marginRight: 'auto',
                height: 40,
                width: 40,
              }}
            />
            <div>
              <Typography style={{ fontFamily: 'Inter', marginBottom: 25 }}>
                Thanks for taking the time to share your thoughts. If it’s
                alright with you, we may reach out to learn more about your
                feedback to help us improve.
              </Typography>
              <Typography style={{ fontFamily: 'Inter' }}>
                - The Goodhood Team
              </Typography>
            </div>
          </Card>
        </Card>
      )}
    </>
  );
}

Feedback.defaultProps = {
  userId: null,
};
