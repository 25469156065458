import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { RequestConfirmAppointment } from 'src/types';
import { confirmAppointment, getAppointment } from 'src/api/quote';
import { Image } from 'src/components/atoms';
import diagnoses from 'src/assets/Diagnoses.png';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import { setAppointment } from 'src/store/actions';
import AppleDownload from 'src/assets/iOS.svg';
import GoogleDownload from 'src/assets/play-badge.svg';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK } from 'src/utils/consts';
import {
  ArrowForward,
  BuildOutlined,
  CalendarToday,
  CheckCircle,
  DirectionsCarOutlined,
  Error,
  LocationOn,
  Schedule,
} from '@material-ui/icons';
import { QuoteContext } from './QuoteContext';

const useStyles = makeStyles((theme) => ({
  Diagnosescard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
    background: '#fff',
  },
  clock: {
    marginTop: 30,
    width: 60,
    height: 60,
    flexShrink: 0,
    color: '#5B7B28',
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    fontFamily: 'inter',
    marginTop: 20,
    borderRadius: '32px',
    textTransform: 'none',
    backgroundColor: '#005959',
  },
  li: {
    color: '#005959',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#fff',
  },
  whiteCard: {
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    flex: 1,
  },
  mobileRoot: {
    width: '100%',
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },
  hDiv: {
    textAlign: 'center',
    gap: 30,
    padding: 100,
    background: '#f3f3f3',
  },
  error: {
    color: '#DC0000',
    width: 50,
    height: 50,
  },
}));

const QuoteDone = (): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { appId: appIdParam }: { appId: string } = useParams();
  const [loading, setLoading] = useState(true);
  const [paymentInfoReceived, setPaymentInfoReceived] = useState(false);
  const [failedPaymentAppId, setFailedPaymentAppId] = useState(0);
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const { membershipSelected } = useContext(QuoteContext);

  useEffect(() => {
    const asyncReadAppointment = async () => {
      const app = await getAppointment(appIdParam);

      dispatch(setAppointment(app.data));

      const data: RequestConfirmAppointment = {
        token: '',
      };

      if (membershipSelected) {
        if (membershipSelected === '$180') {
          data.member_price_agreed_on_before_job = 180;
        }
      }

      if (app.data?.attributes?.payment_setup) {
        setPaymentInfoReceived(true);
        confirmAppointment(app.data.id, data);
      } else {
        setFailedPaymentAppId(app.data.id);
      }

      setLoading(false);

      mixPanel(MIXPANEL_TRACK.CARD_INFO);
    };

    if (appIdParam) asyncReadAppointment();

    return () => {};
  }, [dispatch, appIdParam, membershipSelected, paymentInfoReceived]);

  const handleRetryPayment = () => {
    const paymentRetryUrl = `/appointment/confirm/${failedPaymentAppId}`;
    window.location.href = paymentRetryUrl;
  };

  const isSm = useMediaQuery('(max-width: 1229px)');

  const formatDate = (dateStr?: string): string => {
    if (!dateStr) {
      return 'Invalid date format';
    }

    try {
      const [year, month, day] = dateStr.split('-');
      const dateObject = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10)
      );
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const formattedDate = dateObject.toLocaleDateString('en-US', options);
      return formattedDate;
    } catch (error) {
      return 'Invalid date format';
    }
  };

  if (loading) {
    return (
      <div className={classes.hDiv}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Card
      className={classes.Diagnosescard}
      style={{
        marginTop: 50,
        background: '#F9F9F9',
        width: isSm ? '95%' : 'max-content',
        margin: 'auto',
      }}
      elevation={0}
    >
      {paymentInfoReceived ? (
        <>
          <CheckCircle className={classes.clock} />

          <Typography
            style={{
              marginTop: 30,
              fontSize: 24,
              fontFamily: 'Inter',
              fontWeight: 700,
            }}
          >
            All set
          </Typography>
          <Typography
            style={{
              marginTop: 10,
              fontSize: 16,
              color: 'var(--black-50, #646867)',
              fontFamily: 'Inter',
              marginBottom: isSm ? 20 : 0,
            }}
          >
            Thank you for your request
          </Typography>
          <Card
            style={{
              width: '95%',
              marginTop: 20,
              background: '#fff',
              padding: 20,
              boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
            }}
            elevation={0}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <DirectionsCarOutlined
                style={{ color: '#646867', margin: 15, marginTop: 0 }}
              />
              <Typography
                style={{
                  marginTop: 5,
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                {appointment?.attributes.car.one_string}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {appointment?.attributes.appointment_type === 'repair' ? (
                <BuildOutlined
                  style={{ color: '#646867', margin: 15, marginTop: 0 }}
                />
              ) : (
                <img
                  src={diagnoses}
                  alt="Applied!"
                  style={{
                    width: 25,
                    height: 25,
                    margin: 15,
                    marginTop: 0,
                  }}
                />
              )}
              {appointment?.attributes.appointment_type === 'repair' ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontFamily: 'Inter',
                      fontWeight: 700,
                    }}
                  >
                    Service / Repair
                  </Typography>
                  <ul
                    style={{
                      marginTop: 10,
                      color: '#646867',
                      fontFamily: 'Inter',
                      paddingLeft: 0,
                      marginLeft: 18,
                      fontSize: 16,
                    }}
                  >
                    {appointment?.attributes.services.map((problem, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{problem}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontFamily: 'Inter',
                      fontWeight: 700,
                    }}
                  >
                    {' '}
                    Diagnosis
                  </Typography>
                  <ul
                    style={{
                      marginTop: 10,
                      color: '#646867',
                      fontFamily: 'Inter',
                      paddingLeft: 0,
                      marginLeft: 18,
                      fontSize: 16,
                    }}
                  >
                    <li>{appointment?.attributes.diagnosis_input}</li>
                  </ul>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <CalendarToday
                style={{ color: '#646867', margin: 15, marginTop: 0 }}
              />
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                {formatDate(appointment?.attributes.appointment_day)}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Schedule
                style={{ color: '#646867', margin: 15, marginTop: 0 }}
              />
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                {appointment?.attributes.appointment_time}
              </Typography>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <LocationOn
                style={{ color: '#646867', margin: 15, marginTop: 0 }}
              />
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                {appointment?.attributes.exact_address}
              </Typography>
            </div>
          </Card>
          <Card
            style={{
              width: '95%',
              margin: 30,
              background: '#fff',
              padding: 20,
              fontFamily: 'Inter',
              textAlign: 'center',
              boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
            }}
            elevation={0}
          >
            In the meantime to stay updated, download our app
            <div
              style={{
                display: 'flex',
                flexDirection: isSm ? 'column' : 'row',
                marginTop: 10,
              }}
            >
              <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                <Link href="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907">
                  <Image className={classes.appleIcon} src={AppleDownload} />
                </Link>
              </Box>
              <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                <Link href="https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp">
                  <Image src={GoogleDownload} />
                </Link>
              </Box>
            </div>
          </Card>
        </>
      ) : (
        <div className={classes.hDiv}>
          <Error className={classes.error} />
          <Typography>There was an error processing your payment</Typography>
          <Button
            onClick={handleRetryPayment}
            className={classes.buttonForward}
          >
            Retry Payment &nbsp; <ArrowForward />
          </Button>
        </div>
      )}
    </Card>
  );
};

export default QuoteDone;
