import { ResponseVehicle } from 'src/types';
import { callApi } from './request';

interface ICarRequest {
  year?: number;
  make?: string;
  model?: string;
}

const apiGetVehicles = async (car: ICarRequest): Promise<ResponseVehicle> => {
  const params = [];

  if (car.year) {
    params.push(`year=${car.year}`);
  }

  if (car.make) {
    params.push(`make=${car.make}`);
  }

  if (car.model) {
    params.push(`model=${encodeURIComponent(car.model)}`);
  }

  return callApi<ResponseVehicle>({
    url: `/api/v2/vehicles?${params.join('&')}`,
    method: 'GET',
  });
};

export default apiGetVehicles;
