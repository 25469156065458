import {
  ResponseSignin,
  RequestSignin,
  RequestSignup,
  ResponseSignup,
  RequestSignInAppointment,
  RequestAuth,
  ResponseAuth,
} from 'src/types';
import { callApi } from './request';

export const getToken = async (data: RequestAuth): Promise<ResponseAuth> => {
  return callApi<ResponseAuth>({
    url: `/api/v2/user/auth`,
    method: 'POST',
    data,
  });
};

export const signUp = async (data: RequestSignup): Promise<ResponseSignup> => {
  return callApi<ResponseSignup>({
    url: `/api/v2/user/sign_up`,
    method: 'POST',
    data,
  });
};

export const signIn = async (
  data: RequestSignin | RequestSignInAppointment,
  appointmentMode = false
): Promise<ResponseSignin> => {
  return callApi<ResponseSignin>({
    url: !appointmentMode
      ? `/api/v2/user/sign_in`
      : `/api/v2/appointment/sign_in`,
    method: 'POST',
    data,
  });
};

export const signInWithEmail = async (
  email: string,
  password: string
): Promise<ResponseSignin> => {
  return callApi<ResponseSignin>({
    url: '/api/v2/user/sign_in',
    method: 'POST',
    data: {
      user: {
        email,
        password,
      },
    },
  });
};

export const signInWithGoogle = async (
  email: string
): Promise<ResponseSignin> => {
  return callApi<ResponseSignin>({
    url: '/api/v2/user/sign_in_with_google',
    method: 'POST',
    data: {
      user: {
        email,
      },
    },
  });
};
