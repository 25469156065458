import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    [theme.breakpoints.up('xs')]: {
      h1: {
        fontFamily: 'Tiempos',
        fontWeight: '400',
        fontSize: '60px',
        lineHeight: '62.5px',
      },
      h2: {
        fontFamily: 'Tiempos',
        fontWeight: '900',
        fontSize: '32px',
      },
      h3: {
        fontFamily: 'Tiempos',
        fontWeight: 'bold',
        fontSize: '22px',
      },
      h4: {
        fontFamily: 'Tiempos',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      h5: {
        fontFamily: 'Tiempos',
        fontWeight: 'bold',
        fontSize: '14px',
      },
      s1: {
        fontFamily: 'Inter',
        fontSize: '17px',
      },
      s2: {
        fontFamily: 'Inter',
        fontSize: '16px',
        lineHeight: '22px',
      },
      p1: {
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '22px',
      },
    },

    [theme.breakpoints.up('md')]: {
      h1: {
        fontFamily: 'Tiempos',
        fontWeight: 'bold',
        fontSize: '50px',
      },
      h2: {
        fontFamily: 'Tiempos',
        fontWeight: '900',
        fontSize: '40px',
      },
      h3: {
        fontFamily: 'Tiempos',
        fontWeight: 'bold',
        fontSize: '22px',
      },
      h4: {
        fontFamily: 'Tiempos',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      h5: {
        fontFamily: 'Tiempos',
        fontWeight: 'bold',
        fontSize: '14px',
      },
      s1: {
        fontFamily: 'Inter',
        fontSize: '18px',
      },
      s2: {
        fontFamily: 'Inter',
        fontSize: '16px',
        lineHeight: '22px',
      },
      p1: {
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '22px',
      },
    },
  };
});

export default useStyles;
