import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Link,
  OutlinedInput,
  Typography,
} from '@material-ui/core';

import { callAdsQuote } from 'src/utils/ads';
import { updateAppointmentOutOfRange } from 'src/api/quote';
import { IReduxState } from 'src/store/reducers';
import { ButtonForward, Image } from 'src/components/atoms';
import { URLS } from 'src/utils/consts';
import { IAppointment, QuoteShowModal } from 'src/types';
import { setAppointment } from 'src/store/actions';
import MechanicSVG from 'src/assets/mechanicIcon.svg';
import AppleDownload from 'src/assets/iOS.svg';
import GoogleDownload from 'src/assets/play-badge.svg';

import { QuoteContext } from '../QuoteContext';

interface SimpleCongratsProps {
  className?: string;
  isBooked?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(4),
    maxWidth: 600,
    minHeight: 400,

    [theme.breakpoints.down('sm')]: {
      marginTop: '60%',
      position: 'absolute',
      top: '0rem',
      left: 0,
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      paddingBottom: '40%',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  titleText: {
    fontFamily: 'Inter',
    color: '#2A2D3C',
    fontSize: 32,
    lineHeight: '42px',
    fontWeight: 900,
    textAlign: 'center',
    zIndex: 100,
  },
  subHeaderText: {
    fontFamily: 'Inter',
    color: '#2A2D3C',
    fontSize: 30,
    lineHeight: '42px',
    fontWeight: 600,
    textAlign: 'center',
    zIndex: 100,
  },
  subTitleText: {
    fontFamily: 'Tiempos',
    paddingTop: 15,
    color: '#000',
    fontSize: 20,
    lineHeight: '20px',
    fontWeight: 500,
    textAlign: 'center',
    zIndex: 100,
  },
  titleBgImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },
  linear: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    margin: '20px 0',
  },
  waitTimeText: {
    paddingTop: 8,
    zIndex: 100,
    color: '#9894a8',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
  },
  callUsText: {
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 10,
    zIndex: 100,
  },
  finePrint: {
    zIndex: 100,
    fontSize: 11,
    fontWeight: 400,
    color: '#9894a8',
    paddingBottom: 20,
  },
  modalDiscount: {
    padding: '10px',
  },
  discountCode: {
    fontWeight: 600,
    fontSize: '20px',
    marginTop: '15px',
    color: '#005959',
  },
  inputZip: {
    height: '100%',
    color: '#A2A1A8',
    fontWeight: 500,
    fontSize: 25,
    background: 'white',
    border: '1px solid #ddd',
    padding: '5px',
    borderRadius: '2px',
    '&:focus': {
      outline: 'none',
    },
  },
  confirmBox: {
    padding: '20px',
    margin: '20% 0',
    border: '2px solid rgba(0, 89, 89, 1)',
    backgroundColor: 'rgba(230, 238, 238, 1)',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
    },
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },
}));

const SimpleCongrats = (props: SimpleCongratsProps): ReactElement => {
  const { className, isBooked } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchAnotherZip, setSearchAnotherZip] = useState<boolean>(false);
  const [zipDisabled, setZipDisabled] = useState<boolean>(false);
  const [newZip, setNewZip] = useState<string>('');
  const [newZipSuccess, setNewZipSuccess] = useState<boolean>(false);
  const [newZipError, setNewZipError] = useState<boolean>(false);
  const [nonInstantNewZip, setNonInstantNewZip] = useState<boolean>(false);

  const appointment: IAppointment | null = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const handleDone = () => {
    history.push(URLS.HOME);
  };

  const { handleShowModal } = useContext(QuoteContext);

  const handleOutOfServiceForward = () => {
    if (
      appointment?.attributes?.appointment_type === 'diagnosis' ||
      appointment?.attributes?.estimate?.id
    ) {
      handleShowModal(QuoteShowModal.REVIEW_QUOTE);
    } else {
      setNonInstantNewZip(true);
    }
  };

  const handleChange = useCallback(
    (evt: ChangeEvent<{ value: string }>) => {
      const code = evt.target.value as string;

      setNewZip(code);
    },
    [setNewZip]
  );

  const handleSearchZip = () => {
    setNewZipSuccess(false);
    setNewZipError(false);

    if (appointment?.id) {
      setZipDisabled(true);
      updateAppointmentOutOfRange(appointment?.id, newZip)
        .then((res) => {
          dispatch(setAppointment(res.data));

          callAdsQuote(res.data);

          setNewZipSuccess(true);
          setZipDisabled(false);
        })
        .catch(() => {
          setNewZipSuccess(false);
          setZipDisabled(false);
          setNewZipError(true);
        });
    }
  };

  const NoZipCoverage = () => {
    const zipFirst: string | undefined = appointment?.attributes?.address[0];

    let cityText;

    if (zipFirst === '7') {
      cityText = 'Dallas or Houston';
    } else if (zipFirst === '4') {
      cityText = 'Metro Detroit';
    } else if (zipFirst === '8') {
      cityText = 'Phoenix';
    }

    let headerText;

    if (!cityText) {
      headerText =
        "Unfortunately, we don't have any technicians in your area. We've created an account for you, and we'll notify you when we open in your area.";
    } else {
      headerText =
        "Looks like we aren't in your exact area, but we may be in a city near you.";
    }

    return (
      <div>
        <h3>{headerText}</h3>
        {cityText && (
          <Box style={{ margin: '10px 0' }}>
            <Button
              color="primary"
              size="medium"
              variant="contained"
              onClick={() => setSearchAnotherZip(!searchAnotherZip)}
            >
              Try another zip in {cityText}
            </Button>
            {searchAnotherZip && (
              <Box style={{ margin: '10px 0' }}>
                <OutlinedInput
                  placeholder="Enter zip code"
                  value={newZip}
                  onChange={handleChange}
                />
                <Box style={{ margin: '10px auto' }}>
                  <Button
                    onClick={handleSearchZip}
                    disabled={!newZip || newZip.length !== 5 || zipDisabled}
                  >
                    Search
                  </Button>
                </Box>
                {newZipSuccess && (
                  <Box style={{ margin: '10px 0' }}>
                    <h3 style={{ color: '#4caf50' }}>
                      Excellent! We cover this area.
                    </h3>
                    <ButtonForward
                      key="done"
                      title="Continue"
                      size="large"
                      rounded
                      onClickHandler={handleOutOfServiceForward}
                    />
                  </Box>
                )}
                {newZipError && (
                  <p style={{ color: '#ff0000' }}>
                    That&apos;s still a little outside our coverage.
                    <br />
                    Try another zip in {cityText}
                  </p>
                )}
              </Box>
            )}

            {!newZipSuccess && (
              <>
                <h3>OR</h3>
                <Button
                  color="primary"
                  size="medium"
                  variant="outlined"
                  onClick={() => window.open('tel:469-840-3990')}
                >
                  Call Us For Service
                </Button>
              </>
            )}
          </Box>
        )}
      </div>
    );
  };

  const Content = () => {
    if (
      (appointment?.attributes.status === 'no_service_area' &&
        !nonInstantNewZip) ||
      (newZipSuccess && !isBooked)
    ) {
      return (
        <>
          <p
            className=""
            style={{
              color: '#474747',
              textAlign: 'center',
              margin: '30px 0',
            }}
          >
            <NoZipCoverage />
          </p>
        </>
      );
    }

    if (
      appointment?.attributes.status !== 'no_service_area' &&
      isBooked &&
      appointment?.attributes.appointment_day
    ) {
      return (
        <>
          <Box className={classes.confirmBox}>
            <div className={classes.linear}>
              <Image src={MechanicSVG} />
            </div>
            <Typography key="title" className={classes.titleText}>
              Appointment Confirmed
            </Typography>
            <Typography key="subtitle" className={classes.subTitleText}>
              {appointment ? (
                <>
                  <p>
                    Date:{' '}
                    <span style={{ fontWeight: 800 }}>
                      {appointment.attributes.appointment_day}
                    </span>
                  </p>
                  <p>
                    Arrival time:{' '}
                    <span style={{ fontWeight: 800 }}>
                      {appointment.attributes.appointment_time}
                    </span>
                  </p>
                </>
              ) : (
                'Your Appointment is confirmed.'
              )}
            </Typography>
          </Box>

          <Typography
            key="subtitle"
            className={classes.subTitleText && classes.callUsText}
          >
            In the meantime to stay updated, download our app.
          </Typography>

          <Box style={{ margin: '10px auto', textAlign: 'center' }}>
            <Link href="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907">
              <Image className={classes.appleIcon} src={AppleDownload} />
            </Link>
          </Box>

          <Box style={{ margin: '10px auto', textAlign: 'center' }}>
            <Link href="https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp">
              <Image src={GoogleDownload} />
            </Link>
          </Box>
        </>
      );
    }

    return (
      <>
        <Typography key="title" className={classes.subHeaderText}>
          What&apos;s Next?
        </Typography>
        <Box style={{ margin: '10px 0', textAlign: 'center' }}>
          <Typography style={{ margin: '10px auto', width: '80%' }}>
            1. We&apos;ll text you a link to view and book your service ASAP.
          </Typography>
          <Typography style={{ margin: '10px auto', width: '80%' }}>
            2. Once booked, our Goodhood certified technician will come to your
            location and perform the service.
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box className={clsx(className, classes.root)}>
      <div>
        <Content />
      </div>

      {((appointment?.attributes.status !== 'no_service_area' &&
        !newZipSuccess) ||
        appointment?.attributes.status === 'pending') && (
        <Box style={{ margin: '10px 0', paddingBottom: '50px' }}>
          {!appointment?.attributes.member_job ? (
            <Box style={{ margin: '10px 0', textAlign: 'center' }}>
              <Typography style={{ margin: '10px auto', width: '80%' }}>
                Checkout our{' '}
                <span style={{ fontWeight: 'bold' }}>Goodhood Membership</span>{' '}
                for more savings. Join today and get free oil changes and 10%
                off all repairs.
              </Typography>

              <Box style={{ marginTop: '10px' }}>
                <ButtonForward
                  key="done"
                  title="Join now"
                  size="large"
                  rounded
                  onClickHandler={() =>
                    window.open('https://www.goodhood.auto/membership')
                  }
                />
              </Box>
            </Box>
          ) : (
            <ButtonForward
              key="done"
              title="Go to my account"
              size="large"
              rounded
              onClickHandler={handleDone}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

SimpleCongrats.defaultProps = {
  className: undefined,
  isBooked: false,
};

export default SimpleCongrats;
