import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  Tooltip,
  Typography,
  Grid,
} from '@material-ui/core';
import { ExpandMore, ExpandLess, InfoOutlined } from '@material-ui/icons';
import { IAppointment, ICoupon, IUser } from 'src/types';
import { ButtonForward } from 'src/components/atoms';
import fontStyles from '../../../fontStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '40px',
  },
  invoice: {
    border: '1px solid #EDEDED',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.07)',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '10px',
    zIndex: 3,
    background: '#ffffff',
    padding: '0px 30px 20px 30px',
  },
  priceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  dollarSign: {
    color: '#000',
  },
  installments: {
    display: 'flex',
    color: '#707070',
  },
  content: {
    justifyContent: 'center',
  },
  accordion: {
    borderRadius: 'none',
    boxShadow: 'none',
    background: '#fff',
  },
  accordionSummary: {
    padding: '0 2px',
  },
  buttonBack: {
    marginRight: theme.spacing(1),
  },
  label: {
    fontWeight: 'bold',
  },
  cardModal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    maxWidth: 320,
    minWidth: 300,
    background: '#FFFFFF',
    borderRadius: '16px',
    boxShadow: theme.shadows[5],
    padding: '25px 20px',
    outline: 'none',
  },
  actionContainer: {
    marginBottom: '30px',
    marginTop: '-10px',
    width: '100%',
    minHeight: 70,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '& .button-mobile-info': {
      width: 45,
      height: 45,
    },
  },
}));

interface InvoiceProps {
  price: number;
  onContinue: () => void;
  buttonText: string;
  isMember: boolean;
  memberPrice: number | null | undefined;
  nonMemberPrice: number | null | undefined;
  tax: number | null | undefined;
  convenienceFee: number | null | undefined;
  laborOnlyFee: number | null | undefined;
  discount: number | null | undefined;
  coupon: ICoupon | null;
  subtotal: number | undefined;
  isRepair: boolean;
  hasEstimate: boolean;
  isDiag: boolean;
  diagFee: number;
  handleBack: () => void;
  showBack: boolean;
  isOilChange: boolean | null | undefined;
  estimateResponse: boolean;
  memberType: string | null;
  memberSubtotal: number | undefined;
  memberSavings: number | undefined;
  inconvenienceDiscount: number | undefined;
  fleetJob: boolean;
}

function InvoiceFooter(props: InvoiceProps): React.ReactElement {
  const {
    price,
    onContinue,
    isMember,
    memberPrice,
    buttonText,
    tax,
    convenienceFee,
    discount,
    coupon,
    nonMemberPrice,
    subtotal,
    isRepair,
    hasEstimate,
    isDiag,
    diagFee,
    showBack,
    handleBack,
    isOilChange,
    estimateResponse,
    memberType,
    memberSubtotal,
    memberSavings,
    laborOnlyFee,
    inconvenienceDiscount,
    fleetJob,
  } = props;
  const classes = useStyles();
  const fontClasses = fontStyles();

  const [expanded, setExpanded] = useState<boolean>(false);

  const getPriceToShow = () => {
    if (isMember) {
      if (isOilChange) {
        return 0;
      }

      if (isDiag) {
        return memberPrice;
      }

      return memberPrice || 0;
    }

    return price;
  };

  const getSubtotal = () => {
    let amt = 0;

    if (isMember) {
      amt += memberSubtotal || 0;
    } else {
      amt += subtotal || 0;
    }

    return amt;
  };

  const wording = () => {
    if (coupon && coupon.referrer) {
      return 'Referral discount:';
    }

    if (discount === 25) {
      return 'New customer discount:';
    }

    return 'Discount:';
  };

  const toolTipWording = () => {
    if (coupon) {
      if (coupon.referrer) {
        return `Your friend ${coupon.referrer} has invited you to try us out! We're giving you $30 off!`;
      }

      return `You used coupon ${coupon.code} for $${coupon.dollars_off} off!`;
    }

    if (discount === 25) {
      return "We're giving you a $25 discount for trying us out!";
    }

    return null;
  };

  return (
    <div className={classes.root}>
      <div className={classes.invoice}>
        <Accordion
          className={classes.accordion}
          onChange={() => setExpanded(!expanded)}
        >
          {price > 0 ? (
            <AccordionSummary
              className={classes.accordionSummary}
              classes={{ content: classes.content }}
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </AccordionSummary>
          ) : (
            <></>
          )}

          {getSubtotal() > 0 ? (
            <div className={clsx(classes.priceBox, fontClasses.s1)}>
              <span>Services:</span>
              <span>
                <span className={classes.dollarSign}>$</span>{' '}
                {getSubtotal().toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}

          {tax && tax > 0 ? (
            <div className={clsx(classes.priceBox, fontClasses.s1)}>
              <span>Taxes:</span>
              <span>
                <span className={classes.dollarSign}>$</span> {tax.toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}

          {convenienceFee && convenienceFee > 0 ? (
            <div className={clsx(classes.priceBox, fontClasses.s1)}>
              <span>Convenience Fee:</span>
              <span>
                <span className={classes.dollarSign}>$</span>{' '}
                {convenienceFee?.toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}

          {laborOnlyFee && (
            <div className={clsx(classes.priceBox, fontClasses.s1)}>
              <span style={{ marginRight: '4px' }}>
                <Tooltip
                  arrow
                  title={
                    <Typography
                      style={{ fontSize: '22px', fontFamily: 'Inter' }}
                    >
                      Liability fee since we’re not supplying our parts
                    </Typography>
                  }
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                >
                  <InfoOutlined
                    style={{ fontSize: '20px', marginBottom: '-4px' }}
                  />
                </Tooltip>

                <span style={{ marginLeft: '4px' }}>Liability Fee:</span>
              </span>

              <span>
                <span className={classes.dollarSign}>$</span>{' '}
                {laborOnlyFee?.toFixed(2)}
              </span>
            </div>
          )}

          {isDiag && !estimateResponse && (
            <div className={clsx(classes.priceBox, fontClasses.s1)}>
              <span>Diagnosis Fee: </span>
              <span>
                <span className={classes.dollarSign}>$</span> {diagFee}.00
              </span>
            </div>
          )}

          {discount && discount > 0 ? (
            <div className={clsx(classes.priceBox, fontClasses.s1)}>
              <span>
                {toolTipWording() && (
                  <span style={{ marginRight: '4px' }}>
                    <Tooltip
                      arrow
                      title={
                        <Typography style={{ fontSize: '22px' }}>
                          {toolTipWording()}
                        </Typography>
                      }
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                    >
                      <InfoOutlined
                        style={{ fontSize: '20px', marginBottom: '-4px' }}
                      />
                    </Tooltip>
                  </span>
                )}
                {wording()}
              </span>
              <span>
                <span className={classes.dollarSign}>$</span>
                {'- '}
                {discount?.toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}

          {inconvenienceDiscount && inconvenienceDiscount > 0 ? (
            <div className={clsx(classes.priceBox, fontClasses.s1)}>
              <span>
                <span style={{ marginRight: '4px' }}>
                  <Tooltip
                    arrow
                    title={
                      <Typography
                        style={{ fontSize: '22px', fontFamily: 'Inter' }}
                      >
                        To address the inconvenience, we are offering a
                        compensatory discount
                      </Typography>
                    }
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                  >
                    <InfoOutlined
                      style={{ fontSize: '20px', marginBottom: '-4px' }}
                    />
                  </Tooltip>
                </span>
                Inconvenience Discount
              </span>
              <span>
                <span className={classes.dollarSign}>$</span>
                {'- '}
                {inconvenienceDiscount?.toFixed(2)}
              </span>
            </div>
          ) : (
            <></>
          )}

          {(isRepair || hasEstimate) && (
            <Box style={{ margin: '14px 0' }}>
              <Divider className="row" />
            </Box>
          )}
        </Accordion>

        {memberSavings && !fleetJob && (
          <div
            style={{ color: '#005959' }}
            className={clsx(classes.priceBox, fontClasses.h5)}
          >
            <span style={{ marginTop: '4px' }}>
              <span style={{ marginRight: '4px' }}>
                <Tooltip
                  arrow
                  title={
                    <Typography style={{ fontSize: '22px' }}>
                      Members get FREE oil changes and 10% off all services.
                      Sign up on the next screen!
                    </Typography>
                  }
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                >
                  <InfoOutlined
                    style={{ fontSize: '20px', marginBottom: '-4px' }}
                  />
                </Tooltip>
              </span>
              {isMember ? 'Your Savings' : 'Members Save'}
            </span>
            <span
              style={{
                backgroundColor: '#005959',
                color: '#fff',
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              <span>$</span> {memberSavings?.toFixed(2)}
            </span>
          </div>
        )}

        <div className={clsx(classes.priceBox, fontClasses.h4)}>
          <span>Your Quote:</span>
          <span>
            <span className={classes.dollarSign}>$</span>{' '}
            {isMember ? memberPrice?.toFixed(2) : getPriceToShow()?.toFixed(2)}{' '}
            *
          </span>
        </div>

        {showBack ? (
          <Grid item md={4} sm={12} xs={12} className={classes.actionContainer}>
            <ButtonForward
              title="Back"
              key="Back"
              color="default"
              rounded
              noIcon
              transparent
              size="large"
              onClickHandler={handleBack}
              className={classes.buttonBack}
            />

            <ButtonForward
              title={buttonText}
              key="Continue"
              rounded
              size="large"
              onClickHandler={onContinue}
              disabled={false}
              processing={false}
            />
          </Grid>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                borderRadius: 31,
                textTransform: 'none',
                margin: '10px auto',
                boxShadow: 'none',
              }}
              className={fontClasses.h5}
              onClick={() => onContinue()}
            >
              {buttonText}
            </Button>
          </div>
        )}

        <div
          style={{
            marginTop: '10px',
            textAlign: 'center',
          }}
        >
          <Typography style={{ fontStyle: 'italic', fontSize: '14px' }}>
            * Payment due after service
          </Typography>{' '}
        </div>
      </div>
    </div>
  );
}

export default InvoiceFooter;
