import React, { useState, useEffect, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  IconButton,
  Dialog,
  DialogContent,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Close } from '@material-ui/icons';
import { apiGetBankSetupIntentSecret } from 'src/api/user';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

interface ModalAddBankAccountProps {
  show: boolean;
  onClose: () => void;
  email: string;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  content: {
    minWidth: 600,
    [theme.breakpoints.down('sm')]: {
      minWidth: 500,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
      padding: theme.spacing(0),
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(4),
    '& .title-icon': {
      color: '#C5C9DE',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionContainer: {
    width: '100%',
    textAlign: 'right',
  },
  titleDatetime: {
    fontWeight: 900,
    fontSize: 19,
    lineHeight: '22.8px',
    marginLeft: theme.spacing(1),
  },
  boxInformation: {
    minWidth: '100%',
    background: '#EBF1FA',
    borderRadius: 6,
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      background: 'transparent',
      borderRadius: 0,
      padding: 0,
    },
  },
  contentHolder: {
    minHeight: 250,
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
    padding: theme.spacing(4),
    alignContent: 'flex-start',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      borderRadius: 9,
      minHeight: 500,
      padding: theme.spacing(2),
    },
  },
  success: {
    color: '#36D9A0',
    margin: 10,
  },
}));

const ModalAddBankAccount = (props: ModalAddBankAccountProps): ReactElement => {
  const { show, onClose, email, name } = props;
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [secret, setSecret] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<string | null>(null);

  useEffect(() => {
    const fetchSetupIntent = async () => {
      try {
        const response = await apiGetBankSetupIntentSecret();
        setSecret(response.client_secret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSetupIntent();
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements || !secret) {
      console.error('Stripe or elements not properly initialized');
      return;
    }

    const result = await stripe.collectBankAccountForSetup({
      clientSecret: secret,
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: {
            name,
            email,
          },
        },
      },
    });

    setIsSubmitting(false);

    if (result.error) {
      setErrors('Error connecting bank account, try again or contact us.');
    } else if (
      result.setupIntent.payment_method &&
      result.setupIntent.status === 'requires_confirmation'
    ) {
      const paymentMethodId =
        typeof result.setupIntent.payment_method === 'string'
          ? result.setupIntent.payment_method
          : result.setupIntent.payment_method?.id;

      await stripe.confirmUsBankAccountSetup(secret, {
        payment_method: paymentMethodId,
      });

      setSuccess(true);
    } else {
      setSuccess(true);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      className={classes.root}
    >
      <DialogTitle className={classes.title}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {isLoading ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent className={classes.content}>
          {secret && (
            <Elements stripe={stripePromise} options={{ clientSecret: secret }}>
              <form onSubmit={handleSubmit}>
                <PaymentElement />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !stripe || !elements}
                >
                  {isSubmitting ? 'Processing...' : 'Connect Bank Account'}
                </Button>
              </form>
            </Elements>
          )}

          {errors && (
            <Typography style={{ margin: 10 }} color="error">
              {errors}
            </Typography>
          )}

          {success && (
            <Typography className={classes.success}>
              Bank account successfully connected!
            </Typography>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ModalAddBankAccount;
