export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const MEMBER_PLANS = [
  {
    name: '$180 / 6 months',
    price: 180,
    billing: '6 months',
    billing_interval: 'month',
    billing_count: 6,
  },
];

export const MEMBER_PRICES = {
  BIANNUAL: 180,
};

export const BILLINGS = [
  {
    label: '6 months',
    interval: 'month',
    count: 6,
  },
];

export const processURL = (
  basePath: string,
  params: { [key: string]: string }
): string => {
  return `${basePath}?${new URLSearchParams(params).toString()}`;
};

export const URLS = {
  LOGIN: '/login',
  LOGIN_FLEET: '/login/fleet',
  SIGN_UP_FLEET: '/signup/fleet',
  HOME: '/',
  QUOTE: '/quote',
  QUOTE_NEW: '/get-quote',
  DASHBOARD: '/dashboard',
  ESTIMATE: '/appointment/estimate/:appId',
  NEW_ESTIMATE: '/booking/:appId',
  ESTIMATE_RESPONSE: '/appointment/estimate_response/:appId',
  CONFIRM: '/appointment/confirm/:appId',
  UNSUBSCRIBE: '/unsubscribe',
  REVIEW: '/booking/:appId/review',
  EDIT_APPOINTMENT: '/user/edit_appointment/:appId',
  RESCHEDULE_APPOINTMENT: '/user/reschedule/:appId',
  CANCEL_APPOINTMENT: '/user/cancel/:appId',
  CONFIRMED: '/appointment/confirmed/:appId',
  REVIEW_TEST: '/review',
};

export const MIXPANEL_TRACK = {
  ZIP: 'Zip',
  NOT_SURE_WHATS_WRONG: "Not sure what's wrong",
  NOT_SURE_WHATS_WRONG_CATEGORY: "Not sure what's wrong category",
  NOT_SURE_WHATS_WRONG_TEXTBOX: "Not sure what's wrong textbox",
  NOT_SURE_WHATS_WRONG_NEXT: "Passed not sure what's wrong",
  REPAIR_SERVICE: 'Repair service',
  CAR: 'Car',
  CONFIRM_CAR: 'Confirm car',
  CONTACT_INFO: 'Contact info',
  DIAGNOSIS_ESTIMATE: 'Diagnosis estimate',
  LOADED_REPAIR_ESTIMATE: 'Visited repair estimate',
  LOADED_DIAG_ESTIMATE: 'Visited diag estimate',
  REPAIR_ESTIMATE: 'Repair estimate',
  SCHEDULE_APPOINTMENT: 'Schedule appointment',
  CARD_INFO: 'Card info',
  INSTANT_QUOTE: 'Instant quote service',
  SERVICE: 'Selected Service',
  CALL_US_FROM_QUOTE_PAGE: 'Clicked call us from quote page',
  CLICKED_CALL_US_LINK: 'Clicked call us link',
  WENT_TO_PAY_OPTIONS: 'Selected pay in installments',
  PAY_OPTIONS: 'Passed payment option step',
  REPAIR_ESTIMATE_RESPONSE: 'Passed estimate sent by tech',
  WENT_TO_PAY_OPTIONS_ESTIMATE_RESPONSE:
    'Selected pay in installments estimate response',
  LOADED_ESTIMATE_RESPONSE: 'Loaded repair estimate sent by tech',
  SCHEDULE_APPOINTMENT_BY_TECH:
    'Scheduled appointment from estimate sent by tech',
  SCHEDULE_ESTIMATE_RESPONSE:
    'Selected schedule for later from estimate sent by tech',
  MECHANIC_IS_HERE_NOW: 'Selected mechanic is here now',
  CALL_US_SCHEDULE: 'Call us for earliest availability from schedule',
  CLICKED_PAYPAL: 'Clicked on Pay with PayPal when booking',
  CALL_US_DISCOUNT_CLICKED: 'Call us for discount',
};

export const MIXPANEL_TRACK_FUNNEL_V2 = {
  NF_ZIP: 'New Funnel Zip',
  NF_CAR: 'New Funnel Car',
  NF_SELECTED_SERVICE: 'New Funnel Selected Service',
  NF_CONTACT_INFO: 'New Funnel Contact Info',
  NF_SCHEDULE_APPT: 'New Funnel Schedule Appointment',
  NF_CARD_INFO: 'New Funnel Card Info',
  NF_QUOTE_LOADED: 'New Funnel Quote Loaded',
  NF_DIAG: 'New Funnel Diag Selected',
  NF_REPAIR: 'New Funnel Repair Selected',
  NF_ESTIMATE: 'New Funnel Quote Repair',
  NF_DIAGNOSIS: 'New Funnel Quote Diag',
};

export const MODE_LOGIN = {
  PHONE: 'phone',
  EMAIL: 'email',
};

export const INSTANT_QUOTES = [
  'Replace Battery',
  'Battery Replacement',
  'Replace Starter',
  'Replace Alternator',
  'Alternator Replacement',
  'Front Brake Pad Replacement',
  'Rear Brake Pad Replacement',
  'Front Brake Rotors/Discs Replacement',
  'Rear Brake Rotors/Discs Replacement',
  'Front & Rear Brake Pad Replacement',
  'Front & Rear Brake Rotors/Discs Replacement',
  'Front Brake Pads & Rotors',
  'Oil Change',
  'Engine Oil Change',
  'Rear Brake Pads & Rotors',
  'Alternator / Serpentine Belt Replacement',
  'Serpentine/Drive Belt Replacement',
  'Spark Plug Replacement',
  'Tune Up',
  'Pre-Purchase Inspection',
];

export const EURO_MAKES = [
  'BMW',
  'Bmw',
  'Mercedes Benz',
  'Audi',
  'Volkswagen',
  'Volvo',
  'Jaguar',
  'Land Rover',
];
