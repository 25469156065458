// The "As Featured In Slider"

import React, { ReactElement } from 'react';
import Slider from 'react-slick';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import featuredLogos from '../../../assets/featuredin_logos';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    featuredLogo: {
      // paddingRight: 30,
      // paddingLeft: 30,
    },
    logoBox: {
      // flex: 1,
    },
    featuredSlider: {
      '& .slick-track': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .slick-prev:before, & .slick-next:before': {
        color: '#777',
      },
      '& .slick-prev': {
        left: -25,
        [theme.breakpoints.down(600)]: {
          left: -15,
        },
      },
      '& .slick-next': {
        right: -25,
        [theme.breakpoints.down(600)]: {
          right: -15,
        },
      },
    },
  };
});

function FeaturedSlider(): ReactElement {
  const isSm = useMediaQuery('(max-width: 600px)');
  const classes = useStyles();

  return (
    <Slider
      dots
      infinite
      className={classes.featuredSlider}
      slidesToShow={isSm ? 1 : 3}
      slidesToScroll={isSm ? 1 : 3}
    >
      {Object.keys(featuredLogos).map((key: string) => (
        <div>
          <img
            className={classes.featuredLogo}
            style={{ width: '200px' }}
            src={featuredLogos[key as keyof typeof featuredLogos]}
            alt={key}
          />
        </div>
      ))}
    </Slider>
  );
}

export default FeaturedSlider;
