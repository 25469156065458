import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import './ButtonStyles.css';
import { ResponseSignin } from 'src/types';
import { signInWithGoogle } from 'src/api/auth';
import { useDispatch } from 'react-redux';
import { setAuthToken } from 'src/store/actions';
import { useHistory } from 'react-router-dom';
import { URLS } from 'src/utils/consts';
import { Box, Typography } from '@material-ui/core';

interface GoogleLoginButtonProps {
  setLoggingIn: (arg: boolean) => void;
}

const useStyles = makeStyles({
  root: {
    width: '100% !important',
  },
});

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  setLoggingIn,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loginError, setError] = useState<boolean>(false);

  const handleSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    setLoggingIn(true);

    if ('profileObj' in response) {
      const { email } = response.profileObj;

      if (email) {
        const loginResponse: ResponseSignin = await signInWithGoogle(email);

        if (loginResponse.errors) {
          setError(true);

          setTimeout(() => {
            setError(false);
          }, 4000);
        } else if (
          loginResponse &&
          loginResponse.auth_token &&
          loginResponse.user &&
          loginResponse.user.id
        ) {
          dispatch(
            setAuthToken(
              loginResponse.auth_token,
              loginResponse.user.id,
              loginResponse.user.email
            )
          );

          history.push(URLS.DASHBOARD);
        }
      }

      setLoggingIn(false);
    }
  };

  return (
    <>
      <div className={`${classes.root} google-login-button`}>
        <GoogleLogin
          clientId="304353214312-0s3mi4dh5mn3skfk2t5l3ull0ghld99a.apps.googleusercontent.com"
          onSuccess={handleSuccess}
          cookiePolicy="single_host_origin"
        />
      </div>

      {loginError && (
        <Box style={{ margin: '10px', textAlign: 'center' }}>
          <Typography color="error">
            Error signing in. Please try again.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default GoogleLoginButton;
