import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    maxHeight: '100vh',
  },
  footer: {
    maxWidth: '350px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
      marginTop: '-50px',
      bottom: 0,
      left: 0,
      zIndex: 9999,
      maxWidth: '100%',
    },
    '&> :first-child': {
      border: '1px solid #EDEDED',
      padding: '40px 30px',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      '& > .row': {
        display: 'flex',
        flexDirection: 'row',
        color: '#2E2E2E',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '15px',
        '& strong': {
          color: '#24CF93',
        },
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'auto',
        position: 'sticky',
        bottom: 0,
        zIndex: 99999,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.07)',
      },
    },
  },
  buttonBack: {
    marginRight: theme.spacing(1),
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(4),
    '& .title-icon': {
      color: '#C5C9DE',
    },
  },
  actionContainer: {
    width: '100%',
    textAlign: 'right',
    padding: 0,
    margin: '20px 0',
  },

  titleDatetime: {
    fontWeight: 900,
    fontSize: 19,
    lineHeight: '22.8px',
    marginLeft: theme.spacing(1),
  },
  boxInformation: {
    width: '100%',
    background: '#FFF',
    borderRadius: 16,
    padding: theme.spacing(5),
    boxShadow: '0px 8px 10px 0px #00000029',
    fontFamily: 'Inter',
  },

  creditCardButton: {
    borderRadius: 2,
    textTransform: 'none',
    margin: '10px auto',
    boxShadow: 'none',
    minWidth: '100%',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      height: '35px',
      margin: 'auto',
    },
  },

  textPayment1: {
    fontWeight: 800,
    fontSize: 19,
    lineHeight: '29px',
    color: '#2A2D3C',
    display: 'none',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  textPayment2: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#434446',
    marginBottom: theme.spacing(3),
    '& b': {
      fontWeight: 600,
    },
  },

  intro: {
    display: 'block',
  },

  containerMazda: {
    padding: theme.spacing(2),
    borderRadius: '7px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgMazda: {
    maxHeight: 300,
    objectFit: 'contain',
    color: theme.palette.common.white,
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  titleMazda: {
    color: '#685364',
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: '17px',
    lineHeight: '22px',
  },

  containerWarranty: {
    marginTop: theme.spacing(2),
    background: '#f3f3f4',
    borderRadius: '7px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxHeight: 100,
  },
  imgWarranty: {
    width: 30,
    height: 30,
    objectFit: 'contain',
    color: theme.palette.common.white,
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  titleWarranty: {
    color: '#000',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 17,
    lineHeight: '22px',
  },
  titlePhone: {
    color: '#000',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 17,
    lineHeight: '22px',
    textDecoration: 'none',
  },

  contentHolder: {
    minHeight: '80vh',
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
    alignContent: 'flex-start',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 9,
      padding: theme.spacing(2),
    },
  },

  wrapper: {
    margin: '0 auto',
  },

  boxTitle: {
    font: 'Inter',
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '52px',
    margin: theme.spacing(3, 0, 1, 0),
  },

  reservationButton: {
    borderRadius: 31,
    textTransform: 'none',
    margin: '20px auto',
    boxShadow: 'none',
    width: '100%',
    padding: theme.spacing(2),
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  paymentDivider: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
    '& p': {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '18px',
      color: '#434446',
      margin: theme.spacing(1, 2),
    },
  },
  dividingLine: {
    height: '1px',
    width: '46%',
    background: 'rgba(0,0,0,0.16)',
  },
  appointmentBox: {
    padding: theme.spacing(2),
    borderRadius: 16,
    background: 'rgba(0,0,0,0.04)',
    color: '#000',
    width: '100%',
    fontFamily: 'Inter',
    '& h2': {
      margin: `0 0 ${theme.spacing(2)}px 0`,
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '21px',
    },
  },
  appointmentDetails: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 11,
    '& li': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 11,
    },
  },
  appointmentIcon: {
    width: '24px',
    height: '24px',
  },
  splitText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#434446',
    margin: 0,
    '& b': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#000',
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#000',
    margin: 0,
  },
  paypalWrapper: {
    border: '1px solid #D4D5D5',
    borderRadius: 16,
    padding: theme.spacing(5),
    width: '100%',
    fontFamily: 'Inter',
    '& h2': {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '31px',
      margin: theme.spacing(3, 0, 1, 0),
    },
    '& p': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: 0,
    },
  },
  paypalButton: {
    marginTop: theme.spacing(3),
    borderRadius: '28px',
  },
  callButton: {
    background: 'none',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: theme.spacing(3),
    borderRadius: 16,
    width: '100%',
    '& a': {
      color: '#005959',
      textTransform: 'none',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 11,
      width: '100%',
      padding: theme.spacing(2.375),
    },
  },
  tab1: {
    alignSelf: 'start',
    borderRadius: 8,
    width: '100%',
    color: '#1A5B74',
    fontWeight: 700,
    border: '1px solid var(--blue-50, #227596)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },
    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',

      textTransform: 'none',

      alignItems: 'start',
    },
    fontFamily: 'Inter',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  tab1notSelected: {
    alignSelf: 'start',
    borderRadius: 8,
    fontWeight: 700,
    width: '100%',
    color: '#646867',
    border: '1px solid var(--blue-50, #D4D5D5)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },
    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',

      textTransform: 'none',

      alignItems: 'start',
    },
    fontFamily: 'Inter',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },

  tab2: {
    alignSelf: 'end',
    borderRadius: 8,
    fontWeight: 700,
    fontFamily: 'Inter',
    color: '#1A5B74',
    border: '1px solid var(--blue-50, #227596)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },

    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',
      textTransform: 'none',

      alignItems: 'start',
    },
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  tab2notSelected: {
    alignSelf: 'end',
    borderRadius: 8,
    color: '#646867',
    fontWeight: 700,
    fontFamily: 'Inter',
    border: '1px solid var(--blue-50, #D4D5D5)',
    '& .PrivateTabIndicator-colorPrimary-64': {
      background: '#fff',
    },

    '& .MuiTab-wrapper': {
      textAlign: 'left',
      display: 'flex',
      textTransform: 'none',

      alignItems: 'start',
    },
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
    },
  },
  customTabs: {
    '& .MuiTabs-fixed': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTabs-indicator': {
      display: 'none !important',
    },

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },

  radio: {
    '&$checked': {
      color: '#005959',
    },
  },
  checked: {},
  label: {
    fontFamily: 'Inter',
  },
  cardOnFile: {
    padding: 16,
    background: '#E6EEEE',
    borderRadius: 8,
    border: '1px solid var(--blue-50, #227596)',
    marginTop: 20,
    fontFamily: 'Inter',
    '& .MuiTypography-body1': {
      fontFamily: 'Inter',
    },
  },
}));

export default useStyles;
