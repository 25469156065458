import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ConnectedVehicle } from 'src/types';
import engine from 'src/assets/engine.svg';
import thumbsUpGreen from 'src/assets/thumbsUpGreen.svg';
import thumbGreen from 'src/assets/thumbGreen.svg';
import circleAlert from 'src/assets/circleAlert.svg';
import engineRed from 'src/assets/engineRed.svg';

const styles = {
  container: (hasDiagnosticCodes: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: hasDiagnosticCodes ? '#CE3426CC' : '#157F40CC',
    borderRadius: '16px',
    border: `2px solid ${hasDiagnosticCodes ? '#CE3426' : '#157F40'}`,
    padding: '20px',
    marginTop: '30px',
    rowGap: 2,
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
  },
  statusBox: {
    backgroundColor: '#F4F4F4',
    borderRadius: '120px',
    padding: '12px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
  },
  alertBox: (hasDiagnosticCodes: boolean) => ({
    backgroundColor: hasDiagnosticCodes ? '#FAEBE9' : '#F2F7F7',
    padding: '24px 20px',
    borderRadius: '8px',
    border: `2px solid ${hasDiagnosticCodes ? '#CE3426' : '#99BDBD'}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '8px',
  }),
  alertText: {
    fontSize: '24px',
    lineHeight: '26.4px',
    fontWeight: 600,
    color: '#CE3426',
    textAlign: 'center',
    fontFamily: 'Inter',
  },
  codeBox: {
    backgroundColor: 'white',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #F9F8F8',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  codeDetails: {
    display: 'flex',
    columnGap: '8px',
  },
  ecuText: {
    fontSize: '14px',
    lineHeight: '18.2px',
    color: '#1F2421',
    fontWeight: 600,
    fontFamily: 'Inter',
  },
};

interface EngineDetailsProps {
  selectedCar: ConnectedVehicle;
}

const EngineDetails: React.FC<EngineDetailsProps> = ({ selectedCar }) => {
  const hasDiagnosticCodes = (): boolean =>
    selectedCar.dtcs && Object.keys(selectedCar.dtcs).length > 0;

  return (
    <Box sx={styles.container(hasDiagnosticCodes())}>
      <Box sx={styles.header}>
        <Box sx={styles.titleBox}>
          <img style={{ width: 24, height: 24 }} src={engine} alt="engine" />
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '19.2px',
              fontWeight: 500,
              color: 'white',
              fontFamily: 'Inter',
            }}
          >
            Engine
          </Typography>
        </Box>

        <Box sx={styles.statusBox}>
          <img
            style={{ width: 20, height: 20 }}
            src={hasDiagnosticCodes() ? circleAlert : thumbGreen}
            alt="All good"
          />
          <Typography
            sx={{
              color: hasDiagnosticCodes() ? '#CE3426' : '#157F40',
              fontSize: '14px',
              lineHeight: '18.2',
              fontWeight: 500,
              fontFamily: 'Inter',
              userSelect: 'none',
            }}
          >
            {hasDiagnosticCodes() ? 'Alert' : 'All good'}
          </Typography>
        </Box>
      </Box>

      <Accordion
        disableGutters
        sx={{
          borderRadius: '16px',
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'initial',
          '&:last-of-type': {
            border: 'none',
          },
          '&:first-of-type': {
            border: 'none',
          },
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#D2D3D3' }} />}
          sx={{
            minHeight: 'fit-content',
            margin: '0px',
            padding: '0px',
            '.MuiAccordionSummary-content': {
              margin: '0px',
            },
          }}
        >
          <Typography
            sx={{
              fontSize: '14.5px',
              lineHeight: '19.2px',
              fontWeight: 600,
              color: 'white',
              fontFamily: 'Inter',
            }}
          >
            {hasDiagnosticCodes()
              ? 'Diagnostic Trouble Code detected'
              : 'No Codes found'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0px',
            marginTop: '10px',
          }}
        >
          {hasDiagnosticCodes() ? (
            <Box
              sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}
            >
              <Box sx={styles.alertBox(hasDiagnosticCodes())}>
                <img src={circleAlert} alt="Alert" />
                <Typography sx={styles.alertText}>Alert</Typography>
              </Box>

              <Box sx={styles.codeBox}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '19.2px',
                    fontWeight: 600,
                    color: '#1F2322',
                    fontFamily: 'Inter',
                  }}
                >
                  Diagnostic Trouble Code detected
                </Typography>

                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '15.6px',
                    color: '#797C7A',
                    fontFamily: 'Inter',
                  }}
                >
                  {Object.keys(selectedCar.dtcs).length} Codes found
                </Typography>
              </Box>

              {Object.entries(selectedCar.dtcs).map(([key, value]) => (
                <Box
                  sx={{
                    backgroundColor: 'white',
                    padding: '16px',
                    borderRadius: '8px',
                    border: '1px solid #ECEAEA',
                    display: 'flex',
                  }}
                  key={key}
                >
                  <Box sx={styles.codeDetails}>
                    <Avatar
                      sx={{
                        backgroundColor: '#FAEBE9',
                        width: '48px',
                        height: '48px',
                        border: '2px solid #CE342633',
                      }}
                    >
                      <img
                        src={engineRed}
                        alt="Alert"
                        style={{ width: '20px', height: '20px' }}
                      />
                    </Avatar>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '4px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            lineHeight: '15.6px',
                            fontWeight: 500,
                            color: '#797C7A',
                            fontFamily: 'Inter',
                          }}
                        >
                          {key}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            lineHeight: '15.6px',
                            fontWeight: 500,
                            color: '#797C7A',
                            width: '60px',
                            textAlign: 'right',
                            fontFamily: 'Inter',
                          }}
                        >
                          Sep 18
                        </Typography>
                      </Box>

                      <Typography sx={styles.ecuText}>{value.ecu}</Typography>

                      <Typography
                        sx={{
                          fontSize: '12px',
                          lineHeight: '15.6px',
                          fontWeight: 500,
                          color: '#797C7A',
                          fontFamily: 'Inter',
                        }}
                      >
                        {value.desc}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box sx={styles.alertBox(hasDiagnosticCodes())}>
              <img src={thumbsUpGreen} alt="All good" />
              <Typography
                sx={{
                  fontSize: '24px',
                  lineHeight: '26.4px',
                  fontWeight: 600,
                  color: '#005959',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                }}
              >
                All good
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default EngineDetails;
