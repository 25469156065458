import { useEffect, useMemo, useState } from 'react';
import { getVehicles } from 'src/api/quote';
import { allMileage } from 'src/utils/data';
import { IVehicle } from 'src/types';

export const PHONE_NUMBER_LENGTH = 10;

interface IContactFormProps {
  name: string;
  email: string;
  phone: string;
  zip: string;
  year: string;
  make: string;
  model: string;
  engine: string;
  mileage: string;
  services: string;
  services_brakes: string;
  diagnosis_input: string;
}

// create a type for the return value of the useForm function
type UseFormReturn = {
  fields: IContactFormProps;
  handleChange: (name: keyof IContactFormProps, value: string) => void;
  isValid: boolean;
  options: {
    years: { [key: string]: string };
    makes: { [key: string]: string };
    models: { [key: string]: string };
    engines: { [key: string]: string };
    mileages: { [key: string]: string };
  };
};

// copied from other FormContact.tsx, possibly refactor later
const validateEmail = (em: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(em).toLowerCase());
};

const OLDEST_YEAR = 1990; // oldest year car FMC services
const currentYear = new Date().getFullYear() + 1;
const allYears = Array(currentYear - OLDEST_YEAR + 1)
  .fill(0)
  .reduce((obj, x, index) => {
    return {
      ...obj,
      [currentYear - index]: currentYear - index,
    };
  }, {});

const useForm = (): UseFormReturn => {
  const [vehicleList, setVehicleList] = useState<[IVehicle]>();
  const [fields, setFields] = useState<IContactFormProps>({
    name: '',
    email: '',
    phone: '',
    year: '',
    zip: '',
    make: '',
    model: '',
    engine: '',
    mileage: '',
    services: '',
    services_brakes: '',
    diagnosis_input: '',
  });
  // is the form data valid
  const [isValid, setValid] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(async () => {
      const resp = await getVehicles(fields.year, undefined, undefined);
      if (resp && resp.data) setVehicleList(resp.data);
    });
  }, [fields.year]);

  const allMakes =
    (vehicleList &&
      vehicleList.reduce((obj, x) => {
        return fields.year === x.attributes.year
          ? {
              ...obj,
              [x.attributes.make]: x.attributes.make,
            }
          : obj;
      }, {})) ||
    {};

  const allModels =
    (vehicleList &&
      vehicleList.reduce((obj, x) => {
        return fields.year === x.attributes.year &&
          fields.make === x.attributes.make
          ? {
              ...obj,
              [x.attributes.model]: x.attributes.model,
            }
          : obj;
      }, {})) ||
    {};

  const allMotors = useMemo(
    () =>
      (vehicleList &&
        vehicleList.reduce((obj, x) => {
          return fields.year === x.attributes.year &&
            fields.make === x.attributes.make &&
            fields.model === x.attributes.model
            ? {
                ...obj,
                [x.attributes.engine_size]: x.attributes.engine_size,
              }
            : obj;
        }, {})) ||
      {},
    [fields, vehicleList]
  );

  const allMileages =
    (allMileage &&
      allMileage.reduce((obj, x) => {
        return {
          ...obj,
          [x]: x,
        };
      }, {})) ||
    {};

  const handleChange = (name: keyof IContactFormProps, value: string) => {
    const newFields = { ...fields };
    newFields[name] = value;

    if (name === 'year') {
      newFields.make = '';
      newFields.model = '';
      newFields.engine = '';
    } else if (name === 'make') {
      newFields.model = '';
      newFields.engine = '';
    } else if (name === 'model') {
      newFields.engine = '';
    }
    setFields(newFields);

    // do form validation here
    // check if any field in fields object is empty
    // if no fields are empty, the form is valid
    const fieldNames = Object.keys(newFields) as Array<keyof IContactFormProps>;
    const anyFieldEmpty: boolean = fieldNames.some(
      (key) => newFields[key] === ''
    );

    if (anyFieldEmpty) {
      setValid(false);
      return;
    }

    if (name === 'email') {
      setValid(validateEmail(value));
    } else if (name === 'phone') {
      setValid(value.length === PHONE_NUMBER_LENGTH);
    } else {
      // for every other field, being non empty is enough
      setValid(true);
    }
  };

  return {
    fields,
    handleChange,
    isValid,
    options: {
      years: allYears,
      makes: allMakes,
      models: allModels,
      engines: allMotors,
      mileages: allMileages,
    },
  };
};

export default useForm;
