import React, { ReactElement, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import mixPanel from 'src/utils/mixpanel';
import {
  Box,
  Typography,
  Container,
  Button,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Radio,
  Divider,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import { ButtonForward } from 'src/components/atoms';
import { QuoteContext } from 'src/views/Quote/QuoteContext';
import { Layout } from 'src/layouts';
import { IAppointment, QuoteShowModal } from 'src/types';
import useStyles from './styles';
import fontStyles from '../../fontStyles';

interface ModalScheduleServiceProps {
  show: boolean;
  onClose: () => void;
}

const ModalJoinMembership = (
  props: ModalScheduleServiceProps
): ReactElement => {
  const { show, onClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [selection, setSelection] = useState<string | undefined>();

  const fontClasses = fontStyles();

  const {
    membershipSelected,
    setMembershipSelected,
    requestInProgress,
    handleShowModal,
  } = useContext(QuoteContext);

  const appointment: IAppointment | null = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const estimate = useSelector(
    (state: IReduxState) => state.quote.appointment?.attributes.estimate
  );

  if (!appointment || !appointment?.attributes || !appointment?.id) {
    return <></>;
  }

  const { attributes } = appointment;

  const { appointment_type: appointmentType, diagnosis_price: diagFee } =
    attributes;

  const isDiag: boolean = appointmentType === 'diagnosis';

  const getPrice = (nonMember = false) => {
    let price = 0;

    if (estimate) {
      if (nonMember) {
        price += estimate.total_price;
      } else {
        price += estimate.total_member_price;
      }
    }

    if (isDiag) price += diagFee;

    return price;
  };

  const discountPrice = () => {
    return (getPrice() * 0.9).toFixed(0);
  };

  const totalMemberPrice = () => {
    const price = getPrice() * 0.9;

    return selection === '$162' ? price + 162 : price + 90;
  };

  const handleSelection = (val: string) => {
    mixPanel('Member page passed');
    if (!membershipSelected || membershipSelected !== val) {
      setSelection(val);
      setMembershipSelected(val);
      mixPanel('Member page passed with membership selected');
    } else {
      mixPanel('Member page passed with no membership');
      setSelection(undefined);
      setMembershipSelected();
    }
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      className={classes.root}
      fullScreen
    >
      <Layout className={isSm ? classes.layout : ''}>
        <div className={classes.content}>
          <Container>
            <Box key="pick-date-time-title" flexDirection="row" display="flex">
              <h1
                className={isSm ? fontClasses.h3 : fontClasses.h1}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Join for Savings
              </h1>
            </Box>
            <Box className={classes.boxDateTime}>
              <Typography
                variant="h5"
                style={{ fontWeight: 'bold', fontFamily: 'Inter' }}
              >
                For just $15/month, you get:
              </Typography>

              <div className={classes.containerForIcon}>
                <CheckCircle color="primary" className={classes.icon} />
                <Typography style={{ fontSize: '20px', fontFamily: 'Inter' }}>
                  Free unlimited oil changes
                </Typography>
              </div>
              <div className={classes.containerForIcon}>
                <CheckCircle color="primary" className={classes.icon} />
                <Typography style={{ fontSize: '20px', fontFamily: 'Inter' }}>
                  10% off on all services
                </Typography>
              </div>
              <div className={classes.containerForIcon}>
                <CheckCircle color="primary" className={classes.icon} />
                <Typography style={{ fontSize: '20px', fontFamily: 'Inter' }}>
                  Optional device for remote vehicle diagnostics
                </Typography>
              </div>
            </Box>
            {selection && (
              <Box className={classes.boxDateTime}>
                <Box
                  style={{ display: 'flex', padding: '15px', margin: '10px 0' }}
                >
                  <Typography style={{ fontFamily: 'Inter' }}>
                    Membership Fee
                  </Typography>
                  <Typography
                    style={{ marginLeft: 'auto', fontFamily: 'Inter' }}
                  >
                    {selection}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    padding: '15px',
                    margin: '10px 0 0px 0',
                  }}
                >
                  <Typography style={{ fontFamily: 'Inter' }}>
                    Member Price
                  </Typography>
                  <Typography
                    style={{ marginLeft: 'auto', fontFamily: 'Inter' }}
                  >
                    <span style={{ textDecoration: 'line-through' }}>
                      ${getPrice(true)}
                    </span>{' '}
                    ${discountPrice()}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    marginBottom: '20px',
                    paddingRight: '15px',
                  }}
                >
                  <Typography />
                  <Typography
                    style={{ marginLeft: 'auto', fontFamily: 'Inter' }}
                  >
                    <FormHelperText>
                      {getPrice() === 0
                        ? 'FREE oil change applied'
                        : '10% discount applied'}
                    </FormHelperText>
                  </Typography>
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    padding: '15px',
                    margin: '10px 0',
                    fontWeight: 'bold',
                  }}
                >
                  <Typography style={{ fontFamily: 'Inter' }}>
                    Total Due at Service
                  </Typography>
                  <Typography
                    style={{ marginLeft: 'auto', fontFamily: 'Inter' }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      ${totalMemberPrice()}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
          </Container>
          <div className={classes.footer}>
            <div>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                className={classes.actionContainer}
              >
                <ButtonForward
                  title="Back"
                  key="Back"
                  color="default"
                  rounded
                  noIcon
                  transparent
                  size="large"
                  onClickHandler={() => {
                    handleShowModal(QuoteShowModal.REVIEW_QUOTE);
                  }}
                  className={classes.buttonBack}
                />

                <ButtonForward
                  title="Continue"
                  key="Continue"
                  rounded
                  size="large"
                  onClickHandler={() =>
                    handleShowModal(QuoteShowModal.SCHEDULE_SERVICE)
                  }
                  disabled={requestInProgress}
                  processing={requestInProgress}
                />
              </Grid>
            </div>
          </div>
        </div>
      </Layout>
    </Dialog>
  );
};

ModalJoinMembership.defaultProps = {};

export default ModalJoinMembership;
