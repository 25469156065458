import React, { ReactElement, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Topbar, Sidebar } from '../components';

interface DashboardUCProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
  },
}));

const DashboardUC = (props: DashboardUCProps): ReactElement => {
  const { children } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div className={classes.root}>
      <Topbar
        showMenu
        showServiceWarranty={false}
        onSidebarOpen={handleSidebarOpen}
        unClickable
        isFleet
      />
      <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" />
      <main>{children}</main>
    </div>
  );
};

DashboardUC.defaultProps = {
  children: undefined,
};

export default DashboardUC;
