import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardElement } from '@stripe/react-stripe-js';

interface CheckOutFormProps {
  errors: string | undefined | null;
  message?: string | null;
}

const useStyles = makeStyles((theme) => ({
  cardInput: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    borderRadius: 8,
    border: '2px solid #A5A7A7',
  },
  error: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: 16,
    color: '#a9a35c',
  },
  message: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: 16,
  },
}));

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontSmoothing: 'auto',
      padding: '30px',
      fontSize: '14px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CheckoutForm = ({
  errors,
  message = null,
}: CheckOutFormProps): ReactElement => {
  const classes = useStyles();

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ height: 'max-content', overflow: 'hidden' }}
      >
        <CardElement options={cardStyle} className={classes.cardInput} />
        <p className={classes.error}>{errors}</p>
        {message && <p className={classes.message}>{message}</p>}
      </form>
    </>
  );
};

CheckoutForm.defaultProps = {
  message: null,
};

export default CheckoutForm;
