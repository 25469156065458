import React from 'react';
import {
  Card,
  Typography,
  makeStyles,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import diagnoses from 'src/assets/Diagnoses.png';

import {
  CheckCircle,
  DirectionsCarOutlined,
  BuildOutlined,
  Schedule,
  CalendarToday,
  LocationOn,
  ArrowForward,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';

const useStyles = makeStyles((theme) => ({
  Diagnosescard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
    background: '#fff',
  },
  clock: {
    marginTop: 30,
    width: 60,
    height: 60,
    flexShrink: 0,
    color: '#5B7B28',
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  li: {
    color: '#005959',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#fff',
  },
  whiteCard: {
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    flex: 1,
  },
  mobileRoot: {
    width: '100%',
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },
}));

const SuccessStepAppointment: React.FC = () => {
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );
  const classes = useStyles();
  const isSm = useMediaQuery('(max-width: 1229px)');

  const formatDate = (dateStr?: string): string => {
    if (!dateStr) {
      return 'Invalid date format';
    }

    try {
      const [year, month, day] = dateStr.split('-');
      const dateObject = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10)
      );
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const formattedDate = dateObject.toLocaleDateString('en-US', options);
      return formattedDate;
    } catch (error) {
      return 'Invalid date format';
    }
  };

  const handleEnd = () => {
    window.location.reload();
  };

  return (
    <Card
      className={classes.Diagnosescard}
      style={{
        marginTop: 50,
        background: '#F9F9F9',
        width: '95%',
        margin: 'auto',
      }}
      elevation={0}
    >
      <CheckCircle className={classes.clock} />

      <Typography
        style={{
          marginTop: 30,
          fontSize: 24,
          fontFamily: 'Inter',
          fontWeight: 700,
        }}
      >
        All Set
      </Typography>
      <Card
        style={{
          width: '95%',
          marginTop: 20,
          background: '#fff',
          padding: 20,
          boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
        }}
        elevation={0}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DirectionsCarOutlined
            style={{ color: '#646867', margin: 15, marginTop: 0 }}
          />
          <Typography
            style={{
              marginTop: 5,
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: 700,
            }}
          >
            {appointment?.attributes?.car.year}{' '}
            {appointment?.attributes?.car.make}{' '}
            {appointment?.attributes?.car.model}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {appointment?.attributes?.appointment_type === 'repair' ? (
            <BuildOutlined
              style={{ color: '#646867', margin: 15, marginTop: 0 }}
            />
          ) : (
            <img
              src={diagnoses}
              alt="Applied!"
              style={{
                width: 25,
                height: 25,
                margin: 15,
                marginTop: 0,
              }}
            />
          )}
          {appointment?.attributes?.appointment_type === 'repair' ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                Service / Repair
              </Typography>
              <ul
                style={{
                  marginTop: 10,
                  color: '#646867',
                  fontFamily: 'Inter',
                  paddingLeft: 0,
                  marginLeft: 18,
                  fontSize: 16,
                }}
              >
                {appointment?.attributes?.services.map((problem, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>{problem}</li>
                ))}
              </ul>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                {' '}
                Diagnosis
              </Typography>

              <Typography
                style={{
                  marginTop: 10,
                  color: '#646867',
                  fontFamily: 'Inter',
                  paddingLeft: 0,
                  marginBottom: 10,
                  fontSize: 16,
                }}
              >
                {appointment?.attributes?.diagnosis_input}
              </Typography>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <CalendarToday
            style={{ color: '#646867', margin: 15, marginTop: 0 }}
          />
          <Typography
            style={{
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: 700,
            }}
          >
            {formatDate(appointment?.attributes?.appointment_day)}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Schedule style={{ color: '#646867', margin: 15, marginTop: 0 }} />
          <Typography
            style={{
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: 700,
            }}
          >
            {appointment?.attributes?.appointment_time}
          </Typography>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <LocationOn style={{ color: '#646867', margin: 15, marginTop: 0 }} />
          <Typography
            style={{
              fontSize: 16,
              fontFamily: 'Inter',
              fontWeight: 700,
            }}
          >
            {appointment?.attributes?.exact_address}
          </Typography>
        </div>
      </Card>

      <Button
        style={{
          background: '#005959',
          marginTop: '35px',
          marginLeft: '2px',
        }}
        className={classes.buttonForward}
        variant="contained"
        size="large"
        onClick={handleEnd}
      >
        Continue &nbsp;
        <ArrowForward />
      </Button>
    </Card>
  );
};

export default SuccessStepAppointment;
