import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { ResponseAvailability } from 'src/types';
import { checkAvailability, rescheduleAppointment } from 'src/api/quote';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import MDatePicker from 'src/components/atoms/DatePicker';
import clsx from 'clsx';
import { ButtonForward } from 'src/components/atoms';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    minHeight: '100vh',
    padding: '20px',
    '& h4': {
      marginBottom: '20px',
    },
  },
  timePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '100%',
    maxHeight: 320,
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  itemTimeSlot: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    borderRadius: 6,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: '#7E7A92',

    background: theme.palette.common.white,

    '&.selected': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

const RescheduleAppointment = (): ReactElement => {
  const classes = useStyles();
  const { appId }: { appId: string } = useParams();

  const [availability, setAvailability] = useState<{ [dt: string]: string[] }>(
    {}
  );
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    moment()
  );

  const [success, setSuccess] = useState<boolean | undefined>();

  const selectedDateString =
    (selectedDate && selectedDate.format('YYYY-MM-DD')) || '';

  const timeSlotsForSelectedDay =
    (availability && availability[selectedDateString]) || [];

  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(0);

  const isReadyToSchedule =
    timeSlotsForSelectedDay && timeSlotsForSelectedDay[selectedTimeSlotIndex];

  const [isRequesting, setIsRequesting] = useState(false);

  const isDateDisabled = (day: MaterialUiPickersDate): boolean => {
    return (
      ((availability && availability[day?.format('YYYY-MM-DD') || '']) || [])
        .length <= 0
    );
  };

  useEffect(() => {
    async function getAvailability() {
      if (!appId) {
        return;
      }
      const resp: ResponseAvailability = await checkAvailability(Number(appId));
      if (!resp || !resp.data) {
        return;
      }
      setAvailability(resp.data.attributes.availability);
      const availabilityEntries = Object.entries(
        resp.data.attributes.availability
      );
      if (availabilityEntries.length > 0 && availabilityEntries[0].length > 0) {
        setSelectedDate(moment(availabilityEntries[0][0]));
      }
    }
    getAvailability();
  }, [appId]);

  const handleReschedule = () => {
    setIsRequesting(true);

    if (isReadyToSchedule) {
      rescheduleAppointment(Number(appId), {
        appointment: {
          appointment_day: selectedDateString,
          appointment_time: timeSlotsForSelectedDay[selectedTimeSlotIndex],
        },
      })
        .then(() => setSuccess(true))
        .finally(() => setIsRequesting(false));
    }
  };

  return (
    <Card className={classes.root}>
      <div>
        <Typography variant="h4" color="primary" style={{ fontWeight: 'bold' }}>
          Reschedule
        </Typography>

        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <MDatePicker
            date={selectedDate}
            shouldDisableDate={isDateDisabled}
            onChangeDate={(d: MaterialUiPickersDate) => {
              setSelectedDate(d);
            }}
          />

          <div className={classes.timePickerContainer}>
            {timeSlotsForSelectedDay.length > 0 ? (
              timeSlotsForSelectedDay.map((tm, index) => (
                <Box
                  key={`tm-${tm}`}
                  onClick={() => setSelectedTimeSlotIndex(index)}
                  className={clsx(classes.itemTimeSlot, {
                    selected: selectedTimeSlotIndex === index,
                  })}
                >
                  {tm.replaceAll(':00', '')}
                </Box>
              ))
            ) : (
              <Box className="icon-label-start">
                <Typography>No times available</Typography>
              </Box>
            )}
          </div>
        </div>

        <Box style={{ margin: '20px 0', textAlign: 'center' }}>
          <ButtonForward
            onClickHandler={handleReschedule}
            title="Reschedule"
            disabled={!isReadyToSchedule || isRequesting}
            size="large"
          />

          {success && (
            <Typography style={{ margin: '10px' }}>
              Appointment rescheduled. You&apos;ll receive a confirmation text
              shortly.
            </Typography>
          )}
        </Box>
      </div>
    </Card>
  );
};

export default RescheduleAppointment;
