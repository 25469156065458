import React, { ReactElement, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Close } from '@material-ui/icons';

import { ButtonForward } from 'src/components/atoms';
import { QuoteContext } from 'src/views/Quote/QuoteContext';

import ModalReasonOther from './ModalReasonOther';

interface ModalNotSureProps {
  show: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '5px 0',
    textAlign: 'left',
    padding: theme.spacing(3),
  },
  titleText: {
    color: '#2A2D3C',
    fontSize: 23,
    lineHeight: '24px',
    fontWeight: 900,
    marginTop: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonGroupBack: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    cursor: 'pointer',

    [theme.breakpoints.down('xs')]: {
      '& .title-button': {
        display: 'none',
      },
    },
  },

  reasonTitle: {
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 600,
    color: '#79739C',
  },

  actionContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },

  inspectContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  inspectContent: {
    marginLeft: theme.spacing(2),
    fontSize: 16,
    lineHeight: '28px',
    color: '#7E7A92',
    fontWeight: 400,
    textAlign: 'left',
    display: 'flex',
  },
}));

const ModalNotSure = (props: ModalNotSureProps): ReactElement => {
  const { show, onClose, onContinue } = props;
  const classes = useStyles();

  const { reason, handleSetReason } = useContext(QuoteContext);

  const handleOtherReasonChange = (newOtherReason: string) => {
    handleSetReason({
      ...reason,
      otherReason: newOtherReason,
      note: '',
    });
  };

  const handleContinue = () => {
    onContinue();
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
    >
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>
          Not sure what&apos;s wrong?
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <ModalReasonOther
        otherReason={reason.otherReason}
        onOtherReasonChange={handleOtherReasonChange}
      />

      <DialogActions className={classes.actionContainer}>
        <ButtonForward
          key="button-continue"
          title="Continue"
          size="large"
          rounded
          onClickHandler={handleContinue}
          disabled={reason.otherReason.length < 1}
        />
      </DialogActions>
    </Dialog>
  );
};

ModalNotSure.defaultProps = {};

export default ModalNotSure;
