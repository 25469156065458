import React, { useContext, useState } from 'react';

import { Card, Typography, useMediaQuery } from '@material-ui/core';
import {
  BuildOutlined,
  CalendarToday,
  DirectionsCarOutlined,
  LocationOn,
  Schedule,
} from '@material-ui/icons';
import diagnoses from 'src/assets/Diagnoses.png';

import { IReduxState } from 'src/store/reducers';
import { useSelector } from 'react-redux';

import { QuoteConfirmedContext } from '../steps/Context/QuoteConfirmedContext';

const InfoCard: React.FC = () => {
  const { QuoteConfirmed, setQuoteConfirmed } = useContext(
    QuoteConfirmedContext
  );

  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );
  const formatDate = (dateStr?: string): string => {
    if (!dateStr) {
      return '';
    }

    try {
      const [year, month, day] = dateStr.split('-');
      const dateObject = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10)
      );
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const formattedDate = dateObject.toLocaleDateString('en-US', options);
      return formattedDate;
    } catch (error) {
      return '';
    }
  };
  function formatApiDate(dateStr: string | undefined): string {
    if (!dateStr) {
      return 'Invalid date format';
    }
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const date = new Date(`${dateStr}T00:00:00Z`);
    const dayOfMonth = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();

    const getDayOfMonthWithSuffix = (day: number): string => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    return `${getDayOfMonthWithSuffix(dayOfMonth)} ${
      months[monthIndex]
    }, ${year}`;
  }

  const carYear =
    QuoteConfirmed.car.year || appointment?.attributes.car.year || '';
  const carMake =
    QuoteConfirmed.car.make || appointment?.attributes.car.make || '';
  const carModel =
    QuoteConfirmed.car.model || appointment?.attributes.car.model || '';
  const carVin =
    QuoteConfirmed.car.vin || appointment?.attributes.car.vin || '';
  const carNickname = appointment?.attributes.car.name || '';
  const selectedDay =
    formatDate(QuoteConfirmed.appointment.selected_day) ||
    formatApiDate(appointment?.attributes.appointment_day) ||
    '';
  const selectedTime =
    QuoteConfirmed.appointment.selected_window ||
    appointment?.attributes.appointment_time ||
    '';
  const selectedAddress =
    appointment?.attributes.exact_address ||
    QuoteConfirmed.appointment.exact_address ||
    '';

  return (
    <Card
      style={{
        width: '100%',
        background: '#fff',
        marginTop: 10,
        borderRadius: '8px',
        padding: 20,
      }}
      elevation={0}
    >
      {carYear !== '' && carMake !== '' && carModel !== '' && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DirectionsCarOutlined
            style={{ color: '#646867', margin: 15, marginTop: 0 }}
          />
          <Typography
            style={{
              marginTop: 5,
              fontSize: 14,
              fontFamily: 'Inter',
              fontWeight: 500,
            }}
          >
            {carYear} {carMake} {carModel}
          </Typography>
        </div>
      )}

      {(carVin !== '' || carNickname !== '') && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DirectionsCarOutlined
            style={{ color: '#646867', margin: 15, marginTop: 0 }}
          />
          <Typography
            style={{
              marginTop: 5,
              fontSize: 14,
              fontFamily: 'Inter',
              fontWeight: 500,
            }}
          >
            {carVin} {carNickname !== '' && `(${carNickname})`}
          </Typography>
        </div>
      )}

      {appointment && appointment?.attributes.estimate ? (
        <div style={{ display: 'flex' }}>
          {appointment.attributes.appointment_type === 'repair' ? (
            <div style={{ display: 'flex' }}>
              <BuildOutlined
                style={{ color: '#646867', margin: 15, marginTop: 0 }}
              />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <img
                src={diagnoses}
                alt="Applied!"
                style={{
                  width: 25,
                  height: 25,
                  margin: 15,
                  marginTop: 0,
                }}
              />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {appointment.attributes.appointment_type !== 'repair' ? (
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                }}
              >
                {' '}
                Diagnosis
              </Typography>
            ) : (
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                }}
              >
                Service / Repair
              </Typography>
            )}
            <ul
              style={{
                marginTop: 10,
                color: '#646867',
                fontFamily: 'Inter',
                paddingLeft: 0,
                marginLeft: 18,
                fontSize: 14,
              }}
            >
              {Object.keys(appointment.attributes.estimate.services).map(
                (service, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>{service}</li>
                )
              )}
            </ul>
          </div>
        </div>
      ) : (
        QuoteConfirmed.service.service_type !== '' && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {QuoteConfirmed.service.service_type === 'repair' ? (
              <BuildOutlined
                style={{ color: '#646867', margin: 15, marginTop: 0 }}
              />
            ) : (
              <img
                src={diagnoses}
                alt="Applied!"
                style={{
                  width: 25,
                  height: 25,
                  margin: 15,
                  marginTop: 0,
                }}
              />
            )}
            {QuoteConfirmed.service.service_type === 'repair' ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                  }}
                >
                  Service / Repair
                </Typography>
                <ul
                  style={{
                    marginTop: 10,
                    color: '#646867',
                    fontFamily: 'Inter',
                    paddingLeft: 0,
                    marginLeft: 18,
                    fontSize: 14,
                  }}
                >
                  {QuoteConfirmed.service.repair_problems.map(
                    (problem, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{problem}</li>
                    )
                  )}
                </ul>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                  }}
                >
                  {' '}
                  Diagnosis
                </Typography>
                <ul
                  style={{
                    marginTop: 10,
                    color: '#646867',
                    fontFamily: 'Inter',
                    paddingLeft: 0,
                    marginLeft: 18,
                    fontSize: 14,
                  }}
                >
                  {QuoteConfirmed.service.diagnostic_problems.map(
                    (problem, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{problem}</li>
                    )
                  )}
                </ul>
              </div>
            )}
          </div>
        )
      )}
      {selectedDay !== '' && selectedTime !== '' && (
        <>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CalendarToday
              style={{ color: '#646867', margin: 15, marginTop: 0 }}
            />
            <Typography
              style={{
                fontSize: 14,
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
            >
              {selectedDay}
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Schedule style={{ color: '#646867', margin: 15, marginTop: 0 }} />
            <Typography
              style={{
                fontSize: 14,
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
            >
              {selectedTime}
            </Typography>
          </div>
        </>
      )}

      {selectedAddress !== '' && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <LocationOn style={{ color: '#646867', margin: 15, marginTop: 0 }} />
          <Typography
            style={{
              fontSize: 14,
              fontFamily: 'Inter',
              fontWeight: 500,
            }}
          >
            {selectedAddress}
          </Typography>
        </div>
      )}
    </Card>
  );
};

export default InfoCard;
