import React, { ReactElement, useState, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
  Card,
  FormControl,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { ButtonForward } from 'src/components/atoms';
import {
  cancelAppointment,
  getAppointment,
  cancellationDiscount,
} from 'src/api/quote';
import LineWithOr from '../Login/components/LineWithOr';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    background: '#eeeeee',
    height: '93vh',
  },
  card: {
    maxWidth: '700px',
    margin: '10px',
    background: '#ffffff',
    padding: '25px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: '15px',
    },
  },
  select: {
    '& .MuiOutlinedInput-input': {
      border: 'none',
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Inter',
    },
  },
}));

const CancelAppointment = (): ReactElement => {
  const classes = useStyles();
  const [cancelReason, setCancelReason] = useState<string>('');
  const [cancelReasonSelect, setCancelReasonSelect] = useState<string>('');
  const { appId }: { appId: string } = useParams();
  const [apptExists, setApptExists] = useState(true);
  const [isCancelled, setIsCancelled] = useState(false);
  const [appointmentTime, setAppointmentTime] = useState('');
  const [discountUsed, setDiscountUsed] = useState(false);
  const [requestingDiscount, setRequestingDiscount] = useState(false);

  const handleDiscountButtonClick = () => {
    setRequestingDiscount(true);

    cancellationDiscount(parseInt(appId, 10))
      .then(() => {
        setDiscountUsed(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => setRequestingDiscount(false));
  };

  useEffect(() => {
    if (!appId) {
      return;
    }
    getAppointment(appId)
      .then((response) => {
        if (response && response.data) {
          const { attributes } = response.data;

          if (attributes.appointment_day && attributes.appointment_time) {
            setAppointmentTime(
              `${attributes.appointment_day} with an arrival time between ${attributes.appointment_time}`
            );
          }
          setIsCancelled(attributes.status === 'cancelled');
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setApptExists(false);
        }
      });
  }, [appId]);

  const handleSubmit = () => {
    cancelAppointment(appId, cancelReason, cancelReasonSelect).then(() => {
      setIsCancelled(true);
    });
  };

  if (!apptExists) {
    return <Redirect to="/" />;
  }

  if (isCancelled) {
    return (
      <div className={classes.root}>
        <div className={classes.card}>
          <Typography
            variant="h4"
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            Cancel Your Appointment
          </Typography>
          <Typography>Your appointment has been cancelled.</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Typography
          variant="h4"
          style={{ fontWeight: 'bold', fontFamily: 'Inter' }}
        >
          Cancel
        </Typography>
        <Typography style={{ fontFamily: 'Inter' }}>
          Would you mind telling us a bit about why you decided to cancel?
          <hr
            style={{ border: '0.2px solid rgba(0,0,0,0.14)', height: '0.2px' }}
          />
        </Typography>

        <FormControl fullWidth style={{ margin: '10px 0' }}>
          <Typography
            style={{
              zIndex: 999,
              color: '#000',
              fontFamily: 'Inter',
              marginBottom: 10,
            }}
            id="select-label"
          >
            Tell us more
          </Typography>
          <Select
            id="select-label"
            value={cancelReasonSelect}
            fullWidth
            variant="outlined"
            className={classes.select}
            style={{ padding: '5px', background: '#fff', color: '#000' }}
            onChange={(event) =>
              setCancelReasonSelect(event.target.value as string)
            }
          >
            {[
              'Found service sooner',
              'Found cheaper price',
              'Car already fixed',
              'Not in my budget right now',
              'I have an emergency',
              'Other',
            ].map((item: string) => (
              <MenuItem key={item} value={item} className={classes.select}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {cancelReasonSelect &&
          cancelReasonSelect !== 'Not in my budget right now' && (
            <TextField
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              placeholder="Provide more details (optional)"
              multiline
              rows={3}
            />
          )}
        {cancelReasonSelect === 'Not in my budget right now' &&
          !discountUsed && (
            <>
              <Typography style={{ margin: '10px 0' }}>
                No problem, would you like to take a $30 discount and keep your
                appointment?
              </Typography>
              <ButtonForward
                noIcon
                title="Claim Discount"
                size="large"
                onClickHandler={handleDiscountButtonClick}
                disabled={!cancelReasonSelect || requestingDiscount}
              />
              <br />
              <LineWithOr />
            </>
          )}

        {discountUsed ? (
          <Card
            style={{
              background: '#EDF5E0',
              padding: 20,
              justifyContent: 'center',
              display: 'flex',
              margin: '10px 0',
            }}
          >
            <Typography style={{ fontFamily: 'Inter' }}>
              Great! You&apos;ve claimed a $30 discount and your appointment is
              still on for {appointmentTime}.
            </Typography>
          </Card>
        ) : (
          <ButtonForward
            noIcon
            color={
              cancelReasonSelect === 'Not in my budget right now'
                ? 'inherit'
                : 'primary'
            }
            title="Cancel Appointment"
            size="large"
            onClickHandler={handleSubmit}
            disabled={!cancelReasonSelect}
          />
        )}

        <Typography
          variant="body2"
          style={{ color: '#6e6e6e', margin: '10px 0' }}
        >
          If your cancellation request is within 48 hours of your appointment
          time ({appointmentTime}), you will be charged a $70 cancellation fee.
          Please consider rescheduling by clicking{' '}
          <Link href={`/user/reschedule/${appId}`} underline="always">
            here
          </Link>
          .
        </Typography>
      </div>
    </div>
  );
};

export default CancelAppointment;
