import React, { useContext, useEffect } from 'react';
import {
  Card,
  Typography,
  makeStyles,
  useMediaQuery,
  Box,
  Link,
} from '@material-ui/core';
import { Image } from 'src/components/atoms';
import AppleDownload from 'src/assets/iOS.svg';
import GoogleDownload from 'src/assets/play-badge.svg';
import SuccessImage from 'src/assets/SuccessPage.png';
import checkCircle from 'src/assets/check_circle.png';
import mechanic from 'src/assets/payment/mechanic.svg';
import calendar from 'src/assets/payment/calendar.svg';
import location from 'src/assets/payment/pin.svg';
import clock from 'src/assets/payment/clock.svg';
import Tool from 'src/assets/payment/Tool.svg';
import coin from 'src/assets/payment/coin.svg';
import { QRCodeSVG } from 'qrcode.react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import { getAppointment } from 'src/api/quote';
import { useParams } from 'react-router-dom';
import { setAppointment } from 'src/store/actions';
import { QuoteConfirmedContext } from './Context/QuoteConfirmedContext';

const useStyles = makeStyles((theme) => ({
  Diagnosescard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
    background: '#fff',
  },
  clock: {
    marginTop: 30,
    width: 60,
    height: 60,
    flexShrink: 0,
    color: '#5B7B28',
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  li: {
    color: '#005959',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#fff',
  },
  whiteCard: {
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    flex: 1,
  },
  mobileRoot: {
    width: '100%',
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },
}));

const SuccessPage: React.FC = () => {
  const { QuoteConfirmed } = useContext(QuoteConfirmedContext);
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const getPrice = () => {
    let price = 0;

    const estimate = appointment?.attributes.estimate;

    if (estimate) {
      if (!appointment?.attributes.member_job) {
        price += estimate.total_price;
      } else {
        price += estimate.total_member_price;
      }
    }

    if (appointment?.attributes.appointment_type === 'diagnosis')
      price += appointment.attributes.diagnosis_price;

    price = price >= 0 ? price : 0;

    return price.toFixed(2);
  };

  const { appId: appIdParam }: { appId: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncReadAppointment = async () => {
      const app = await getAppointment(String(appIdParam));
      dispatch(setAppointment(app.data));
    };

    asyncReadAppointment();
  }, [appIdParam, dispatch]);

  const classes = useStyles();
  const isSm = useMediaQuery('(max-width: 1229px)');
  const isVerySm = useMediaQuery('(max-width: 370px)');

  const formatDate = (dateStr?: string): string => {
    if (!dateStr) {
      return 'Invalid date format';
    }

    try {
      const [year, month, day] = dateStr.split('-');
      const dateObject = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10)
      );
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const formattedDate = dateObject.toLocaleDateString('en-US', options);
      return formattedDate;
    } catch (error) {
      return 'Invalid date format';
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 80,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <Card
          className={classes.Diagnosescard}
          style={{
            marginTop: 50,
            background: '#F3F3F3',
            width: isSm ? '95%' : 'max-content',
            margin: 'auto',
            paddingLeft: isSm ? 30 : 0,
          }}
          elevation={0}
        >
          <img
            src={checkCircle}
            alt="correct-pic"
            style={{ marginRight: 'auto' }}
          />

          <Typography
            style={{
              marginTop: 30,
              fontSize: isSm ? 32 : 40,
              fontFamily: 'Inter',
              fontWeight: 700,
              marginRight: 'auto',
              maxWidth: 500,
            }}
          >
            Appointment Confirmed
          </Typography>

          {isSm &&
            !appointment?.attributes.fleet_job &&
            !appointment?.attributes.shop_network && (
              <>
                <div
                  style={{
                    background: '#E3F2F9',
                    padding: 20,
                    marginLeft: -30,
                    borderRadius: 12,
                    marginTop: 20,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      color: '#0F3443',
                      fontFamily: 'Inter',
                      textAlign: 'center',
                    }}
                  >
                    Next steps: Download the app. You&apos;ll need it to chat
                    with your technician for your service.
                  </Typography>
                </div>
                <div
                  style={{
                    width: '100%',
                    padding: 20,
                    fontFamily: 'Inter',
                    textAlign: 'center',
                    marginTop: 10,
                    paddingRight: 'auto',
                    marginLeft: -100,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isVerySm ? 'column' : 'row',
                      justifyContent: !isSm ? 'center' : 'left',
                      alignItems: 'center',
                      gap: '2vw',
                      width: 'fit-content',
                    }}
                  >
                    {' '}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10,
                      }}
                    >
                      <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                        <Link href="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907">
                          <Image
                            className={classes.appleIcon}
                            src={AppleDownload}
                          />
                        </Link>
                      </Box>
                      <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                        <Link href="https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp">
                          <Image src={GoogleDownload} />
                        </Link>
                      </Box>
                    </div>
                    <div
                      style={{
                        border: '6px solid #005959',
                        padding: 10,
                        paddingBottom: 2,
                        borderRadius: '12px',
                      }}
                    >
                      {' '}
                      <QRCodeSVG value="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907" />
                    </div>
                  </div>
                </div>
              </>
            )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 'auto',
              marginTop: 20,
            }}
          >
            <img
              src={mechanic}
              alt="mechanic"
              style={{
                color: '#646867',
                margin: 15,
                marginTop: 0,
                marginLeft: 0,
                alignSelf: 'flex-top',
              }}
            />
            <div>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                {appointment?.attributes?.mechanic?.name || 'Houston'}
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  color: '#646867',
                }}
              >
                Mechanic
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 'auto',
              marginTop: 10,
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <img
              src={Tool}
              alt="mechanic"
              style={{
                color: '#646867',
                margin: 15,
                marginTop: 0,
                marginBottom: 10,
                marginLeft: 0,
              }}
            />
            {appointment?.attributes.appointment_type === 'repair' ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: 'Inter',
                    fontWeight: 700,
                  }}
                >
                  Service / Repair
                </Typography>
                <ul
                  style={{
                    marginTop: 10,
                    color: '#646867',
                    fontFamily: 'Inter',
                    paddingLeft: 0,
                    marginLeft: 18,
                    fontSize: 16,
                  }}
                >
                  {appointment?.attributes.services.map((problem, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>{problem}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    marginTop: 10,
                  }}
                >
                  {' '}
                  Diagnosis
                </Typography>
                <ul
                  style={{
                    marginTop: 10,
                    color: '#646867',
                    fontFamily: 'Inter',
                    paddingLeft: 0,
                    marginLeft: 18,
                    fontSize: 16,
                  }}
                >
                  {QuoteConfirmed.service.diagnostic_problems.map(
                    (problem, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>{problem}</li>
                    )
                  )}
                </ul>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 'auto',
            }}
          >
            <img
              src={calendar}
              alt="mechanic"
              style={{
                color: '#646867',
                margin: 15,
                marginTop: 0,
                marginLeft: 0,
              }}
            />
            <Typography
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: 700,
              }}
            >
              {formatDate(appointment?.attributes.appointment_day)}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 'auto',
              marginTop: 10,
            }}
          >
            <img
              src={clock}
              alt="mechanic"
              style={{
                color: '#646867',
                margin: 15,
                marginTop: 0,
                marginLeft: 0,
              }}
            />
            <Typography
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: 700,
              }}
            >
              {appointment?.attributes.appointment_time}
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 'auto',
              marginTop: 10,
            }}
          >
            <img
              src={location}
              alt="mechanic"
              style={{
                color: '#646867',
                margin: 15,
                marginTop: 0,
                marginLeft: 0,
              }}
            />
            <Typography
              style={{
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: 700,
              }}
            >
              {appointment?.attributes.exact_address}
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: 'auto',
            }}
          >
            <img
              src={coin}
              alt="mechanic"
              style={{
                color: '#646867',
                margin: 15,
                marginTop: 0,
                marginLeft: 0,
                alignSelf: 'flex-top',
              }}
            />
            <div>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                  fontWeight: 700,
                }}
              >
                ${getPrice() || ''}
              </Typography>
              {!appointment?.attributes.shop_network && (
                <Typography
                  style={{
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    color: '#646867',
                  }}
                >
                  to be charged at time of service
                </Typography>
              )}
            </div>
          </div>
        </Card>
      </div>

      {!isSm &&
      !appointment?.attributes.fleet_job &&
      !appointment?.attributes.shop_network ? (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              style={{
                padding: 20,
                background: '#005959',
                maxWidth: 288,
                position: 'sticky',
              }}
            >
              <Typography
                style={{
                  color: '#EDF5E0',
                  fontFamily: 'Inter',
                  fontSize: 16,
                  fontWeight: 700,
                }}
              >
                Next steps: Download the app. You&apos;ll need it to chat with
                your technician for your service.
              </Typography>
            </Card>
            <img
              src={SuccessImage}
              alt="nothing"
              height={500}
              width={300}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: -85,
              }}
            />
            <div
              style={{
                width: '95%',
                padding: 20,
                fontFamily: 'Inter',
                textAlign: 'center',
                marginTop: 30,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 20,
                }}
              >
                {' '}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 10,
                  }}
                >
                  <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                    <Link href="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907">
                      <Image
                        className={classes.appleIcon}
                        src={AppleDownload}
                      />
                    </Link>
                  </Box>
                  <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                    <Link href="https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp">
                      <Image src={GoogleDownload} />
                    </Link>
                  </Box>
                </div>
                <div
                  style={{
                    border: '6px solid #005959',
                    padding: 10,
                    paddingBottom: 2,
                    borderRadius: '12px',
                  }}
                >
                  {' '}
                  <QRCodeSVG value="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SuccessPage;
