import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import logo from '../../assets/logo.svg';
import phone from '../../assets/badges/phone-2.svg';

const useStyles = makeStyles({
  root: { background: '#fff' },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    paddingTop: '15px',
  },
  btnCall: {
    width: '100px',
    height: '42px',
    borderRadius: '32px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
    boxShadow: 'none',
    '& > .MuiButton-label': {
      alignItems: 'center',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#2da379',
    },
  },
  invoice: {
    border: '1px solid #EDEDED',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.07)',
    height: '180px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '10px',
  },
});

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = (props: LayoutProps): React.ReactElement => {
  const classes = useStyles();
  const { children, className } = props;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)}>
      <nav className={classes.nav}>
        <img src={logo} width="170" height={40} alt="logo" />
        {isSm && (
          <Button
            variant="contained"
            className={classes.btnCall}
            color="secondary"
            onClick={() => {
              window.open('tel:469-840-3990');
            }}
          >
            <img
              src={phone}
              width="15"
              height="15"
              alt="phone"
              style={{ marginRight: '4px' }}
            />
            <Typography style={{ fontSize: 13 }}>Call Us</Typography>
          </Button>
        )}
      </nav>
      {children}
    </div>
  );
};

Layout.defaultProps = {
  className: '',
};

export default Layout;
