import React from 'react';

import { Card, Typography, makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    marginBottom: 0,
    color: '#fff',
  },
}));

const EmptyQuote: React.FC = () => {
  const classes = useStyles();

  const isSm = useMediaQuery('(max-width: 1229px)');

  return (
    <Card
      style={{
        minWidth: !isSm ? '364px' : '95%',
        maxWidth: !isSm ? '364px' : '95%',
        height: 'fit-content',
        background: '#fff',
        borderRadius: 8,
        marginRight: 'auto',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
      }}
      elevation={0}
    >
      <div className={classes.greenCard}>
        <Typography
          variant="body2"
          style={{
            fontSize: isSm ? '0.5rem' : 'inherit',
            fontFamily: 'Inter',
            margin: !isSm ? 5 : 0,
            fontWeight: 550,
          }}
        >
          Calculating Your Quote
        </Typography>

        <Typography
          variant="body2"
          style={{
            alignSelf: 'flex-end',
            fontSize: '32px',
            fontFamily: 'Inter',
            borderRadius: '5px',
            padding: 4,
          }}
        >
          $ 0.00
        </Typography>
      </div>
    </Card>
  );
};

export default EmptyQuote;
