import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { GOOGLE_API_KEY } from 'src/utils/config';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from 'react-google-places-autocomplete';

interface GoogleAddressInputWithStatusProps {
  className?: string;
  value?: string;
  start?: React.ReactNode;
  valueChanged?: (v: string) => void;
  zipCodeChanged?: (zipCode: string) => void;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
    alignItems: 'center',

    borderRadius: 5,
    border: '2px solid #D8D8D8',
    background: theme.palette.common?.white,
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,

    '& .google-places-autocomplete': {
      flexGrow: 1,
      height: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      '& input': {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 17,
        lineHeight: '21px',
        color: '#2A2D3C',
        width: '100%',
        border: 'none',
        outline: 'none',
      },
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  checked: {
    height: '100%',
    fontSize: 25,
    width: 30,
    maxHeight: 55,
  },
  start: {
    background: 'transparent',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    maxHeight: 55,
  },
  googlecontainer: {
    padding: 10,
  },
  googlesuggestion: {
    padding: 5,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#e5e5e5',
    },
  },
}));

/**
 * Component to display the icon
 *
 * @param GoogleAddressInputWithStatusProps props
 */
const GoogleAddressInputWithStatus = (
  props: GoogleAddressInputWithStatusProps
): ReactElement => {
  const { className, valueChanged, value, start, zipCodeChanged } = props;
  const [zipError, setZipError] = useState<string | unknown>('');

  const classes = useStyles();

  const handleChange = async (newAddr: string) => {
    if (valueChanged) {
      valueChanged(newAddr);
    }

    // Use the geocodeByAddress function to get details about the selected address
    try {
      const results = await geocodeByAddress(newAddr);
      if (results && results.length > 0) {
        const addressComponents = results[0]
          .address_components as AddressComponent[];
        const zipCodeComponent = addressComponents.find((component) =>
          component.types.includes('postal_code')
        );

        if (zipCodeComponent) {
          const zipCode = zipCodeComponent.long_name;

          // Call the zipCodeChanged callback with the ZIP code
          if (zipCodeChanged) {
            zipCodeChanged(zipCode);
          }
        }
      }
    } catch (error) {
      setZipError(error);
    }
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={clsx(
        classes.formControl,
        classes.root,
        className,
        start && 'with-start-icon',
        value && 'input-checked'
      )}
    >
      {start && <Box className={classes.start}>{start}</Box>}
      <GooglePlacesAutocomplete
        onSelect={(address) => handleChange(address.description)}
        apiKey={GOOGLE_API_KEY}
        initialValue={value}
        debounce={300}
        suggestionsClassNames={{
          container: classes.googlecontainer,
          suggestion: classes.googlesuggestion,
        }}
      />
    </FormControl>
  );
};

GoogleAddressInputWithStatus.defaultProps = {
  className: '',
  value: '',
  valueChanged: undefined,
  start: undefined,
  zipCodeChanged: undefined,
};

export default GoogleAddressInputWithStatus;
