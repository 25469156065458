import React, { ChangeEvent, ReactElement, useState, useContext } from 'react';
import clsx from 'clsx';
import { ButtonForward } from 'src/components/atoms';
import { makeStyles, Typography } from '@material-ui/core';
import { getHappyCustomer } from 'src/api/quote';
import { ResponseAuth, ResponseSignin } from 'src/types';
import { getToken, signIn } from 'src/api/auth';
import { useDispatch } from 'react-redux';
import { setAuthCode, setAuthToken } from 'src/store/actions';
import { QuoteContext } from 'src/views/Quote/QuoteContext';

interface ZipcodeQuoteProps {
  className?: string;
  onGetQuote?: (payload: { zip?: string; customer?: number }) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '230px',
    [theme.breakpoints.up('md')]: {
      width: '320px',
    },
    '& form > *': {
      marginTop: '8px',
    },
    '& input': {
      color: '#A2A1A8',
      fontFamily: 'Inter',
      fontWeight: 500,
    },
  },
  inputZip: {
    height: '100%',
    color: '#A2A1A8',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 20,
    background: 'transparent',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  ButtonForward: {
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  customer: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '22px',
    marginTop: '4px',
  },
  container: {
    position: 'relative',
    borderRadius: 31,
    height: '45px',
    background: '#ECE9F1',
    paddingLeft: '20px',
  },
}));

const ZipcodeQuote = ({
  className,
  onGetQuote,
  ...rest
}: ZipcodeQuoteProps): ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [customer, setCustomer] = useState(0);
  const [zip, setZip] = useState('');
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [loginPhone, setLoginPhone] = useState<string>('');
  const [codeSent, setCodeSent] = useState(false);
  const [loginCode, setCode] = useState<string>('');
  const [loginProcessing, setLoginProcessing] = useState(false);
  const [codeError, setCodeError] = useState<boolean>(false);

  const { car, handleSetCar, setIsLoggedIn, handleSetContact } = useContext(
    QuoteContext
  );

  const [processing, setProcessing] = useState(false);

  const isReadyToQuote = !!zip && zip.length === 5;

  const handleChange = async (evt: ChangeEvent<{ value: string }>) => {
    const code = evt.target.value as string;
    setZip(code);

    setProcessing(true);
    if (code.length === 5) {
      try {
        const happyCustomer = await getHappyCustomer(code);
        setCustomer((happyCustomer && happyCustomer['times-used']) || 0);
      } catch (error) {
        setCustomer(0);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'quote_noservice',
          zip: code,
        });
      }
    } else {
      setCustomer(0);
    }
    setProcessing(false);
  };

  const handleGetQuote = () => {
    if (zip && onGetQuote) {
      onGetQuote({ zip, customer });
    }
  };

  const handleForward = async () => {
    setLoginProcessing(true);

    if (!codeSent) {
      const response: ResponseAuth = await getToken({
        user: {
          phone: loginPhone,
        },
      });

      if (response && response.status === 'Code sent') {
        dispatch(setAuthCode(response.code));

        setCodeSent(true);
      } else {
        setCodeError(true);
      }
    } else {
      const response: ResponseSignin = await signIn({
        user: { code: loginCode },
      });

      if (
        response &&
        response.auth_token &&
        response.user &&
        response.user.id
      ) {
        dispatch(
          setAuthToken(
            response.auth_token,
            response.user.id,
            response.user.email
          )
        );

        if (onGetQuote && response.user.zip) {
          if (response.user.car) {
            handleSetCar({
              ...car,
              attributes: {
                id: response.user.car.id,
                year: response.user.car.year,
                make: response.user.car.make,
                model: response.user.car.model,
                engine_size: response.user.car.engine_size,
                mileage: response.user.car.mileage,
                vin: response.user.car.vin,
                last_oil_change: response.user.car.last_oil_change,
              },
            });

            handleSetContact({
              name: response.user.name,
              email: response.user.email,
              phone: response.user.phone,
            });
          }

          setIsLoggedIn(true);

          onGetQuote({ zip: response.user.zip, customer });
        }
      } else {
        setCodeError(true);
      }
    }

    setLoginProcessing(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {!existingCustomer && (
        <>
          <div style={{ textAlign: 'center' }}>
            <h3>Where is your vehicle located?</h3>
          </div>
          <div className={classes.container}>
            <input
              type="text"
              pattern="[0-9]{5}"
              inputMode="numeric"
              className={classes.inputZip}
              placeholder="Zip"
              value={zip}
              onChange={handleChange}
            />
            <ButtonForward
              title="Continue"
              rounded
              size="medium"
              className={classes.ButtonForward}
              onClickHandler={handleGetQuote}
              disabled={!isReadyToQuote}
              processing={processing}
            />
          </div>

          <div style={{ textAlign: 'center', marginTop: '60px' }}>
            Existing customer?
            <div style={{ margin: '5px' }}>
              <ButtonForward
                color="default"
                noIcon
                rounded
                size="medium"
                onClickHandler={() => setExistingCustomer(true)}
                title="Continue with my account"
              />
            </div>
          </div>
        </>
      )}
      {existingCustomer && (
        <>
          {!codeSent && (
            <div className={classes.container}>
              <input
                className={classes.inputZip}
                placeholder="Phone number"
                value={loginPhone}
                onChange={(e) => setLoginPhone(e.target.value)}
              />
              <ButtonForward
                rounded
                title="Enter"
                size="medium"
                className={classes.ButtonForward}
                onClickHandler={handleForward}
                disabled={loginPhone === ''}
                processing={loginProcessing}
              />
            </div>
          )}
          {codeSent && !codeError && (
            <div style={{ margin: '10px 0' }}>
              <div className={classes.container}>
                <input
                  className={classes.inputZip}
                  placeholder="Enter code"
                  value={loginCode}
                  onChange={(e) => setCode(e.target.value)}
                />
                <ButtonForward
                  rounded
                  size="medium"
                  className={classes.ButtonForward}
                  onClickHandler={handleForward}
                  disabled={loginCode === ''}
                  processing={loginProcessing}
                />
              </div>
              <Typography style={{ textAlign: 'center', marginTop: '5px' }}>
                We texted a code to verify it&apos;s you. Enter that code here.
              </Typography>
            </div>
          )}
          {codeError && (
            <Typography
              style={{ textAlign: 'center', margin: '10px', color: '#ff0000' }}
            >
              We couldn&apos;t find an account to log you in. Please continue
              with zip code.
            </Typography>
          )}
          <div style={{ textAlign: 'center', margin: '10px' }}>
            <div style={{ marginTop: '5px' }}>
              <ButtonForward
                color="default"
                noIcon
                rounded
                size="medium"
                onClickHandler={() => {
                  setExistingCustomer(false);
                  setCodeError(false);
                  setCode('');
                  setLoginPhone('');
                }}
                title="Continue with zip code"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ZipcodeQuote.defaultProps = {
  className: undefined,
  onGetQuote: undefined,
};

export default ZipcodeQuote;
