import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0',
  },
  divider: {
    flexGrow: 1,
    background: 'rgba(0, 0, 0, 0.3)',
    height: '1px',
    margin: '0 1rem',
  },
  text: {
    margin: '0 0.5rem',
  },
});

const LineWithOr: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <Typography variant="body2" className={classes.text}>
        OR
      </Typography>
      <Divider className={classes.divider} />
    </div>
  );
};

export default LineWithOr;
